import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { VixoAPI } from "./settings";
function APIErrorComponent({ APIResponse }) {
  const BillingURL=`${VixoAPI.DashBoard}Billing`;
  const handleAddCreditClick = () => {

    window.open(`${VixoAPI.DashBoard}Billing`, '_blank');
  };

  const [error, setError] = useState(null);

  useEffect(() => {
    if (APIResponse && APIResponse !== null && APIResponse.data.statusCode === 507) {
      setError(
        <div>
          {APIResponse.data.message}
          <Link  to={BillingURL}>
            Click to Add Credit
          </Link>
        </div>
      );
    } else if (APIResponse && APIResponse !== null) {
      setError(<p>{APIResponse.data.message}</p>);
    } else if (APIResponse === null) {
      setError(null);
    }
  }, [APIResponse]);




  return (


    <>
      {error && error != null ? (
        <div
          className="alert alert-danger"
          style={{ color: "red" }}>
          {error}

        </div>
      ) : ("")}
    </>

  );
}

export default APIErrorComponent