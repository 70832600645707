import React, { useState } from "react";
import { VixoAPI } from "../../settings";
import axios from "axios";
import ErrorComponent from "../../ErrorComponent";
import Spinner from "../../Spinner";
import "./Billingmodule.css";
import { ToastContainer, toast } from "react-toastify";
import { NumericFormat } from "react-number-format";
import { APIHeader, ExpireToken, HasLoggedInUser } from "../../APICallWrapper";
import { useNavigate } from "react-router-dom";

const PullPaymentACH = ({ creditAdded, OrganizationID }) => {
  const [errorType, setErrorType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [organization, setOrganization] = useState({});
  const navigate=useNavigate();
  const [achdata, setAchData] = useState({
    AccountNumber: "",
    RoutingNumber: "",
    EntityName: "",
    AccountType: "",
    amount: "",
  });

  const handleAchDetailsChange = (name, value) => {
    setAchData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  function handleAddAchCredit() {
    setLoading(true);
    const data = {
      AccountNumber: achdata.AccountNumber,
      RoutingNumber: achdata.RoutingNumber,
      EntityName: achdata.EntityName,
      AccountType: achdata.AccountType,
      amount: achdata.amount,
    };
    if(HasLoggedInUser())
      {
        const config=APIHeader();
    const OrgAPIPath = `${VixoAPI.BillingAPI}PullPaymetnFromACH?OrganizationID=${OrganizationID}`;
    axios
      .put(OrgAPIPath, data, {headers:config.headers})
      .then((response) => {
        if (response.data.statusCode === 200) {
          toast.success(response.data.message);
          creditAdded(OrganizationID);
          setErrorType(null);
          setAchData({
            AccountNumber: "",
            RoutingNumber: "",
            EntityName: "",
            AccountType: "",
            amount: "",
          });
        } else {
          setErrorType(response.data.message);
        }
      })
      .catch((error) => {
        setErrorType(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
    }
    else{
      ExpireToken(navigate)
    }
  }

  return (
    <div>
      {errorType && <ErrorComponent errorType={errorType} />}
      <Spinner show={loading} />
      <ToastContainer />
      <p className="mt-5 mb-5"></p>
      <h4 className="mt-4 mb-4">Pay With ACH</h4>
      <div className="col-md-12">
        <div className="form-group mt-2">
          <NumericFormat
            allowNegative={false}
            decimalScale={2}
            placeholder="Amount"
            value={achdata.amount}
            onValueChange={(values) =>
              handleAchDetailsChange("amount", values.value)
            }
            fixedDecimalScale
            className="form-control"
            style={{
              width: "100%",
              height: "51px",
              border: "1px solid #c4cdd5",
              borderRadius: "inherit",
            }}
          />
        </div>
        <div className="form-group mt-2">
          <NumericFormat
            allowNegative={false}
            placeholder="Account Number"
            value={achdata.AccountNumber}
            onValueChange={(values) =>
              handleAchDetailsChange("AccountNumber", values.value)
            }
            className="form-control"
            style={{
              width: "100%",
              height: "51px",
              border: "1px solid #c4cdd5",
              borderRadius: "inherit",
            }}
          />
        </div>
        <div className="form-group mt-2">
          <NumericFormat
            allowNegative={false}
            placeholder="Routing Number"
            value={achdata.RoutingNumber}
            onValueChange={(values) =>
              handleAchDetailsChange("RoutingNumber", values.value)
            }
            className="form-control"
            style={{
              width: "100%",
              height: "51px",
              border: "1px solid #c4cdd5",
              borderRadius: "inherit",
            }}
          />
        </div>
        <div className="form-group mt-2">
          <input
            type="text"
            name="AccountType"
            value={achdata.AccountType}
            onChange={(e) =>
              handleAchDetailsChange(e.target.name, e.target.value)
            }
            className="form-control"
            placeholder="Account Type"
            style={{
              width: "100%",
              height: "51px",
              border: "1px solid #c4cdd5",
              borderRadius: "inherit",
            }}
          />
        </div>
        <div className="form-group mt-2">
          <input
            type="text"
            name="EntityName"
            value={achdata.EntityName}
            onChange={(e) =>
              handleAchDetailsChange(e.target.name, e.target.value)
            }
            className="form-control"
            placeholder="Entity Name"
            style={{
              width: "100%",
              height: "51px",
              border: "1px solid #c4cdd5",
              borderRadius: "inherit",
            }}
          />
        </div>
      </div>

      <button
        id="payment-button"
        className="btn w-100 mt-3 btn-primary text-white"
        onClick={handleAddAchCredit}
      >
        Pay With ACH
      </button>

      <div
        className="card-footer py-3 bg-transparent error-container"
        style={{ color: "red" }}
      >
        {errorType && (
          <div className="" role="alert">
            {errorType}
          </div>
        )}
      </div>
    </div>
  );
};

export default PullPaymentACH;
