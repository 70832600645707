import React, { useState, useEffect } from "react";
import TopnavBar from "../Header/TopnavBar";
import OrganizationSettingBar from "../Header/OrganizationSettingBar";
import { faGears } from "@fortawesome/free-solid-svg-icons";
import {
  ValidatePhoneGlobal,
  ValidateEmailGlobal,
} from "../Architecture/Validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Breadcrumbs from "../HomeVixo/Breadcrumbs";
import { VixoAPI } from "../../settings";
import { APIHeader, ExpireToken, HasLoggedInUser } from "../../APICallWrapper";
import axios from "axios";
import ErrorComponent from "../../ErrorComponent";
import Spinner from "../../Spinner";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";

const General = () => {
  const navigate = useNavigate();

  const [userInfo, setUserInfo] = useState({
    id: "",
    organizationId: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    emailIsConfirmed: true,
    phoneIsConfirmed: true,
    isActive: true,
    isAdmin: true,
    organizationName: "",
  });

  const [errorType, setErrorType] = useState(null);
  const [oldEmail, setOldEmail] = useState("");

  const [phoneIsValid, setPhoneIsValid] = useState(true);
  const [emailIsValid, setEmailIsValid] = useState(true);
  const [allFieldsFilled, setAllFieldsFilled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    LoadData();
  }, []);

  const LoadData = () => {
    const loggedInUser = localStorage.getItem("User");
    var userObject = JSON.parse(loggedInUser);

    if (userObject && userObject !== null) {
      setUserInfo(userObject);
      setOldEmail(userObject.email);
    } else {
      navigate("/");
    }
  };

  const handleUseryInfoChange = (e) => {
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value,
    });
  };

  const validatePhone = (phone) => {
    const isValid = ValidatePhoneGlobal(phone);
    return isValid;
  };

  const validateEmail = (email) => {
    const isValid = ValidateEmailGlobal(email);
    return isValid;
  };

  const formatPhoneNumber = (phoneNumber) => {
    return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  };

  const unformatPhoneNumber = (formattedPhoneNumber) => {
    return formattedPhoneNumber.replace(/\D/g, "");
  };

  useEffect(() => {
    if (
      userInfo &&
      userInfo.firstName &&
      userInfo.lastName &&
      userInfo.email &&
      userInfo.phone &&
      userInfo.firstName.trim() !== "" &&
      userInfo.lastName.trim() !== "" &&
      userInfo.email.trim() !== "" &&
      userInfo.phone.trim() !== ""
    ) {
      setAllFieldsFilled(true);
    } else {
      setAllFieldsFilled(false);
    }
  }, [userInfo]);

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setUserInfo({
      ...userInfo,
      email: value,
    });
    if (userInfo.email !== value) {
      setOldEmail(userInfo.email);
    }

    setEmailIsValid(validateEmail(value));
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    setUserInfo({
      ...userInfo,
      phone: value,
    });
    setPhoneIsValid(validatePhone(value));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      id: userInfo.id,
      organizationId: userInfo.organizationId,
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      email: userInfo.email,
      phone: userInfo.phone,
      emailIsConfirmed: userInfo.emailIsConfirmed,
      phoneIsConfirmed: userInfo.phoneIsConfirmed,
      isActive: userInfo.isActive,
      isAdmin: userInfo.isAdmin,
      organizationName: userInfo.organizationName,
      OldEmail: oldEmail,
    };

    if(HasLoggedInUser())
      {
        const config= APIHeader();
    try {
      const UserAPISave = VixoAPI.UserAPI + "update?Id=" + userInfo.id;
      setLoading(true);

      const response = await axios.put(UserAPISave, data,{
       headers:config.headers
      });

      if (response.data.statusCode === 200) {
        const updatedUserObject = {
          ...userInfo,
          id: userInfo.id,
          organizationId: userInfo.organizationId,
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
          name: `${userInfo.firstName} ${userInfo.lastName}`,
          email: userInfo.email,
          phone: userInfo.phone,
          emailIsConfirmed: userInfo.emailIsConfirmed,
          phoneIsConfirmed: userInfo.phoneIsConfirmed,
          isActive: userInfo.isActive,
          isAdmin: userInfo.isAdmin,
          organizationName: userInfo.organizationName,
          OldEmail: oldEmail,
        };
        localStorage.setItem("User", JSON.stringify(updatedUserObject));

        setData(response.data.message, "success");

        showToast("Changes saved successfully", "success");
      } else {
        setData(response.data.message, "error");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error during update:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  }
  else{
    ExpireToken(navigate);
  }
  };

  function showToast(message, type) {
    if (type === "success") {
      toast.success(message);
    } else {
      toast.error(message);
    }
  }

  function showToast(message, type) {
    if (type === "success") {
      toast.success(message);
    } else {
      toast.error(message);
    }
  }
  const setData = (message, type) => {
    if (type === "success") {
      toast.success(message);
      setErrorType(null);
    } else {
      toast.error(message);
      setErrorType(message);
    }
  };

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  const sidebarStyle = {
    marginLeft: collapsed ? "" : "250px",
  };

  return (
    <>
      <ErrorComponent errorType={errorType} />
      <Spinner show={loading} />
      <TopnavBar />
      <OrganizationSettingBar
        collapsed={collapsed}
        toggleSidebar={toggleSidebar}
      />

      <main className="Gen" style={sidebarStyle}>
        <div className="container-fluid  p-3 bg-white col-lg-12 col-sm-12">
          <div className="row">
            <div className="col-lg-12 mx-auto">
              <div className="card">
                <div className="card-header py-3 bg-transparent">
                  <h5 className="mb-0 d-inline">Setting</h5>
                  <FontAwesomeIcon
                    icon={faGears}
                    className="fa-2x "
                    style={{ width: "25px" }}
                  />
                  <Breadcrumbs />
                </div>
                <div className=" py-3 px-3 bg-transparent">
                  <h5 className="mb-0 d-inline">User Profile</h5>
                </div>
                <div className="card-header py-3 bg-transparent">
                  <fieldset>
                    <legend>Personal Information</legend>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="firstName" className="form-label">
                            First Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="firstName"
                            placeholder="Enter your First Name"
                            name="firstName"
                            value={userInfo.firstName}
                            onChange={handleUseryInfoChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="lastName" className="form-label">
                            Last Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="lastName"
                            placeholder="Enter your Last Name"
                            name="lastName"
                            value={userInfo.lastName}
                            onChange={handleUseryInfoChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="email" className="form-label">
                            Email
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              !emailIsValid ? "is-invalid" : ""
                            }`}
                            id="email"
                            placeholder="example@vixolink.com"
                            name="email"
                            value={userInfo.email}
                            onChange={handleEmailChange}
                            disabled
                          />
                          {!emailIsValid && (
                            <span className="error">
                              Enter a valid email address.
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="phone" className="form-label">
                            Phone
                          </label>
                          <InputMask
                            mask="(999) 999-9999"
                            type="tel"
                            name="phone"
                            className={`form-control ${!phoneIsValid ? "is-invalid" : ""}`}
                            id="phone"
                            placeholder="Phone"
                            value={formatPhoneNumber(userInfo.phone)}
                            onChange={handlePhoneChange}
                            onInput={(e) => {
                              const unformattedValue = unformatPhoneNumber(
                                e.target.value
                              );
                              if (unformattedValue.length > 10) {
                                e.target.value = formatPhoneNumber(
                                  unformattedValue
                                ).slice(0, 14);
                              }
                            }}
                          />

                          {!phoneIsValid && (
                            <span className="error">
                              Enter a valid phone number exactly 10 digits.
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </fieldset>

                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group float-end">
                          <br />
                          <div className="col-md-12 container text-center">
                            <button
                              type="submit"
                              className="btn btn-info"
                              disabled={
                                !phoneIsValid ||
                                !emailIsValid ||
                                !allFieldsFilled
                              }
                              style={{
                                borderRadius: "20px",
                                color: "White",
                                fontWeight: "500",
                              }}
                            >
                              Save Changes
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default General;
