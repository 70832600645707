import React, { useState } from "react";
import Sidebar from "../Header/SideBar";
import Breadcrumbs from "./Breadcrumbs";

function AddAccount() {

  const [collapsed, setCollapsed] = useState(false);
  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };
  const sidebarStyle = {
    width: collapsed ? "100%" : "84%",
    marginLeft: collapsed ? "" : "250px", 
  
  };
  return (
    <>
      {/* <Sidebar collapsed={collapsed} toggleSidebar={toggleSidebar} /> */}
      <main className="Invitee" style={sidebarStyle}>
        <div
          className="p-3 bg-white col-lg-12 col-sm-12"
          style={{ marginLeft: "-1rem", marginTop: "-1rem" }}
        >
          <div class="row">
            <div class="col-md-12  mb-3 col-sm-12">
              <div class="card">
                <div class="card-header py-3 bg-transparent">
                  <h5 class="">Add an Account</h5>
                  <Breadcrumbs />
                </div>
                <div class="card-body">
                  <div class="border p-3 rounded">
                    <form class="row g-3">
                    <div class="col-12">
                        <label class="form-label">Institute Name</label>
                        <select class="form-select">
                          <option>Bank of America</option>
                          <option>Bank of Colombia</option>
                          <option>American Express</option>
                          <option>JPMorgan Chase</option>
                        </select>
                      </div>
                      <div class="col-12">
                        <input  
                          type="text"
                          class="form-control"
                          placeholder="Account title"
                        />
                      </div>
                      <div class="col-12">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Account Type"
                        />
                      </div>
                      <div class="col-12">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Currency"
                        />
                      </div>
                      <div class="col-12">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Owner Name"
                        />
                      </div>
                      <div class="col-12">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Owner Address"
                        />
                      </div>
                      <div class="col-12">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Routing Number"
                        />
                      </div>
                      <div class="col-12">
                        <div class="row g-3">
                          <div class="col-lg-12">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Account Number"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-12  ">
                        <button className="    btn-transparent  float-end" style={{ padding: '10px 20px', marginRight: '10px'}}>
                        Cancel
                        </button>

                        <button className="  float-end  btn btn-default btn-transparent " style={{ padding: '10px 20px', marginRight: '10px'}} >
                          Add Account
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default AddAccount;
