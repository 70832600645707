import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { VixoAPI } from "../../settings";
import Spinner from "../../Spinner";
import ErrorComponent from "../../ErrorComponent";
const SupportToken = () => {
  const [loading, setLoading] = useState(false);
  const [errorType, setErrorType] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const supportToken = searchParams.get("SupportToken");
    if (supportToken) {
      loginBySupportToken(supportToken);
    }
  }, [location.search]);
  const loginBySupportToken = async (supportToken) => {
    var SupportToken = encodeURIComponent(supportToken);
    const AuthApiPath =
      VixoAPI.AuthenticationAPI +
      "loginBySupportToken?SupportToken=" +
      SupportToken;
    try {
      setLoading(true);
      const response = await axios.post(AuthApiPath);
      const responseData = response;
      if (responseData.status === 200) {
        if (responseData.data.data && responseData.data.data.organizationId) {
          
          localStorage.setItem("User", JSON.stringify(responseData.data.data));

          navigate("/Home");
        } else {
          setErrorType("User could not be Loaded. Vixo Team is Working on it.");
        }
      } else {
        setLoading(false);
        setErrorType(responseData.message);
        setIsErrorModalOpen(true);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      {errorType && <ErrorComponent errorType={errorType} />}
      {isErrorModalOpen && (
        <div
          className="modal"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}>
          <div className="modal-dialog " role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" >Error</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setIsErrorModalOpen(false)}
                >
                </button>
              </div>
              <div className="modal-body">
                <p style={{ color: "red" }}>{errorType}</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setIsErrorModalOpen(false)}
                  style={{ background: "rgb(11, 94, 215)" }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <Spinner show={loading} />
      Redirecting to Home, Please wait...
    </>
  );
};

export default SupportToken;
