import React, { useState, useEffect } from "react";
import { VixoAPI } from "../../settings";
import { APIHeader, ExpireToken, HasLoggedInUser } from "../../APICallWrapper";
import axios from "axios";
import ErrorComponent from "../../ErrorComponent";
import Spinner from "../../Spinner";
import "./Billingmodule.css";
import { ToastContainer, toast } from "react-toastify";
import { NumericFormat } from "react-number-format";
import { useNavigate } from "react-router-dom";

const AddCredit = ({ creditAdded }) => {
  const [amount, setAmount] = useState("100.00");
  const [organization, setOrganization] = useState({});
  const [errorType, setErrorType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [IsClearentSDKReady, SetIsClearentSDKReady] = useState(false);
  const [IsClearentSDKReadyState, SetIsClearentSDKReadyState] = useState(0);

  function initializeClearent() {
    var vixoAPI = VixoAPI;
    setLoading(true);
    const clearentObject = window.ClearentSDK;
    if (clearentObject) {
      clearentObject.init({
        baseUrl: vixoAPI.ClearantAPI,
        pk: vixoAPI.ClearntPublicKey,
      });
      setLoading(false);
    }
  }
const navigate =  useNavigate();
  useEffect(() => {
    setOrganizationData();
    SetIsClearentSDKReady(false);
  }, []); // Empty

  useEffect(() => {
    if (!IsClearentSDKReady) {
      if (typeof window.ClearentSDK === "undefined") {
        SetIsClearentSDKReady(false);
        var lIsClearentSDKReadyState = IsClearentSDKReadyState;
        lIsClearentSDKReadyState = lIsClearentSDKReadyState + 1;
        SetIsClearentSDKReadyState(lIsClearentSDKReadyState);
      } else {
        SetIsClearentSDKReady(true);
        initializeClearent();
      }
    }
  }, [IsClearentSDKReady, IsClearentSDKReadyState]);

  function LoadOrganization(OrganizationID) {
    if(HasLoggedInUser()){

  
    try {
      setLoading(true);
      const OrgnizationUpdateAPIPath =
        VixoAPI.OrganizationAPI + "getAny?Id=" + OrganizationID;
      axios
        .get(OrgnizationUpdateAPIPath,{
         headers:APIHeader().headers
        })
        .then((response) => {
          var response = response.data;
          setLoading(false);
          if (response.statusCode === 200) {
            var Organization = JSON.parse(JSON.stringify(response.data));
            if (Organization != null) {
              setOrganization(Organization);
            }
          } else {
            setErrorType(response.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }
  else {navigate("/")}
  }
  function setOrganizationData() {
    const LoggedUser = localStorage.getItem("User");
    var userObject = JSON.parse(LoggedUser);
    LoadOrganization(userObject.organizationId);
  }
  
  function AddCredit(
    
   
  ) {
    setLoading(false);
    setLoading(true);
    const data = {
      OrganizationID: organization.id,
      Amount: amount,
    };
    if(HasLoggedInUser())
      {
        const config=APIHeader();
    try {
      const OrgAPIPath = `${VixoAPI.BillingAPI}addCreditFromDefaultPayment`;

      axios
        .post(OrgAPIPath, data,{
         headers:config.headers
        })
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            toast.success(response.message);
            creditAdded(organization.id);
          } else {
            setErrorType(response.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          if(error.response && error.response.status && error.response.status==401)
            {
              ExpireToken(navigate);
            }
        })
        .finally(() => {
          setLoading(false);
        });
      // setLoading(false);
    } catch (error) {
      setErrorType(error.message);
      setLoading(false);
    }
  }
  else{
    ExpireToken(navigate);
  }
  }


  const handleAmountChange = (e) => {
    const value = e.target.value;
    let parsedValue = parseFloat(value);
    if (!isNaN(parsedValue)) {
      parsedValue = parseFloat(parsedValue.toFixed(2));
    }
    setAmount(parsedValue.toString());
  };

  return (
    <div>
      {errorType && <ErrorComponent errorType={errorType} />}
      <Spinner show={loading} />
      <ToastContainer />
      <p className="mt-5 mb-5"></p>
      <h4 className="mt-4 mb-4"> Add Credit Card</h4>
      <div class="col-md-12">
        <NumericFormat
          allowNegative={false}
          decimalScale={2}
          placeholder="100.00"
          value={amount}
          onChange={handleAmountChange}
          fixedDecimalScale
          style={{
            width: "100%",
            height: "51px",
            border: "1px solid #c4cdd5",
            borderRadius: "inherit",
          }}
        />
      </div>

      <button
        id="payment-button"
        className="btn w-100 mt-3 bg-success"
        onClick={AddCredit}
      >
        Add Credit Card
      </button>

      <div
        className="card-footer py-3 bg-transparent error-container"
        style={{ color: "red" }}
      >
        {errorType && (
          <div className="" role="alert">
            {errorType}
          </div>
        )}
      </div>
    </div>
  );
};

export default AddCredit;
