import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { VixoAPI } from "../../../settings";
import axios from "axios";
import { APIHeader, ExpireToken, HasLoggedInUser } from "../../../APICallWrapper";
import { ToastContainer, toast } from "react-toastify";
import InputMask from "react-input-mask";
import Spinner from "../../../Spinner";
import "react-toastify/dist/ReactToastify.css";
import {
  ValidateEmailGlobal,
  ValidatePhoneGlobal,
  ValidateNameGlobal,
} from "../../Architecture/Validation";
import { AdminUserRoleTypes } from "../../../lookup";

const EditUser = ({
  showModal,
  handleModalClose,
  editUser,
  onDeleteUser,
  usersArray,
  setUsers,
}) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPermissionErrorModal, setShowPermissionErrorModal] = useState(
    false
  );
  const [phoneIsValid, setPhoneIsValid] = useState(true);
  const [emailError, setEmailError] = useState("");
  const [sendInvitation, setSendInvitation] = useState(false);
  const [changesMade, setChangesMade] = useState(false);
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [roleError, setRoleError] = useState("");
  const [userRoles, setUserRoles] = useState([]);
  const navigate=useNavigate();
  const [currentUser, setCurrentUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    role: "",
    RoleName: "",
  });

  const validateEmail = (email) => {
    const isValid = ValidateEmailGlobal(email);

    if (!isValid) {
      setEmailError("Enter a valid email address");
    } else {
      setEmailError("");
    }

    return isValid;
  };

  const validatePhone = (phone) => {
    const isValid = ValidatePhoneGlobal(phone);
    if (!isValid) {
      setPhoneIsValid(false);
    } else {
      setPhoneIsValid(true);
    }
    return isValid;
  };

  const validateName = (name) => {
    return ValidateNameGlobal(name);
  };

  const validateRole = (role) => {
    if (role === "0") {
      setRoleError("Role is required");
      return false;
    } else {
      setRoleError("");
      return true;
    }
  };

  const setEditUser = (e) => {
    try {
      const { name, value } = e.target;
      setCurrentUser({
        ...currentUser,
        [name]: value,
      });

      if (name === "email") {
        validateEmail(value);
      }
      if (name === "phone") {
        validatePhone(value);
      }
      if (name === "firstName" || name === "lastName") {
        const isValid = validateName(value);
        if (name === "firstName") {
          setFirstNameError(isValid ? "" : "Enter a valid first name");
        } else {
          setLastNameError(isValid ? "" : "Enter a valid last name");
        }
      }
      if (name === "role") {
        validateRole(value);
      }
    } catch (exception) {
      alert(e.target.value);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();

    try {
      const ForgotAPIPath = VixoAPI.AuthenticationAPI + "forgot";
      setLoading(true);
      axios
        .get(ForgotAPIPath, {
          params: {
            email: currentUser.email,
          },
        })
        .then((response) => {
          var responseData = response.data;
          if (responseData.statusCode === 200) {
            toast.success(responseData.message);
            handleModalClose();
          } else {
            handleModalClose();
          }
        })
        .catch((error) => {
          setLoading(false);

          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (showModal && editUser) {
      setCurrentUser({
        id: editUser.id,
        firstName: editUser.firstName,
        lastName: editUser.lastName,
        email: editUser.email,
        phone: editUser.phone,
        role: editUser.role,
        RoleName: editUser.roleName,
      });
    } else {
      setCurrentUser({
        id: 0,
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        role: "",
        RoleName: "",
      });
    }
  }, [showModal, editUser]);

  useEffect(() => {
    const checkChanges = () => {
      if (
        currentUser.firstName !== editUser?.firstName ||
        currentUser.lastName !== editUser?.lastName ||
        currentUser.email !== editUser?.email ||
        currentUser.phone !== editUser?.phone ||
        currentUser.role !== editUser?.role ||
        sendInvitation === true
      ) {
        setChangesMade(true);
      } else {
        setChangesMade(false);
      }
    };
    checkChanges();
  }, [currentUser, editUser, sendInvitation]);

  useEffect(() => {
    setSendInvitation(false);
  }, [showModal]);

  useEffect(() => {
    if (!showModal) {
      setEmailError("");
    }
  }, [showModal]);

  useEffect(() => {
    setPhoneIsValid(true);
  }, [showModal]);

  useEffect(() => {
    if (!showModal) {
      setFirstNameError("");
    }
  }, [showModal]);

  useEffect(() => {
    if (!showModal) {
      setLastNameError("");
    }
  }, [showModal]);

  useEffect(() => {
    if (!showModal) {
      setRoleError("");
    }
  }, [showModal]);

  const handleUpdateUser = async (e) => {
    e.preventDefault();
    const isEmailValid = validateEmail(currentUser.email);
    const isPhoneValid = validatePhone(currentUser.phone);
    const isFirstNameValid = validateName(currentUser.firstName);
    const isLastNameValid = validateName(currentUser.lastName);
    const isRoleValid = validateRole(currentUser.role);

    if (
      !isEmailValid ||
      !isPhoneValid ||
      !isFirstNameValid ||
      !isLastNameValid ||
      !isRoleValid
    ) {
      if (!isEmailValid) {
        setEmailError("Enter a valid email address");
      } else {
        setEmailError("");
      }
      setPhoneIsValid(isPhoneValid);
      setFirstNameError(isFirstNameValid ? "" : "Enter a valid first name");
      setLastNameError(isLastNameValid ? "" : "Enter a valid last name");
      setRoleError(isRoleValid ? "" : "Role is required");

      return;
    }

    const roleId = Number(currentUser.role);
    const selectedRole = userRoles.find((role) => role.id === roleId);
    if (!selectedRole) {
      setRoleError("Invalid role selected");
      return;
    }
    const data = {
      Id: editUser.id,
      organizationId: editUser.organizationId,
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      email: currentUser.email,
      oldEmail: editUser.email,
      phone: currentUser.phone,
      role: selectedRole&&selectedRole.id,
      roleName: selectedRole&&selectedRole.displayValue,
      organizationName: "",
      phoneIsConfirmed: true,
      emailIsConfirmed: true,
      resendInvite: sendInvitation,
    };

    if(HasLoggedInUser())
      {
        const config=APIHeader();
    try {
      const UserUpdate = VixoAPI.UserAPI + "update?Id=" + editUser.id;
      setLoading(true);
      await axios
        .put(UserUpdate, data, {headers:config.headers})
        .then((response) => {
          var response = response.data;
          console.log("response", response);
          if (response.statusCode === 200) {
            toast.success(response.message);
            setData(response.data);
          } else {
            toast.error(response.message);
            handleModalClose();
          }
        })
        .catch((error) => {
          setLoading(false);
          if(error.response && error.response.status && error.response.status==401)
            {
               ExpireToken(navigate);
            }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      throw error;
    }
  }
  else{
    ExpireToken(navigate);
  }
  };

  function setData(data) {
    handleModalClose(data);
  }

  const handleDeleteUser = async (userToDelete) => {
    if(HasLoggedInUser())
      {
        const config=APIHeader();
    try {
      if (userToDelete.isAdmin) {
        confirmDelete();
      } else {
        const response = await axios.delete(`${VixoAPI.UserAPI}deleteById`, {
          headers:config.headers,
          params: {
            Id: userToDelete.id,
          },
        });
        if (response.data.statusCode === 200) {
          toast.success(response.data.message);
          setUsers((prevUsers) =>
            prevUsers.filter((user) => user.id !== userToDelete.id)
          );
          handleModalClose();
        } else if (response.data.statusCode === 405) {
          toast.error(response.data.message);
          handleModalClose();
        }
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      handleModalClose();
    }
  }
  else{
    ExpireToken(navigate);
  }
  };

  const confirmDelete = () => {
    onDeleteUser(editUser);
    setShowDeleteConfirmation(false);
    handleModalClose();
  };

  async function LoadLookups() {
    try {
      AdminUserRoleTypes()
        .then((result) => {
          setUserRoles(result);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (ex) {
      console.error(ex + " User Role Lookup Call is not successfull");
    }
  }

  useEffect(() => {
    LoadLookups();
  }, []);

  return (
    <>
      <Spinner show={loading} />
      <ToastContainer />
      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton style={{ marginTop: "-4em" }}>
          <Modal.Title className="mx-auto">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center">
                  <span className="m-0">
                    {" "}
                    <img
                      src="assets/images/LogoVixo.png"
                      className="logo-icon  d-block mx-auto"
                      alt="logo icon"
                      style={{
                        width: "46%",
                        display: "flex",
                        marginTop: "3pc",
                        justifyContent: "center",
                        alignItemstems: "center",
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <div className="modal-body" style={{ paddingTop: "0pc" }}>
              <h5 className="text-center">Edit User</h5>
              <div className="mb-3">
                <label htmlFor="role" className="form-label">
                  Role
                </label>
                <select
                  className="form-select"
                  id="role"
                  name="role"
                  value={currentUser.role}
                  onChange={setEditUser}
                >
                  {userRoles &&
                    userRoles.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.displayValue}
                      </option>
                    ))}
                </select>
                {roleError && (
                  <div className="invalid-feedback">{roleError}</div>
                )}
              </div>
              <div className="mb-3 row">
                <div className="col">
                  <label htmlFor="firstName" className="form-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    className={
                      "form-control " + (firstNameError ? "is-invalid" : "")
                    }
                    id="firstName"
                    name="firstName"
                    value={currentUser.firstName}
                    onChange={(e) => {
                      setEditUser(e);
                    }}
                  />
                  {firstNameError && (
                    <div className="invalid-feedback">{firstNameError}</div>
                  )}
                </div>
                <div className="col">
                  <label htmlFor="lastName" className="form-label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className={
                      "form-control " + (lastNameError ? "is-invalid" : "")
                    }
                    id="lastName"
                    name="lastName"
                    value={currentUser.lastName}
                    onChange={(e) => {
                      setEditUser(e);
                    }}
                  />
                  {lastNameError && (
                    <div className="invalid-feedback">{lastNameError}</div>
                  )}
                </div>
              </div>

              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="text"
                  className={"form-control " + (emailError ? "is-invalid" : "")}
                  id="Email"
                  name="email"
                  value={currentUser.email}
                  onChange={(e) => {
                    setEditUser(e);
                  }}
                />
                {emailError && (
                  <div className="invalid-feedback">{emailError}</div>
                )}
              </div>

              <div className="mb-3">
                <label htmlFor="phone" className="form-label">
                  Phone
                </label>
                <InputMask
                  mask="(999) 999-9999"
                  type="tel"
                  className={
                    "form-control " + (phoneIsValid ? "" : "is-invalid")
                  }
                  id="phone"
                  placeholder="+1 123 1234567"
                  name="phone"
                  value={currentUser.phone}
                  onChange={(e) => {
                    setEditUser(e);
                    validatePhone(e.target.value);
                  }}
                />
                {!phoneIsValid && (
                  <div className="invalid-feedback">
                    Please enter a valid phone number.
                  </div>
                )}
              </div>

              <div className="form-check mb-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="inviteCheckbox"
                  checked={sendInvitation}
                  onChange={(e) => setSendInvitation(e.target.checked)}
                />
                <label className="form-check-label" htmlFor="inviteCheckbox">
                  Send Invitation
                </label>
              </div>
              {editUser ? (
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Status:
                  </label>
                  <span>
                    <FontAwesomeIcon
                      className="p-1"
                      style={{
                        backgroundColor: "#fcb43e",
                        color: "white",
                        fontSize: "20px",
                        borderRadius: "50%",
                        height: "14px",
                        width: "14px",
                      }}
                      // icon={faLink}
                    />
                  </span>
                  <span style={{ marginLeft: "2px" }}>{editUser.status}</span>
                </div>
              ) : (
                ""
              )}
              <div className="text-center">
                <button
                  type="button"
                  className="btn btn-info mx-2"
                  onClick={handleUpdateUser}
                  disabled={!changesMade}
                  style={{
                    borderRadius: "20px",
                    color: "White",
                    fontWeight: "500",
                  }}
                >
                  &nbsp;&nbsp;&nbsp; Update &nbsp;&nbsp;&nbsp;
                </button>
                <button
                  type="button"
                  className="btn btn-warning mx-2"
                  onClick={handleResetPassword}
                  style={{
                    borderRadius: "20px",
                    color: "White",
                    fontWeight: "500",
                  }}
                >
                  <Link
                    to=""
                    style={{ color: "white", textDecoration: "none" }}
                  >
                    Reset Password
                  </Link>
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => handleDeleteUser(currentUser)}
                  style={{
                    borderRadius: "20px",
                    color: "White",
                    fontWeight: "500",
                  }}
                >
                  &nbsp;&nbsp; Delete User &nbsp;&nbsp;
                </button>
              </div>
            </div>
          </p>
        </Modal.Body>
      </Modal>

      <Modal
        show={showDeleteConfirmation}
        onHide={() => setShowDeleteConfirmation(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this user?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowDeleteConfirmation(false)}
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* show error modal  */}

      <Modal
        show={showPermissionErrorModal}
        onHide={() => setShowPermissionErrorModal(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Permission Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>You don't have permission to delete this user.</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => setShowPermissionErrorModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditUser;