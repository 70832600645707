import React, { useState, useEffect } from "react";
import TopnavBar from "../Header/TopnavBar";
import OrganizationSettingBar from "../Header/OrganizationSettingBar";
import { faGears } from "@fortawesome/free-solid-svg-icons";
import Breadcrumbs from "../HomeVixo/Breadcrumbs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { ValidatePasswordGlobal } from "../Architecture/Validation";
import Spinner from "../../Spinner";
import "bootstrap/dist/css/bootstrap.css";
import { VixoAPI } from "../../settings";
import { APIHeader } from "../../APICallWrapper";
import axios from "axios";

const PasswordReset = () => {
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorType, setErrorType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({
    password: "",
    confirmPassword: "",
  });

  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  useEffect(() => {
    LoadData();
  }, []);

  const LoadData = () => {

    const loggedInUser = localStorage.getItem("User");
    var userObject = JSON.parse(loggedInUser);
    if (userObject && userObject !== null) {
      setLoading(true);
      setUserInfo(userObject);
      setLoading(false);
    } else {
      navigate("/");
    }
  };
  
  const validatePassword = (password) => {
    var IsValid = ValidatePasswordGlobal(password);
    return IsValid;
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
  
    const isPasswordValid = validatePassword(userInfo.password);
  
    if (!isPasswordValid) {
      setErrorType("Invalid password format. At least must be  8 characters, one digit, one uppercase letter, and one special character.");
      return;
    }
  
    if (userInfo.password !== userInfo.confirmPassword) {
      setErrorType("Passwords do not match.");
      return;
    }

    try {
      var encodedPassword = encodeURIComponent(userInfo.password);
      var encodedKey = encodeURIComponent(userInfo.key);
  
      const ResetAPIPath = `${VixoAPI.AuthenticationAPI}reset?Password=${encodedPassword}&Key=${encodedKey}`;
      setLoading(true);
      axios
        .get(ResetAPIPath,{
         headers:APIHeader().headers
        })
        .then((response) => {
          var responseData = response.data;
          if (responseData.statusCode === 200) {
            setSuccessMessage(responseData.message);
            setErrorType(null); 
          } else {
            setErrorType(responseData.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      throw error;
    }
  };

  const handleUserInfoChange = (e) => {
    setErrorType(null);

    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value,
    });
  };

  const [collapsed, setCollapsed] = useState(false);

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  const sidebarStyle = {
    marginLeft: collapsed ? "" : "250px",
  };

  return (
    <>
      <Spinner show={loading} />
      <TopnavBar />
      <OrganizationSettingBar
        collapsed={collapsed}
        toggleSidebar={toggleSidebar}
      />
      {successMessage && (
        <div
          className="alert alert-success"
          role="alert"
          style={{ marginLeft: "40%" }}
        >
          {successMessage}
        </div>
      )}

      <main className="Invitee" style={sidebarStyle}>
        <div className="container-fluid p-3 bg-white col-lg-12 col-sm-12">
          <div className="row">
            <div className="col-md-12 mx-auto">
              <div className="card">
                <div className="card-header py-3 bg-transparent">
                  <h5 className="mb-0 d-inline">Setting</h5>
                  <FontAwesomeIcon
                    icon={faGears}
                    className="fa-2x "
                    style={{ width: "25px" }}
                  />
                  <Breadcrumbs />
                </div>
                <div className="card-header py-3 bg-transparent">
                  <form>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label className="fw-bold mb-2 mt-2"> New Password</label>
                          <div className="input-group">
                            <input
                              type={showPassword1 ? "text" : "password"}
                              name="password"
                              value={userInfo.password}
                              className="form-control"
                              placeholder="Enter Password"
                              onChange={handleUserInfoChange}
                            />
                            <span
                              className="input-group-text"
                              style={{ cursor: "pointer" }}
                              onClick={() => setShowPassword1(!showPassword1)}
                            >
                              {showPassword1 ? (
                                <span role="img" aria-label="Visible">
                                  👁️
                                </span>
                              ) : (
                                <span role="img" aria-label="Hidden">
                                  🙈
                                </span>
                              )}
                            </span>
                          </div>
                        </div>
                        <br />
                        <label className="fw-bold mb-2 mt-2">
                          Confirm Password
                        </label>
                        <div className="input-group">
                          <input
                            type={showPassword2 ? "text" : "password"}
                            name="confirmPassword"
                            value={userInfo.confirmPassword}
                            className="form-control"
                            placeholder="Confirm Password"
                            onChange={handleUserInfoChange}
                          />
                          <span
                            className="input-group-text"
                            style={{ cursor: "pointer" }}
                            onClick={() => setShowPassword2(!showPassword2)}
                          >
                            {showPassword2 ? (
                              <span role="img" aria-label="Visible">
                                👁️
                              </span>
                            ) : (
                              <span role="img" aria-label="Hidden">
                                🙈
                              </span>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </form>
                     <br></br>
                  <form onSubmit={handleResetPassword}>
                    <div className="col-md-12 d-flex justify-content-end">
                      <button type="submit" className="btn btn-info" style={{borderRadius:"20px", color:"White",fontWeight:"500"}}
>
                        Reset Password
                      </button>
                    </div>
                  </form>
                </div>
                <div className="card-footer py-3 bg-transparent error-container" style={{ color: "red" }}>
                  {errorType && (
                    <div className="" role="alert">
                      {errorType}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default PasswordReset;
