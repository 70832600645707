import React, { useState, useEffect } from "react";
import { VixoAPI } from "../../settings";
import { APIHeader, ExpireToken, HasLoggedInUser } from "../../APICallWrapper";
import axios from "axios";
import ErrorComponent from "../../ErrorComponent";
import Spinner from "../../Spinner";
import "./Billingmodule.css";
import { ToastContainer, toast } from "react-toastify";
import { NumericFormat } from "react-number-format";
import { useNavigate } from "react-router-dom";

function ClearentComponent({ creditAdded }) {
  const [amount, setAmount] = useState("100.00");
  const [organization, setOrganization] = useState({});
  const [errorType, setErrorType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [IsClearentSDKReady, SetIsClearentSDKReady] = useState(false);
  const [IsClearentSDKReadyState, SetIsClearentSDKReadyState] = useState(0);

  function initializeClearent() {
    var vixoAPI = VixoAPI;
    setLoading(true);
    const clearentObject = window.ClearentSDK;
    if (clearentObject) {
      clearentObject.init({
        baseUrl: vixoAPI.ClearantAPI,
        pk: vixoAPI.ClearntPublicKey,
      });
      setLoading(false);
    }
  }
const navigate = useNavigate();

  useEffect(() => {
    setOrganizationData();
    SetIsClearentSDKReady(false);
   
  }, []); // Empty

  useEffect(() => {

    if (!IsClearentSDKReady) {
      if (typeof window.ClearentSDK === "undefined") {

        SetIsClearentSDKReady(false);
        var lIsClearentSDKReadyState=IsClearentSDKReadyState;
        lIsClearentSDKReadyState=lIsClearentSDKReadyState+1;
        SetIsClearentSDKReadyState(lIsClearentSDKReadyState);
       
      } else {
        SetIsClearentSDKReady(true);
        initializeClearent();
      }
    }
  }, [IsClearentSDKReady,IsClearentSDKReadyState]);



  function LoadOrganization(OrganizationID) {
    if(HasLoggedInUser()){
      const config=APIHeader();
    try {
      setLoading(true);
      const OrgnizationUpdateAPIPath =
        VixoAPI.OrganizationAPI + "getAny?Id=" + OrganizationID;
      axios
        .get(OrgnizationUpdateAPIPath,{
         headers:config.headers
        })
        .then((response) => {
          var response = response.data;
          setLoading(false);
          if (response.statusCode === 200) {
            var Organization = JSON.parse(JSON.stringify(response.data));
            if (Organization != null) {
              setOrganization(Organization);
            
            }
          } else {
            setErrorType(response.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          if(error.response && error.response.status && error.response.status==401)
            {
              ExpireToken(navigate);
            }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }else {
    ExpireToken(navigate);
  }
  }
  function setOrganizationData() {
    const LoggedUser = localStorage.getItem("User");
    var userObject = JSON.parse(LoggedUser);
    LoadOrganization(userObject.organizationId)
  }
  function GetPaymentFromCard(jwt, lcardNumber, lcardExpiry, CreditCardType) {
    setErrorType("");
    setLoading(false);
    setLoading(true);
    try {
      var lAmcount = parseInt(amount);
      var vixoAPI = VixoAPI;
      const apiUrl = vixoAPI.ClearantAPI + "/rest/v2/mobile/transactions/sale";
      const data = {
        type: "SALE",
        amount: lAmcount + ".00",
        "software-type": "vixolink.com",
        "software-type-version": "1.0",
        "create-token": "true",
      };
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "api-key": vixoAPI.CleartantAPIKey,
          mobilejwt: jwt,
        },
      };
      axios
        .post(apiUrl, data, config,{
         headers:config.headers
        })
        .then((response) => {
          var response = response.data;
          var ResponseData = JSON.parse(JSON.stringify(response));
          if ((ResponseData.code = 200)) {
            const transactionId = JSON.parse(
              JSON.stringify(ResponseData.links[0])
            ).id;
            const reuseableToken = JSON.parse(
              JSON.stringify(ResponseData.links[1])
            ).id;
            AddCredit(
              amount,
              lcardNumber,
              transactionId,
              reuseableToken,
              CreditCardType,
              lcardExpiry
            );
            console.log("POST request successful. Response:", response.data);
          } else {
            setErrorType("Server error: " + ResponseData.status);
          }
        })
        .catch((error) => {
          if (error.response) {
            var response = error.response;
            var ResponseData = JSON.parse(JSON.stringify(response.data));
            var ResponseData = JSON.parse(JSON.stringify(ResponseData));
            var payload = JSON.parse(JSON.stringify(ResponseData.payload));
            var errorResponse = JSON.parse(JSON.stringify(payload.error));

            setErrorType(
              "Server Returned Error: " +
                errorResponse["error-message"] +
                " with Error Code : " +
                errorResponse["result-code"]
            );
          } else if (error.response) {
            setErrorType("Server error: " + error);
          } else if (error.message) {
            setErrorType("Server error: " + error.message);
          }
          else if (error.request) {
            setErrorType("Network error: No response received");
          } else {
          }
          setLoading(false);
        });
    } catch (error) {
      setErrorType(error.message);
      setLoading(false);
    }
  }
  function AddCredit(
    amount,
    cardNumber,
    transactionReference,
    paymentToken,
    cardType,
    expiry
  ) {
    setLoading(false);
    setLoading(true);
    const data = {
      organizationId: organization.id,
      credit: amount,
      date: new Date(),
      referenceNumber: transactionReference,
      cardReference: cardNumber,
      paymentToken: paymentToken,
      creditCardType: cardType,
      expiry: expiry,
    };
  if(HasLoggedInUser()){
      const config=APIHeader();
    try {
      const OrgAPIPath = `${VixoAPI.BillingAPI}addCredit`;

      axios
        .post(OrgAPIPath, data,{
         headers:config.headers
        })
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            toast.success(response.message);
            creditAdded(organization.id);
          } else {
            setErrorType(response.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
      // setLoading(false);
    } catch (error) {
      setErrorType(error.message);
      setLoading(false);
    }
  }
  else{
    ExpireToken(navigate);
  }
  }
  const handleAddCredit = async (e) => {
    e.preventDefault();
    try {
      setErrorType("");
      setLoading(true);
      window.ClearentSDK.getPaymentToken().then(
        (result) => {
          console.log("ClearentTokenSuccess");
          console.log(result);
          var payload = result.payload;
          var mobileJWT = payload["mobile-jwt"];
          var CreditCardType = mobileJWT["card-type"];
          var CreditCardExpiry = mobileJWT["exp-date"];
          var CreditCardNumber = mobileJWT.bin + mobileJWT["last-four"];
          var jwt = mobileJWT.jwt;
          GetPaymentFromCard(
            jwt,
            CreditCardNumber,
            CreditCardExpiry,
            CreditCardType
          );
        },
        (error) => {
          setErrorType(error);
          console.log("ClearentTokenError");
          console.log(error);
        }
      );
    } catch (error) {}
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    let parsedValue = parseFloat(value);
    if (!isNaN(parsedValue)) {
      parsedValue = parseFloat(parsedValue.toFixed(2));
    }
    setAmount(parsedValue.toString());
  };

  return (
    <div>
      {errorType && <ErrorComponent errorType={errorType} />}
      <Spinner show={loading} />
      <ToastContainer />
      <p className="mt-5 mb-5"></p>
      <h4 className="mt-4 mb-4"> Charge Credit Card</h4>
      <div class="col-md-12">
        <NumericFormat
          allowNegative={false}
          decimalScale={2}
          placeholder="100.00"
          value={amount}
          onChange={handleAmountChange}
          fixedDecimalScale
          style={{
            width: "100%",
            height: "51px",
            border: "1px solid #c4cdd5",
            borderRadius: "inherit",
          }}
        />
      </div>
      <div id="payment-form"></div>
      <button
        id="payment-button"
        className="btn w-100 mt-3 bg-black"
        onClick={handleAddCredit}
      >
        Charge Credit Card
      </button>

      <div
        className="card-footer py-3 bg-transparent error-container"
        style={{ color: "red" }}
      >
        {errorType && (
          <div className="" role="alert">
            {errorType}
          </div>
        )}
      </div>
    </div>
  );
}

export default ClearentComponent;
