import React, { useEffect, useRef, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import "./Sidebar.css";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import TopnavBar from "../Header/TopnavBar";
import AddCustomerModal from "./AddCustomerModal";
import EditCustomer from "./EditCustomer";
import Customerdelete from "./Customerdelete";
import { useSelector } from "react-redux";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import {
  faLink,
  faArrowRightArrowLeft,
  faBell,
  faUser,
  faCaretUp,
  faClone ,
} from "@fortawesome/free-solid-svg-icons";

import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { APIHeader, HasLoggedInUser,ExpireToken } from "../../APICallWrapper";
import ErrorComponent from "../../ErrorComponent";
import Spinner from "../../Spinner";
import { VixoAPI, Environment, GetEnvironmentBasedAPI } from "../../settings";
import { ToastContainer } from "react-toastify";
import Dropup from "./Dropup";
import { createPortal } from "react-dom";
import { selectNotification } from "../Notification/Slices/sendNotificationSlice";
import { selectUser } from "../Notification/Slices/UserSlice";
import { IsComponentAllowToRole } from "../../Authentication";
import CustomerTransfer from "./CustomerTransfer";
import "./CustomerTransfer.css";
function SideBar({
  collapsed,
  toggleSidebar,
  customerSelected,
  Caller,
  handleResendInviteClick,

}) {
  const navigate = useNavigate();
  const APIHeaders = APIHeader();
  const ComponentName = "Resend Invite";

  const [selectedCustomerIndex, setSelectedCustomerIndex] = useState(-1);
  const dropupButtonRef = useRef(null);
  const dropdownRef = useRef(null);
  const environment = Environment.ENV
    ? Environment.ENV.trim().toUpperCase()
    : "";
  const API = GetEnvironmentBasedAPI();
  const [OrganizationID, setOrganizationID] = useState();
  const handleLogout = async (e) => {
    e.preventDefault();
    localStorage.clear();
    navigate("/");
  };
  const handleuserdropdown = async (e) => {
    e.preventDefault();
    navigate("/Users");
  };
  const handlepasswordreetdropdown = async (e) => {
    e.preventDefault();
    navigate("/PasswordReset");
  };
  const handleGeneralProfiledropdown = async (e) => {
    e.preventDefault();
    navigate("/General");
  };
  const [showModaltransfer, setShowModaltransfer] = useState(false);

  const handleIconClick = () => {
    setShowModaltransfer(true);
  };
  const [reloadFilter, setReloadFilter] = useState(0);
  const [User, setUser] = useState({});
  const [org, setOrg] = useState({});
  const [customerArray, setCustomers] = useState([]);
  const [filteredCustomers, setfilteredCustomers] = useState([]);
  const [showDeleteLink, setShowDeleteLink] = useState(false);
  const [showsyndicateorg, setshowsyndicateorg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorType, setErrorType] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const lastAddedCustomerRef = useRef(null);
  const selectedCustomerRef = useRef(null);
  const notification = useSelector(selectNotification);
  useEffect(() => {
    LoadOrganizationCustomers();
  }, []);
  const Usrsss = useSelector(selectUser);
  // function IsFunctionalityVisible(FunctionalityName) {
  //   if (User.role === 10) {
  //     // Lead Gen
  //     if (FunctionalityName === "Resend Invite") {
  //       return true;
  //     }
  //     if (FunctionalityName === "Edit Customer") {
  //       return true;
  //     }
  //     if (FunctionalityName === "Delete Customer") {
  //       return true;
  //     }
  //     return false;
  //   }
  //   return true;
  // }
  function SetCustomerArray(addedCustomer) {}

  function customerAdded(addedCustomer) {
    if (customerArray != null) {
      const newIndex = customerArray.length; // Index of the newly added customer
      setCustomers([...customerArray, addedCustomer]);
      setActiveCustomerIndex(newIndex);
      ScrollToBottom();
    } else {
      setCustomers([addedCustomer]);
      // setActiveCustomerIndex(0);
      ScrollToBottom();
    }
    ReloadFilters();
  }

  function customerUpdate(customerUpdated) {
    const objectIndex = customerArray.findIndex(
      (obj) => obj.id && obj.id === customerUpdated.id
    );

    if (objectIndex !== -1) {
      const newData = [...customerArray];

      newData[objectIndex] = customerUpdated;

      setCustomers(newData);
      ReloadFilters();
    }
  }
  function setCustomersData(data) {
    try {
      setLoading(true);
      var customers = JSON.parse(JSON.stringify(data));
      setCustomers(customers);
      setActiveCustomerIndex(0);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw error;
    }
    ReloadFilters();
  }

  function setError(message) {
    setErrorType(message);
  }
  function LoadOrganizationCustomers() {
    const loggedInUser = localStorage.getItem("User");
    var userObject = JSON.parse(loggedInUser);
   var Config= APIHeader();
   if(HasLoggedInUser())
    {
    if (userObject != null) {
      setUser(userObject);
      setOrganizationID(userObject.organizationId);

      if (HasLoggedInUser()) {
        const config=APIHeader();
        try {
          // setLoading(true);
          const GetCustomersAPIPath =
            VixoAPI.CustomerAPI + "GetCustomersByOrganization";
          axios
            .get(GetCustomersAPIPath, {
              headers: config.headers,
              params: {
                // Id: OrganizationID,
                Id: userObject.organizationId,
              },
            })
            .then((response) => {
              var response = response.data;
              setLoading(false);
              if (response.statusCode === 200) {
                setCustomersData(response.data);
              } else {
                setError(response.message);
              }
              if (response.data == null) {
                navigate("/Welcome");
              }
            })
            .catch((error) => {
              setLoading(false);
              throw error;
            })
            .finally(() => {
              setLoading(false);
            });
        } catch (error) {
          setLoading(false);
          if(error.response && error.response.status && error.response.status==401)
            {
               ExpireToken(navigate);
            }
        }
      } else {
        ExpireToken(navigate);
      }

      ReloadFilters();
    }
  }else
  {
    ExpireToken(navigate);

  }
  }

  const [showDropUp, setShowDropUp] = useState(false);

  const toggleDropUp = () => {
    setShowDropUp(!showDropUp);
  };

  const clearCustomerNotifications = async (Id) => {
    if (HasLoggedInUser()) {
      const config=APIHeader();
      setLoading(true);
      const ClearAPIPath = `${VixoAPI.AlertsAPI}clearAlerts?CustomerID=${Id}`;
      axios
        .get(ClearAPIPath, {
          headers: config.headers,
        })
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
          } else {
            setError(response.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          if(error.response && error.response.status && error.response.status==401)
            {
              ExpireToken(navigate);
            }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      ExpireToken(navigate);
    }
  };

  function setError(message) {
    setErrorType(message);
  }

  const [activeCustomerIndex, setActiveCustomerIndex] = useState(-1);

  const [isCustomerEditModalOpen, setCustomerEditModalOpen] = useState(false);

  const handleActualCustomernameClick = (index) => {
    handleCustomernameClick(index);
  };

  const handleListCustomernameClick = (index) => {
    handleCustomernameClick(index);
  };
  const handleCustomernameClick = (index) => {
    setActiveCustomerIndex(
      index === activeCustomerIndex ? activeCustomerIndex : index
    );
    setSelectedCustomerIndex(index);

    const customer = customerArray[index];
    const filteredCustomer = filteredCustomers[index];

    const selectedCustomer = filteredCustomer || customer;

    if (selectedCustomer) {
      localStorage.setItem(
        "SelectedCustomer",
        JSON.stringify(selectedCustomer)
      );

      if (customerSelected && typeof customerSelected === "function") {
        customerSelected(selectedCustomer);
      }

      // Scroll to bottom if the last index in the array is clicked
      if (index === customerArray.length - 1) {
        ScrollToBottom();
      }

      setCustomerEditModalOpen(true);
    }
  };

  useEffect(() => {
    if (activeCustomerIndex > -1 && customerArray != null) {
      handleCustomernameClick(activeCustomerIndex);
    }
  }, [activeCustomerIndex, customerArray, filteredCustomers]);

  useEffect(() => {
    if (
      customerArray != null &&
      customerArray.length > 0 &&
      activeCustomerIndex == -1
    ) {
      setSelectedCustomerIndex(0);
    }
  }, [customerArray]);

  const handleAddCustomerSuccess = () => {
    toast.success("Customer added successfully!");
  };
  var sendinvite = "/ResendInvite";

  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [editCustomer, setEditCustomer] = useState(null);

  const handleModalEditOpen = (customerData) => {
    if (customerData) {
      setEditCustomer(customerData);
    } else {
      setEditCustomer(null);
    }
    setShowModalEdit(true);
  };
  const handleModalEditClose = (customer) => {
    setShowModalEdit(false);
    setEditCustomer(customer);
    if (customer) {
      customerUpdate(customer);
    }
  };

  const handleModalOpen = () => {
    setShowModal(true);
  };
  const handleModalClose = (customer) => {
    if (customer) {
      customerAdded(customer);
    }
    setShowModal(false);
  };
  const scrollToCustomer = () => {
    const lastAddedCustomerElement = lastAddedCustomerRef.current;
    if (lastAddedCustomerElement) {
      lastAddedCustomerElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  async function GetCustomerByKey(CustomerKey) {
    if (HasLoggedInUser()) {
      const config=APIHeader();
      try {
        var Key = encodeURIComponent(CustomerKey);
        const GetCustomerId = `${VixoAPI.CustomerAPI}getCustomerBykey?key=${Key}`;

        const response = await axios.get(GetCustomerId, {
          headers: config.headers,
        });
        var apiResponse = response.data;
        if (apiResponse.statusCode === 200) {
          const data = apiResponse.data;
          var customersdata = JSON.parse(JSON.stringify(data));
          return customersdata;
        }
      } catch (error) {
        if(error.response && error.response.status && error.response.status==401)
          {
             ExpireToken(navigate);
            }
          }
        } else {
          ExpireToken(navigate);
    }
  }

  function GotNewAlert(notification) {
    console.log("Sidebar: " + notification.notificationType);
    if (
      notification != null &&
      parseInt(notification.organiationID) == OrganizationID &&
      notification.notificationType == 22 // New Customer is Added
    ) {
      GetCustomerByKey(notification.customerKey).then((customer) => {
        if (customer) {
          var index =
            customerArray &&
            customerArray.findIndex((p) => p.id == customer.id);
          if (customer && index < 0) {
            // Add Customer to Array if Customer is not Already in List and Added by Someone else.
            CustomerAddedByNotification(customer);
          }
        }
      });
    }

    if (
      notification != null &&
      parseInt(notification.organiationID) == OrganizationID &&
      notification.notificationType == 25 // Customer is Edited
    ) {
      GetCustomerByKey(notification.customerKey).then((customer) => {
        if (customer) {
          var index =
            customerArray &&
            customerArray.findIndex((p) => p.id == customer.id);
          if (customer && index > -1) {
            // Realod Customer if this is not Found
            CustomerEditedByNotification(customer);
          }
        }
      });
    }

    if (
      notification != null &&
      parseInt(notification.organiationID) == OrganizationID &&
      notification.notificationType == 26 // Delete Customer
    ) {
      var index =
        customerArray &&
        customerArray.findIndex((p) => p.id == notification.cutomerId);
      if (index > -1) {
        // Remove Customer from List if it already Exists.
        CustomerDeleteSuccess(notification.cutomerId);
        //CustomerDeletedByNotification(notification.cutomerId);
        ReloadFilters();
        // setCustomersData(customerArray);
        LoadOrganizationCustomers();
      }
    }
  }
  function CustomerAddedByNotification(Customer) {
    setCustomers([...customerArray, Customer]);
    ReloadFilters();
  }
  function CustomerEditedByNotification(Customer) {
    customerUpdate(Customer);
  }
  function CustomerDeletedByNotification(Id) {
    if (Id) {
      const updatedCustomer = customerArray.filter(
        (item) => item.id !== null && item.id !== Id
      );

      setCustomers(updatedCustomer);

      ReloadFilters();
    }
  }

  function CustomerDeletedByNotification(customerId) {
    const updatedCustomers = customerArray.filter(
      (customer) => customer.id !== null && customer.id !== customerId
    );

    setCustomers(updatedCustomers);
    ReloadFilters();
  }

  function ReloadFilters() {
    var lReloadFilter = reloadFilter + 1;
    setReloadFilter(lReloadFilter);
  }
  useEffect(() => {
    if (reloadFilter > 0) {
      var lfilteredCustomers;
      if (customerArray != null) {
        lfilteredCustomers = customerArray.filter(
          (customer) =>
            customer &&
            [
              "name",
              "firstName",
              "lastName",
              "phone",
              "identity",
              "email",
            ].some((field) =>
              customer[field].toLowerCase().includes(searchQuery.toLowerCase())
            )
        );
        setfilteredCustomers(lfilteredCustomers);
      }
    }
  }, [reloadFilter, customerArray, searchQuery]);

  useEffect(() => {
    if (notification) {
      GotNewAlert(notification);
    }
  }, [notification]);

  const [showDeleteCustomer, setShowDeleteCustomer] = useState(false);

  const [selecetedCustomerKey, setSelectedCustomerKey] = useState(null);
  const [customerName, setCustomerName] = useState(null);

  const handleDeleteClick = (customerKey, customerName) => {
    setSelectedCustomerKey(customerKey);
    setCustomerName(customerName);
    setShowDeleteCustomer(true);
  };

  useEffect(() => {
    if (selecetedCustomerKey != null) {
      setShowDeleteCustomer(true);
    }
  }, [selecetedCustomerKey]);
  const handleDeleteCancel = () => {
    setShowDeleteCustomer(false);
  };

  const CustomerDeleteSuccess = (id) => {
    const updatedCustomers =
      customerArray &&
      customerArray.filter(
        (customer) => customer.key !== null && customer.key !== id
      );

    // Handle both first and last customer deletion cases
    let newSelectedIndex;
    if (updatedCustomers.length === 0) {
      navigate("/Welcome");
      setCustomers([]);
    } else {
      // Determine newSelectedIndex based on deleted customer's index
      const deletedIndex = customerArray.findIndex(
        (customer) => customer.key === id
      );

      // First customer deleted and select the second customer
      if (deletedIndex === 0) {
        newSelectedIndex = deletedIndex + 0; // Select the next customer
      } else if (deletedIndex === updatedCustomers.length) {
        // Last customer deleted, select the new last customer (adjusted for deletion)
        newSelectedIndex = deletedIndex - 1; // Select the previous customer (now the last)
      } else {
        // Customer deleted from the middle, no change in selected index
        newSelectedIndex = deletedIndex;
      }
      setActiveCustomerIndex(newSelectedIndex);
      setCustomers(updatedCustomers);
      ReloadFilters();
    }
  };

  // const CustomerDeleteSuccess = (id) => {
  //   const updatedCustomers =
  //     customerArray &&
  //     customerArray.filter(
  //       (customer) => customer.key !== null && customer.key !== id
  //     );
  //   setActiveCustomerIndex(activeCustomerIndex);
  //   setCustomers(updatedCustomers);
  //   ReloadFilters();
  // };

  function ScrollToBottom() {
    if (lastAddedCustomerRef.current) {
      lastAddedCustomerRef.current.scrollIntoView({
        // behavior: "smooth",
      });
    }
    if (selectedCustomerRef.current) {
      // selectedCustomerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  const customerArrayLength = customerArray != null ? customerArray.length : 0;
  const customerListContainer = document.querySelector(
    ".customer-list-container"
  );

  if (customerListContainer && customerArrayLength > 10) {
    customerListContainer.style.overflowY = "auto";
  } else if (customerListContainer) {
    customerListContainer.style.overflowY = "hidden";
  }
  function handleDeleteFinicityCustomer(customerKey) {
    if (HasLoggedInUser()) {
      const config=APIHeader();
      try {
        const apiUrl =
          VixoAPI.FinicityAPI +
          "deletefinicitytestaccount?customerKey=" +
          customerKey;
        setLoading(true);
        axios
          .post(apiUrl, {
            headers: config.headers,
          })
          .then((response) => {
            const responseData = response.data;
            if (responseData.statusCode === 200) {
              setfilteredCustomers(responseData.data);
              toast.success(responseData.message);
              LoadOrganizationCustomers(OrganizationID);
            } else {
              toast.error(responseData.message);
            }
          })
          .catch((responseData) => {
            toast.error(responseData.message);
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        console.error("Error in:", error);
        setLoading(false);
      }
    } else {
      ExpireToken(navigate)
    }
  }

  return (
    <>
      {/* <ToastContainer /> */}
      {/* <TopnavBar /> */}
      {errorType && <ErrorComponent errorType={errorType} />}
      <Spinner show={loading} />

      <Customerdelete
        show={showDeleteCustomer}
        onHide={handleDeleteCancel}
        customerKey={selecetedCustomerKey}
        customerName={customerName}
        onDeleteCancel={handleDeleteCancel}
        onDeleteSuccess={CustomerDeleteSuccess}
      />
      <div className={`IsActiveSideBar ${collapsed ? "collapsed" : ""}`}>
        <div className="text-start"></div>

        <aside
          className={`sidebar-wrapper ${collapsed ? "collapsed" : ""}`}
          data-simplebar="true"
          style={{
            position: "fixed !important",
            top: "0 !important",
            left: "0 !important",
            width: "250px !important",
            height: "100% !important",
            backgroundColor: "#ffffff !important",
            backgroundClip: "padding-box !important",
            borderRight: "1px solid #e2e3e4 !important",
            transition: "all .2s !important",
            zindex: "16 !important",
          }}
        >
          <div className={`sidebar-header ${collapsed ? "collapsed" : ""}`}>
            <Link to="/Home">
              <img
                src="assets/images/LogoVixo.png"
                className="logo-icon"
                alt="logo icon"
                style={{
                  width: "150px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
            </Link>
          </div>
          <div className="container" style={{ zIndex: 1000 }}>
            <ul
              className="metismenu  "
              id="menu"
              style={{
                listStyle: "none",
                color: "rgb(1,1,1)",
              }}
            >
              <li className="pt-2">
                <button
                  className="btn"
                  onClick={() => handleModalOpen(null)}
                  style={{
                    background: "#0D6EFD",
                    borderRadius: "3px",
                    color: "#FFF",
                    fontSize: "15px",
                    fontWeight: "400",
                  }}
                >
                  Add Customer
                </button>
                <AddCustomerModal
                  showModal={showModal}
                  handleModalClose={handleModalClose}
                  onAddCustomerSuccess={handleAddCustomerSuccess}
                />
              </li>
              <li>
                <form className="searchbar d-xl-flex">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                    style={{
                      backgroundImage: `url('assets/images/search.png')`,
                      backgroundSize: "16px",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "9px 11px",
                      paddingLeft: "32px",
                    }}
                  />
                </form>
              </li>
            </ul>

            <div className="customer-list-container">
              <div
                className={
                  customerArray != null && customerArray.length === 10
                    ? "customer-list CustomerListScrollbar "
                    : "customer-list"
                }
                // style={{overflowY:"auto}"}}
              >
                {filteredCustomers &&
                  Array.isArray(filteredCustomers) &&
                  filteredCustomers.map((customer, index) => (
                    <ul
                      className={`metismenu ${
                        selectedCustomerIndex === index ? "active" : ""
                      }`}
                      id="menu"
                      style={{
                        listStyle: "none",
                        color: "rgb(1,1,1)",
                        marginBottom: "12px",
                        border: "0px solid",
                        width: "100%",
                        padding: "1rem",
                        backgroundColor: "#EAF0F6",
                        // borderRadius: "3px",
                        borderBottom: "1px solid #BECDDB",
                        background:
                          selectedCustomerIndex === index ? "white" : "#EAF0F6",
                      }}
                      key={index}
                      ref={
                        index === customerArray.length - 1
                          ? lastAddedCustomerRef
                          : activeCustomerIndex === index
                          ? selectedCustomerRef
                          : null
                      }
                    >
                      <li
                        className={`p-3 text-decoration-none ${
                          activeCustomerIndex === index ? "active-customer" : ""
                        }`}
                        style={{
                          marginBottom: "-4rem",
                          color:
                            activeCustomerIndex === index ? "white" : "black",
                        }}
                        onClick={() => {
                          handleListCustomernameClick(index);
                        }}
                      >
                       
                        <Link
                          to={`/Home`}
                          style={{
                            color: "",
                            marginTop: "-2.2rem",
                            marginLeft: "-1rem",
                          }}
                          title={customer.identity}
                          
                        >
                            {customer.customerType === 2 && (
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="button-tooltip">
                                  Syndicate Customer
                                </Tooltip>
                              }
                            >
                              <FontAwesomeIcon
                              icon={faClone}
                              style={{ marginLeft: "-16px", color: "#2196F3",paddingRight:"4px" }}
                              title="Syndicate Customer"
                            />
                            </OverlayTrigger>
                          )}

                          {customer.identity &&
                          customer?.identity.length > 13 ? (
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id={`tooltip-${index}`}>
                                  {customer.identity}
                                </Tooltip>
                              }
                            >
                              <span>{customer.identity.slice(0, 11)}...</span>
                            </OverlayTrigger>
                          ) : (
                            customer.identity
                          )}
                        </Link>

                        <div
                          className="rightrashicont"
                          style={{ marginTop: "-32px", marginRight: "-25px" }}
                        >
                          {customer.count > 0 && (
                            <span
                              className="iconSideBar p-1"
                              style={{ marginTop: "-2.5rem" }}
                            >
                              <FontAwesomeIcon
                                style={{
                                  color: "rgb(72, 17, 74)",
                                  fontSize: "20px",
                                }}
                              />
                              {customer.count > 0 && (
                                <span
                                  style={{
                                    marginTop: "-1.1rem",
                                    position: "absolute",
                                    top: "0.5px",
                                    backgroundColor: "red",
                                    color: "white",
                                    borderRadius: "50%",
                                    padding: "0.2rem 0.4rem",
                                    fontSize: "11px",
                                  }}
                                >
                                  {customer.count}
                                </span>
                              )}
                            </span>
                          )}
                          <span>
                            <FontAwesomeIcon
                              className="p-1"
                              style={{
                                color:
                                  activeCustomerIndex === index
                                    ? "#00AADB"
                                    : "#BECDDB",

                                fontSize: "20px",
                                borderRadius: "57%",
                                height: "18px",
                                width: "18px",
                                marginTop: "4px",
                                marginLeft: "1.1rem",
                                backgroundColor: "transparent !important",
                              }}
                              icon={faLink}
                            />
                          </span>
                          <span className="iconSideBar p-1">
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="link"
                                id="dropdown-basic"
                                style={{
                                  color:
                                    activeCustomerIndex === index
                                      ? "#00AADB"
                                      : "#BECDDB",

                                  padding: "0px",
                                  marginTop: "1px",
                                  marginLeft: "2px",
                                  backgroundColor: "black !important",
                                }}
                              >
                                <FontAwesomeIcon icon={faEllipsis} />{" "}
                              </Dropdown.Toggle>
                              <Dropdown.Menu
                                className="custom-dropdown-menu"
                                style={{ background: "#EAF0F6" }}
                              >
                                <Dropdown.Header>
                                  <strong> {customer.identity} </strong>
                                  {customer.count > 0 && (
                                    <span className="iconSideBar p-1">
                                      <FontAwesomeIcon
                                        style={{
                                          color: "rgb(72, 17, 74)",
                                          fontSize: "20px",
                                        }}
                                      />
                                      {customer.count > 0 && (
                                        <span
                                          style={{
                                            position: "absolute",
                                            top: "4px",
                                            backgroundColor: "transparent",
                                            borderRadius: "50%",
                                            padding: "0.2rem 0.4rem",
                                            fontSize: "11px",
                                            marginTop: "7.5%",
                                            marginLeft: "2.4px",
                                          }}
                                        >
                                          {customer.count}
                                        </span>
                                      )}
                                    </span>
                                  )}

                                  <span className="iconSideBar p-1">
                                    <span>
                                      <FontAwesomeIcon
                                        className="p-1"
                                        style={{
                                          color:
                                            activeCustomerIndex === index
                                              ? "#00AADB"
                                              : "#BECDDB",
                                          backgroundColor:
                                            customer.count === 0
                                              ? "red"
                                              : customer.count === 2
                                              ? "green"
                                              : "transparent",
                                          fontSize: "20px",
                                          borderRadius: "50%",
                                          height: "20px",
                                          width: "20px",
                                          marginLeft: "1rem",
                                        }}
                                        icon={faLink}
                                      />
                                    </span>
                                  </span>
                                </Dropdown.Header>
                                <hr />
                                {IsComponentAllowToRole("Edit Customer") ===
                                true ? (
                                  <>
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleModalEditOpen(customer)
                                      }
                                    >
                                      Edit Customer
                                    </Dropdown.Item>
                                  </>
                                ) : (
                                  ""
                                )}
                                {IsComponentAllowToRole("Refresh") === true ? (
                                  <>
                                    <Dropdown.Item>Refresh</Dropdown.Item>
                                  </>
                                ) : (
                                  ""
                                )}

                                {IsComponentAllowToRole("Resend Invite") ===
                                true ? (
                                  <>
                                    <Dropdown.Item>
                                      <Link
                                        to={`/ResendInvite`}
                                        style={{
                                          padding: "unset",
                                          textDecoration: "none",
                                          color: "black",
                                        }}
                                      >
                                        Resend Invite
                                      </Link>
                                    </Dropdown.Item>
                                  </>
                                ) : (
                                  ""
                                )}
                                {IsComponentAllowToRole("DownloadHome") ===
                                true ? (
                                  <>
                                    <Link
                                      target="_blank"
                                      to={"/DownloadHome"}
                                      style={{
                                        padding: "unset",
                                        textDecoration: "none",
                                        color: "black",
                                        marginLeft: "15px",
                                      }}
                                    >
                                      Download
                                    </Link>
                                  </>
                                ) : (
                                  ""
                                )}
                                {IsComponentAllowToRole(
                                  "Clear Notification"
                                ) === true ? (
                                  <>
                                    <Dropdown.Item
                                      onClick={() =>
                                        clearCustomerNotifications(customer.id)
                                      }
                                    >
                                      Clear Notification
                                    </Dropdown.Item>
                                  </>
                                ) : (
                                  ""
                                )}
                                {IsComponentAllowToRole("Delete Customer") ===
                                true ? (
                                  <>
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleDeleteClick(
                                          customer.key,
                                          customer.name
                                        )
                                      }
                                    >
                                      Delete Customer
                                    </Dropdown.Item>
                                  </>
                                ) : (
                                  ""
                                )}

                                {IsComponentAllowToRole("Customer Portal") ===
                                true ? (
                                  <>
                                    <Link
                                      target="_blank"
                                      to={`${
                                        VixoAPI.CustomeDashboard
                                      }home?id=${encodeURIComponent(
                                        customer.key
                                      )}`}
                                      style={{
                                        cursor: "pointer",
                                        textDecoration: "none",
                                        color: "black",
                                      }}
                                    >
                                      Customer Portal
                                    </Link>
                                  </>
                                ) : (
                                  ""
                                )}
                                {IsComponentAllowToRole("Salesforce") ===
                                true ? (
                                  <>
                                    <Link
                                      target="_blank"
                                      to={`${
                                        VixoAPI.DashBoard
                                      }customer?u_id=${encodeURIComponent(
                                        User.id
                                      )}&u_email=${encodeURIComponent(
                                        User.email
                                      )}&u_phone=${encodeURIComponent(
                                        User.phone
                                      )}&c_id=${encodeURIComponent(
                                        customer.id
                                      )}&c_email=${encodeURIComponent(
                                        customer.email
                                      )}&c_phone=${encodeURIComponent(
                                        customer.phone
                                      )}&c_fname=${encodeURIComponent(
                                        customer.firstName
                                      )}&c_lname=${encodeURIComponent(
                                        customer.lastName
                                      )}`}
                                      style={{
                                        cursor: "pointer",
                                        textDecoration: "none",
                                        color: "black",
                                      }}
                                    >
                                      Salesforce
                                    </Link>

                                    <a
                                    
                                      onClick={handleIconClick}
                                      style={{
                                        cursor: "pointer",
                                        textDecoration: "none",
                                        color: "black",
                                      }}
                                    >
                                       Transfer Customer
                                    </a>
                                    <CustomerTransfer showModaltransfer={showModaltransfer} setShowModaltransfer={setShowModaltransfer} customer={customer} />

                                  </>
                                  
                                ) : (
                                  ""
                                )}
                                {IsComponentAllowToRole("Delete Finicity") ===
                                true ? (
                                  <>
                                    {(Environment.ENV.trim().toUpperCase() ===
                                      "LOCAL" ||
                                      Environment.ENV.trim().toUpperCase() ===
                                        "DEV" ||
                                      Environment.ENV.trim().toUpperCase() ===
                                        "DEVELOPMENT") &&
                                    customer.openBankingType === 1 ? (
                                      <Link
                                        onClick={() =>
                                          handleDeleteFinicityCustomer(
                                            customer.key
                                          )
                                        }
                                        style={{
                                          cursor: "pointer",
                                          textDecoration: "none",
                                          color: "black",
                                        }}
                                      >
                                        Delete Finicity
                                      </Link>
                                    ) : null}
                                  </>
                                ) : (
                                  ""
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </span>
                        </div>
                      </li>
                    </ul>
                  ))}
                <EditCustomer
                  showModalEdit={showModalEdit}
                  handleModalEditClose={handleModalEditClose}
                  editCustomer={editCustomer}
                />
              </div>
            </div>

            <div className="container mb-3 mt-3" style={{ zIndex: 1000 }}>
              <ul>
                <li className="">
                  <div
                    style={{ position: "fixed", bottom: "0" }}
                    className={`IsActiveSideBar   jut ${collapsed ? "" : ""}`}
                  >
                    <li className=" dropup-btn" onClick={toggleDropUp}>
                      {/* <OverlayTrigger placement="top" overlay={renderTooltip}> */}

                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{User?.name || ""}</Tooltip>}
                      >
                        <button
                          ref={dropupButtonRef}
                          className="btn btn-primary fw-bold"
                          style={{ width: "100%" }}
                        >
                          <span style={{ fontSize: "12px", marginTop: "11px" }}>

                            <FontAwesomeIcon icon={faUser} />{" "}

                            <span style={{ margin: "0 5px" }}>

                              {User?.name && User.name.length > 16
                                ? `${User.name.substring(0, 16)}...`
                                : User?.name}
                            </span>
                            <FontAwesomeIcon icon={faCaretUp} />
                          </span>
                        </button>
                      </OverlayTrigger>

                      {showDropUp &&
                        createPortal(
                          <Dropup
                            handleLogout={handleLogout}
                            handleuserdropdown={handleuserdropdown}
                            handleGeneralProfiledropdown={
                              handleGeneralProfiledropdown
                            }
                            handlepasswordreetdropdown={
                              handlepasswordreetdropdown
                            }
                            referenceElement={dropupButtonRef.current}
                          />,
                          document.body
                        )}
                    </li>
                  </div>
                </li>
              </ul>{" "}
            </div>
          </div>
        </aside>
        <button
          className={`toggle-icon ${
            collapsed ? `` : `togglesideauto sidebartogglemob`
          }`}
          onClick={toggleSidebar}
          style={{
            marginTop: 1,
            backgroundColor: "white",
            borderBottomRightRadius: 5,
            border: "1px solid #e2e3e4",
            borderLeft: "none",
            zIndex: 100,
            position: "relative",
          }}
        >
          <FontAwesomeIcon
            icon={faArrowRightArrowLeft}
            className="collapsetogglebtn"
            style={{ color: "rgb(1, 1, 1)", borderWidth: "0px !important" }}
          />
        </button>
      </div>
    </>
  );
}

export default SideBar;
