import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { IsComponentAllowToRole } from "../../Authentication";
import { selectUser } from "../Notification/Slices/UserSlice";
import { useSelector } from "react-redux";
function OrganizationSettingBar({ collapsed, toggleSidebar }) {

  const isAdmin = IsComponentAllowToRole("User Administration")
  // const RoleBaseUI =  useSelector(selectUser);
  return (
    <div className={`IsActiveSideBar ${collapsed ? "" : "active"}`}>
      <div className="text-start">
        <button
          className={`collapsetogglebtn toggle-icon ${
            collapsed ? `` : `togglesideauto sidebartogglemob`
          }`}
          onClick={toggleSidebar}
          style={{ backgroundColor: "#cecece" }}
        >
          <FontAwesomeIcon
            icon={faArrowRightArrowLeft}
            className="collapsetogglebtn"
            style={{ color: "rgb(1, 1, 1)", borderWidth: "0px !important" }}
          />
        </button>
      </div>
      <aside
        className={`sidebar-wrapper  ${collapsed ? "collapsed" : ""}`}
        data-simplebar="true"
        style={{
          overflowY: "auto",
          overflowX: "hidden",
          WebkitOverflowScrolling: "touch", // Enable smooth scrolling on iOS devices
          WebkitScrollbarWidth: "thin", // Adjust scrollbar width as needed
          scrollbarWidth: "thin", // Adjust scrollbar width as needed
          msOverflowStyle: "none", // Hide scrollbar on IE and Edge

          // Change scrollbar track color
          "&::-webkit-scrollbar": {
            width: "10px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888",

            // Change scrollbar thumb color
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#ddd",

            // Change scrollbar track color
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#666", // Change scrollbar thumb color on hover
          },
        }}
      >
        <div className={`sidebar-header ${collapsed ? "collapsed" : ""}`}>
          <Link to="/Home">
            <img
              src="assets/images/LogoVixo.png"
              className="logo-icon"
              alt="logo icon"
              style={{ width: "180px", height: "101px" }}
            />
          </Link>
        </div>
        <ul className="metismenu" id="menu" style={{ color: "black" }}>
          <li className=" fw-bold">
            <h5 className="text-primary fw-bold ">General Settings</h5>
          </li>

          <li>
            <Link to="/General"> General</Link>
          </li>
          <li>
            <Link to="/DisallowNotifications">Notifications</Link>
          </li>
          <li>
            <Link to="/UserPreferences">User Preferences</Link>
          </li>
          <li>
            <Link to="/PasswordReset"> Password Reset</Link>
          </li>
          {isAdmin && (
            <>
              <h5 className="text-primary fw-bold">User Administration</h5>
              <li>
                <Link to="/Users">Users</Link>
              </li>
              <li>
                <Link to="/Companyinfo"> Company Info</Link>
              </li>
              <li>
                <Link to="/PaymentAnalytics"> Payment Analytics</Link>
              </li>
              <li>
                <Link to="/FundingRequest">Funding Request</Link>
              </li>

              <li>
                <Link to="/Achworks">ACH Works</Link>
              </li>

              <br />

              <h5 className="text-primary fw-bold">Company Consent</h5>
              <li>
                <Link to="/CompanyConsent">Consent</Link>
              </li>

              <br />

              <h5 className="text-primary fw-bold">DocuSign</h5>
              <li>
                <Link to="/DocuCompanyDocuments"> Company Documents</Link>
              </li>
              <li>
                <Link to="/DocuCustomerDocuments"> Sent Documents </Link>
              </li>
              {/* <li>
            <Link to="/organizationDocumnetStatus">
              {" "}
              Organization Documents{" "}
            </Link>
          </li> */}
              <br />

              <h5 className="text-primary fw-bold">Billing And Activity</h5>
              <li>
                <Link to="/Pricing">Pricing</Link>
              </li>
              <li>
                <Link to="/Billing"> Billing</Link>
              </li>
              <li>
                <Link to="/Activity">Activity Log</Link>
              </li>
            </>
          )}
        </ul>
      </aside>
    </div>
  );
}

export default OrganizationSettingBar;
