import React from 'react';

const PhoneNumber = ({ number }) => {
  let formattedNumber = "";

  if (number) {
    if (number.startsWith("+(") && number.endsWith(")")) {
      formattedNumber = number;
    } else {
      const digitsOnly = number.replace(/\D/g, '');

      if (digitsOnly.length === 10) {
        formattedNumber = `(${digitsOnly.slice(0, 3)}) ${digitsOnly.slice(3, 6)}-${digitsOnly.slice(6, 10)}`;
      } else {
        formattedNumber = number;
      }
    }
  }

  return <div>{formattedNumber}</div>;
};

export default PhoneNumber;
