import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

function ErrorComponent({ ErrorMessage,ErrorType=0 }) {
  if(ErrorMessage=="")
  {

    ErrorMessage="An error occurred.";
  }
  var errorMessage = ErrorMessage; 
  if(ErrorType==0)
  {
  toast.error(errorMessage); 
  }else if(ErrorType==1)
  {

    toast.warning(errorMessage);
  }
  else if(ErrorType==2)
  {

    toast.info(errorMessage);
  }
  else if(ErrorType>2)
  {

    toast.success(errorMessage);
  }
  
  return null; 
}

export default ErrorComponent;
