import TopnavBar from "../Header/TopnavBar";
import OrganizationSettingBar from "../Header/OrganizationSettingBar";
import { faGears } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { VixoAPI } from "../../settings";
import { APIHeader, ExpireToken, HasLoggedInUser } from "../../APICallWrapper";
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Breadcrumbs from "../HomeVixo/Breadcrumbs";
import { useNavigate } from "react-router-dom";
const Pricing = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [finicityRows, setFinicityRows] = useState([]);
  const [vixoRows, setVixoRows] = useState([]);
  const [plaidRows, setPlaidRows] = useState([]);
  const [StatementOcrRows, setStatementOcrRows] = useState([]);
  const [IdVerificationRows, setIdVerificationRows] = useState([]);
  const [DocuSingRows, setDocuSingRows] = useState([]);
  const navigate=useNavigate();
  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };
  const sidebarStyle = {
    marginLeft: collapsed ? "" : "250px",
  };
  
  function getPricingDetail() {
    if(HasLoggedInUser())
      {
        const config = APIHeader();
    try {
      const APIPath = VixoAPI.BillingAPI + "getPricingDetail";
      axios
        .get(APIPath,{
         headers:config.headers
        })
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            setGroupsData(response.data);
          } else {
          }
        })
        .catch((error) => {
          if(error.response && error.response.status && error.response.status==401)
            {
              ExpireToken(navigate);
            }
        })
        .finally(() => {});
    } catch (error) {
      throw error;
    }
  }
  else{
    ExpireToken(navigate)
  }
  }
  useEffect(() => {
    getPricingDetail();
  }, []);

  function filterRowsByName(data, groupName) {
    const filteredRows = data.filter((object) => object.group === groupName && object.isActive);
    return filteredRows;
  }
  
  function setGroupsData(data) {
    var finicityRows = filterRowsByName(data, "Bank Data");
    setFinicityRows(finicityRows);
    var vixoRows = filterRowsByName(data, "Vixo");
    setVixoRows(vixoRows);
    var plaidRows = filterRowsByName(data, "Plaid");
    setPlaidRows(plaidRows);
    var StatementOcrRows = filterRowsByName(data, "Statement OCR");
    setStatementOcrRows(StatementOcrRows);
    var IdVerificationRows = filterRowsByName(data, "ID Verification");
    setIdVerificationRows(IdVerificationRows);
    var DocuSingRows = filterRowsByName(data, "DocuSign");
    setDocuSingRows(DocuSingRows);
  }
  
  return (
    <>
    <TopnavBar/>
      <OrganizationSettingBar
        collapsed={collapsed}
        toggleSidebar={toggleSidebar}
      />

      <main className="page- " style={sidebarStyle}>
        <div className="container-fluid  p-3 bg-white col-lg-12 col-sm-12">
          <div className="row">
            <div className="col-lg-12 mx-auto">
              <div className="card">
                <div className="card-header py-3 bg-transparent">
                  <h5 className="mb-0 d-inline">Setting</h5>
                  <FontAwesomeIcon
                    icon={faGears}
                    className="fa-2x"
                    style={{ width: "25px" }}
                  />
                  <Breadcrumbs />
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <h4 className="mt-4 mx-3">Pricing</h4>
                  </div>
                  <div className="col-md-4">
                    <h3 className="mt-3" style={{ textAlign: "center" }}>
                      Banking Data
                    </h3>
                  </div>
                  <div className="col-md-4"></div>
                </div>

                <div className="" style={{ padding: "1pc" }}>
                  <div className="row">
                    <div className="col-md-8">
                      {finicityRows.map((row) => (
                        <div key={row}>
                          <h5  style={{
                              fontWeight: "700",
                              textDecoration: "underline",
                            }}>
                            {row.name} : ${row.price}.00
                          </h5>

                          <p>{row.description}</p>
                        </div>
                      ))}
                      {plaidRows.map((row) => (
                        <div key={row}>
                          <h5  style={{
                              fontWeight: "700",
                              textDecoration: "underline",
                            }}>
                            {row.name} : ${row.price}.00
                          </h5>

                          <p>{row.description}</p>
                        </div>
                      ))}
                      {vixoRows.map((row) => (
                        <div key={row}>
                          <h5  style={{
                              fontWeight: "700",
                              textDecoration: "underline",
                            }}>
                            {row.name} : ${row.price}.00
                          </h5>

                          <p>{row.description}</p>
                        </div>
                      ))}
                    </div>
                    <div className="col-md-4 text-end">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          /* flexWrap: 'wrap', */
                          alignContent: "stretch",
                          justifyContent: "space-evenly",
                          alignItems: "flex-end",
                        }}
                      >
                        <img
                          src="assets/images/FincityMasterLogo.png"
                          className="logo-icon"
                          alt="logo icon"
                          style={{ width: "45%" }}
                        />

                        <img
                          src="assets/images/PlaidLogo.png"
                          className="logo-icon mt-5"
                          alt="logo icon"
                          style={{ width: "40%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className=""
                  style={{ borderTop: "1px dashed", padding: "1pc" }}
                >
                  <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                      <h3 className="mt-3" style={{ textAlign: "center" }}>
                        Statement OCR
                      </h3>
                    </div>
                    <div className="col-md-4"></div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-8">
                      {StatementOcrRows.map((row) => (
                        <div key={row}>
                          <h5
                          style={{
                            fontWeight: "700",
                            textDecoration: "underline",
                          }}
                          >
                            {row.name} : ${row.price}.00
                          </h5>

                          <p>{row.description}</p>
                        </div>
                      ))}
                    </div>
                    <div className="col-md-4 text-end">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignContent: "stretch",
                          justifyContent: "space-evenly",
                          alignItems: "flex-end",
                        }}
                      >
                        
                        <img
                          src="assets/images/logoVixo.png"
                          className="logo-icon mt-1"
                          alt="logo icon"
                          style={{ width: "50%" }}
                        />
                        <img
                          src="assets/images/OcrolusLogo.png"
                          className="logo-icon"
                          alt="logo icon"
                          style={{ width: "55%" }}
                        />

                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className=""
                  style={{ borderTop: "1px dashed", padding: "1pc" }}
                >
                  <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                      <h3 className="mt-3" style={{ textAlign: "center" }}>
                        ID Verification
                      </h3>
                    </div>
                    <div className="col-md-4"></div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-8">
                      {IdVerificationRows.map((row) => (
                        <div key={row}>
                          <h5
                          
                          style={{
                            fontWeight: "700",
                            textDecoration: "underline",
                          }}
                          
                          >
                            {row.name} : ${row.price}.00
                          </h5>

                          <p>{row.description}</p>
                        </div>
                      ))}
                    </div>
                    <div className="col-md-4 text-end">
                    
                    </div>
                  </div>
                </div>
                <div
                  className=""
                  style={{ borderTop: "1px dashed", padding: "1pc" }}
                >
                  <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                      <h3 className="mt-3" style={{ textAlign: "center" }}>
                        DocuSign
                      </h3>
                    </div>
                    <div className="col-md-4"></div>
                  </div>
                  <div className="row">
                    <div className="col-md-8 mt-4">
                      {DocuSingRows.map((row) => (
                        <div key={row}>
                          <h5  style={{
                              fontWeight: "700",
                              textDecoration: "underline",
                            }}
                         
                          >
                            {row.name} : ${row.price}.00
                          </h5>

                          <p>{row.description}</p>
                        </div>
                      ))}
                    </div>
                    <div className="col-md-4 text-end">
                      <img
                        src="assets/images/docusign.svg"
                        className="logo-icon"
                        alt="logo icon"
                        style={{ width: "60%" }}
                      />
                    </div>
                    <div
                  className=""
                  style={{ borderTop: "1px dashed", padding: "1pc" }}
                >
                  <div className="col-md-12 mt-5">
                      <h5  style={{
                              fontWeight: "700",
                              textDecoration: "underline",
                            }}>Volume Spend Discounts Tiers:</h5>
                      <p>Monthly Spend $10,000-$25,000 10% OFF</p>
                      <p>Monthly Spend $25,001-$50,000 15% OFF</p>
                      <p>Monthly Spend $50,001+ 20% OFF</p>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div></div>
        </div>
      </main>
    </>
  );
};

export default Pricing;
