import React, { useState, useEffect } from "react";
import "./TransferRequest.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faEnvelope, faPhone, faBuilding,faExchangeAlt  } from "@fortawesome/free-solid-svg-icons";
import Spinner from "../../Spinner";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { VixoAPI } from "../../settings";

const TransferRequest = () => {
  const [otpValue, setOTPValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [userKey, setUserKey] = useState("");
  const [transferKey, setTransferKey] = useState("");
  const [showOtpButton, setShowOtpButton] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const TransferRequestKey = params.get("ID");
    if (TransferRequestKey) {
      setTransferKey(TransferRequestKey);
    }
  }, [location]);

  useEffect(() => {
    const fetchCustomerDetails = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${VixoAPI.CustomerAPI}GetTransferRequest?TransferRequestKey=${transferKey}`
        );
        if (response.data.statusCode === 200) {
          setCustomer(response.data.data);
          setAlertMessage(response.data.message);
          setAlertType("success");
          setShowOtpButton(true);
        } else if (response.data.statusCode === 501 || response.data.statusCode === 208) {
          setAlertMessage(response.data.message);
          setCustomer(response.data.data);
          setAlertType("danger");
          setShowOtpButton(false);
        } else {
          setAlertMessage(response.data.message);
          setAlertType("danger");
        }
      } catch (error) {
        setAlertMessage(error.message);
        setAlertType("danger");
      } finally {
        setLoading(false);
      }
    };
    if (transferKey) {
      fetchCustomerDetails();
    }
  }, [transferKey]);

  const handleStartProcess = async () => {
    // if (!customer || !customer.email) {
    //   setAlertMessage("Customer email not available.");
    //   setAlertType("danger");
    //   return;
    // }
    const encodedEmail = encodeURIComponent(customer.email);
    try {
      const SigninAPIPath = `${VixoAPI.AuthenticationAPI}createOTP?email=${encodedEmail}`;
      setLoading(true);
      const response = await axios.get(SigninAPIPath);
      if (response.data.statusCode === 200) {
        setAlertMessage(response.data.message);
        setAlertType("success");
        setUserKey(response.data.data);
        setShowOTPInput(true);
      } else {
        setAlertMessage(response.data.message);
        setAlertType("danger");
      }
    } catch (error) {
      setAlertMessage(error.message);
      setAlertType("danger");
    } finally {
      setLoading(false);
    }
  };

  const handleOTPChange = (e) => {
    setOTPValue(e.target.value);
  };

  const handleOTPVerification = async () => {
    const data = {
      UserKey: userKey,
      Otp: otpValue,
    };
    try {
      const OtpVerifyOrganization = `${VixoAPI.AuthenticationAPI}verifyOTP`;
      setLoading(true);
      const response = await axios.post(OtpVerifyOrganization, data);
      if (response.data.statusCode === 200) {
        setAlertMessage(response.data.message);
        setAlertType("success");
        setShowOTPInput(false);
        setOtpVerified(true);
      } else {
        setAlertMessage(response.data.message);
        setAlertType("danger");
      }
    } catch (error) {
      setAlertMessage(error.message);
      setAlertType("danger");
    } finally {
      setLoading(false);
    }
  };

  const handleCompleteTransfer = async () => {
    try {
      setLoading(true);
      const ApproveTransferRequestAPI = `${VixoAPI.CustomerAPI}ApproveTransferRequest?TransferRequestKey=${transferKey}`;
      const response = await axios.get(ApproveTransferRequestAPI);
      if (response.data.statusCode === 200) {
        setAlertMessage("Transfer Request is Completed.");
        setAlertType("success");
      } else {
        setAlertMessage(response.data.message);
        setAlertType("danger");
      }
    } catch (error) {
      setAlertMessage(error.message);
      setAlertType("danger");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Spinner show={loading} />
      <main className="authentic-content-one">
        <div className="card-one">
          <div className="header-one">Transfer Request</div>
          {alertMessage && (
            <div
              className={`alert alert-${alertType}`}
              role="alert"
              dangerouslySetInnerHTML={{ __html: alertMessage }}
            />
          )}
          <div className="customer-info-one">
            <p>
              <FontAwesomeIcon icon={faBuilding} /> <strong>Organization Name: </strong> {customer && customer.organizationName}
            </p>
            <p>
              <FontAwesomeIcon icon={faUser} /> <strong>Name: </strong> {customer && customer.customerName}
            </p>
            <p>
              <FontAwesomeIcon icon={faEnvelope} /> <strong>Email: </strong> {customer && customer.customerEmail}
            </p>

            <p>
              <FontAwesomeIcon icon={faPhone} /> <strong>Phone No: </strong> {customer && customer.customerPhoneNumber}
            </p>

            <p>
              <FontAwesomeIcon icon={faExchangeAlt } /> <strong>Transfer Type: </strong> {customer && customer.transferTypeName}
            </p>

        
          </div>
          {!showOTPInput && !otpVerified && showOtpButton && (
            <div className="button-group">
              <button
                className="btn-secondary"
                onClick={handleStartProcess}
                disabled={loading}
              >
                {"Send OTP"}
              </button>
            </div>
          )}
         
          {showOTPInput && !otpVerified && (
            <>
              <div className="label">Enter OTP:</div>
              <div className="input-group">
                <input
                  type="text"
                  name="otp"
                  placeholder="Enter OTP"
                  value={otpValue}
                  onChange={handleOTPChange}
                  disabled={loading}
                />
              </div>
              <div className="button-group">
                <button
                  className="btn-secondary"
                  onClick={handleOTPVerification}
                  disabled={loading}
                >
                  {"Verify OTP"}
                </button>
              </div>
            </>
          )}
          {otpVerified && (
            <div className="button-group">
              <button
                className="btn-primary"
                onClick={handleCompleteTransfer}
                disabled={loading}
              >
                {"Complete Transfer"}
              </button>
            </div>
          )}
        </div>
      </main>
    </>
  );
};

export default TransferRequest;
