import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import TopnavBar from "../Header/TopnavBar";
import {
  faEye,
  faPaperPlane,
  faArrowRotateRight,
  faGears,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import Spinner from "../../Spinner";
import { OverlayTrigger, Tooltip, Card, Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { VixoAPI } from "../../settings";
import { APIHeader, ExpireToken, HasLoggedInUser } from "../../APICallWrapper";
import OrganizationSettingBar from "../Header/OrganizationSettingBar";
import Breadcrumbs from "../HomeVixo/Breadcrumbs";
import "./DocuCompanyDocuments.css";
import { CheckAllowComponentAndRedirect} from "../../Authentication";
import { useNavigate } from "react-router-dom";
const DocuCompanyDocuments = () => {
  const navigate =  useNavigate();
  const [loading, setLoading] = useState(false);
  const [docuSignAuth, setdocuSignAuth] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [docuSignFiles, setDocuSignFiles] = useState([]);
  const [APIResponse, setAPIResponse] = useState(null);
  const [OrganizationDocuments, setOrganizationDocuments] = useState(null);
  const [docOrganization, setDocOrganizationId] = useState("");
  const [selectedDocument, setSelectedDocument] = useState([]);
  const [documents, setdocument] = useState(null);
  const [isAddingSignature, setIsAddingSignature] = useState(false);
  const [signatureCanvas, setSignatureCanvas] = useState(null);
  ////////////signature
  const [signaturePosition, setSignaturePosition] = useState({ x: 0, y: 0 });
  const [dragStartX, setDragStartX] = useState(0);
  const [dragStartY, setDragStartY] = useState(0);
  const [signatureFields, setSignatureFields] = useState([]);
  const [pdfDoc, setPdfDoc] = useState(null);
  const [showModaldelete, setShowModaldelete] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState(null);
  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };
  const sidebarStyle = {
    marginLeft: collapsed ? "" : "250px",
  };
  const [Userkey, setUserkey] = useState(null);
  const [Userinfo, setUserInfo] = useState(null);
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedDocument([]);
  };

  function AssignDocuSignFiles(docusignAPIData) {
    if (docusignAPIData) {
      const DocusignFilesSource = [];
      var docusignFiles = JSON.parse(JSON.stringify(docusignAPIData));
      docusignFiles.forEach((docusignFile) => {
        var docusignFileRequest = {
          Id: docusignFile.id,
          IsActive: docusignFile.isActive,
          Title: docusignFile.documentTitle,
          DocumentURL: docusignFile.documentUrl,
          Status: docusignFile.status,
          IsSelected: false,
        };
        DocusignFilesSource.push(docusignFileRequest);
      });
      setDocuSignFiles(DocusignFilesSource);
    }
  }
  function GetOrganizationDocuments(OrganizationID) {
    if(HasLoggedInUser()){
      const config=APIHeader();
    try {
      const apiUrl =
        VixoAPI.FinicityAPI +
        "GetOrganizationDocuments?OrganizationID=" +
        OrganizationID;
      setLoading(true);
      axios
        .post(apiUrl,{
         headers:config.headers
        })
        .then((response) => {
          const responseData = response.data;
          if (responseData.statusCode === 200) {
            // var type = null;
            //  type: GetDocumentType(element.title);
            setOrganizationDocuments(responseData.data);
          } else {
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error("Error in GetOrganizationDocuments:", error);
      setLoading(false);
    }
  }else{
    ExpireToken(navigate)
  }
  }
  function loadUserforOrgId() {
    const loggedInUser = localStorage.getItem("User");
    var userObject = JSON.parse(loggedInUser);
    
    const ComponentName="User Administration";
    CheckAllowComponentAndRedirect(ComponentName, navigate)
    if (userObject) {
      setUserInfo(userObject);
      GetOrganizationDocuments(userObject.organizationId);
    }
  }

  useEffect(() => {
    loadUserforOrgId();
  }, []);
  useEffect(() => {
    if (Userinfo && Userinfo.organizationId !== null) {
      GetOrganizationDocuments(Userinfo.organizationId);
    }
  }, [Userinfo]);

  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      setSelectedDocument([...selectedDocument, ...files]);
      setShowModal(true);
    }
  };

  function GetDocumentType(DocumentTitle) {
    if (DocumentTitle.toLowerCase().includes("pdf")) {
      return "application/pdf";
    } else {
      return "application/msword";
    }
  }
  ///
  const renderFilePreview = () => {
    return (
      selectedDocument &&
      selectedDocument.map((document, index) => (
        <div key={index}>
          <h5>File Name: {document.name}</h5>
          {document.type.startsWith("image") ? (
            <img src={URL.createObjectURL(document)} alt="Preview" />
          ) : (
            <object
              data={URL.createObjectURL(document)}
              type={document.type}
              width="100%"
              height="500px"
              style={{ margin: "20px" }}
            >
              <p>
                This browser does not support{" "}
                {document.type.includes("pdf") ? "PDFs" : "DOCX files"}. Please
                download the{" "}
                {document.type.includes("pdf") ? "PDF" : "DOCX file"} to view
                it.
              </p>
            </object>
          )}
        </div>
      ))
    );
  };

  const handleFileUpload = async () => {
    setLoading(true);
    const data = new FormData();
    data.append("UserKey", Userinfo.key);

    if(HasLoggedInUser())
      {
        const config= APIHeader();
    try {
      for (let i = 0; i < selectedDocument.length; i++) {
        const file = selectedDocument[i];
        const fileType = file.name.split(".").pop();
        if (fileType === "docx" || fileType === "pdf") {
          data.append("Files", file);
        } else {
          toast.error(
            `Invalid file type: ${file.name}. Only .docx and .pdf files are allowed.`
          );
        }
      }
      const contractAPIPath =
        VixoAPI.FinicityAPI + "CreateOrganizationDocuments";
      const response = await axios.post(contractAPIPath, data,{
       headers:config.headers
      });
      if (response.status === 200) {
        toast.success("Documents uploaded successfully!");
        // call this function GetOrganizationDocuments for state update
        GetOrganizationDocuments(Userinfo.organizationId);
        setSelectedDocument([]);
        setShowModal(false);
      } else {
        toast.error("Failed to upload documents.");
        
      }
    } catch (error) {
      toast.error("Failed to upload documents.");
     
    } finally {
      setLoading(false);
     
    }
  }
  else{
    ExpireToken(navigate);
  }
  };
  function DeleteOrgDocument(DocId) {
    if(HasLoggedInUser())
      {
        const config=APIHeader();
    try {
      const apiUrl =
        VixoAPI.FinicityAPI + "DeleteOrgDocument?OrganizationID=" + DocId;
      setLoading(true);
      axios
        .post(apiUrl,{
         headers:config.headers
        })
        .then((response) => {
          const responseData = response.data;
          if (responseData.statusCode === 200) {
            setOrganizationDocuments(responseData.data);
            toast.success(responseData.message);
          } else {
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error("Error in GetOrganizationDocuments:", error);
      setLoading(false);
    }
  }
  else{
    ExpireToken(navigate);
  }
}
  const handleDeleteClick = (document) => {
    setDocumentToDelete(document);
    setShowModaldelete({ show: true, title: document.title });
  };
  const handleCancelDelete = () => {
    setDocumentToDelete(null);
    setShowModaldelete(false);
  };
  const handleConfirmDelete = () => {
    DeleteOrgDocument(documentToDelete.id);
    setShowModaldelete(false);
  };

  return (
    <>
      <div>
        <Spinner show={loading} />
        <TopnavBar />
        <ToastContainer />

        <OrganizationSettingBar
          collapsed={collapsed}
          toggleSidebar={toggleSidebar}
        />

        <Modal className="companydoc-modal" show={showModaldelete} onHide={handleCancelDelete}>
          <Modal.Header closeButton>
            <Modal.Title>{showModaldelete.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete this document?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCancelDelete}>
              Cancel
            </Button>
            <Button variant="danger" onClick={handleConfirmDelete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showModal} onHide={handleCloseModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>
              {selectedDocument && (
                <div>
                  <p>File Name: {selectedDocument.name}</p>
                  {/* Add additional information about the selected file as needed */}
                </div>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="mt-5">{renderFilePreview()}</Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={handleFileUpload}
              disabled={loading}
            >
              {loading ? "Uploading..." : "Upload"}
            </Button>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <main className="Gen" style={sidebarStyle}>
          <div className="container-fluid bg-white col-lg-12 col-sm-12">
            <div className="row">
              <div className="col-lg-12 mx-auto">
                <div className="card">
                  <div className="card-header py-3 bg-transparent">
                    <h5 className="mb-0 d-inline">Setting</h5>
                    <FontAwesomeIcon
                      icon={faGears}
                      className="fa-2x "
                      style={{ width: "25px" }}
                    />
                    <Breadcrumbs />

                    {/* ------------------------   For a Email Doucment Start -------------------------  */}

                    {/* ------------------------ Start Refresh Button ------------------------- */}

                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-md-12">
                          <div
                            className="text-end "
                            style={{ marginRight: "39px" }}
                          ></div>
                        </div>
                      </div>
                    </div>

                    {/* ------------------------ End Refresh Button  ------------------------- */}
                    {/*-------------------------  Additional Files Upload Start -------------------------  */}
                    <div className="container-fluid" style={{ padding: "1pc" }}>
                      <div className="row">
                        <div className="col-md-12 mx-auto">
                          <div className="card">
                            <div class="card-header py-3 bg-transparent">
                              <h5 class="mb-0">Upload Company Doucments </h5>
                            </div>
                            <div
                              className="card-body"
                              style={{ padding: "13px" }}
                            >
                              <div className="container">
                                <div
                                  className="row"
                                  style={{ justifyContent: "center" }}
                                >
                                  <div className="col-md-8">
                                    <fieldset
                                      className="upload_dropZone text-center mb-3 mt-3 p-4 dropzone"
                                      style={{
                                        height: "10pc",
                                        cursor: "pointer",
                                        color: "#999",
                                      }}
                                    >
                                      <legend className="visually-hidden">
                                        Image uploader
                                      </legend>

                                      <label
                                        htmlFor="formFileLg"
                                        style={{
                                          display: "block",
                                          width: "100%",
                                          height: "100%",
                                          cursor: "cursor ",
                                        }}
                                      >
                                        <img
                                          src="assets/images/fileuploader.svg"
                                          alt="File uploader"
                                        />
                                        <p
                                          className="my-2"
                                          style={{ fontSize: "20px" }}
                                        >
                                          <p
                                            style={{
                                              fontSize: "20px",
                                              fontWeight: "600",
                                              color: "#004265",
                                            }}
                                          >
                                            Upload a File
                                          </p>
                                        </p>

                                        <input
                                          id="formFileLg"
                                          data-post-name="image_background"
                                          className="position-absolute invisible"
                                          type="file"
                                          onChange={handleFileChange}
                                          multiple
                                        />

                                        <span
                                          className="mb-3"
                                          style={{
                                            marginTop: "-11px",
                                            color: "#004265",
                                            fontSize: "16px",
                                            cursor: "cursor ",
                                            fontWeight: "300",
                                          }}
                                        >
                                          Drag and drop files here
                                        </span>
                                      </label>
                                    </fieldset>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*-------------------------  Additional Files Upload End -------------------------  */}

                    {/* ------------------------ Start for Company Doucment List ------------------------- */}

                    <div className="container-fluid" style={{ padding: "1pc" }}>
                      <div className="row">
                        <div className="col-md-12 mx-auto">
                          <div className="card">
                            <div class="card-header py-3 bg-transparent">
                              <h5 class="mb-0" style={{ marginLeft: "3pc" }}>
                                Company Documents
                              </h5>

                              <div
                                style={{ textAlign: "center", float: "right" }}
                              ></div>
                            </div>
                            <Row>
                              <div className="row">
                                {OrganizationDocuments &&
                                  OrganizationDocuments.map(
                                    (document, index) => (
                                      <Col
                                        key={index}
                                        md={6}
                                        lg={4}
                                        xl={4}
                                        className="mb-4"
                                      >
                                        <Card
                                          className="shadow-lg"
                                          style={{
                                            height: "26pc",
                                            cursor: "pointer",
                                            padding: "5px",
                                          }}
                                        >
                                          <Card.Header className="p-3 d-flex justify-content-center align-items-center col-md-12">
                                            <div className="col-md-11">
                                              <Card.Title
                                                className="mb-0"
                                                style={{ fontWeight: "500" }}
                                              >
                                                <h5>{document.title}</h5>
                                              </Card.Title>
                                            </div>
                                            <div className="col-md-1">
                                              <FontAwesomeIcon
                                                icon={faTrash}
                                                onClick={() =>
                                                  handleDeleteClick(document)
                                                }
                                                style={{
                                                  cursor: "pointer",
                                                  color: "red",
                                                }}
                                              />
                                            </div>
                                          </Card.Header>
                                          <Card.Body>
                                            <div className="position-relative">
                                              <div>
                                                {/* Assuming the document has a 'DocumentUrl' property */}
                                                {/* <embed
                                              type="application/pdf"
                                              src={document.documentUrl}
                                              className=" border-0"
                                              style={{
                                                overflow: "hidden",
                                                height: "300px",
                                                cursor: "pointer",
                                                width: "100%",
                                              }}
                                            /> */}
                                                {document &&
                                                document.title
                                                  .toLowerCase()
                                                  .includes("pdf") ? (
                                                  <embed
                                                    type="application/pdf"
                                                    src={document.documentUrl}
                                                    className="border-0"
                                                    style={{
                                                      overflow: "hidden",
                                                      height: "300px",
                                                      cursor: "pointer",
                                                      width: "100%",
                                                    }}
                                                  />
                                                ) : (
                                                  <>
                                                    {document.title
                                                      .toLowerCase()
                                                      .includes("docx") ? (
                                                      <div
                                                      className="docx"
                                                        style={{
                                                          margin: "100px",
                                                        }}
                                                      >
                                                        This is a DOCX file.
                                                        Preview is not
                                                        available.{" "}
                                                        <a
                                                          href={
                                                            document.documentUrl
                                                          }
                                                          download
                                                        >
                                                          Download
                                                        </a>
                                                      </div>
                                                    ) : (
                                                      <iframe
                                                        title={document.title}
                                                        src={
                                                          document.documentUrl
                                                        }
                                                        className="border-0"
                                                        style={{
                                                          overflow: "hidden",
                                                          height: "300px",
                                                          cursor: "pointer",
                                                          width: "100%",
                                                        }}
                                                        sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                                                      />
                                                    )}
                                                  </>
                                                )}
                                              </div>
                                            </div>
                                          </Card.Body>
                                        </Card>
                                      </Col>
                                    )
                                  )}
                              </div>

                              {/* ))} */}
                            </Row>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*-------------------------  End for Company Doucment List -------------------------  */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default DocuCompanyDocuments;
