
// export function RedirectUserToLandingPage(User,navigate)
// {
   
//    if(User.role===10) // Lead Gen
//    {
//     navigate("/home");

//    }
// }

// // const RoleLandingPages = [
// //     { 1: "/Home" },
// //     { 2: "/Home" },
// //     {10: "/Home" },
// // ];

// export function IsRoleAllowed(DissllowRoles,navigate)
//   {
//     const loggedInUser = localStorage.getItem("User");

    
//     var userObject = JSON.parse(loggedInUser);
//     if (DissllowRoles.findIndex(p=>p===userObject.role)>-1)
//     {
//         return RedirectUserToLandingPage(userObject,navigate);
//     }
  
//   }
//   export function IsRoleDisaAllowed(DissllowRoles)
//   {
//     const loggedInUser = localStorage.getItem("User");
//     var userObject = JSON.parse(loggedInUser);
//     if (DissllowRoles.findIndex(p=>p===userObject.role)>-1)
//     {
//        return true;
//     }
//     return false;
  
//   }

//   export function RoleBaseUI(disallowedRoles) {
//     const loggedInUser = localStorage.getItem("User");
//     const userObject = JSON.parse(loggedInUser);
  
//     if (disallowedRoles.includes(userObject.role)) {
//       return false; 
//     }
    
//     return true;
//   }
  
///////////////////////////////////////////////////////////////////////
        const RoleLandingPages = [
            { 1: "/Home" },
            { 2: "/Home" },
            {10: "/ResendInvite" },
        ];

      const AllowUIOnRoles = [
          { 1: ["*"] },
          {2:  ["*"] },
          { 10: ["Resend Invite","Edit Customer"] },
         ];

      function getLandingPageByRoleId(roleId) {
            // Find the item based on the given role ID
            const foundItem = RoleLandingPages.find(
              (item) => Object.keys(item)[0] === roleId.toString()
            );

            // If the item is found, return the landing page associated with that role ID
            if (foundItem) {
              const roleId = Object.keys(foundItem)[0];
              return foundItem[roleId];
            } else {
              // If no item is found for the given role ID, return a default landing page or handle the case as appropriate
              return "/Home";
            }
      }
      

     function getComponentsByRoleId(roleId) {
          // Find the item based on the given role ID
          const foundItem = AllowUIOnRoles.find(
            (item) => Object.keys(item)[0] === roleId.toString()
          );

          // If the item is found, return the array of components associated with that role ID
          if (foundItem) {
            const roleId = Object.keys(foundItem)[0];
            return foundItem[roleId];
          } else {
            // If no item is found for the given role ID, return an empty array or handle the case as appropriate
            return [];
          }
      }

      export function IsComponentAllowToRole(ComponentName) {
          const loggedInUser = localStorage.getItem("User");
          var hasComponent = false;
          if (loggedInUser) {
            const userObject = JSON.parse(loggedInUser);

            if (userObject && userObject.role && AllowUIOnRoles) {
              const foundComponents = getComponentsByRoleId(userObject.role);

              if (foundComponents && foundComponents.length > 0) {
                return (
                  foundComponents.includes(ComponentName) ||
                  foundComponents.includes("*")
                );
              }
              return false;
            }
          }

          return false;
      }
      export function CheckAllowComponentAndRedirect(ComponentName, navigate) {
          const loggedInUser = localStorage.getItem("User");

          if (loggedInUser) {
            const userObject = JSON.parse(loggedInUser);
            var isComponentAllowed = IsComponentAllowToRole(ComponentName);
            if (!isComponentAllowed || isComponentAllowed == false) {
              var LandingPageForRole = getLandingPageByRoleId(userObject.role);
              navigate(LandingPageForRole);
            }
          }
      }


