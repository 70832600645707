import "./App.css";
import React, { useEffect, useState } from "react";
import Home from "./Components/HomeVixo/HomeVixo";
import Invite from "./Components/HomeVixo/Invitation";
import Signin from "./Components/Auth/Signin";
import SignUp from "./Components/Auth/Signup";
import Forget from "./Components/Auth/Forgotpassword";
import Reset from "./Components/Auth/Resetpassword";
import ConfirmEmail from "./Components/Auth/ConfirmEmail";
import General from "./Components/OrganizationsSetting/General";
import UserAdmin from "./Components/OrganizationsSetting/UserAdministration";
import Activity from "./Components/OrganizationsSetting/ActivityLog";
import { Route, Routes, useNavigate } from "react-router-dom";
import Pricing from "./Components/OrganizationsSetting/Pricing";
import Billing from "./Components/OrganizationsSetting/Billing";
import PasswordReset from "./Components/OrganizationsSetting/PasswordReset";
import Users from "./Components/OrganizationsSetting/Users";
import Companyinfo from "./Components/OrganizationsSetting/Companyinfo";
import AddAccount from "./Components/HomeVixo/AddAccount";
import ResendInvite from "./Components/HomeVixo/ResendInvite";
import PaymentAnalytics from "./Components/OrganizationsSetting/PaymentAnalytics";
import AddBankAccountFile from "./Components/HomeVixo/AddBankAccountFile";
import DownloadHome from "./Components/Download/DownloadHome";
import OrgLogo from "./Components/OrganizationsSetting/OrgLogo";
import FundingRequest from "./Components/OrganizationsSetting/FundingRequest";
import Customer from "./Components/CustomerHome/Customer";
import TermsCondition from "./Components/Auth/Terms";
import OrganizationDocusignStauts from "./Components/OrganizationsSetting/OrganizationDocuDignStatus";
import DocuCompanyDocuments from "./Components/OrganizationsSetting/DocuCompanyDocuments";
import ACHwork from "./Components/OrganizationsSetting/ACHwork";
import NotificationsHome from "./Components/Notification/NotificationsHome";
import { addNotification } from "./Components/Notification/Slices/sendNotificationSlice";
import { useDispatch } from "react-redux";
import * as signalR from "@microsoft/signalr";
import { VixoAPI } from "./settings";
import SupportToken from "./Components/Auth/SupportToken";
import DocuCustomerDocuments from "./Components/OrganizationsSetting/DocuCustomerDocuments";
import APIErrorComponent from "./APIErrorComponent";
import DisallowNotification from "./Components/OrganizationsSetting/DisallowNotification";
import UserPreferences from "./Components/OrganizationsSetting/UserPreferences";
import CompanyConsent from "./Components/OrganizationsSetting/CompanyConsent";
import TransferRequest from "./Components/TransferRequest/TransferRequest";
import Layout from "./Components/Layout/Layout";

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [SelectedCustomer, setSelectedCustomer] = useState(null);
  const handleCustomerSelected = (Customer) => {
    setSelectedCustomer(Customer);
  };
  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${VixoAPI.NotificationAPI}notificationHub`) // Adjust the URL based on your server setup
      .build();

    connection
      .start()
      .catch((error) => console.error("SignalR Connection Error:", error));

    connection.on("ReceiveNotification", (receivedNotification) => {
      const { organiationID, cutomerId, notificationType, referenceID } =
        receivedNotification;
      sendNotificationToRedux(receivedNotification);
    });

    function sendNotificationToRedux(receivedNotification) {
      dispatch(addNotification(receivedNotification));
    }

    return () => {
      if (connection.state === signalR.HubConnectionState.Connected) {
        connection.stop();
      }
    };
  }, [dispatch]);

  return (
    <>
      <Routes>
        <Route
          path="/Home"
          element={
            <Layout CustomerSelected={handleCustomerSelected}>
              <Home SelectedCustomer={SelectedCustomer} />
            </Layout>
          }
        />
        <Route
          path="/ResendInvite"
          element={
            <Layout CustomerSelected={handleCustomerSelected}>
              <ResendInvite SelectedCustomer={SelectedCustomer} />
            </Layout>
          }
        />
        <Route
          path="/Welcome"
          element={
            <Layout CustomerSelected={handleCustomerSelected}>
              <OrgLogo SelectedCustomer={SelectedCustomer} />
            </Layout>
          }
        />
      </Routes>

      <Routes>
        <Route path="/notification" element={<NotificationsHome />} />
        <Route path="/AddBankAccountFile" Component={AddBankAccountFile} />

        <Route path="/DownloadHome" element={<DownloadHome />} />
        <Route path="/PaymentAnalytics" Component={PaymentAnalytics} />

        <Route path="/" Component={Signin} />

        <Route path="/AddAccount" Component={AddAccount} />
        {/* <Route path="/Home" Component={Home} /> */}

        <Route path="/Signin" Component={Signin} />
        <Route path="/SupportToken" Component={SupportToken} />
        <Route path="/Signup" Component={SignUp} />
        <Route path="/Forgot" Component={Forget} />

        <Route path="/Reset" Component={Reset} />
        <Route path="/ConfirmEmail" Component={ConfirmEmail} />
        <Route path="/General" Component={General} />
        <Route path="/Users" Component={Users} />
        <Route path="/Companyinfo" Component={Companyinfo} />
        <Route path="/Pricing" Component={Pricing} />
        <Route path="/Billing" Component={Billing} />
        <Route path="/Administration" Component={UserAdmin} />
        <Route path="/Activity" Component={Activity} />
        <Route path="/Invite" Component={Invite} />
        <Route path="/PasswordReset" Component={PasswordReset} />
        {/* <Route path="/ResendInvite" Component={ResendInvite} /> */}
        <Route path="/FundingRequest" Component={FundingRequest} />
        <Route path="/DisallowNotifications" Component={DisallowNotification} />
        {/* <Route path="/Welcome" Component={OrgLogo} /> */}
        <Route path="/CompanyConsent" Component={CompanyConsent} />
        <Route path="/UserPreferences" Component={UserPreferences} />
        <Route path="/customer" Component={Customer} />
        <Route path="/termscondition" Component={TermsCondition} />
        <Route path="/DocuCompanyDocuments" Component={DocuCompanyDocuments} />
        <Route path="/TransferRequest" Component={TransferRequest} />

        <Route
          path="/organizationDocumnetStatus"
          Component={OrganizationDocusignStauts}
        />
        <Route
          path="/DocuCustomerDocuments"
          Component={DocuCustomerDocuments}
        />
        <Route path="/APIErrorComponent" Component={APIErrorComponent} />

        <Route path="/Achworks" Component={ACHwork} />
      </Routes>
    </>
  );
};

export default App;
