import React, { useLayoutEffect } from "react";
import axios from "axios";
import Sidebar from "../Header/SideBar";
import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Breadcrumbs from "./Breadcrumbs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ErrorComponent from "../../ErrorComponent";
import Spinner from "../../Spinner";
import { VixoAPI } from "../../settings";
import PhoneNumber from "../../VixoFormats/Phone";
import { useSelector } from "react-redux";
import { OpenBankingType } from "../../lookup";
import { selectNotification } from "../Notification/Slices/sendNotificationSlice";
import { APIHeader, ExpireToken, HasLoggedInUser } from "../../APICallWrapper";
import {
  faCommentSms,
  faEnvelope,
  faBuildingColumns,
  faCheck,
  faFileContract,
  faCheckDouble,
  faPercentage,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Tooltip as BootstrapTooltip } from "react-bootstrap";
function ResendInvite({ SelectedCustomer }) {
  const [Organization, setOrganization] = useState({});
  const [
    editedBankLinkInvitationText,
    setEditedBankLinkInvitationText,
  ] = useState(Organization.bankLinkInvitationSms);

  const [selectedRadio, setSelectedRadio] = useState("invitePhone");
  const [collapsed, setCollapsed] = useState(false);
  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };
  const sidebarStyle = {
    width: collapsed ? "100%" : "100%",
    // marginLeft: collapsed ? "" : "250px",
  };
  const location = useLocation();
  const [User, setUser] = useState(null);
  const [Userkey, setUserKey] = useState(null);
  const [SelectedCustomerData, setSelectedCustomer] = useState({});
  const [CustomerKey, setCustomerKey] = useState(null);
  const [invitePhone, setInvitePhone] = useState(true);
  const [inviteEmail, setInviteEmail] = useState(true);
  const [OpenBankingRoles, setOpenBankingRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorType, setErrorType] = useState(null);
  const [org, setOrg] = useState({});
  const [APIResponse, setAPIResponse] = useState(null);
  const [loginCustomerKey, setLoginCustomerKey] = useState("");
  const [isIdentityVerified, setIsIdentityVerified] = useState([]);
  const [userId, setUserId] = useState("");
  const [identityRequired, setIdentityRequired] = useState(true);
  const [customerLastRefreshedOn, setCustomerLastRefreshOn] = useState(null);
  const [ReloadResendCustomer, setReloadResendCustomer] = useState(0);
  const [kyc, setKyc] = useState(true);
  const [getcontracts, setGetContracts] = useState("");
  const [ipaddress, setApiAddress] = useState("");
  const [createdate, setCreateDate] = useState("");
  const [orgid, setOrgId] = useState(null);
  const [
    customerIdentityResponseData,
    setCustomerIdentityResponseData,
  ] = useState();
  const navigate = useNavigate();
  ////////////////////////////////////////////

  const notification = useSelector(selectNotification);
  const LoadData = (CustomerID) => {
    if (CustomerID) {
      setCustomerKey(JSON.stringify(CustomerID));
    }

    const loggedInUser = localStorage.getItem("User");
    var userObject = JSON.parse(loggedInUser);
    if (userObject) {
      setUser(userObject);
      setUserKey(userObject.key);

      setOrgId(userObject.organizationId);
      LoadOrganization(userObject.organizationId);
    } else {
    }
  };
  useEffect(() => {
    if (SelectedCustomer != null) {
      LoadData(SelectedCustomer.id);
      LoadLookups();
      if (SelectedCustomer.inviteEmail === true) {
        setSelectedRadio("inviteEmail");
      } else if (SelectedCustomer.invitePhone === true) {
        setSelectedRadio("invitePhone");
      }
    }
  }, [SelectedCustomer]);

  async function LoadLookups() {
    try {
      OpenBankingType()
        .then((result) => {
          setOpenBankingRoles(result);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (ex) {
      console.error(
        ex + " setOpenBankingRoles Role Lookup Call is not successfull"
      );
    }
  }
  function setError(message) {
    setErrorType(message);
  }
  function LoadOrganization(OrganizationID) {
    if (HasLoggedInUser()) {
      try {
        const OrgnizationUpdateAPIPath =
          VixoAPI.OrganizationAPI + "get?Id=" + OrganizationID;
        axios
          .get(OrgnizationUpdateAPIPath, {
            headers: APIHeader().headers,
          })
          .then((response) => {
            var response = response.data;

            if (response.statusCode === 200) {
              var Organization = JSON.parse(JSON.stringify(response.data));
              if (Organization != null) {
                setOrganization(Organization);
                setEditedBankLinkInvitationText(
                  Organization.bankLinkInvitationSms
                );
              }
            } else {
              setError(response.message);
            }
          })
          .catch((error) => {
            throw error;
          })
          .finally(() => {});
      } catch (error) {
        throw error;
      }
    } else {
      navigate("/");
    }
  }
  const handleCompanyInfoChange = (e) => {
    setOrganization({
      ...Organization,
      bankLinkInvitationSms: e.target.value,
    });
    setEditedBankLinkInvitationText(e.target.value);
  };
  function LoadCustomer(CustomerKey) {
    if (HasLoggedInUser()) {
      const config = APIHeader();
      try {
        var Key = encodeURIComponent(CustomerKey);
        const GetCustomerId = `${VixoAPI.CustomerAPI}getCustomerBykey?key=${Key}`;

        axios
          .get(GetCustomerId, {
            headers: config.headers,
          })
          .then((response) => {
            var response = response.data;
            if (response.statusCode === 200) {
              CustomerData(response.data);
            } else {
            }
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.status &&
              error.response.status == 401
            ) {
              ExpireToken(navigate, CustomerKey);
            }
          })
          .finally(() => {});
      } catch (error) {
        throw error;
      }
    } else {
      ExpireToken(navigate, CustomerKey);
    }
  }
  function CustomerData(data) {
    try {
      var customersdata = JSON.parse(JSON.stringify(data));
      setSelectedCustomer(customersdata);
      if (customersdata.invitePhone) {
        setSelectedRadio("invitePhone");
      } else {
        setSelectedRadio("inviteEmail");
      }
    } catch (error) {
      throw error;
    }
  }

  const sendInvite = async (e) => {
    e.preventDefault();

    const updatedInvitePhone = selectedRadio === "invitePhone";
    const updatedInviteEmail = selectedRadio === "inviteEmail";

    const data = {
      eventType: 0,
      customerID: SelectedCustomer.id,
      invitePhone: updatedInvitePhone,
      inviteEmail: updatedInviteEmail,
      organizationID: SelectedCustomer.organizationId,
      contactMethod: selectedRadio,
      userId: User.id,
      massage: editedBankLinkInvitationText,
      openBankingType: SelectedCustomer.openBankingType,
      contactMethod: selectedRadio,
    };
    if (HasLoggedInUser()) {
      const config = APIHeader();
      try {
        const CreateAccountAPIPath = VixoAPI.CustomerAPI + "resendInvite";
        setLoading(true);
        const response = await axios.post(CreateAccountAPIPath, data, {
          headers: config.headers,
        });

        if (response.status === 200) {
          const responseData = response.data;

          if (responseData.statusCode === 200) {
            toast.success(responseData.message);
            setSelectedCustomer({
              ...SelectedCustomer,
              openBankingType: SelectedCustomer.openBankingType,
            });
          } else {
            toast.error(responseData.message);
          }
        }
      } catch (error) {
        setLoading(false);
        console.error("Error sending invite:", error);
      } finally {
        setLoading(false);
      }
    } else {
      ExpireToken(navigate, CustomerKey);
    }
  };
  const [customerData, setCustomerData] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    inviteEmail: "",
    invitePhone: "",
    openBankingType: "",
    IsEmail: SelectedCustomer != null ? SelectedCustomer.inviteEmail : "",
    IsPhone: SelectedCustomer != null ? SelectedCustomer.invitePhone : "",
  });

  // const handleRadioChange = (e) => {
  //   const value = e;
  //   if (value === "inviteEmail") {
  //     setInvitePhone(false);
  //     setInviteEmail(true);
  //   } else if (value === "invitePhone") {
  //     setInvitePhone(true);
  //     setInviteEmail(false);
  //   }
  //   setSelectedRadio(value);
  // };

  const handleRadioChange = (value) => {
    if (value === "inviteEmail") {
      setInvitePhone(!SelectedCustomer?.invitePhone);
      setInviteEmail(true);
    } else if (value === "invitePhone") {
      setInvitePhone(true);
      setInviteEmail(!SelectedCustomer?.inviteEmail);
    }
    setSelectedRadio(value);
  };

  useEffect(() => {
    if (userId) {
      LoadUser();
    }
  }, [userId]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    var userKey = params.get("U");

    if (userKey != null) {
      setUserId(userKey);
    } else {
    }
  }, []);

  function setUserDataFromAPI(data) {
    try {
      var userObject = JSON.parse(JSON.stringify(data));
      setUser(userObject);
    } catch (error) {
      throw error;
    }
  }

  function LoadUser() {
    if (HasLoggedInUser()) {
      const config = APIHeader();
      try {
        var Key = encodeURIComponent(userId);
        const GetUserByKeyAPI = `${VixoAPI.UserAPI}getUserByKey?key=${Key}`;

        axios
          .get(GetUserByKeyAPI, {
            headers: config.headers,
          })
          .then((response) => {
            var response = response.data;
            if (response.statusCode === 200) {
              setUserDataFromAPI(response.data);
            } else {
            }
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.status &&
              error.response.status == 401
            ) {
              ExpireToken(navigate, CustomerKey);
            }
          })
          .finally(() => {});
      } catch (error) {
        throw error;
      }
    } else {
      ExpireToken(navigate, CustomerKey);
    }
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    var CustomerKey = params.get("Id");
    if (CustomerKey) {
      LoadCustomer(CustomerKey);
    }
  }, [location]);

  const [buttonState, setButtonState] = useState(-1);

  useEffect(() => {
    if (SelectedCustomer != null) {
      const result = ShouldShowButtons();
      setButtonState(result);
    }
  }, [SelectedCustomer, buttonState]);

  useEffect(() => {
    if (
      SelectedCustomer !== null &&
      SelectedCustomer.identityRequired == true
    ) {
      PlaidIdentityVerifiedCustomer(CustomerKey);
    }
  }, [SelectedCustomer]);

  function ShouldShowButtons() {
    if (SelectedCustomer == null) {
      return -1;
    } else if (
      (SelectedCustomer.identityRequired == true &&
        SelectedCustomer.idVerificationProcessStatus == 1) ||
      SelectedCustomer.idVerificationProcessStatus == 2
    ) {
      return 2;
    } else if (
      SelectedCustomer.identityRequired == null ||
      SelectedCustomer.identityRequired == false
    ) {
      return 3;
    } else if (
      SelectedCustomer.identityRequired == true &&
      SelectedCustomer.customerIdentityId !== null
    ) {
      return 4;
    } else {
    }
  }

  function LoadCustomer(CustomerKey) {
    if (HasLoggedInUser()) {
      const config = APIHeader();
      try {
        var Key = encodeURIComponent(CustomerKey);
        const GetCustomerId = `${VixoAPI.CustomerAPI}getCustomerBykey?key=${CustomerKey}`;

        axios
          .get(GetCustomerId, {
            headers: config.headers,
          })
          .then((response) => {
            var response = response.data;
            if (response.statusCode === 200) {
              var customersdata = JSON.parse(JSON.stringify(response.data));
              setSelectedCustomer(customersdata);
            } else {
              // setError(response.message);
            }
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.status &&
              error.response.status == 401
            ) {
              ExpireToken(navigate, CustomerKey);
            }
          })
          .finally(() => {});
      } catch (error) {
        throw error;
      }
    } else {
      ExpireToken(navigate, CustomerKey);
    }
  }

  async function GetCustomerByKey(CustomerKey) {
    if (HasLoggedInUser()) {
      const config = APIHeader();
      try {
        const Key = encodeURIComponent(CustomerKey);
        const response = await axios.get(
          `${VixoAPI.CustomerAPI}getCustomerBykey?key=${Key}`,
          { headers: config.headers }
        );
        if (response.data.statusCode === 200) {
          return response.data.data;
        }
      } catch (error) {
        if (
          error.response &&
          error.response.status &&
          error.response.status == 401
        ) {
          ExpireToken(navigate, CustomerKey);
        }
      }
    } else {
      ExpireToken(navigate, CustomerKey);
    }
  }

  function IdentityVerificationRequest() {
    if (HasLoggedInUser()) {
      const config = APIHeader();
      try {
        const request = {
          customerKey: SelectedCustomer.key,
          userKey: User.key,
        };

        const PlaidIdentificationRequest = `${VixoAPI.FinicityAPI}createIdentityVerificationRequest`;

        axios
          .post(PlaidIdentificationRequest, request, {
            headers: config.headers,
          })
          .then((response) => {
            var response = response.data;
            if (response.statusCode === 200) {
              toast.success(response.message);
              setCustomerIdentityResponseData(response.data);
            } else if (response) {
              setAPIResponse(response);
            }
          })
          .catch((error) => {
            throw error;
          })
          .finally(() => {});
      } catch (error) {
        if (
          error.response &&
          error.response.status &&
          error.response.status == 401
        ) {
          ExpireToken(navigate, CustomerKey);
        }
      }
    } else {
      ExpireToken(navigate, CustomerKey);
    }
  }

  function PlaidIdentityVerifiedCustomer(CustomerKey) {
    if (HasLoggedInUser()) {
      const config = APIHeader();
      try {
        const PlaidIdentifiedCustomerData = `${VixoAPI.FinicityAPI}getCustomerIdentityDetail?CustomerKey=${SelectedCustomer.key}`;

        axios
          .get(PlaidIdentifiedCustomerData, {
            headers: config.headers,
          })
          .then((response) => {
            var responseData = response.data;
            if (responseData.statusCode === 200) {
              setCustomerIdentityResponseData(responseData.data);
              console.log(responseData); // Use responseData directly here
            } else {
              setError(responseData.message);
            }
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.status &&
              error.response.status == 401
            ) {
              ExpireToken(navigate, CustomerKey);
            }
          })
          .finally(() => {});
      } catch (error) {
        throw error;
      }
    } else {
      ExpireToken(navigate, CustomerKey);
    }
  }

  //////////////Notifications ///////////////

  useEffect(() => {
    if (notification) {
      GotNewAlert(notification);
    }
  }, [notification]);

  function GotNewAlert(notification) {
    if (
      (notification != null &&
        parseInt(notification.organiationID) === orgid &&
        notification.customerKey === CustomerKey &&
        notification.notificationType === 25) ||
      notification.notificationType === 1
    ) {
      const url = "/Home";
      // const url = "/vixohome?Id=igqjKwoSCaE=&U=5TqmP1TI+GY=#accountdetails";
      window.location.href = url;
      // navigate(url);
    }
  }
  useEffect(() => {
    if (SelectedCustomer != null) {
      GetAccountsCountByCustomer();
    }
  }, [SelectedCustomer]);
  const [CustomerAccountCount, setCustomerAccountCount] = useState(null);
  function GetAccountsCountByCustomer() {
    if (HasLoggedInUser()) {
      try {
        const GetCustomerId = `${VixoAPI.AccountAPI}getAccountCountByCustomer?CustomerKey=${SelectedCustomer.key}`;

        axios
          .get(GetCustomerId, {
            headers: APIHeader().headers,
          })
          .then((response) => {
            var response = response.data;
            if (response.statusCode === 200) {
              var customersdata = JSON.parse(JSON.stringify(response.data));
              setCustomerAccountCount(customersdata);
            } else {
              // setError(response.message);
            }
          })
          .catch((error) => {
            throw error;
          })
          .finally(() => {});
      } catch (error) {
        throw error;
      }
    } else {
      navigate("/");
    }
  }
  ///////////////////////
  const handleSendContracts = (e) => {
    e.preventDefault();
    if (HasLoggedInUser()) {
      const config = APIHeader();
      try {
        const sendcontactsAPIPath =
          VixoAPI.CustomerAPI +
          "SendContractToCustomer?CustomerKey=" +
          SelectedCustomer.key;
        axios
          .get(sendcontactsAPIPath, { headers: config.headers })
          .then((response) => {
            var responsedata = response.data;
            if (responsedata.statusCode === 200) {
              setCreateDate(responsedata.data.createdDate);
              toast.success(responsedata.message);
            } else {
              toast.error(responsedata.message);

            }
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.status &&
              error.response.status == 401
            ) {
              ExpireToken(navigate, CustomerKey);
            }
          })
          .finally(() => {});
      } catch (error) {
        throw error;
      }
    } else {
      ExpireToken(navigate, CustomerKey);
    }
  };

  const handlGetContracts = () => {
    if (!SelectedCustomer) return;
    if (HasLoggedInUser()) {
      const config = APIHeader();
      try {
        const getcontactsAPIPath =
          VixoAPI.CustomerAPI +
          "GetContractSignedDetailByKey?CustomerKey=" +
          SelectedCustomer.key;
        axios
          .post(getcontactsAPIPath, { headers: config.headers })
          .then((response) => {
            var responsedata = response.data;
            if (responsedata.statusCode === 200) {
              setGetContracts(responsedata.data.strSignature);
            } else {
              setError(response.message);
            }
          })
          .catch((error) => {})
          .finally(() => {});
      } catch (error) {
        throw error;
      }
    } else {
      ExpireToken(navigate);
    }
  };
  useEffect(() => {
    if (SelectedCustomer) {
      handlGetContracts();
    }
  }, []);

  return (
    <>
      <Spinner show={loading} Title="Resend Invite" />
      {errorType && <ErrorComponent errorType={errorType} />}
      <ToastContainer />
      <main className="Invitee" style={sidebarStyle}>
        <div className="container" style={{ textAlign: "center" }}>
          <div className="row identifyMediaQury">
            <span>
              <span
                style={{
                  color: "#004265",
                  fontSize: "16px",

                  fontWeight: "400",
                  background: "rgb(220, 230, 241)",
                  borderRadius: "20px",
                  padding: "10px",
                }}
              >
                <span
                  style={{
                    color: "rgb(35 92 122)",
                    fontWeight: "700",
                    bottom: "-1px",
                    position: "relative",
                  }}
                >
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="34"
                    viewBox="0 0 34 39"
                    fill="none"
                  >
                    <path
                      d="M33.4977 10.901L33.3518 7.98198L33.2772 6.48678L32.236 7.56034L17.8062 22.4734L12.9314 17.0018L12.5 16.5186L12.0168 16.9499L10.1259 18.6365L9.6426 19.0679L10.074 19.5511L17.2354 27.585L17.6992 28.1072L18.1857 27.6045L29.703 15.698C29.5603 18.1143 29.0835 20.4106 28.2824 22.5416C27.4391 24.786 26.239 26.8423 24.7211 28.6489C23.4302 30.1862 21.8961 31.5355 20.2939 32.5539C19.0971 33.3128 17.8613 33.8837 16.7878 34.1691C15.7175 33.8804 14.4785 33.3128 13.2817 32.5539C11.6762 31.5355 10.1453 30.1862 8.85445 28.6489C7.33655 26.8423 6.13649 24.786 5.29321 22.5416C4.3202 19.9533 3.8272 17.1186 3.8272 14.1185C3.8272 12.7173 3.87585 11.4913 3.93099 10.5897C6.2792 10.3853 8.65336 9.80801 10.8362 8.90635C12.0038 8.42308 13.1422 7.83927 14.2158 7.17438C15.1239 6.61003 15.9867 5.98405 16.791 5.30943C17.9197 6.25974 19.1619 7.10627 20.4885 7.82954C22.347 8.84148 24.3384 9.60043 26.4109 10.0805L27.0434 10.2264L27.1893 9.59394L27.7602 7.12573L27.9061 6.49326L27.2737 6.34731C25.5384 5.94513 23.8746 5.31267 22.321 4.46615C20.7577 3.61314 19.3533 2.56876 18.1468 1.35898L17.2516 0.460561L16.7943 0L16.3337 0.460561L15.4385 1.35898C13.7649 3.03257 11.6697 4.4175 9.37664 5.36457C7.07707 6.31488 4.57318 6.82409 2.13739 6.84031L1.00545 6.84679L0.424883 6.85004L0.36326 7.42736L0.246497 8.55606C0.236767 8.65336 0 10.94 0 14.1185C0 22.3405 3.22393 27.8996 5.92567 31.1138C6.76571 32.1128 7.68683 33.0436 8.65661 33.8772C9.54205 34.6362 10.4761 35.3237 11.4362 35.914C13.113 36.9487 14.8774 37.6947 16.4018 38.0158L16.6613 38.0709L16.7943 38.1001L16.9272 38.0709L17.1867 38.0158C18.7111 37.6947 20.4723 36.9487 22.1523 35.914C23.1091 35.3237 24.0465 34.6394 24.9319 33.8772C25.9017 33.0436 26.8228 32.1128 27.6629 31.1138C30.3679 27.8964 33.5885 22.3372 33.5885 14.1185C33.5788 13.0514 33.5529 11.9681 33.4977 10.901Z"
                      fill="url(#paint0_linear_103_6)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_103_6"
                        x1="16.7845"
                        y1="0.202131"
                        x2="16.7845"
                        y2="38.0203"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#30C1FF" />
                        <stop offset="1" stop-color="#4741BF" />
                      </linearGradient>
                    </defs>
                  </svg>
                  &nbsp; {SelectedCustomer && SelectedCustomer.identity}
                </span>
              </span>
            </span>
          </div>
        </div>

        <div className="container-fluid shadow p-3 bg-white col-lg-12 col-sm-12 mt-5">
          <div className="row">
            <div className="col-md-12 mx-auto">
              <div className="card">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-12 ">
                        <p style={{ marginLeft: "10px" }}>
                          <Breadcrumbs />
                        </p>
                        <div
                          className="d-flex align-items-center justify-content-end px-5 my-5 "
                          style={{ gap: "50px" }}
                        >
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <BootstrapTooltip id="tooltip">
                                <center>
                                  <span style={{ fontSize: "13px" }}>
                                    Consent successfully Delivered
                                  </span>
                                </center>
                              </BootstrapTooltip>
                            }
                          >
                            <div className="position-relative">
                              <span className="text-secondary">
                                <FontAwesomeIcon
                                  icon={faFileContract}
                                  fontSize="25px"
                                />
                              </span>
                              {getcontracts ? (
                                <>
                                  <span
                                    className=""
                                    style={{
                                      position: "absolute",
                                      top: "-20px",
                                      left: "3px",
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      fontSize="20px"
                                      icon={faCheckDouble}
                                      style={{ color: "green" }}
                                    />
                                  </span>
                                </>
                              ) : createdate ? (
                                <span
                                  className=""
                                  style={{
                                    position: "absolute",
                                    top: "-23px",
                                    left: "2px",
                                  }}
                                >
                                  <FontAwesomeIcon
                                    fontSize="25px"
                                    icon={faCheck}
                                    style={{ color: "green" }}
                                  />
                                </span>
                              ) : null}
                            </div>
                          </OverlayTrigger>

                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <BootstrapTooltip id="tooltip">
                                <center>
                                  <span style={{ fontSize: "13px" }}>
                                    Customer Attached Accounts{" "}
                                    {CustomerAccountCount}
                                  </span>
                                </center>
                              </BootstrapTooltip>
                            }
                          >
                            <div className="position-relative">
                              <span className="text-secondary">
                                <FontAwesomeIcon
                                  icon={faBuildingColumns}
                                  fontSize="25px"
                                />
                              </span>
                              <span
                                class="badge rounded-pill badge-notification bg-danger  "
                                style={{
                                  position: "absolute",
                                  top: "-4px",
                                  left: "15px",
                                }}
                              >
                                {CustomerAccountCount}
                              </span>
                            </div>
                          </OverlayTrigger>

                          {SelectedCustomer &&
                          SelectedCustomer.customerInvitation === 1 ? (
                            <div className="d-flex align-items-center justify-content-end  fs-3">
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <BootstrapTooltip id="tooltip">
                                    <center>
                                      <span style={{ fontSize: "13px" }}>
                                        Invitation successfully sent via Email
                                      </span>
                                    </center>
                                  </BootstrapTooltip>
                                }
                              >
                                <span className="text-secondary">
                                  <FontAwesomeIcon icon={faEnvelope} />
                                </span>
                              </OverlayTrigger>
                            </div>
                          ) : (
                            <div className="d-flex align-items-center justify-content-end fs-3">
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <BootstrapTooltip id="tooltip">
                                    <center>
                                      <span style={{ fontSize: "13px" }}>
                                        Invitation successfully sent via SMS
                                      </span>
                                    </center>
                                  </BootstrapTooltip>
                                }
                              >
                                <span className="text-secondary">
                                  <div className="position-relative">
                                    <span className="text-secondary">
                                      <FontAwesomeIcon
                                        icon={faCommentSms}
                                        fontSize="30px"
                                      />
                                    </span>
                                    {SelectedCustomer &&
                                      SelectedCustomer.customerReceivedSms ===
                                        true && (
                                        <span
                                          className=""
                                          style={{
                                            position: "absolute",
                                            top: "-22px",
                                            left: "08px",
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            fontSize="25px"
                                            icon={faCheck}
                                            style={{ color: "green" }}
                                          />
                                        </span>
                                      )}
                                  </div>
                                </span>
                              </OverlayTrigger>
                            </div>
                          )}
                        </div>

                        <h3 className="fw-bold px-2"> Customer Details</h3>
                      </div>
                    </div>
                    {/* <h5 className="m-3">
                      {" "}
                      Current Attach Customer Accounts : {
                        CustomerAccountCount
                      }{" "}
                    </h5> */}

                    <div className="row m-1">
                      <div className="col-md-6">
                        <span className="fw-bold ">First Name</span>
                        <br />
                        <span>
                          {SelectedCustomer != null &&
                            SelectedCustomer.firstName}
                        </span>
                      </div>
                      <div className="col-md-6 ">
                        <span className="fw-bold">Last Name</span>
                        <br />
                        <span>
                          {SelectedCustomer != null &&
                            SelectedCustomer.lastName}
                        </span>
                      </div>
                    </div>
                    <div className="row m-2">
                      <div className="col-md-6">
                        <span className="fw-bold">Email</span>
                        <br />
                        <span>
                          {SelectedCustomer != null && SelectedCustomer.email}
                        </span>
                      </div>
                      <div className="col-md-6">
                        <span className="fw-bold">Phone</span>
                        <br />
                        <span>
                          <PhoneNumber
                            number={
                              SelectedCustomer != null && SelectedCustomer.phone
                            }
                          />
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="modal-body">
                      <form className="m-3">
                        <div className="form-group">
                          <textarea
                            className="form-control"
                            rows="4"
                            style={{ height: "100px" }}
                            placeholder="Enter your comment..."
                            value={editedBankLinkInvitationText}
                            onChange={handleCompanyInfoChange}
                          ></textarea>
                        </div>
                        <div className="col-md-2 mb-4 mt-3">
                          <select
                            className="form-select"
                            name="OpenBankingType"
                            value={
                              SelectedCustomer != null &&
                              SelectedCustomer.openBankingType
                            }
                            onChange={(e) =>
                              setSelectedCustomer({
                                ...SelectedCustomer,
                                openBankingType: e.target.value,
                              })
                            }
                          >
                            {OpenBankingRoles.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.displayValue}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-md-12 mb-2 mt-2">
                          <div className="form-check d-flex gap-1">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="inviteEmail"
                              name="inviteMethod"
                              value={
                                SelectedCustomer && SelectedCustomer.inviteEmail
                              }
                              checked={selectedRadio === "inviteEmail"}
                              onChange={() => handleRadioChange("inviteEmail")}
                            />

                            <label
                              className="form-check-label"
                              htmlFor="inviteEmail"
                            >
                              Invite by Email
                            </label>
                            {selectedRadio === "inviteEmail" ? (
                              <span className="text-success">
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  fontSize="20px"
                                />
                              </span>
                            ) : null}
                          </div>
                          <div className="form-check d-flex gap-1">
                            <input
                              type="radio"
                              className="form-check-input"
                              id="invitePhone"
                              name="inviteMethod"
                              value={
                                SelectedCustomer && SelectedCustomer.invitePhone
                              }
                              checked={selectedRadio === "invitePhone"}
                              onChange={() => handleRadioChange("invitePhone")}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="invitePhone"
                            >
                              Invite by Phone Number
                            </label>
                            {selectedRadio === "invitePhone" ? (
                              <span className="text-success">
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  fontSize="20px"
                                />
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div>
                          {buttonState === 4 && (
                            <p
                              style={{
                                fontSize: "20px",
                                marginBottom: "10px",
                              }}
                            >
                              Identity Verification Request was completed.
                            </p>
                          )}
                          {buttonState === 2 && (
                            <p
                              style={{
                                fontSize: "20px",
                                marginBottom: "10px",
                              }}
                            >
                              Identity Verification Request was already Sent to
                              Customer
                            </p>
                          )}
                        </div>

                        <div className="d-flex">
                          <button
                            className="btn"
                            onClick={sendInvite}
                            style={{
                              borderRadius: "20px",
                              color: "white",
                              fontWeight: "500",
                              width: "9pc",
                              backgroundColor: "#94ba33",
                            }}
                          >
                            Resend Invite
                          </button>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          {buttonState === 3 && (
                            <div className="d-flex align-items-center">
                              <button
                                id="myButton"
                                type="button"
                                className="btn btn-info"
                                onClick={IdentityVerificationRequest}
                                style={{
                                  borderRadius: "20px",
                                  color: "white",
                                  fontWeight: "500",
                                  width: "23pc",
                                }}
                              >
                                Request Customer Identity Verification
                              </button>
                            </div>
                          )}
                          {buttonState === 2 && (
                            <>
                              <div className="d-flex align-items-center">
                                <button
                                  id="myButton"
                                  type="button"
                                  className="btn btn-info custom-button"
                                  onClick={IdentityVerificationRequest}
                                  style={{
                                    borderRadius: "20px",
                                    color: "white",
                                    fontWeight: "500",
                                    width: "23pc",
                                  }}
                                >
                                  Restart Customer Identification Process
                                </button>
                              </div>
                            </>
                          )}
                          {buttonState === 4 && (
                            <>
                              <div className="d-flex align-items-center">
                                <div className="d-flex align-items-center">
                                  <button
                                    id="myButton"
                                    type="button"
                                    className="btn btn-info custom-button"
                                    onClick={IdentityVerificationRequest}
                                    style={{
                                      borderRadius: "20px",
                                      color: "white",
                                      fontWeight: "500",
                                      maxWidth: "23pc",
                                    }}
                                  >
                                    Completed Customer Identification Process
                                  </button>
                                </div>
                              </div>
                            </>
                          )}
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <button
                            id="myButton"
                            type="button"
                            className="btn"
                            onClick={handleSendContracts}
                            style={{
                              borderRadius: "20px",
                              color: "white",
                              fontWeight: "500",
                              backgroundColor: "#a13a7d",
                            }}
                          >
                            Send Consent
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default ResendInvite;
