import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Billingmodule.css";
import SideBar from "../Header/SideBar";

const styles = {
  wrap: {
    margin: "40px auto 0",
    textAlign: "center",
  },
  h1: {
    fontSize: "30px",
    fontWeight: 700,
  },
  h2: {
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: 1.6,
  },
};

const OrgLogo = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [orgName, setOrgName] = useState("");

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    const loggedInOrg = localStorage.getItem("User");
    if (loggedInOrg != null) {
      const orgObject = JSON.parse(loggedInOrg);
      setOrgName(orgObject.organizationName);
    }
  };

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-7">
            <div style={{ textAlignLast: "center" }}>
              <Link to="/Home">
                <img
                  src="assets/images/LogoVixo.png"
                  alt="logo icon"
                  className="logoIcon"
                  style={{
                    width: "250px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                />
              </Link>
              <h2 style={styles.h2}>
                <p style={{ textAlign: "center" }}>
                  Welcome <b>{orgName ? orgName : ""}</b>
                  <br />
                  Feel free to contact us
                  <br />
                  with any questions or inquiries.
                  <br />
                </p>
              </h2>
              <h2>
                Introduction to{" "}
                <b>Vixo Link</b>
              </h2>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/DcgQCVC491Y"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>

              <p>
                Powered By <Link to="http://vixolink.com/"> Vixo Link.</Link>
              </p>
            </div>
          </div>
          <div className="col-md-3"></div>
        </div>
      </div>
    </>
  );
};

export default OrgLogo;
