import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { selectNotification } from "../Notification/Slices/sendNotificationSlice";
import { useSelector } from "react-redux";
import { VixoAPI } from "../../settings";
import { APIHeader, HasLoggedInUser } from "../../APICallWrapper";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const DisableOrg = () => {
  const [disableOrganizaton, setdisableOrganizaton] = useState(false);
  const [disableOrganizatonTemp, setdisableOrganizatonTemp] = useState(false);
  const [disableOrganizatonChange, setdisableOrganizatonChange] = useState(0);
  const notification = useSelector(selectNotification);
  const [User, setUser] = useState(null);
  const [LocalOrganization, setLocalOrganization] = useState(null);
  const [OrganizationID, setOrganizationID] = useState();

  useEffect(() => {
    LoadUserforOrgId();
  }, []);

  useEffect(() => {
    if (OrganizationID) {
      LoadOrganization(OrganizationID);
    }
  }, [OrganizationID]);

  const LoadUserforOrgId = () => {
    const loggedInUser = localStorage.getItem("User");
    var userObject = JSON.parse(loggedInUser);

    if (userObject != null) {
      setUser(userObject);
      setOrganizationID(userObject.organizationId);
    }
  };

  useEffect(() => {
    if (notification) {
      GotNewAlert(notification);
    }
  }, [notification]);

  function GotNewAlert(notification) {
    if (
      (notification != null &&
        OrganizationID != null &&
        notification.organiationID == OrganizationID &&
        notification.notificationType == 12) ||
      notification.notificationType == 28
    ) {
      LoadOrganization(OrganizationID);
    }
  }
const navigate =  useNavigate();
  function LoadOrganization(OrganizationID) {
    if(HasLoggedInUser()){

    
    try {
      const OrgnizationUpdateAPIPath =
        VixoAPI.OrganizationAPI + "getAny?Id=" + OrganizationID;
      axios
        .get(OrgnizationUpdateAPIPath, {
          headers: APIHeader().headers,
        })
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            var Organization = JSON.parse(JSON.stringify(response.data));
            if (Organization != null) {
              var TempOrganization = {
                isOrganizationDisable: Organization.isOrganizationDisable,
              };
              setLocalOrganization(TempOrganization);
            }
          } else {
          }
        })
        .catch((error) => {
          throw error;
        })
        .finally(() => {});
    } catch (error) {
      throw error;
    }
  }else {
    navigate("/");
  }
  }

  window.addEventListener("Billing", () => {
    LoadUserforOrgId();

    // ...
  });

  useEffect(() => {
    if (OrganizationID) {
      LoadOrganization(OrganizationID);
    }
  }, [OrganizationID]);

  const redDivStyle = {
    backgroundColor: "#FFDDDD",
    width: "100%",
    padding: "10px",
    textAlign: "center",
    borderRadius: "10px",
    color: "#FF0000",
    fontWeight: "bold",
    zIndex: 9999,
  };
  const iconStyle = {
    marginRight: "8px",
  };
  function IsOrganizationDisabled() {
    var lIsOrganiationDisabled =
      LocalOrganization &&
      LocalOrganization.isOrganizationDisable &&
      LocalOrganization.isOrganizationDisable != null &&
      LocalOrganization.isOrganizationDisable === true
        ? true
        : false;
    return lIsOrganiationDisabled;
  }
  return (
    <>
      {IsOrganizationDisabled() ? (
        <div style={redDivStyle}>
          Your Organization Is Disabled
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            size="2x"
            style={iconStyle}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default DisableOrg;
