import React, { useEffect } from "react";
import * as signalR from "@microsoft/signalr";
import { VixoAPI } from "../../settings";
import { useDispatch } from "react-redux";


const NotificationsHome = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${VixoAPI.NotificationAPI}notificationHub`)
      .build();

    connection
      .start()
      .catch((error) => console.error("SignalR Connection Error:", error));

    connection.on("ReceiveNotification", (receivedNotification) => {
      alert(receivedNotification);


    });

    return () => {
      if (connection.state === signalR.HubConnectionState.Connected) {
        connection.stop();
      }
    };
  }, [dispatch]);

  return <></>;
};

export default NotificationsHome;
