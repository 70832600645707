import React, { useState, useEffect } from "react";
import TopnavBar from "../Header/TopnavBar";
import { Link, useNavigate } from "react-router-dom";
import OrganizationSettingBar from "../Header/OrganizationSettingBar";

import Breadcrumbs from "../HomeVixo/Breadcrumbs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGears } from "@fortawesome/free-solid-svg-icons";

import axios from "axios";
import Spinner from "../../Spinner";
import { VixoAPI } from "../../settings";
import { APIHeader, ExpireToken, HasLoggedInUser } from "../../APICallWrapper";

const DisallowNotification = () => {
  
  const navigate = useNavigate();
  const DissAllowRoles=[10];
  const [collapsed, setCollapsed] = useState(false);
  const [DisabledNotifications, setDisabledNotifications] = useState([]);

  const [loading, setLoading] = useState(false);

  // user key
  const getUserKeyFromLocalStorage = () => {
    const loggedInUser = localStorage.getItem("User");
   
    const userObject = JSON.parse(loggedInUser);
    return userObject.key;
  };

  useEffect(() => {
    const userKey = getUserKeyFromLocalStorage();
    getDisabledNotifications(userKey);
  }, []);

  // getDisabledNotifications
  const getDisabledNotifications = async (userKey) => {
    if(HasLoggedInUser()){
      const config=APIHeader();
    setLoading(true);
    try {
      const VixoDisabledNotifications = `${VixoAPI.UserAPI}getDisabledNotifications?UserKey=${userKey}`;
      const response = await axios.get(VixoDisabledNotifications,{
       headers:config.headers
      });
      const responseData = response.data;
      console.log("responseData", responseData);
      if (responseData.statusCode === 200) {
        setDisabledNotifications(responseData.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }else{
    ExpireToken(navigate);
  }
};

  // updatedDisabledNotification
const updatedDisabledNotification = async (
  userKey,
   event,
  eventName,
  isEnabled,
 
 
) => {
  setLoading(true);

  const data = {
    UserKey: userKey,
    Event: event,
    EventName: eventName,
    IsEnabled: isEnabled,
  };

  if(HasLoggedInUser())
    {
      const config=APIHeader();
  try {
    const VixoDisabledNotifications = `${VixoAPI.UserAPI}updatedDisabledNotification`;
    const response = await axios.post(VixoDisabledNotifications, data,{
     headers:config.headers
    });

    const responseData = response.data;
  
    if (responseData.statusCode === 200) {
       await getDisabledNotifications(userKey);
      setLoading(false);
    }
  } catch (error) {
    setLoading(false);
    console.error("Error:", error);
  }
}
else{
  ExpireToken(navigate);
}
};


  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };
  const sidebarStyle = {
    marginLeft: collapsed ? "" : "250px",
  };

  return (
    <>
      <Spinner show={loading} />
      <TopnavBar />
      <OrganizationSettingBar
        collapsed={collapsed}
        toggleSidebar={toggleSidebar}
      />

      <main className="page" style={sidebarStyle}>
        <div className="container-fluid  p-3 bg-white col-lg-12 col-sm-12">
          <div className="row">
            <div className="col-md-12 mx-auto">
              <div className="card">
                <div className="card-header py-3 bg-transparent">
                  <h5 className="mb-0 d-inline">Setting</h5>
                  <FontAwesomeIcon
                    icon={faGears}
                    className="fa-2x "
                    style={{ width: "25px" }}
                  />

                  <Breadcrumbs />
                </div>

                <div className="card-header py-3 bg-transparent">
                  <div class="row">
                    <div class="col-sm-8">
                      <h4>Disallow Notification</h4>
                    </div>
                    <div class="col-sm-4">
                      <form class="form-inline my-2 my-lg-0">
                        <input
                          class="form-control mr-sm-2"
                          type="search"
                          placeholder="Search"
                          aria-label="Search"
                        />
                      </form>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="table-responsive">
                    <table
                      id="example"
                      className="table table-striped"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th>Disallow Notification</th>
                          <th>Events</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/*DisabledNotifications*/}
                        {DisabledNotifications.map((item, index) => (
                          <tr>
                            <td>
                              <div class="form-check">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  checked={item.isEnabled} // Assuming checked when isEnabled is false
                                  onChange={() =>
                                    updatedDisabledNotification(
                                      item.userKey,
                                      item.event,
                                      item.eventName,
                                      !item.isEnabled
                                    )
                                  } // Pass userKey here
                                  id={`flexCheckChecked-${index}`}
                                />
                              </div>
                            </td>
                            <td style={{ whiteSpace: "pre-wrap" }}>
                              {item.eventName}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default DisallowNotification;
