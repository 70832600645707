export const Environment = {
  ENV: process.env.REACT_APP_ENV, // 'DEV' ,'PRODUCTION' ,LOCAL
};

export const VixoAPILocal = {
  CustomeDashboard: "http://localhost:3001/",
  DashBoard: "http://localhost:3000/", 
  OrganizationAPI: "https://vixoapimanagement3.azure-api.net/vixoorg/",
  NotificationAPI: "https://vixonotification.azurewebsites.net/",
  AuthenticationAPI: "https://vixoapimanagement3.azure-api.net/vixoauth/",
  CustomerAPI: "https://vixoapimanagement3.azure-api.net/vixocustomer/",
  UserAPI: "https://vixoapimanagement3.azure-api.net/vixouser/",
  AccountAPI: "https://vixoapimanagement3.azure-api.net/vixoaccount/",
  DocumentAPI: "https://vixoapimanagement3.azure-api.net/vixodoc/",
  StatmentAPI: "https://vixoapimanagement3.azure-api.net/vixostatement/",
  OCRAPI: "https://vixoapimanagement3.azure-api.net/VixoOCR/",
  AlertsAPI: "https://vixoapimanagement3.azure-api.net/vixoemail/",
  FundingAPI: "https://vixoapimanagement3.azure-api.net/vixofunding/",
  LookupAPI: "https://vixoapimanagement3.azure-api.net/vixolookup/",
  BillingAPI: "https://vixoapimanagement3.azure-api.net/vixobilling/",
  ClearantAPI: "https://gateway-sb.clearent.net",
  FinicityAPI: "https://vixoapimanagement3.azure-api.net/vixofinicity/",

  ClearntPublicKey:
    "307a301406072a8648ce3d020106092b240303020801010c03620004674d355f67526bff4bb3c9bc2ad084482efbe69340daae8bb3e1e605153a13f5c4b87cc2cb595d54ef1fb5fb42ecdc026d122533f9520a5c65c674ed5bc2146d3bb6600547128eb47be7cd0abb02f4ecb5f4032931eb8e55f27fdca8a51a9843",
  CleartantAPIKey: "1a0a6bd6a0ed44d79303945082836410",
};

export const VixoAPIProductionLocal = {
  CustomeDashboard: "http://localhost:3001/",
  DashBoard: "http://localhost:3000/",
  OrganizationAPI: "https://vixoapimanagement2.azure-api.net/vixoorg/",
  NotificationAPI: "https://vixonotification.azurewebsites.net/",
  AuthenticationAPI: "https://vixoapimanagement2.azure-api.net/vixoauth/",
  CustomerAPI: "https://vixoapimanagement2.azure-api.net/vixocustomer/",
  UserAPI: "https://vixoapimanagement2.azure-api.net/vixouser/",
  AccountAPI: "https://vixoapimanagement2.azure-api.net/vixoaccount/",
  DocumentAPI: "https://vixoapimanagement2.azure-api.net/vixodoc/",
  StatmentAPI: "https://vixoapimanagement2.azure-api.net/vixostatement/",
  OCRAPI: "https://vixoapimanagement2.azure-api.net/VixoOCR/",
  AlertsAPI: "https://vixoapimanagement2.azure-api.net/vixoemail/",
  FundingAPI: "https://vixoapimanagement2.azure-api.net/vixofunding/",
  LookupAPI: "https://vixoapimanagement2.azure-api.net/vixolookup/",
  BillingAPI: "https://vixoapimanagement2.azure-api.net/vixobilling/",
  ClearantAPI: "https://gateway.clearent.net",
  ClearntPublicKey:
    "307a301406072a8648ce3d020106092b240303020801010c036200046cbdc4e22c13a282e8240743417e0d0e535b41c7e9cc454920e1c6d8887fe14f8f6d4e1f687b4b1afcac59a7feac4c1e8334f2fda109a14e49f37baeb54f46e5141ec281afdb071270a8bf21e358a454c53c80b89f18605386a2e2a989e7ef98",
  CleartantAPIKey: "8801cc1c0b834d06957fc5d7a2a69fb4",
  FinicityAPI: "https://vixoapimanagement2.azure-api.net/vixofinicity/",
};

export const VixoAPIDev = {
  CustomeDashboard: "https://devhome.vixolink.com/",
  DashBoard: "https://devdashboard.vixolink.com/",
  OrganizationAPI: "https://vixoapimanagement3.azure-api.net/vixoorg/",
  NotificationAPI: "https://vixonotification.azurewebsites.net/",
  AuthenticationAPI: "https://vixoapimanagement3.azure-api.net/vixoauth/",
  CustomerAPI: "https://vixoapimanagement3.azure-api.net/vixocustomer/",
  UserAPI: "https://vixoapimanagement3.azure-api.net/vixouser/",
  AccountAPI: "https://vixoapimanagement3.azure-api.net/vixoaccount/",
  DocumentAPI: "https://vixoapimanagement3.azure-api.net/vixodoc/",
  StatmentAPI: "https://vixoapimanagement3.azure-api.net/vixostatement/",
  OCRAPI: "https://vixoapimanagement3.azure-api.net/VixoOCR/",
  AlertsAPI: "https://vixoapimanagement3.azure-api.net/vixoemail/",
  FundingAPI: "https://vixoapimanagement3.azure-api.net/vixofunding/",
  LookupAPI: "https://vixoapimanagement3.azure-api.net/vixolookup/",
  BillingAPI: "https://vixoapimanagement3.azure-api.net/vixobilling/",
  ClearantAPI: "https://gateway-sb.clearent.net",
  ClearntPublicKey:
    "307a301406072a8648ce3d020106092b240303020801010c03620004674d355f67526bff4bb3c9bc2ad084482efbe69340daae8bb3e1e605153a13f5c4b87cc2cb595d54ef1fb5fb42ecdc026d122533f9520a5c65c674ed5bc2146d3bb6600547128eb47be7cd0abb02f4ecb5f4032931eb8e55f27fdca8a51a9843",
  CleartantAPIKey: "1a0a6bd6a0ed44d79303945082836410",
  FinicityAPI: "https://vixoapimanagement3.azure-api.net/vixofinicity/",
};

export const VixoAPIStaging = {
  CustomeDashboard: "https://staginghome.vixolink.com/",
  DashBoard: "https://stagingdashboard.vixolink.com/",
  OrganizationAPI: "https://vixoapimanagement4.azure-api.net/vixoorg/",
  NotificationAPI: "https://vixonotification.azurewebsites.net/",
  AuthenticationAPI: "https://vixoapimanagement4.azure-api.net/vixoauth/",
  CustomerAPI: "https://vixoapimanagement4.azure-api.net/vixocustomer/",
  UserAPI: "https://vixoapimanagement4.azure-api.net/vixouser/",
  AccountAPI: "https://vixoapimanagement4.azure-api.net/vixoaccount/",
  DocumentAPI: "https://vixoapimanagement4.azure-api.net/vixodoc/",
  StatmentAPI: "https://vixoapimanagement4.azure-api.net/vixostatement/",
  OCRAPI: "https://vixoapimanagement4.azure-api.net/VixoOCR/",
  AlertsAPI: "https://vixoapimanagement4.azure-api.net/vixoemail/",
  FundingAPI: "https://vixoapimanagement4.azure-api.net/vixofunding/",
  LookupAPI: "https://vixoapimanagement4.azure-api.net/vixolookup/",
  BillingAPI: "https://vixoapimanagement4.azure-api.net/vixobilling/",
  ClearantAPI: "https://gateway.clearent.net",
  ClearntPublicKey:
    "307a301406072a8648ce3d020106092b240303020801010c036200046cbdc4e22c13a282e8240743417e0d0e535b41c7e9cc454920e1c6d8887fe14f8f6d4e1f687b4b1afcac59a7feac4c1e8334f2fda109a14e49f37baeb54f46e5141ec281afdb071270a8bf21e358a454c53c80b89f18605386a2e2a989e7ef98",
  CleartantAPIKey: "8801cc1c0b834d06957fc5d7a2a69fb4",
  FinicityAPI: "https://vixoapimanagement4.azure-api.net/vixofinicity/",
};

export const VixoAPIProduction = {
  CustomeDashboard: "https://home.vixolink.com/",
  DashBoard: "https://dashboard.vixolink.com/",
  OrganizationAPI: "https://vixoapimanagement2.azure-api.net/vixoorg/",
  NotificationAPI: "https://vixonotification.azurewebsites.net/",
  AuthenticationAPI: "https://vixoapimanagement2.azure-api.net/vixoauth/",
  CustomerAPI: "https://vixoapimanagement2.azure-api.net/vixocustomer/",
  UserAPI: "https://vixoapimanagement2.azure-api.net/vixouser/",
  AccountAPI: "https://vixoapimanagement2.azure-api.net/vixoaccount/",
  DocumentAPI: "https://vixoapimanagement2.azure-api.net/vixodoc/",
  StatmentAPI: "https://vixoapimanagement2.azure-api.net/vixostatement/",
  OCRAPI: "https://vixoapimanagement2.azure-api.net/VixoOCR/",
  AlertsAPI: "https://vixoapimanagement2.azure-api.net/vixoemail/",
  FundingAPI: "https://vixoapimanagement2.azure-api.net/vixofunding/",
  LookupAPI: "https://vixoapimanagement2.azure-api.net/vixolookup/",
  BillingAPI: "https://vixoapimanagement2.azure-api.net/vixobilling/",
  ClearantAPI: "https://gateway.clearent.net",
  FinicityAPI: "https://vixoapimanagement2.azure-api.net/vixofinicity/",

  ClearntPublicKey:
    "307a301406072a8648ce3d020106092b240303020801010c036200046cbdc4e22c13a282e8240743417e0d0e535b41c7e9cc454920e1c6d8887fe14f8f6d4e1f687b4b1afcac59a7feac4c1e8334f2fda109a14e49f37baeb54f46e5141ec281afdb071270a8bf21e358a454c53c80b89f18605386a2e2a989e7ef98",
  CleartantAPIKey: "8801cc1c0b834d06957fc5d7a2a69fb4",
};

export const VixoAPI = {
  CustomeDashboard: GetEnvironmentBasedAPI().CustomeDashboard,
  DashBoard: GetEnvironmentBasedAPI().DashBoard,
  OrganizationAPI: GetEnvironmentBasedAPI().OrganizationAPI,
  NotificationAPI: GetEnvironmentBasedAPI().NotificationAPI,
  AuthenticationAPI: GetEnvironmentBasedAPI().AuthenticationAPI,
  CustomerAPI: GetEnvironmentBasedAPI().CustomerAPI,
  UserAPI: GetEnvironmentBasedAPI().UserAPI,
  AccountAPI: GetEnvironmentBasedAPI().AccountAPI,
  DocumentAPI: GetEnvironmentBasedAPI().DocumentAPI,
  StatmentAPI: GetEnvironmentBasedAPI().StatmentAPI,
  OCRAPI: GetEnvironmentBasedAPI().OCRAPI,
  AlertsAPI: GetEnvironmentBasedAPI().AlertsAPI,
  FundingAPI: GetEnvironmentBasedAPI().FundingAPI,
  LookupAPI: GetEnvironmentBasedAPI().LookupAPI,
  BillingAPI: GetEnvironmentBasedAPI().BillingAPI,
  ClearantAPI: GetEnvironmentBasedAPI().ClearantAPI,
  ClearntPublicKey: GetEnvironmentBasedAPI().ClearntPublicKey,
  CleartantAPIKey: GetEnvironmentBasedAPI().CleartantAPIKey,
  FinicityAPI: GetEnvironmentBasedAPI().FinicityAPI,
};

export function GetEnvironmentBasedAPI() {
  if (
    Environment.ENV != null &&
    Environment.ENV.trim().toUpperCase() == "LOCAL"
  ) {
    return VixoAPILocal;
  } else if (Environment.ENV.trim().toUpperCase() == "PRODUCTIONLOCAL") {
    return VixoAPIProductionLocal;
  } else if (Environment.ENV.trim().toUpperCase() == "STAGING") {
    return VixoAPIStaging;
  } else if (
    (Environment.ENV != null &&
      Environment.ENV.trim().toUpperCase() == "DEV") ||
    Environment.ENV.trim().toUpperCase() == "DEVELOPMENT"
  ) {
    return VixoAPIDev;
  } else {
    return VixoAPIProduction;
  }
}

export const VixoConstants = {
  MinimumReloadAmount: 100,
};
