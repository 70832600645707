import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { Link, useNavigate, json, useLocation } from "react-router-dom";
import axios from "axios";
import Spinner from "../../Spinner";
import { toast } from "react-toastify";
import "./CustomerTransfer.css";
import { VixoAPI } from "../../settings";
import { APIHeader, HasLoggedInUser, ExpireToken } from "../../APICallWrapper";
const CustomerTransfer = ({
  showModaltransfer,
  setShowModaltransfer,
  customer,
}) => {
  const [transferTo, setTransferTo] = useState("");
  const [transferType, setTransferType] = useState(1);
  const [otpValue, setOTPValue] = useState("");
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [UserEmail, setUserEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [UserKey, setUserKey] = useState(null);
  const [emailError, setEmailError] = useState("");
  const [userId, setUserId] = useState("");
  const [CustomerKey, setCustomerKey] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [User, setUser] = useState(null);

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };
  useEffect(() => {
    const loggedInUser = localStorage.getItem("User");

    if (loggedInUser) {
      try {
        setUser(JSON.parse(loggedInUser));
      } catch (exception) {}
    }
  }, []);

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setUserEmail(email);
    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };

  const handleStartProcess = async () => {
    if (HasLoggedInUser()) {
      const config = APIHeader();
      if (!UserEmail) {
        setEmailError("Email address is required.");
        return;
      }

      if (!validateEmail(UserEmail)) {
        setEmailError("Please enter a valid email address.");
        return;
      }
      setEmailError("");
      const encodedemail = encodeURIComponent(User.email);
      try {
        const SigninAPIPath = `${VixoAPI.AuthenticationAPI}createOTP?email=${encodedemail}`;
        setLoading(true);
        const response = await axios.get(SigninAPIPath, {
          headers: config.headers,
        });
        if (response.data.statusCode === 200) {
          setUserKey(response.data.data);
          toast.success(response.data.message);
          setShowOTPInput(true);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error(error.message);
        if (
          error.response &&
          error.response.status &&
          error.response.status == 401
        ) {
          ExpireToken(navigate);
        }
      } finally {
        setLoading(false);
      }
    } else {
      ExpireToken(navigate, CustomerKey);
    }
  };

  const handleOTPVerification = async () => {
    if (HasLoggedInUser()) {
      const config = APIHeader();
      const data = {
        UserKey: UserKey,
        Otp: otpValue,
      };
      try {
        const OtpVerifyOrganization = `${VixoAPI.AuthenticationAPI}verifyOTP`;
        setLoading(true);
        const response = await axios.post(OtpVerifyOrganization, data, {
          headers: config.headers,
        });
        if (response.data.statusCode === 200) {
          toast.success(response.data.message);
          handleTransferRequest();
          setShowOTPInput(false);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error(error.message);
        if (
          error.response &&
          error.response.status &&
          error.response.status == 401
        ) {
          ExpireToken(navigate);
        }
      } finally {
        setLoading(false);
      }
    } else {
      ExpireToken(navigate, CustomerKey);
    }
  };

  const handleTransferRequest = async () => {
    if (HasLoggedInUser()) {
      const config = APIHeader();
      const data = {
        email: UserEmail,
        transferType: transferType,
        CustomerKey: customer.key,
        UserKey: UserKey,
      };
      try {
        const createTransferAPI = `${VixoAPI.CustomerAPI}CreateTransferRequest`;
        setLoading(true);
        const response = await axios.post(createTransferAPI, data, {
          headers: config.headers,
        });
        if (response.data.statusCode === 200) {
          toast.success("Transfer request created successfully.");
          handleCloseModal();
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error(error.message);
        if (
          error.response &&
          error.response.status &&
          error.response.status == 401
        ) {
          ExpireToken(navigate);
        }
      } finally {
        setLoading(false);
      }
    } else {
      ExpireToken(navigate, CustomerKey);
    }
  };

  const handleOTPChange = (e) => {
    setOTPValue(e.target.value);
  };

  const resetForm = () => {
    setTransferTo("");
    setTransferType(1); // Complete Transfer
    setOTPValue("");
    setShowOTPInput(false);
    setUserEmail("");
    setEmailError("");
  };

  const handleCloseModal = () => {
    resetForm();
    setShowModaltransfer(false);
  };

  return (
    <>
      <Spinner show={loading} />
      <Modal
        show={showModaltransfer}
        onHide={handleCloseModal}
        centered
        size="lg"
      >
        <Modal.Header
          closeButton
          style={{ backgroundColor: "#007bff", color: "white" }}
        >
          <Modal.Title>Transfer Customer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="customer-section">
            <div className="text-center">
              <div className="customer-details">
                <h4 className="mb-4">Customer Details</h4>
                <p>
                  <strong>Customer:</strong> {customer.name}
                </p>
                <p>
                  <strong> Customer Email:</strong> {customer.email}
                </p>
                <p>
                  <strong>Customer Phone:</strong> {customer.phone}
                </p>
              </div>
            </div>
          </div>
          <Form>
            <Form.Group controlId="transferTo">
              <Form.Label className="mt-2">
                Transfer to Organization:
              </Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email address"
                value={UserEmail}
                onChange={handleEmailChange}
                isInvalid={emailError !== ""}
                required
              />
              <Form.Control.Feedback type="invalid">
                {emailError}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="transferType">
              <Form.Label className="mt-2">Transfer Type:</Form.Label>
              <Form.Check
                type="radio"
                label="Full Transfer"
                name="transferType"
                value="full"
                checked={transferType === 1}
                onChange={() => setTransferType(1)}
              />
              <Form.Check
                type="radio"
                label="Syndicate"
                name="transferType"
                value="syndicate"
                checked={transferType === 2}
                onChange={() => setTransferType(2)}
              />
            </Form.Group>
          </Form>
          {showOTPInput && (
            <Form.Group controlId="otp">
              <Form.Label>Enter OTP:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter OTP"
                value={otpValue}
                onChange={handleOTPChange}
                required
              />
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          {!showOTPInput ? (
            <Button
              variant="primary"
              onClick={handleStartProcess}
              disabled={loading}
            >
              {loading ? "Sending..." : "Start Process"}
            </Button>
          ) : (
            <Button
              variant="primary"
              onClick={handleOTPVerification}
              disabled={loading}
            >
              {loading ? "Verifying..." : "Verify OTP"}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CustomerTransfer;
