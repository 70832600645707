export function ValidateEmailGlobal(email) {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

  const isValidFormat = emailRegex.test(email);

  //const endsWithCom = email.toLowerCase().endsWith('.com');
  const endsWithDomain = /\.[A-Z]{2,}$/i.test(email);

  return isValidFormat && endsWithDomain;
}

export function ValidatePhoneGlobal(phone) {
  const cleanedPhone = phone.replace(/\D/g, "");

  if (/^\d{10}$/.test(cleanedPhone)) {
    return true;
  } else {
    return false;
  }
}

export function ValidatePasswordGlobal(password) {
  if (password.length < 8) {
    return false;
  }

  if (!/[A-Z]/.test(password)) {
    return false;
  }

  if (!/[a-z]/.test(password)) {
    return false;
  }

  if (!/\d/.test(password)) {
    return false;
  }

  if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(password)) {
    return false;
  }

  return true;
}
export function ValidateNameGlobal(name) {
  // Combine empty string check and regular expression validation
  return name.trim() !== "" && /^[A-Za-z\s]+$/.test(name);
}
