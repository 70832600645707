import React, { useState, useEffect } from "react";
import TopnavBar from "../Header/TopnavBar";
import OrganizationSettingBar from "../Header/OrganizationSettingBar";
import { faGears } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Breadcrumbs from "../HomeVixo/Breadcrumbs";
import { VixoAPI } from "../../settings";
import axios from "axios";
import { APIHeader, ExpireToken, HasLoggedInUser } from "../../APICallWrapper";
import ErrorComponent from "../../ErrorComponent";
import Spinner from "../../Spinner";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CheckAllowComponentAndRedirect} from "../../Authentication";
const ACHwork = () => {
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState(null);
  const [userInfo, setUserInfo] = useState({});
  const [achWorksData, setAchWorksData] = useState({
    id: "",
    organizationId: "",
    organizationName: "",
    Sss: "",
    Apikey: "",
    LocId: "",
    Options: "",
    Apiuser: "",
  });

  const [errorType, setErrorType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    LoadData();
  }, []);

  const LoadData = () => {
    const loggedInUser = localStorage.getItem("User");
    var userObject = JSON.parse(loggedInUser);
    
    const ComponentName="User Administration";
     CheckAllowComponentAndRedirect(ComponentName, navigate)
    if (userObject && userObject !== null) {
      setUserInfo(userObject);
      loadACHWorksData(userObject.organizationId);
    } else {
      navigate("/");
    }
  };

  const loadACHWorksData = async (organizationId) => {
    try {
      const response = await axios.get({
       headers:APIHeader().headers
      }
        `${VixoAPI.OrganizationAPI}getACHWorks?Id=${organizationId}`
      );

      if (response.data.statusCode === 200) {
        const achWorksData = response.data.data;
        setAchWorksData({
          ...achWorksData,
          Sss: achWorksData.Sss || "",
          LocId: achWorksData.LocId || "",
          Apikey: achWorksData.Apikey || "",
          Options: achWorksData.Options || "",
          Apiuser: achWorksData.Apiuser || "",
        });
      } else {
        const achWorksData = {};
        setAchWorksData({
          ...achWorksData,
          Sss: achWorksData.Sss || "",
          LocId: achWorksData.LocId || "",
          Apikey: achWorksData.Apikey || "",
          Options: achWorksData.Options || "",
          Apiuser: achWorksData.Apiuser || "",
        });
      }
    } catch (error) {
      console.error("Error loading ACH works data:", error);
    }
  };

  const handleUseryInfoChange = (e) => {
    setAchWorksData({
      ...achWorksData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      id: 0,
      organizationId: userInfo.organizationId,
      Sss: achWorksData.Sss,
      ApiKey: achWorksData.Apikey,
      locId: achWorksData.LocId,
      Options: achWorksData.Options,
      Created: null,
      Apiuser: achWorksData.Apiuser,
    };

    if(HasLoggedInUser())
      {
        const config=APIHeader();
    try {
      const UserAPISave = VixoAPI.OrganizationAPI + "updateACHWorks";

      setLoading(true);

      const response = await axios.post(UserAPISave, data,{
       headers:config.headers
      });

      if (response.data.statusCode === 200) {
        setData(response.data.message, "success");

        showToast("Changes saved successfully", "success");
      } else {
        setData(response.data.message, "error");
      }
    } catch (error) {
      setLoading(false);

      throw error;
    } finally {
      setLoading(false);
    }
  }
  else{
    ExpireToken(navigate);
  }
  };

  function showToast(message, type) {
    if (type === "success") {
      toast.success(message);
    } else {
      toast.error(message);
    }
  }

  function showToast(message, type) {
    if (type === "success") {
      toast.success(message);
    } else {
      toast.error(message);
    }
  }
  const setData = (message, type) => {
    if (type === "success") {
      toast.success(message);
      setSuccessMessage(message);
      setErrorType(null);
    } else {
      toast.error(message);
      setSuccessMessage(null);
      setErrorType(message);
    }
  };
  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  const sidebarStyle = {
    marginLeft: collapsed ? "" : "250px",
  };
  return (
    <>
      <ErrorComponent errorType={errorType} />
      <Spinner show={loading} />
      <TopnavBar />
      <OrganizationSettingBar
        collapsed={collapsed}
        toggleSidebar={toggleSidebar}
      />
      <main className="Gen" style={sidebarStyle}>
        <div className="container-fluid  p-3 bg-white col-lg-12 col-sm-12">
          <div className="row">
            <div className="col-lg-12 mx-auto">
              <div className="card">
                <div className="card-header py-3 bg-transparent">
                  <h5 className="mb-0 d-inline">Setting</h5>
                  <FontAwesomeIcon
                    icon={faGears}
                    className="fa-2x "
                    style={{ width: "25px" }}
                  />
                  <Breadcrumbs />
                  <div className="container">
                    <div className="row">
                      <label className="mb-2 mt-2">SSS </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="SSS"
                        id="Sss"
                        name="Sss"
                        value={achWorksData.Sss}
                        onChange={handleUseryInfoChange}
                      />

                      <label className="mb-2 mt-2">Location ID</label>

                      <input
                        type="text"
                        className="form-control"
                        id="LocId"
                        name="LocId"
                        onChange={handleUseryInfoChange}
                        value={achWorksData.LocId}
                        placeholder="LocID "
                      />

                      <label className="mb-2 mt-2">API Key </label>

                      <input
                        type="password"
                        className="form-control"
                        placeholder="APIKey"
                        id="Apikey"
                        name="Apikey"
                        onChange={handleUseryInfoChange}
                        value={achWorksData.Apikey}
                      />

                      <label className="mb-2 mt-2">API User </label>

                      <input
                        type="text"
                        className="form-control"
                        placeholder="API User"
                        id="Apiuser"
                        name="Apiuser"
                        onChange={handleUseryInfoChange}
                        value={achWorksData.Apiuser}
                      />

                      <label className="mb-2 mt-2"> Options</label>

                      <textarea
                        id="Options"
                        name="Options"
                        value={achWorksData.Options}
                        onChange={handleUseryInfoChange}
                      ></textarea>
                      <p
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100px",
                        }}
                      >
                        <form onSubmit={handleSubmit}>
                          <button
                            type="submit"
                            className="mb-2 mt-2 form-control btn btn-info w-100"
                            style={{
                              borderRadius: "20px",
                              color: "White",
                              fontWeight: "500",
                            }}
                          >
                            {" "}
                            Save
                          </button>
                        </form>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ACHwork;
