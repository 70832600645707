import React, { useEffect, useState } from "react";
import TopnavBar from "../Header/TopnavBar";
import OrganizationSettingBar from "../Header/OrganizationSettingBar";
import { AdminUserRoleTypes } from "../../lookup";
import {
  faGears,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";


import "bootstrap/dist/css/bootstrap.min.css";
import AddUser from './UserModals/CreateUser'
import EditUser from './UserModals/EditUser'
import { useNavigate } from "react-router-dom";
import "../../Sidebar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import axios from "axios";
import { VixoAPI } from "../../settings";
import { APIHeader, ExpireToken, HasLoggedInUser } from "../../APICallWrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/css/bootstrap.min.css";
import Breadcrumbs from "../HomeVixo/Breadcrumbs";
import { CheckAllowComponentAndRedirect, IsRoleAllowed } from "../../Authentication";
// ------------------------------------------------
const Users = () => {
 const [userTypesLookup, setUserTypesLookup] = useState(null);
  const populateLookups = async () => {
    const userTypes = await AdminUserRoleTypes(); 
    setUserTypesLookup(userTypes);
  };


  const DissAllowRoles=[10];

  const [collapsed, setCollapsed] = useState(false);
  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };
  const sidebarStyle = {
    marginLeft: collapsed ? "" : "250px", 
  };



  const navigate = useNavigate();



  const [usersArray, setUsers] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [User, setUser] = useState({});
  const [editUser, setEditUser] = useState(null);
 
  useEffect(() => {
    populateLookups();
    LoadData();
  }, []);


  const handleUserDeletion = (userToDelete) => {
    if (userToDelete.isAdmin) {
      setUsers(usersArray.filter((user) => user.id !== userToDelete.id));
      handleEditModalClose();
    } else {
    }
  };
 
  
  const LoadData = () => {

    const loggedInUser = localStorage.getItem("User");
    var userObject = JSON.parse(loggedInUser);
    const ComponentName="User Administration";
    CheckAllowComponentAndRedirect(ComponentName, navigate)
    if (userObject && userObject !== null) {
      setUser(userObject);
      setLoading(true);
      setUserInfo(userObject);
      LoadOrganizationUsers(userObject.organizationId);
      setLoading(false);
      console.log("navigate to user ");
    } else {
      console.log("navigate to home ");

      navigate("/");
    }

  };
 
  function LoadOrganizationUsers(OrganizationID) {

    if(HasLoggedInUser())
      {
        const config=APIHeader();
    try {
      setLoading(true);
      const GetUsersAPIPath = VixoAPI.UserAPI + "getallbyOrg";
      axios
        .get(GetUsersAPIPath, {
         headers:config.headers,
          params: {
            OrgnizationID: OrganizationID
          },
        })
        .then((response) => {
          var response = response.data;
          setLoading(false);
          if (response.statusCode === 200) {
            setData(response.data);
          } else {
          }
        })
        .catch((error) => {

          setLoading(false);
          if(error.response && error.response.status && error.response.status==401)
            {
               ExpireToken(navigate);
            }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }
  else{
    ExpireToken(navigate);
  }
  }
  function setData(data) {
    try {
      setLoading(true);
      var users = JSON.parse(JSON.stringify(data));
      setUsers(users);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }

  const [showAddModal, setAddModalShow] = useState(false);

  const handleAddModalOpen = () => {
    setAddModalShow(true);
  };
  const handleAddModalClose = (addedUser) => {
    if (addedUser) {
      userAdded(addedUser);
    }
    setAddModalShow(false);
  };
  function userAdded(addedUser) {
    setUsers(current => [...current, addedUser]);

  }
  const userUpdated = (updatedUser) => {
    const indexToUpdate = usersArray.findIndex((user) => user.id === updatedUser.id);

    if (indexToUpdate !== -1) {
      const updatedData = [...usersArray];
      updatedData[indexToUpdate] = updatedUser;
      setUsers(updatedData);
      

    }
  };
  const [showEditModal, setEditModalShow] = useState(false);

  const handleEditModalOpen = (selectedUser) => {
    if (selectedUser) {

      setEditUser(selectedUser);
    } else {
      setEditUser(selectedUser);
    }
    setEditModalShow(true);
  };

  const handleEditModalClose = (editedUser) => {
    setEditModalShow(false);
    if (editedUser) {
      userUpdated(editedUser);
    }
  };
 

  return (
    <>
      <TopnavBar />
      <OrganizationSettingBar
        collapsed={collapsed}
        toggleSidebar={toggleSidebar}
      />
      <div>
        <Button variant="primary  d-none " onClick={handleAddModalClose}>
          Open Modal
        </Button>
      </div>
      <main className="pag" style={sidebarStyle}>
        <div className="container-fluid shadow p-3 bg-white col-lg-12 col-sm-12">
          <div className="row">
            <div className="col-md-12 mx-auto">
              <div className="card">
                <div className="card-header py-3 bg-transparent">
                  <h5 className="mb-0 d-inline">Setting</h5>
                  <FontAwesomeIcon
                    icon={faGears}
                    className="fa-2x "
                    style={{ width: "25px" }}
                  />
                  <Breadcrumbs />
                </div>
                <div className="row">
                  <div className="col-lg-12 mx-auto">
                    <div className="col-6  py-3 px-3 fs-4  fw-bold  col-md-auto col-lg-10 ">
                      <p className="mb-0"> Users</p>
                    </div>
                    <div className="col-6 px-3 col-md-auto col-lg-2 float-md-end  ">
                      <button
                        className="btn btn-info col-md-auto"
                        onClick={handleAddModalOpen}
                        style={{
                          marginTop: "-6pc",
                          borderRadius: "20px",
                          color: "White",
                          fontWeight: "500",
                          width: "7pc",
                        }}
                      >
                        ADD USER
                      </button>
                      <AddUser
                        showModal={showAddModal}
                        handleModalClose={handleAddModalClose}
                        userTypesLookup={userTypesLookup}
                      />
                      <EditUser
                        showModal={showEditModal}
                        usersArray={usersArray}
                        handleModalClose={handleEditModalClose}
                        editUser={editUser}
                        setUsers={setUsers}
                        onDeleteUser={handleUserDeletion}
                      />
                    </div>
                  </div>
                  <div className="container">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Role</th>
                            <th>Edit</th>
                          </tr>
                        </thead>
                        <tbody>
                          {usersArray != null &&
                            usersArray.map((item, index) => (
                              <tr key={index}>
                                <td>{item.name}</td>
                                <td>{item.email}</td>
                                <td>{item.phone}</td>
                                <td>{item.roleName}</td>
                                <td>
                                  {item.viewAccess}{" "}
                                  <FontAwesomeIcon
                                    icon={faPenToSquare}
                                    className="fa-1x"
                                    onClick={() => handleEditModalOpen(item)}
                                  />{" "}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
export default Users;
