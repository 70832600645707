import React from "react";
import Spinner from "../../../Spinner";
import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import InputMask from "react-input-mask";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

import ErrorComponent from "../../../ErrorComponent";
import { VixoAPI } from "../../../settings";
import { APIHeader } from "../../../APICallWrapper";
import {
  ValidateEmailGlobal,
  ValidatePhoneGlobal,
  ValidateNameGlobal,
} from "../../Architecture/Validation";
import { AdminUserRoleTypes } from "../../../lookup";
import { HasLoggedInUser } from "../../../APICallWrapper";
import { useNavigate } from "react-router-dom";

const AddUser = ({ showModal, handleModalClose }) => {

  const navigate = useNavigate();
  const [authenticated, setauthenticated] = useState(null);
  const [User, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorType, setErrorType] = useState(null);
  const [lastError, setLastError] = useState(""); // State to store last error
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [phoneIsValid, setPhoneIsValid] = useState(true);
  const [emailIsValid, setEmailIsValid] = useState(true);
  const [emailError, setEmailError] = useState("");
  const [roleError, setRoleError] = useState("");
  const [userRoles, setUserRoles] = useState([]);

  const validateEmail = (email) => {
    const isValid = ValidateEmailGlobal(email);

    if (!isValid) {
      setEmailError("Enter a valid email address");
    } else {
      setEmailError("");
    }

    return isValid;
  };

  const validatePhone = (phone) => {
    const isValid = ValidatePhoneGlobal(phone);
    return isValid;
  };

  const validateFirstName = (firstName) => {
    const isValid = ValidateNameGlobal(firstName);
    if (!isValid) {
      setFirstNameError("Enter a valid first name");
    } else {
      setFirstNameError("");
    }
    return isValid;
  };

  const validateLastName = (lastName) => {
    const isValid = ValidateNameGlobal(lastName);
    if (!isValid) {
      setLastNameError("Enter a valid last name");
    } else {
      setLastNameError("");
    }
    return isValid;
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    setPhone(value);
    setPhoneIsValid(validatePhone(value));
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setEmailIsValid(validateEmail(value));
  };
  const validateRole = (role) => {
    if (role == "0") {
      setRoleError("Role is required");
      return false;
    } else {
      setRoleError("");
      return true;
    }
  };

  useEffect(() => {
    const loggedInUser = localStorage.getItem("User");
    if (loggedInUser) {
      try {
        setUser(JSON.parse(loggedInUser));
        setauthenticated(true);
        LoadLookups();
      } catch (exception) {
        setauthenticated(false);
      }
    }
  }, []);

  async function LoadLookups() {
    try {
      AdminUserRoleTypes()
        .then((result) => {
          setUserRoles(result);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (ex) {
      console.error(ex + " User Role Lookup Call is not successfull");
    }
  }

  const [currentUser, setCurrentUser] = useState({
    id: 0,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    role: 0,
  });

  const handleAddSubmit = async (e) => {
    e.preventDefault();

    const isFirstNameValid = validateFirstName(currentUser.firstName);
    const isLastNameValid = validateLastName(currentUser.lastName);
    const isEmailValid = validateEmail(currentUser.email);
    const isPhoneValid = validatePhone(currentUser.phone);
    const isRoleValid = validateRole(currentUser.role);

    if (!isFirstNameValid) {
      setFirstNameError("Enter a valid first name");
    } else {
      setFirstNameError("");
    }

    if (!isLastNameValid) {
      setLastNameError("Enter a valid last name");
    } else {
      setLastNameError("");
    }

    if (!isEmailValid) {
      setEmailError("Enter a valid email address");
    } else {
      setEmailError("");
    }

    if (!isPhoneValid) {
      setPhoneIsValid(false);
    }

    if (!isRoleValid) {
      setRoleError("Role is required");
    } else {
      setRoleError("");
    }

    if (
      !isFirstNameValid ||
      !isLastNameValid ||
      !isEmailValid ||
      !isPhoneValid ||
      !isRoleValid
    ) {
      return;
    }

    const data = {
      Id: 0,
      organizationId: User.organizationId,
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      email: currentUser.email,
      phone: currentUser.phone,
      userRole: currentUser.role,
      isActive: true,
      phoneIsConfirmed: true,
      emailIsConfirmed: true,
      password: "",
    };
    if(HasLoggedInUser())
    {

    try {
      const OrganizationInvitePath = VixoAPI.OrganizationAPI + "inviteuser";
      setLoading(true);

      axios
        .post(OrganizationInvitePath, data,{
         headers:APIHeader().headers
        })
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            toast.success(response.message);
            clearData();
            setErrorType("");
            setData(response.data);
          } else {
            setErrorType(response.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      throw error;
    }
  }else
  {
    navigate("/");

  }
  };

  function clearData() {
    setCurrentUser({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      role: 0,
    });
  }
  function setData(data) {
    handleModalClose(data);
  }

  const isButtonDisabled =
    currentUser.role === 0 ||
    !currentUser.firstName ||
    !currentUser.lastName ||
    !currentUser.email ||
    !currentUser.phone ||
    !phoneIsValid ||
    !emailIsValid;

  const clearForm = () => {
    setCurrentUser({
      id: 0,
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      role: 0,
    });
    setPhoneIsValid(true);
    setEmailIsValid("");
    setEmailError("");
    setRoleError("");
    setErrorType("");
    setFirstNameError("");
    setLastNameError("");
  };

  return (
    <>
      {errorType && <ErrorComponent errorType={errorType} />}
      <Spinner show={loading} />
      <Modal
        show={showModal}
        onHide={() => {
          clearForm();
          setErrorType("");
          handleModalClose();
        }}
      >
        <Modal.Header closeButton style={{marginTop:"-4em"}}>
          <Modal.Title className="mx-auto">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center">
                  <span className="m-0">
                    {" "}
                    <img
                      src="assets/images/LogoVixo.png"
                      className="logo-icon  d-block mx-auto"
                      alt="logo icon"
                      style={{
                        width: "46%",
                        display: "flex",
                        marginTop:"3pc",
                        justifyContent: "center",
                        alignItemstems: "center",
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body > 
          <p>
            <div className="modal-body" style={{paddingTop:"0pc"}}>
              <h5 className="text-center">Add User</h5>
              <div className="mb-3">
                <label htmlFor="role" className="form-label">
                  Role
                </label>
                <select
                  className={"form-control " + (roleError ? "is-invalid" : "")}
                  id="role"
                  name="role"
                  value={currentUser ? currentUser.role : "0"}
                  onChange={(e) => {
                    setCurrentUser({
                      ...currentUser,
                      role: e.target.value,
                    });
                    validateRole(e.target.value);
                  }}
                >
                  {/* <option value={0}>Select Role</option>
                  <option value={1}>Admin</option>
                  <option value={2}>User</option> */}
                  <option value="">Select Role</option>{" "}
                  {/* Default and first option */}
                  {userRoles != null &&
                    userRoles.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.displayValue}
                      </option>
                    ))}
                </select>
                {roleError && (
                  <div className="invalid-feedback">{roleError}</div>
                )}
              </div>
              <div className="col">
                <label htmlFor="firstName" className="form-label">
                  First Name
                </label>
                <input
                  type="text"
                  className={
                    "form-control " + (firstNameError ? "is-invalid" : "")
                  }
                  id="firstName"
                  name="firstName"
                  value={currentUser ? currentUser.firstName : ""}
                  onChange={(e) => {
                    setCurrentUser({
                      ...currentUser,
                      firstName: e.target.value,
                    });
                    validateFirstName(e.target.value);
                  }}
                />
                {firstNameError && (
                  <div className="invalid-feedback">{firstNameError}</div>
                )}
              </div>
              <div className="col">
                <label htmlFor="lastName" className="form-label">
                  Last Name
                </label>
                <input
                  type="text"
                  className={
                    "form-control " + (lastNameError ? "is-invalid" : "")
                  }
                  id="lastName"
                  name="lastName"
                  value={currentUser ? currentUser.lastName : ""}
                  onChange={(e) => {
                    setCurrentUser({
                      ...currentUser,
                      lastName: e.target.value,
                    });
                    validateLastName(e.target.value);
                  }}
                />
                {lastNameError && (
                  <div className="invalid-feedback">{lastNameError}</div>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="text"
                  className={"form-control " + (emailError ? "is-invalid" : "")}
                  id="email"
                  name="email"
                  value={currentUser ? currentUser.email : ""}
                  onChange={(e) => {
                    setCurrentUser({
                      ...currentUser,
                      email: e.target.value,
                    });
                    handleEmailChange(e);
                  }}
                  // style={{ borderColor: emailIsValid ? "initial" : "red" }}
                />
                {emailError && (
                  <div className="invalid-feedback">{emailError}</div>
                )}
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Phone
                </label>
                <InputMask
                  mask="(999) 999-9999"
                  type="tel"
                  name="phone"
                  className="form-control"
                  id="phone"
                  placeholder="(xxx) xxx-xxxx"
                  value={currentUser ? currentUser.phone : ""}
                  onChange={(e) => {
                    setCurrentUser({
                      ...currentUser,
                      phone: e.target.value,
                    });
                    handlePhoneChange(e);
                  }}
                  style={{ borderColor: phoneIsValid ? "initial" : "red" }}
                />
              </div>
              {phoneIsValid ? null : (
                <span className="error">
                  Enter a valid phone number exactly 10 digits.
                </span>
              )}

              {currentUser ? (
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Status:
                  </label>
                  <span>
                    <FontAwesomeIcon
                      className="p-1"
                      style={{
                        backgroundColor: "#fcb43e",
                        color: "white",
                        fontSize: "20px",
                        borderRadius: "50%",
                        height: "14px",
                        width: "14px",
                      }}
                      icon={faLink}
                    />
                  </span>
                  <span style={{ marginLeft: "2px" }}>
                    {currentUser.status}
                  </span>
                </div>
              ) : (
                ""
              )}
              <div
                className="card-footer bg-transparent error-container"
                style={{ color: "red" }}
              >
                {errorType && (
                  <div className="" role="alert">
                    {errorType}
                  </div>
                )}
              </div>
              <div className="text-center">
                <button
                  type="button"
                  className="btn btn-info mx-2"
                  onClick={handleAddSubmit}
                  // disabled={isButtonDisabled}
                  style={{
                    borderRadius: "20px",
                    fontWeight: "500",
                    color: "white",
                    width: "7pc",
                  }}
                >
                  &nbsp;&nbsp;&nbsp; Save &nbsp;&nbsp;&nbsp;
                </button>
              </div>
            </div>
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddUser;
