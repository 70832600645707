function CreateAlert(NotificationData) {
  var AlertTitle = "";
  var referenceId = "";
  var cutomerId = "";
  var organiationID = "";
  var createdDate = "";
  var id = "";
  var customerViewedOn = "";
  var clientViewedOn = "";
  var description = "";
var eventTypeId="";
  if (NotificationData.notificationType === 1) {
    AlertTitle = "Bank Account Synced Successfully";
  } else if (NotificationData.notificationType === 2) {
    AlertTitle = "Customer Statement Uploaded";
  } else if (NotificationData.notificationType === 3) {
    AlertTitle = "Customer Document Uploaded";
  } else if (NotificationData.notificationType === 4) {
    AlertTitle = "Customer Document Disconnected";
  } else if (NotificationData.notificationType === 5) {
    AlertTitle = "Invitation Sent Successfully";
  } else if (NotificationData.notificationType === 7) {
    AlertTitle = "Password Reset Request Generated";
  }

  referenceId = NotificationData.referenceID;
  organiationID = NotificationData.organiationID;
  cutomerId = NotificationData.cutomerId;
  createdDate = NotificationData.createdDate;
  clientViewedOn = NotificationData.clientViewedOn;
  customerViewedOn = NotificationData.customerViewedOn;
  description = NotificationData.description;
  id = NotificationData.id;
  eventTypeId = NotificationData.notificationType;

  const newAlert = {
    description: AlertTitle,
    referenceId: referenceId,
    customerViewedOn: null, // Fixed typo: custromerViewedOn -> customerViewedOn
    cutomerId: cutomerId,
    organiationID: organiationID,
    createdDate: Date(),
    eventTypeId: eventTypeId,
    id: id,
    clientViewedOn: null,
  };
  return newAlert;
}

export default CreateAlert;
