import React, { useEffect, useState } from "react";
import axios from "axios";
import Spinner from "../../Spinner";
import { VixoAPI } from "../../settings";
import { APIHeader, ExpireToken, HasLoggedInUser } from "../../APICallWrapper";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OrganizationSettingBar from "../Header/OrganizationSettingBar";
import TopnavBar from "../Header/TopnavBar";
import { useNavigate } from "react-router-dom";


function OrganizationDocusignStauts(){

    const [DocusignCallback, setDocusignCallBack] = useState(null);
    const [loading, setLoading] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const [showModal, setShowModal] = useState(false)
    const navigate= useNavigate();


    const toggleSidebar = () => {
        setCollapsed(!collapsed);
    };

    useEffect(() => {
        LoadDocusignDocuments();
    },[])

    function LoadDocusignDocuments() {
      if(HasLoggedInUser())
        {
          const config=APIHeader();
        try {
          const contractAPIPath =
            VixoAPI.FinicityAPI + "organizationDocumentsStatus";
            setLoading(true);
          axios
            .get(contractAPIPath,{
             headers:config.headers
            })
            .then((response) => {
              var response = response.data;
              if (response.statusCode === 200) {
                setLoading(true);
                setDocusignCallBack(response.data);
                window.open(response.data, '_blank');

                // setShowModal(true);

              } else {
              }
            })
            .catch((error) => {
              setLoading(false);
              throw error;
            })
            .finally(() => {
              setLoading(false);
            });
        } catch (error) {
          setLoading(false);
          if(error.response && error.response.status && error.response.status==401)
            {
               ExpireToken(navigate);
            }
        }
      }
      else{
        ExpireToken(navigate)
      }
    }

    const closeModal = () => {
        setShowModal(false);
    };

    return(
        <>
          <div>
            <Spinner show={loading} />
            <TopnavBar />
            <ToastContainer />

            <OrganizationSettingBar
              collapsed={collapsed}
              toggleSidebar={toggleSidebar}
            />
          </div>
          
        </>
    );
}
export default OrganizationDocusignStauts;

