import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import ErrorComponent from "../../ErrorComponent";
import Spinner from "../../Spinner";
import { VixoAPI } from "../../settings";
import { APIHeader, HasLoggedInUser,ExpireToken } from "../../APICallWrapper";

const ConfirmEmail = () => {

  const [loading, setLoading] = useState(false);
  const [errorType, setErrorType] = useState(null);
  const [confirmationSuccess, setConfirmationSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const navigate= useNavigate();
  const successMessageStyle = {
    backgroundColor: "white", 
    color: "Green", 
    padding: "20px",
    marginBottom: "10px",
    border: "none",
  };
  const location = useLocation();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const keyFromParams = params.get("key");
    if (keyFromParams) {
      CallConfirm(keyFromParams);
    }
  }, [location]);
  function CallConfirm(key){
   
    try {
      var encodedKey = encodeURIComponent(key);
      const ConfirmAPIPath =
        VixoAPI.UserAPI + "ConfirmEmail?UserKey=" + encodedKey;
      setLoading(true);
      axios
        .post(ConfirmAPIPath,)
        .then((response) => {
          var responseData = response.data;
          if (responseData.statusCode === 200) {
            setConfirmationSuccess(true);
            setSuccessMessage(responseData.message); 
          } else {
            setErrorType(responseData.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          if(error.response && error.response.status && error.response.status==401)
            {
               ExpireToken(navigate);
            }
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setErrorType(error.message);
      setLoading(false);
    }
  }

  return (
    <>
      {errorType && <ErrorComponent errorType={errorType} />}
      <Spinner show={loading} />
      <main className="authentication-content">
        <div className="container-fluid d-flex align-items-center justify-content-center vh-100 "
          style={{ overflow: "hidden" }} >
          <div className="authentication-card">
            <div className="card shadow rounded-0 overflow-hidden">
              <div className="row g-0">
                <div className="col-lg-12">
                  <div className="card-body p-4 p-sm-5">
                    <form className="form-body">
                      <span className="m-5">
                        <center><img
                          src="assets/images/LogoVixo.png"
                          className="logo-icon"
                          alt="logo icon"
                          style={{ width: "180px"}}
                        />
                        </center>
                      </span>
                      <h2 className="fw-bold mb-3" style={{ textAlign: "center" }}>Email Confirmation</h2>
                      {confirmationSuccess ? (
                        <p className="text-success">
                          {successMessage && (
                            <div
                              className="alert alert-success"
                              style={successMessageStyle}
                              role="alert"
                            >
                              {successMessage}
                            </div>
                          )}
                        </p>
                      ) : (
                        <>
                          {errorType && (
                            <p className="text-danger mt-2" style={{ textAlign: "center" }}>{errorType}</p>
                          )}
                        </>
                      )}
                      <p className="mb-0" style={{ textAlign: "center" }}>
                        <Link to="/signin" style={{ textDecoration: "none" }}>
                          Sign In here
                        </Link>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ConfirmEmail;
