import 'bootstrap/dist/css/bootstrap.css';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { VixoAPI } from '../../settings';
import 'react-toastify/dist/ReactToastify.css';
import {ValidateEmailGlobal} from '../Architecture/Validation';
import ErrorComponent from '../../ErrorComponent';
import Spinner from '../../Spinner';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';

export default function Forgotpassword() {
  
  const [errorType, setErrorType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({
    email: '',
  });
  const [emailError, setEmailError] = useState('');
  const handleUserInfoChange = (e) => {
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value,
    });
    if (emailError) {
      setEmailError('');
    }
  };
  const validateEmail = (email) => {
    var IsValid = ValidateEmailGlobal(email);
      if (!IsValid) {
      setEmailError('Enter a valid email address');
    }
      return IsValid;
  }
  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (!validateEmail(userInfo.email)) {
      return;
    }
    try {
      const ForgotAPIPath = VixoAPI.AuthenticationAPI + 'forgot';
      setLoading(true);
      axios
        .get(ForgotAPIPath, {
          params: {
            email: userInfo.email,
          },
        })
        .then((response) => {
          var responseData = response.data;
          if (responseData.statusCode === 200) {
            toast.success(responseData.message);
          } else {
            setError(responseData.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      throw error;
    }
  };
  function setError(message) {
    setErrorType(message);
  }
  return (
    <>
      <ToastContainer /> 
      {errorType && <ErrorComponent errorType={errorType} />}
      <Spinner show={loading} />
      <main className="authentication-content">
        <div className="container-fluid d-flex align-items-center justify-content-center vh-100">
          <div className="authentication-card">
            <div className="card shadow overflow-hidden" style={{borderRadius:"7%"}} >
              <div className="row g-0">
                <div className="col-lg-12">
                  <div className="card-body p-4 p-sm-5">
                    <form className="form-body">
                      <span className="m-5">
                        <img
                          src="assets/images/LogoVixo.png"
                          className="logo-icon"
                          alt="logo icon"
                          style={{ width: '180px', height: '101px' }}
                        />
                      </span>
                      <h2 className="fw-bold">Forgot Password</h2>
                      <br />
                      <label className="fw-bold mb-4 mt-4">Email</label>
                      <input
                        type="text"
                        name="email"
                        className={`form-control ${emailError ? 'is-invalid' : ''}`}
                        value={userInfo.email}
                        placeholder="Enter Email Address"
                        onChange={handleUserInfoChange}
                        style={{ padding: '10px' }}
                      />
                      {emailError && <div className="invalid-feedback">{emailError}</div>}
                      <br></br>
                      <button
                        className=" send btn btn-primary form-control"
                        onClick={handleResetPassword} >
                        Send
                      </button>
                    </form>
                    <br />
                    <div className="col-md-12">
                      Do you have an Account?&nbsp;&nbsp;
                      <Link to="/Signin" style={{ textDecoration: 'none' }}>
                        Sign In
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
