import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GlobalErrorBoundary from './GlobalErrorBoundary';
import { Provider } from "react-redux";
import store from "./Components/Notification/store/store";
import env from './env';
if (env.trim().toUpperCase() === 'PRODUCTION' || env.trim().toUpperCase() === 'STAGING') {
  // Include the script only in development
  const script = document.createElement('script');
  script.src = 'https://gateway.clearent.net/js-sdk/js/clearent-host.js';
  document.head.appendChild(script);
}else
{
  const script = document.createElement('script');
  script.src = 'https://gateway-sb.clearent.net/js-sdk/js/clearent-host.js';
  document.head.appendChild(script);

}

// Listen for the custom event to reload all tabs
window.addEventListener("reloadAllTabs", (event) => {
  // Check if the event originated from the current tab
  if (event.source !== window) {
    // If not, reload the current tab
    window.location.reload();
  } else {
    // If yes, close the current tab
    window.close();
  }
});

window.addEventListener("reloadAllTabsLogin", (event) => {
  // Check if the event originated from the current tab
  if (event.source !== window) {
    // If not, reload the current tab
    window.location.reload();
  } else {
    // If yes, close the current tab
    window.close();
  }
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
  <BrowserRouter>
     <GlobalErrorBoundary>
      <App />
    </GlobalErrorBoundary>
    <ToastContainer />
  </BrowserRouter>
  </Provider>
);
