import React, { useState, useEffect } from "react";
import TopnavBar from "../Header/TopnavBar";
import OrganizationSettingBar from "../Header/OrganizationSettingBar";
import Breadcrumbs from "../HomeVixo/Breadcrumbs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGears } from "@fortawesome/free-solid-svg-icons";
import "./CompanyContract.css";
import HtmlEditor from "../HtmlEditor/HtmlEditor";
import axios from "axios";
import { APIHeader, HasLoggedInUser } from "../../APICallWrapper";
import { ToastContainer, toast } from "react-toastify";
import { VixoAPI } from "../../settings";
import "../../../src/Toggle.css";
import { Navigate, useNavigate } from "react-router-dom";


const CompanyConsent = () => {
  const navigate=useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showContractData, setShowContractData] = useState("");
  const [contractId, setContractId] = useState(0);
  const [organizationId, setOrganizationId] = useState(0);
  const [isConsentActive, SetConsentActive] = useState(false);

  const LoadData = () => {
    const loggedInOrg = localStorage.getItem("User");
    var orgObject = JSON.parse(loggedInOrg);
    if (orgObject) {
      setOrganizationId(orgObject.organizationId);
    }
  };

  useEffect(() => {
    LoadData();
  }, []);

  function fetchContractData() {
    if(HasLoggedInUser())
      {
    if (!organizationId) return;
    const OrgAPIPath = `${VixoAPI.OrganizationAPI}getOrganizationContracts?ID=${organizationId}`;
    setLoading(true);
    axios
      .get(OrgAPIPath, {headers:APIHeader().headers})
      .then((response) => {
        const responseData = response.data;
        if (responseData.statusCode === 200) {
          setShowContractData(responseData.data.contract);
          setContractId(responseData.data.id);
          SetConsentActive(responseData.data.isActive);
          toast.success(responseData.message);
        } else {
          toast.error(responseData.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
    }else
    {
      navigate("/");

    }
  }

  useEffect(() => {
    if (organizationId) {
      fetchContractData();
    }
  }, [organizationId]);

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  const sidebarStyle = {
    marginLeft: collapsed ? "" : "250px",
  };

  const handleUpdate = () => {
    if (!organizationId) return;
    const data = {
      Id: contractId,
      OrganizationId: organizationId,
      Contracts: showContractData,
      isActive:isConsentActive
    };
    const OrgAPIPath = `${VixoAPI.OrganizationAPI}UpdateOrganizationContracts`;
    setLoading(true);
    axios
      .post(OrgAPIPath, data, {headers:APIHeader().headers})
      .then((response) => {
        const responseData = response.data;
        if (responseData.statusCode === 200) {
          setShowContractData(responseData.data.contract);
          toast.success(responseData.message);
        } else {
          toast.error(responseData.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleEditorDataChange = (newData) => {
    setShowContractData(newData);
  };

  const handleToggle = () => {
    isConsentActive(!isConsentActive);
  };

  return (
    <>
      <TopnavBar />
      <OrganizationSettingBar
        collapsed={collapsed}
        toggleSidebar={toggleSidebar}
      />
      <main className="page" style={sidebarStyle}>
        <div className="container-fluid p-3 bg-white col-lg-12 col-sm-12">
          <div className="row">
            <div className="col-md-12 mx-auto">
              <div className="card">
                <div className="card-header py-3 bg-transparent">
                  <h5 className="mb-0 d-inline">Setting</h5>
                  <FontAwesomeIcon
                    icon={faGears}
                    className="fa-2x"
                    style={{ width: "25px" }}
                  />
                  <Breadcrumbs />
                </div>
                <div className="card-header py-3 bg-transparent">
                  <div className="row">
                    <div
                      className="col-sm-11"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="heading">
                        <h4>Consent</h4>
                      </div>
                      <div className="Toggle_btn">
                        <label className="switch">
                          <input type="checkbox" onClick={handleToggle} checked={isConsentActive}/>
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>

                   
                      <div className="editor_table">
                        <div className="Html_Editor mt-2 ">
                          <HtmlEditor
                            data={showContractData}
                            onDataChange={handleEditorDataChange}
                          />
                        </div>

                        <button
                          type="button"
                          className="btn updateBtn btn-info custom-button"
                          onClick={handleUpdate}
                          style={{
                            borderRadius: "20px",
                            fontWeight: "500",
                            color: "white",
                            marginTop: "1rem",
                            marginBottom: "2rem",
                            paddingRight: "20px",
                            paddingLeft: "20px",
                          }}
                        >
                          Update Consent
                        </button>
                      </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <ToastContainer />
    </>
  );
};

export default CompanyConsent;
