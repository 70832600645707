import React, { useEffect, useState } from "react";
import TopnavBar from "../Header/TopnavBar";
import { Row } from "react-bootstrap";
import SideBar from "../Header/SideBar";
import { useLocation } from "react-router-dom";
import FloatingBanner from "../Banner/FloatingBanner";

const Layout = ({ children, CustomerSelected }) => {
  const [collapsed, setCollapsed] = useState(false);

  const location = useLocation();

  const handleCustomerSelected = (Customer) => {
    CustomerSelected(Customer);
  };
  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };
  return (
    <>
      <FloatingBanner environment={process.env.REACT_APP_ENV} />
      <Row>
        <TopnavBar />
      </Row>
      <SideBar
        collapsed={collapsed}
        toggleSidebar={toggleSidebar}
        customerSelected={handleCustomerSelected}
      />
      <main className="Gen" style={{ marginLeft: collapsed ? "" : "250px"}}>
      {/* <div style={{height: "100vh",overflow:"hidden"}}> */}
      {children}
      </main>
    </>
  );
};

export default Layout;
