import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import ErrorComponent from "../../ErrorComponent";
import Spinner from "../../Spinner";
import { VixoAPI } from "../../settings";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { login } from "../Notification/Slices/UserSlice";
import { APIHeader } from "../../APICallWrapper";
import {HasLoggedInUser} from "../../APICallWrapper"
const Signin = () => {
   const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [errorType, setErrorType] = useState(null);
  const [userInfo, setUserInfo] = useState({
    email: "",
    password: "",
  });
  const [emailError, setEmailError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const APIHeaders = APIHeader();
  const handleUserInfoChange = (e) => {
    const { name, value } = e.target;
    setUserInfo({
      ...userInfo,
      [name]: value,
    });
    setEmailError("");
    if (name === "email" && !isValidEmail(value)) {
      setEmailError("Please enter a valid email address");
    }
  };
  const navigate = useNavigate();
  const [authenticated, setAuthenticated] = useState(false);
  const [User, setUser] = useState(null);
  const [getorganizationId, setgetorganizationId] = useState(null);

  useEffect(() => {
    const loggedInUser = localStorage.getItem("User");
    var userObject = JSON.parse(loggedInUser);
  
    if (userObject && HasLoggedInUser()) {
      try {
        setUser(userObject);
        setgetorganizationId(userObject.organizationId);
        setAuthenticated(true);
        const OrgAPIPath = `${VixoAPI.OrganizationAPI}get?ID=${userObject.organizationId}`;
        setLoading(true);
        axios
          .get(OrgAPIPath, {headers:APIHeader().headers})
          .then((response) => {
            var response = response.data;
            if (response.statusCode === 200) {
              localStorage.setItem("Org", JSON.stringify(response.data));
              window.dispatchEvent(new Event("Org"));
              navigate("/Home");
            }
          })
          .catch((error) => {
            setLoading(false);
            setAuthenticated(false);
            throw error;
          })
          .finally(() => {
            setLoading(false);
          });

        setLoading(false);
        setFormSubmitted(true);
      } catch (error) {
        setErrorType(error.message);
        setLoading(false);
      }
    } else {
      navigate("/");
    }
    ////////else past here
    // if (userObject != null) {
    //   try {
    //     setUser(userObject);
    //     if (!orgObject) {
    //       getorganizationId(userObject.organizationId);
    //     }

    //     setAuthenticated(true);
    //   } catch (exception) {
    //     setAuthenticated(false);
    //   }
    // } else {
    //   navigate("/");
    // }
  }, []);

    if (authenticated) {
    navigate("/Home");
  }

  const isValidEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._+%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email) && email.endsWith(".com");
  };

  const handleSignIn = async (e) => {
    e.preventDefault();

    try {
      const SigninAPIPath = VixoAPI.AuthenticationAPI + "login";
      setLoading(true);
      const data = {
        UserName: userInfo.email,
        Password: userInfo.password,
      };

      const response = await axios.post(SigninAPIPath, data);
      const responseData = response.data;

      if (responseData.statusCode === 200) {
        if (responseData.data) {
          // getorganizationId(responseData.data.organizationId);
          localStorage.removeItem("User");
          localStorage.setItem("User", JSON.stringify(responseData.data));
           dispatch(login(responseData.data));
          window.dispatchEvent(new Event("User"));
         
          navigate("/Home");
        } else {
          setErrorType("User could not be Loaded. Vixo Team is Working on it.");
        }
      } else {
        setErrorType(responseData.message);
        setIsErrorModalOpen(true);
      }
    } catch (error) {
      setErrorType(error.message);
      setIsErrorModalOpen(true);
    } finally {
      setLoading(false);
      setFormSubmitted(true);
    }

    if (window.newrelic) {
      console.log("Adding page action with custom attributes... on signin");
      window.newrelic.addPageAction("SignInEvent", {
        Email: userInfo.email,
      });
    }
  };

 

  // function getorganizationId(OrganizationID) {
  //   try {
  //     const OrgAPIPath = `${VixoAPI.OrganizationAPI}get?ID=${OrganizationID}`;
  //     setLoading(true);
  //     axios
  //       .get(OrgAPIPath)
  //       .then((response) => {
  //         var response = response.data;
  //         if (response.statusCode === 200) {
  //           localStorage.setItem("Org", JSON.stringify(response.data));
  //           window.dispatchEvent(new Event("Org"));
  //           navigate("/Home");
  //         }
  //       })
  //       .catch((error) => {
  //         setLoading(false);
  //         throw error;
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });

  //     setLoading(false);
  //     setFormSubmitted(true);
  //   } catch (error) {
  //     setErrorType(error.message);
  //     setLoading(false);
  //   }
  // }
  return (
    <>
      {errorType && <ErrorComponent errorType={errorType} />}
      <Spinner show={loading} />
      <main className="authentication-content">
        <div
          className="container-fluid d-flex align-items-center justify-content-center vh-100 "
          style={{ overflow: "hidden" }}
        >
          <div className="authentication-card">
            <div
              className="card shadow  overflow-hidden"
              style={{ borderRadius: "7%" }}
            >
              <div className="row g-0">
                <div className="col-lg-12">
                  <div className="card-body p-4 p-sm-5">
                    <form className="form-body">
                      <span className="m-5">
                        <img
                          src="assets/images/LogoVixo.png"
                          className="logo-icon"
                          alt="logo icon"
                          style={{ width: "180px", height: "101px" }}
                        />
                      </span>
                      <h2 className="fw-bold mb-3">Sign in</h2>
                      <label className="fw-bold mb-2">Email</label>
                      <input
                        type="text"
                        name="email"
                        value={userInfo.email}
                        className={`form-control mt-2 mb-2 ${
                          emailError ? "is-invalid" : ""
                        }`}
                        placeholder="Enter Email Address"
                        onChange={handleUserInfoChange}
                      />
                      {emailError && (
                        <div className="invalid-feedback">{emailError}</div>
                      )}
                      <label className="fw-bold mb-2 mt-2">Password</label>
                      <div className="input-group">
                        <input
                          type={showPassword ? "text" : "password"}
                          name="password"
                          value={userInfo.password}
                          className="form-control"
                          placeholder="Enter Password"
                          onChange={handleUserInfoChange}
                        />
                        <span
                          className="input-group-text"
                          style={{ cursor: "pointer" }}
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                            <span role="img" aria-label="Visible">
                              👁️
                            </span>
                          ) : (
                            <span role="img" aria-label="Hidden">
                              🙈
                            </span>
                          )}
                        </span>
                      </div>
                      <br />
                      <p className="text-end">
                        <Link to="/forgot" style={{ textDecoration: "none" }}>
                          Forgot Password
                        </Link>
                      </p>
                      {
                        <button
                          className="btn btn-primary form-control my-signin-button"
                          onClick={handleSignIn}
                          disabled={!userInfo.password}
                        >
                          Sign In
                        </button>
                      }
                      {isErrorModalOpen && (
                        <div
                          className="modal"
                          tabIndex="-1"
                          role="dialog"
                          style={{ display: "block" }}
                        >
                          <div className="modal-dialog " role="document">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title">Error</h5>
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                  onClick={() => setIsErrorModalOpen(false)}
                                ></button>
                              </div>
                              <div className="modal-body">
                                <p style={{ color: "red" }}>{errorType}</p>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  onClick={() => setIsErrorModalOpen(false)}
                                  style={{ background: "rgb(11, 94, 215)" }}
                                >
                                  Close
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </form>
                    <div className="col-12 text-end">
                      <p className="mb-2 mt-2">
                        Don't have an account yet?{" "}
                        <Link to="/signup" style={{ textDecoration: "none" }}>
                          Sign up here
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Signin;
