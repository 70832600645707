import React, { useState, useEffect } from "react";
import TopnavBar from "../Header/TopnavBar";

import OrganizationSettingBar from "../Header/OrganizationSettingBar";
import { LoadLookUpDatePeriodTypes } from "../../lookup";
import Breadcrumbs from "../HomeVixo/Breadcrumbs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGears } from "@fortawesome/free-solid-svg-icons";

import axios from "axios";
import Spinner from "../../Spinner";
import { VixoAPI } from "../../settings";
import { APIHeader, ExpireToken, HasLoggedInUser } from "../../APICallWrapper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
const  UserPreferences = () => {
      const [collapsed, setCollapsed] = useState(false);
   
     const [selectedOption, setSelectedOption] = useState("");
     
    
      const [loading, setLoading] = useState(false);
      const [dateLookup, setDateLookup] = useState([]);
      const [userKey, setUserKey]=useState(null)
      const [userObject, setUserObject] = useState(null);
      const navigate=useNavigate();

      const LoadData = () => {
        const loggedInUser = localStorage.getItem("User");
        var userObject = JSON.parse(loggedInUser);

        if (userObject && userObject !== null) {
          setUserKey(userObject.key);
            setUserObject(userObject);
            setSelectedOption(userObject.datePeriods);
        } else {
       
        }
      };
        useEffect(() => {
          LoadData();
            
        }, []);

      
        const handleDropdownChange = (event) => {
          const newSelectedValue = event.target.value;
          setSelectedOption(newSelectedValue);
        
        };

        useEffect(()=>{
          LoadLookups();
          
        },[])
    async function LoadLookups() {
      try {
        LoadLookUpDatePeriodTypes()
          .then((result) => {
            setDateLookup(result);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      } catch (ex) {
        console.error(ex + " User Role Lookup Call is not successfull");
      }
    }

   

    const UpdatePreferences = async () => {
      const data = {
        UserKey: userKey,
        DatePeriods: selectedOption,
      };
      if(HasLoggedInUser())
        {
          const config=APIHeader();
      try {
        setLoading(true);
        const DateLookupData = VixoAPI.UserAPI + "UpdatePreferences"; // Corrected URL
        const response = await axios.post(DateLookupData, data,{
         headers:config.headers
        });
        const responseData = response.data;
        if (responseData.statusCode === 200) {
          const updatedUserObject = {
            ...userObject,
            datePeriods: selectedOption,
          };
          localStorage.setItem("User", JSON.stringify(updatedUserObject));
         toast.success("Preference saved successfully.");
          
        } else {
          console.error("Failed to update preferences:", responseData.message);
        }
      } catch (error) {
        console.error("Error updating preferences:", error);
         setLoading(false); 
      } finally {
        setLoading(false); 
    
      }
    }
    else{
      ExpireToken(navigate)
    }
    };

    const toggleSidebar = () => {
      setCollapsed(!collapsed);
    };
    const sidebarStyle = {
      marginLeft: collapsed ? "" : "250px",
    };


  return (
    <>
      <Spinner show={loading} />
      <TopnavBar />
      <OrganizationSettingBar
        collapsed={collapsed}
        toggleSidebar={toggleSidebar}
      />

      <main className="Gen" style={sidebarStyle}>
        <div className="container-fluid  p-3 bg-white col-lg-12 col-sm-12">
          <div className="row">
            <div className="col-lg-12 mx-auto">
              <div className="card">
                <div className="card-header py-3 bg-transparent">
                  <h5 className="mb-0 d-inline">Setting</h5>
                  <FontAwesomeIcon
                    icon={faGears}
                    className="fa-2x "
                    style={{ width: "25px" }}
                  />
                  <Breadcrumbs />
                </div>
                <div className=" py-3 px-3 bg-transparent">
                  <div className="d-flex align-items-center gap-1">
                    <h5 className="mb-0 fs-4"> Date Preferences</h5>
                    <p className="mb-0 " style={{fontSize:"12px"}}>
                    (For Transaction, Income, Expense, and
                      Balance Tabs)
                    </p>
                  </div>
                
                </div>
                <div className="text-end px-2" style={{ width: "250px" }}>
                  <select
                    className="dropdowntabmob h-80 rounded form-control"
                    value={selectedOption}
                    onChange={handleDropdownChange} // Assuming you have a function to handle dropdown change
                  >
                    {dateLookup &&
                      dateLookup.map((datePeriods) => (
                        <option key={datePeriods.id} value={datePeriods.id}>
                          {datePeriods.displayValue}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="card-header py-3 bg-transparent">
                  <div className="d-flex align-items-center justify-content-end">
                    <button
                      className="btn btn-info"
                      style={{
                        borderRadius: "20px",
                        color: "White",
                        fontWeight: "500",
                      }}
                      onClick={UpdatePreferences}
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default UserPreferences;
