import React, { useEffect, useState } from "react";
import { VixoAPI } from "../../settings";
import { useNavigate } from "react-router-dom";
import { CheckAllowComponentAndRedirect } from "../../Authentication";

const Home = ({ SelectedCustomer }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [customerHome, setCustomerHome] = useState(null);
  const navigate = useNavigate();
  
  useEffect(() => {
    if(SelectedCustomer!=null)
      {
          CustomerSelected(SelectedCustomer);
      }
  }, [SelectedCustomer]);

  function CustomerSelected(customer) {
  
    const loggedInUser = localStorage.getItem("User");
    var customerHomeLink = "/ResendInvite";
    var userObject = JSON.parse(loggedInUser);
    
    if (loggedInUser != null && !CheckAllowComponentAndRedirect("Home", navigate) && customer != null) {
      try {
        if (loggedInUser && customer && customer.key && userObject.key) {
          customerHomeLink =
            VixoAPI.CustomeDashboard +
            "vixohome?Id=" +
            SelectedCustomer.key +
            "&U=" +
            userObject.key;
          setCustomerHome(customerHomeLink);
        }
      } catch (Exception) {}
    } else {
      navigate(customerHomeLink);
    }
  }

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  const sidebarStyle = {
    width: collapsed ? "100%" : "100%",
    marginTop: "-3rem", 
  };

  return (
    <>
      <iframe
        title="Sidebar"
        src={customerHome}
        height="800px"
        overflow="hidden"
        style={sidebarStyle}
        className={`${
          collapsed
            ? "iframwidth iframwidthmob"
            : "iframwidthcollapse iframwidthcollapsemob"
        }`}
      />
    </>
  );
};

export default Home;
