import React from 'react'
import OrganizationSettingBar from '../Header/OrganizationSettingBar'

const UserAdministration = () => {
  return (
    <>
      <OrganizationSettingBar />
      <main className="page-content">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <div className="card">
              <div className="card-header py-3 bg-transparent">
                <h5 className="mb-0">Add User To Organization</h5>
              </div>
              <div className="card-body">
                <div className="border p-3 rounded">
                  <form>
                    <h6 className="mb-0">User Information</h6>
                    <br />
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="name" className="form-label">First Name</label>
                          <input type="text" className="form-control" id="name" placeholder="Enter your first name" />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="website" className="form-label">Last Name</label>
                          <input type="url" className="form-control" id="website" placeholder="Enter your last name" />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="website" className="form-label">Cell Phone</label>
                          <input type="url" className="form-control" id="website" placeholder="Enter your cell phone" />
                        </div>
                      </div>
                    </div>
                  </form>
                  <br />

                  <h6 className="mb-0">Other Information</h6>
                  <br />
                  <form>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="name" className="form-label">Email</label>
                          <input type="text" className="form-control" id="name" placeholder="example@vixolink.com" />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="website" className="form-label">Password</label>
                          <input type="url" className="form-control" id="website" placeholder="Password" />
                        </div>
                      </div>

                      <div className="form-group col-md-2 pt-md-2">
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input" id="invite" name="invite" />
                          <label className="form-check-label" htmlFor="invite">Invite</label>
                        </div>
                      </div>
                      <div className="form-group col-md-2 pt-md-2">
                        <div className="form-check">
                          <input type="checkbox" className="form-check-input" id="admin" name="admin" />
                          <label className="form-check-label" htmlFor="admin">Admin</label>
                        </div>
                      </div>
                      <div className="form-group pt-md-3">
                        <div className='row justify-content-center'>
                          <div className='col-md-4 '>
                            <button type="submit" className="btn btn-primary w-100 ">Add User</button>

                          </div>
                          <div className='col-md-4'>
                            <button type="button" className="btn btn-primary w-100">Cancel</button>

                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default UserAdministration