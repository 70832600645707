import React, { useState } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import Spinner from "../../Spinner";
import { VixoAPI } from "../../settings";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { APIHeader, ExpireToken, HasLoggedInUser } from "../../APICallWrapper";
import { useNavigate } from "react-router-dom";

const Customerdelete = ({
  show,
  onHide,
  customerName,
  customerKey,
  onDeleteCancel,
  onDeleteSuccess,
}) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleDeleteConfirm = async () => {
    if (HasLoggedInUser) {
      const config=APIHeader();
      try {
        setLoading(true);
        const DeleteCustomerAPIPath = VixoAPI.CustomerAPI + "DeleteCustomer";
        axios
          .delete(DeleteCustomerAPIPath, {
            headers: config.headers,
            params: {
              CustomerKey: customerKey,
            },
          })
          .then((response) => {
            var response = response.data;
            if (response.statusCode === 200) {
              onHide();
              onDeleteSuccess(customerKey);
            } else {
            }
          })
          .catch((error) => {
            setLoading(false);
            if(error.response && error.response.status && error.response.status==401)
              {
                 ExpireToken(navigate);
              }
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        throw error;
      }
    } else {
      ExpireToken(navigate);
    }
  };
  return (
    <>
      <Spinner show={loading} />

      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>
            Delete Customer {customerName != null && customerName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Deleting your account will result in permanent data loss. Are you sure
          you want to delete?
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={onDeleteCancel}
            style={{ borderRadius: "20px", width: "7pc" }}
          >
            No
          </button>
          <button
            className="btn btn-danger"
            onClick={handleDeleteConfirm}
            style={{ borderRadius: "20px", width: "7pc" }}
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Customerdelete;
