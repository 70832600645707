import React, { useState, useEffect } from "react";
import TopnavBar from "../Header/TopnavBar";
import OrganizationSettingBar from "../Header/OrganizationSettingBar";
import { faGears } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Breadcrumbs from "../HomeVixo/Breadcrumbs";
import MultiRangeSlider from "multi-range-slider-react";
import ErrorComponent from "../../ErrorComponent";
import Spinner from "../../Spinner";
import Button from "react-bootstrap/Button";
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { VixoAPI } from "../../settings";
import { APIHeader, ExpireToken, HasLoggedInUser } from "../../APICallWrapper";
import DollarSign from "../../VixoFormats/DollarSign";
import { CheckAllowComponentAndRedirect, IsRoleAllowed } from "../../Authentication";
import { useNavigate } from "react-router-dom";

const PaymentAnalytics = () => {
  const navigate= useNavigate();
  const [minValueBalanceError, setMinValueBalanceError] = useState("");
  const [maxValueFundingError, setMaxValueFundingError] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorType, setErrorType] = useState(null);
  const [inputTag, setInputTag] = useState("");
  const [inputAccount, setInputAccount] = useState("");
  const handleInputTagChange = (e) => {
    setInputTag(e.target.value);
  };
  const handleInputAccountChange = (e) => {
    setInputAccount(e.target.value);
  };

  const handleInputTagKeyPress = (e) => {
    if (e.key === "Enter" && inputTag.trim() !== "") {
      setAppliedTags([...appliedTags, inputTag.trim()]);
      setInputTag("");
    }
  };
  const handleInputAccountKeyPress = (e) => {
    if (e.key === "Enter" && inputAccount.trim() !== "") {
      setAppliedAccounts([...appliedAccounts, inputAccount.trim()]);
      setInputAccount("");
    }
  };

  const [appliedTags, setAppliedTags] = useState([]);
  const [appliedAccounts, setAppliedAccounts] = useState([]);

  const removeTag = (filter) => {
    const updatedTags = appliedTags.filter((f) => f !== filter);
    setAppliedTags(updatedTags);
  };
  const removeAccount = (filter) => {
    const updatedAccounts = appliedAccounts.filter((f) => f !== filter);
    setAppliedAccounts(updatedAccounts);
  };

  const [collapsed, setCollapsed] = useState(false);
  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };
  const sidebarStyle = {
    marginLeft: collapsed ? "" : "250px",
  };

  const [pastDaysWithNoNegativeValue, setPastDaysWithNoNegativeValue] =
    useState(50);
  const [daysNegativeinthepast90days, setDaysNegativeinthepast90days] =
    useState(50);
  const [enableAutomaticFunding, setEnableAutomaticFunding] = useState(false);
  const [minValueFunding, setMinValueFunding] = useState(0);
  const [maxValueFunding, setMaxValueFunding] = useState(100000);
  const [minValueBalance, setMinValueBalance] = useState(0);
  const [maxValueBalance, setMaxValueBalance] = useState(100000);
  const [minValueMonthlyIncome, setMinValueMonthlyIncome] = useState(0);
  const [maxValueMonthlyIncome, setMaxValueMonthlyIncome] = useState(100000);
  const [minValueMonthlyExpense, setMinValueMonthlyExpense] = useState(0);
  const [maxValueMonthlyExpense, setMaxValueMonthlyExpense] = useState(100000);
  const [minValueMonthlyExpenseError, setMinValueMonthlyExpenseError] =
    useState("");
  const [minValueMonthlyIncomeError, setMinValueMonthlyIncomeError] =
    useState("");
  const [minValueFundingError, setMinValueFundingError] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  const handleButtonClick = () => {
    try {
      setEnableAutomaticFunding(!enableAutomaticFunding);
    } catch (exception) {}
  };
  const handleMinInputChangeMonthlyIncome = (e) => {
    try {
      const value = parseInt(e.target.value);

      if (!isNaN(value) && value <= maxValueMonthlyIncome) {
        setMinValueMonthlyIncome(value);
        setMinValueMonthlyIncomeError(""); // Clear the error message
      } else {
        setMinValueMonthlyIncomeError(
          "The maximum value cannot be less than the minimum value"
        );
      }
    } catch (exception) {
      setMinValueMonthlyIncomeError("Error");
      console.error(exception);
    }
  };
  const handleRangeChangeMonthlyIncome = (value) => {
    try {
      if (value.minValue <= value.maxValue) {
        setMinValueMonthlyIncome(value.minValue);
        setMaxValueMonthlyIncome(value.maxValue);
      } else {
        alert("Minimum value should be less than or equal to Maximum value");
      }
    } catch (ex) {
      alert("Error");
    }
  };
  const handleMaxInputChangeMonthlyIncome = (e) => {
    try {
      const value = parseInt(e.target.value);

      if (!isNaN(value) && value >= minValueMonthlyIncome) {
        setMaxValueMonthlyIncome(value);
        setMinValueMonthlyIncomeError("");
      } else {
        setMinValueMonthlyIncomeError(
          "The maximum value cannot be less than the minimum value"
        );
      }
    } catch (exception) {
      setMinValueMonthlyIncomeError("Error");
      console.error(exception);
    }
  };
  const handleRangeChangeMonthlyExpense = (value) => {
    try {
      if (value.minValue <= value.maxValue) {
        setMinValueMonthlyExpense(value.minValue);
        setMaxValueMonthlyExpense(value.maxValue);
      } else {
        alert("Minimum value should be less than or equal to Maximum value");
      }
    } catch (exception) {
      alert("Error");
      console.error(exception);
    }
  };
  const handleMinInputChangeMonthlyExpense = (e) => {
    try {
      const value = parseInt(e.target.value);

      if (!isNaN(value) && value <= maxValueMonthlyExpense) {
        setMinValueMonthlyExpense(value);
        setMinValueMonthlyExpenseError("");
      } else {
        setMinValueMonthlyExpenseError(
          "The maximum value cannot be less than the minimum value"
        );
      }
    } catch (exception) {
      setMinValueMonthlyExpenseError("Error");
      console.error(exception);
    }
  };
  const handleMaxInputChangeMonthlyExpense = (e) => {
    try {
      const value = parseInt(e.target.value);

      if (!isNaN(value) && value >= minValueMonthlyExpense) {
        setMaxValueMonthlyExpense(value);
        setMinValueMonthlyExpenseError(""); // Clear the error message
      } else {
        setMinValueMonthlyExpenseError(
          "The maximum value cannot be less than the minimum value"
        );
      }
    } catch (exception) {
      setMinValueMonthlyExpenseError("Error");
      console.error(exception);
    }
  };
  const handleMinFundingInputChange = (e) => {
    try {
      const value = parseInt(e.target.value);

      if (!isNaN(value) && value <= maxValueFunding) {
        setMinValueFunding(value);
        setMinValueFundingError("");
      } else {
        setMinValueFundingError(
          "The maximum value cannot be equal to the minimum value"
        );
      }
    } catch (exception) {
      setMinValueFundingError("Error");
      console.error(exception);
    }
  };
  const handleMaxFundingInputChange = (e) => {
    try {
      const value = parseInt(e.target.value);

      if (!isNaN(value) && value >= minValueFunding) {
        setMaxValueFunding(value);
        setMaxValueFundingError("");
      } else {
        setMaxValueFundingError(
          "The maximum value cannot be equal to the minimum value"
        );
      }

      if (value === minValueFunding) {
        setMaxValueFundingError(
          "The maximum value cannot be equal to the minimum value"
        );
      }
    } catch (exception) {
      setMaxValueFundingError("Error");
      console.error(exception);
    }
  };
  const handleMinInputChangeBalance = (e) => {
    try {
      const value = parseInt(e.target.value);

      if (!isNaN(value) && value <= maxValueBalance) {
        setMinValueBalance(value);
        setMinValueBalanceError("");
      } else {
        setMinValueBalanceError(
          "The maximum value cannot be less than the minimum value"
        );
      }
    } catch (exception) {
      setMinValueBalanceError("Error");
      console.error(exception);
    }
  };
  const handleMaxInputChangeBalance = (e) => {
    try {
      const value = parseInt(e.target.value);

      if (!isNaN(value) && value >= minValueBalance) {
        setMaxValueBalance(value);
        setMinValueBalanceError("");
      } else {
        setMinValueBalanceError(
          "The maximum value cannot be less than the minimum value"
        );
      }
    } catch (exception) {
      setMinValueBalanceError("Error");
      console.error(exception);
    }
  };
  const handleRangeChangeBalance = (value) => {
    try {
      if (value.minValue <= value.maxValue) {
        setMinValueBalance(value.minValue);
        setMaxValueBalance(value.maxValue);
      } else {
        setMinValueBalanceError(
          "Minimum value should be less than or equal to Maximum value"
        );
      }
    } catch (exception) {
      setMinValueBalanceError("Error");
      console.error(exception);
    }
  };
  const handleRangeChangeFund = (value) => {
    try {
      if (value.minValue <= value.maxValue) {
        setMinValueFunding(value.minValue);
        setMaxValueFunding(value.maxValue);
      } else {
        setMinValueFundingError(
          "Minimum value should be less than or equal to Maximum value"
        );
      }
    } catch (exception) {
      setMinValueFundingError("Error");
      console.error(exception);
    }
  };
  const [orgId, setOrgId] = useState({});
  useEffect(() => {
   const loggedInUser = localStorage.getItem("User");
   var userObject = JSON.parse(loggedInUser);
   
   const ComponentName="User Administration";
   CheckAllowComponentAndRedirect(ComponentName, navigate)
    if (userObject) {
      setOrgId(userObject);
      GetOrganizationFundingDetail(userObject.organizationId);
    }
  }, []);
  useEffect(() => {
    if (maxValueFunding <= minValueFunding) {
      setMaxValueFundingError("");
    } else {
      setMaxValueFundingError("");
    }
  }, [maxValueFunding, minValueFunding]);
  function GetOrganizationFundingDetail(OrganizationID) {
    if(HasLoggedInUser())
      {
        const config=APIHeader();
    try {
      const FundAPIPath = `${VixoAPI.FundingAPI}GetOrganizationFundingDetail?OrganizationID=${OrganizationID}`;
      setLoading(true);
      axios
        .get(FundAPIPath,{
         headers:config.headers
        })
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            setFunding(response.data);
          } else {
            setErrorType(response.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
      setLoading(false);
    } catch (error) {
      setErrorType(error.message);
      setLoading(false);
    }
  }
  else{
    ExpireToken(navigate)
  }
}

  function setFundData(fundingDetail) {
    setMaxValueFunding(fundingDetail.maxFundingLimit.toFixed(2));
    setMinValueFunding(fundingDetail.minFundingLimit.toFixed(2));
    setMaxValueBalance(fundingDetail.maxBalanceLimit.toFixed(2));
    setMinValueBalance(fundingDetail.minBalanceLimit.toFixed(2));
    setMaxValueMonthlyIncome(fundingDetail.maxMonthlyIncome.toFixed(2));
    setMinValueMonthlyIncome(fundingDetail.minMonthlyIncome.toFixed(2));
    setMaxValueMonthlyExpense(fundingDetail.maxMonthlyExpense.toFixed(2));
    setMinValueMonthlyExpense(fundingDetail.minMonthlyExpense.toFixed(2));
    setPastDaysWithNoNegativeValue(fundingDetail.pastDaysWithNoNegativeValue);

    setDaysNegativeinthepast90days(fundingDetail.pastNegativeIn90Days);

    setEnableAutomaticFunding(fundingDetail.enableAutomaticFunding);

    setAppliedTags(fundingDetail.tags);

    setAppliedAccounts(fundingDetail.accountTypes);
  }
  function setFunding(data) {
    try {
      setLoading(true);
      var Paymentdata = JSON.parse(JSON.stringify(data));
      setFundData(Paymentdata);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }

  useEffect(() => {
    const storedData = localStorage.getItem("fundingData");
    if (storedData) {
      setFundData(JSON.parse(storedData));
    }
  }, []);

  const UpdateOrganizationFundingDetail = async (e) => {
    e.preventDefault();
    const data = {
      Id: 0,
      OrganizationId: orgId.id,
      MinFundingLimit: minValueFunding,
      MaxFundingLimit: maxValueFunding,
      MinMonthlyIncome: minValueMonthlyIncome,
      MaxMonthlyIncome: maxValueMonthlyIncome,
      MinMonthlyExpense: minValueMonthlyExpense,
      MaxMonthlyExpense: maxValueMonthlyExpense,
      PastDaysWithNoNegativeValue: pastDaysWithNoNegativeValue,
      PastNegativeIn90Days: daysNegativeinthepast90days,
      EnableAutomaticFunding: enableAutomaticFunding,
      MinBalanceLimit: minValueBalance,
      MaxBalanceLimit: maxValueBalance,
      Tags: appliedTags,
      AccountTypes: appliedAccounts,
    };
    try {
      setLoading(true);
      const FundAPIPath = `${VixoAPI.FundingAPI}UpdateOrganizationFundingDetail?OrganizationID=${orgId.id}`;
      axios
        .put(FundAPIPath, data,{
         headers:APIHeader().headers
        })
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            toast.success(response.message);
          } else {
            toast(response.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setErrorType(error.message);
      setLoading(false);
    }
  };

  return (
    <>
      {errorType && <ErrorComponent errorType={errorType} />}
      <Spinner show={loading} />
      <TopnavBar />
      <OrganizationSettingBar
        collapsed={collapsed}
        toggleSidebar={toggleSidebar}
      />
      <main className="Gen" style={sidebarStyle}>
        <div className="container-fluid  p-3 bg-white col-lg-12 col-sm-12">
          <div className="row">
            <div className="col-lg-12 mx-auto">
              <div className="card">
                <div className="card-header py-3 bg-transparent">
                  <h5 className="mb-0 d-inline">Setting</h5>
                  <FontAwesomeIcon
                    icon={faGears}
                    className="fa-2x "
                    style={{ width: "25px" }}
                  />
                  <Breadcrumbs />
                </div>

                <div className="card-header py-3 bg-transparent">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="container">
                          <h6>Funding Limits</h6>
                          <div className="row">
                            <div className="col-md-4">
                              <div className="input-group">
                                <span className="input-group-text">
                                  <DollarSign />
                                </span>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Minimum Value"
                                  value={minValueFunding}
                                  onChange={(e) =>
                                    handleMinFundingInputChange(e)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <MultiRangeSlider
                                min={0}
                                max={100000}
                                minValue={minValueFunding}
                                maxValue={maxValueFunding}
                                onInput={(value) =>
                                  handleRangeChangeFund(value)
                                }
                                label={false}
                                ruler={false}
                                style={{
                                  border: "none",
                                  boxShadow: "none",
                                  padding: "15px 10px",
                                }}
                              />
                            </div>
                            <div className="col-md-4">
                              <div className="input-group">
                                <span className="input-group-text">
                                  <DollarSign />
                                </span>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Maximum Value"
                                  value={maxValueFunding}
                                  onChange={(e) =>
                                    handleMaxFundingInputChange(e)
                                  }
                                />
                              </div>
                              {maxValueFundingError && (
                                <div className="error-message fw-bold text-danger">
                                  {maxValueFundingError}
                                </div>
                              )}
                            </div>
                          </div>

                          <h6>Balance</h6>
                          <div className="row">
                            <div className="col-md-4">
                              <div className="input-group">
                                <span className="input-group-text">
                                  <DollarSign />
                                </span>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Minimum Value"
                                  value={minValueBalance}
                                  onChange={(e) =>
                                    handleMinInputChangeBalance(e)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <MultiRangeSlider
                                min={0}
                                max={100000}
                                minValue={minValueBalance}
                                maxValue={maxValueBalance}
                                onInput={(value) =>
                                  handleRangeChangeBalance(value)
                                }
                                label={false}
                                ruler={false}
                                style={{
                                  border: "none",
                                  boxShadow: "none",
                                  padding: "15px 10px",
                                }}
                              />
                            </div>
                            <div className="col-md-4">
                              <div className="input-group">
                                <span className="input-group-text">
                                  <DollarSign />
                                </span>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Maximum Value"
                                  value={maxValueBalance}
                                  onChange={(e) =>
                                    handleMaxInputChangeBalance(e)
                                  }
                                />
                              </div>
                              {minValueBalanceError && (
                                <div className="error-message fw-bold text-danger">
                                  {minValueBalanceError}
                                </div>
                              )}
                            </div>
                          </div>
                          <h6>Monthly Income</h6>
                          <div className="row">
                            <div className="col-md-4">
                              <div className="input-group">
                                <span className="input-group-text">
                                  <DollarSign />
                                </span>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Minimum Value"
                                  value={minValueMonthlyIncome}
                                  onChange={(e) =>
                                    handleMinInputChangeMonthlyIncome(e)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <MultiRangeSlider
                                min={0}
                                max={100000}
                                minValue={minValueMonthlyIncome}
                                maxValue={maxValueMonthlyIncome}
                                onInput={(value) =>
                                  handleRangeChangeMonthlyIncome(value)
                                }
                                label={false}
                                ruler={false}
                                style={{
                                  border: "none",
                                  boxShadow: "none",
                                  padding: "15px 10px",
                                }}
                              />
                            </div>
                            <div className="col-md-4">
                              <div className="input-group">
                                <span className="input-group-text">
                                  <DollarSign />
                                </span>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Maximum Value"
                                  value={maxValueMonthlyIncome}
                                  onChange={(e) =>
                                    handleMaxInputChangeMonthlyIncome(e)
                                  }
                                />
                              </div>
                              {minValueMonthlyIncomeError && (
                                <div className="error-message fw-bold text-danger">
                                  {minValueMonthlyIncomeError}
                                </div>
                              )}
                            </div>
                          </div>
                          <h6>Monthly Expense Limits</h6>
                          <div className="row">
                            <div className="col-md-4">
                              <div className="input-group">
                                <span className="input-group-text">
                                  <DollarSign />
                                </span>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Minimum Value"
                                  value={minValueMonthlyExpense}
                                  onChange={(e) =>
                                    handleMinInputChangeMonthlyExpense(e)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-md-4">
                              <MultiRangeSlider
                                min={0}
                                max={100000}
                                minValue={minValueMonthlyExpense}
                                maxValue={maxValueMonthlyExpense}
                                onInput={(value) =>
                                  handleRangeChangeMonthlyExpense(value)
                                }
                                label={false}
                                ruler={false}
                                style={{
                                  border: "none",
                                  boxShadow: "none",
                                  padding: "15px 10px",
                                }}
                              />
                            </div>
                            <div className="col-md-4">
                              <div className="input-group">
                                <span className="input-group-text">
                                  <DollarSign />
                                </span>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Maximum Value"
                                  value={maxValueMonthlyExpense}
                                  onChange={(e) =>
                                    handleMaxInputChangeMonthlyExpense(e)
                                  }
                                />
                              </div>
                              {minValueMonthlyExpenseError && (
                                <div className="error-message fw-bold text-danger">
                                  {minValueMonthlyExpenseError}
                                </div>
                              )}
                            </div>
                          </div>

                          <br />
                          <div className="row ">
                            <div className="col-md-4">
                              Past Days With No Negative Value
                            </div>
                            <div className="col-md-4">
                              <input
                                type="number"
                                className="form-control"
                                value={pastDaysWithNoNegativeValue}
                                onChange={(e) => {
                                  const value = parseInt(e.target.value);
                                  setPastDaysWithNoNegativeValue(value);
                                }}
                              />
                            </div>
                            <div className="col-md-4">
                              <input
                                type="range"
                                min={0}
                                max={100} // Adjust the min and max values as needed
                                value={pastDaysWithNoNegativeValue}
                                onChange={(e) => {
                                  const value = parseInt(e.target.value);
                                  setPastDaysWithNoNegativeValue(value);
                                }}
                              />
                            </div>
                          </div>
                          <br />
                          <div className="row mb-4">
                            <div className="col-md-4">
                              Days Negative in the past 90 days
                            </div>
                            <div className="col-md-4">
                              <input
                                type="number"
                                className="form-control"
                                value={daysNegativeinthepast90days}
                                onChange={(e) => {
                                  const value = parseInt(e.target.value);
                                  setDaysNegativeinthepast90days(value);
                                }}
                              />
                            </div>
                            <div className="col-md-4">
                              <input
                                type="range"
                                min={0}
                                max={100}
                                value={daysNegativeinthepast90days}
                                onChange={(e) => {
                                  const value = parseInt(e.target.value);
                                  setDaysNegativeinthepast90days(value);
                                }}
                              />
                            </div>
                          </div>
                          <br />
                          <div className="row mb-4">
                            <div className="col-md-4">
                              <span>Transaction Filter</span>
                            </div>
                            <div className="col-md-4">
                              <input
                                type="text"
                                className="form-control"
                                value={inputTag}
                                onChange={handleInputTagChange}
                                onKeyPress={handleInputTagKeyPress}
                              />
                            </div>
                            <div className="col-md-4">
                              <span>
                                Restrict Funding with any transaction
                                description
                              </span>
                            </div>
                          </div>
                          <div className="row mt-2 mb-2">
                            <div className="col-md-12">
                              {appliedTags &&
                                appliedTags.map((filter, index) => (
                                  <div
                                    key={index}
                                    style={{
                                      display: "inline-block",
                                      marginRight: "8px",
                                      marginBottom: "8px",
                                    }}
                                  >
                                    <button
                                      key={index}
                                      className="btn btn-outline-primary btn-sm mr-2 mb-2"
                                      onClick={() => removeTag(filter)}
                                      style={{}}
                                    >
                                      {filter}{" "}
                                      <span
                                        className="ml-1"
                                        style={{
                                          padding: "2px",
                                          marginLeft: "5px",
                                        }}
                                      >
                                        ×
                                      </span>
                                    </button>
                                  </div>
                                ))}
                              &nbsp;&nbsp;
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-3">
                              <span>Accounts</span>
                            </div>
                            <div className="col-md-4">
                              <input
                                type="text"
                                className="form-control"
                                value={inputAccount}
                                onChange={handleInputAccountChange}
                                onKeyPress={handleInputAccountKeyPress}
                              />
                            </div>

                            <div className="row mt-2 mb-2">
                              <div className="col-md-12">
                                {appliedAccounts &&
                                  appliedAccounts.map((filter, index) => (
                                    <div
                                      key={index}
                                      style={{
                                        display: "inline-block",
                                        marginRight: "8px",
                                        marginBottom: "8px",
                                      }}
                                    >
                                      <button
                                        key={index}
                                        className="btn btn-outline-primary btn-sm mr-2 mb-2"
                                        onClick={() => removeAccount(filter)}
                                        style={{}}
                                      >
                                        {filter}{" "}
                                        <span
                                          className="ml-1"
                                          style={{
                                            padding: "2px",
                                            marginLeft: "5px",
                                          }}
                                        >
                                          ×
                                        </span>
                                      </button>
                                    </div>
                                  ))}
                                &nbsp;&nbsp;
                              </div>
                            </div>
                          </div>
                          <br />
                          <div className="row">
                            <div className="col-md-12">
                              {selectedOption && (
                                <button
                                  type="button"
                                  className="btn btn-outline-primary "
                                  onClick={handleButtonClick}
                                >
                                  {selectedOption}
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <h6 className="">Enable Automatic Funding</h6>
                        <Button
                          variant={
                            enableAutomaticFunding ? "danger" : "success"
                          }
                          onClick={handleButtonClick}
                          style={{
                            borderRadius: "20px",
                            color: "White",
                            fontWeight: "500",
                          }}
                          className="ms-sm-2 ms-md-0"
                        >
                          <FontAwesomeIcon
                            icon={faPowerOff}
                            style={{
                              marginRight: "5px",
                              color: enableAutomaticFunding ? "blue" : "",
                            }}
                          />
                          {enableAutomaticFunding ? "Disabled" : "Enabled"}
                        </Button>
                      </div>
                      <div className="col-md-3"></div>
                      {/* <div className="col-md-3">
                                                <button className="btn btn-info mb-5" style={{ borderRadius: "20px", color: "White", fontWeight: "500" }}>Add New Funding Account</button>
                                                <div className='col-md-10'>
                                                    <input type="checkbox" className="" />
                                                    <span>
                                                        <img src="assets/images/1.svg" style={{ width: '180px', backgroundColor: 'white', margin: '5px' }} />

                                                        <div style={{ backgroundColor: '#00aadb' }}>
                                                            <p className=" p-2 fw-bold">Checking Account</p>
                                                            <p className=" p-2">$123434354</p>
                                                        </div>
                                                    </span>
                                                    <input type="checkbox" className="" />
                                                    <span>
                                                        <img src="assets/images/transwest bank.svg" style={{ width: '180px', backgroundColor: 'white', margin: '5px' }} />

                                                        <div style={{ backgroundColor: '#00aadb' }}>
                                                            <p className=" p-2 fw-bold">Checking Account</p>
                                                            <p className=" p-2">$123434354</p>
                                                        </div>
                                                    </span>
                                                </div>
                                            </div> */}
                    </div>
                  </div>
                  <div className="justify-content-center text-center mt-5 mb-5">
                    <button
                      className="btn btn-info  w-25 text-white "
                      style={{
                        borderRadius: "20px",
                        color: "White",
                        fontWeight: "500",
                      }}
                      onClick={UpdateOrganizationFundingDetail}
                    >
                      Save{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default PaymentAnalytics;
