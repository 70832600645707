import React from "react";
import { Modal, Button } from "react-bootstrap";
const Fileopen = ({ show, onHide, title, pdfSrc }) => {
  
  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        style={{ marginTop: "-6px!important" }}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <div
          className="col-6"
          style={{
            width: "100%",
            height: "300px",
            marginTop: "-16px!important",
          }}
        >
          <embed
            src={pdfSrc}
            type="application/pdf"
            style={{ width: "100%", height: "300px" }}
          />
        </div>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Fileopen;
