import React, { useState, useEffect, useRef } from "react";
import { Nav, Dropdown } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import "./Topnav.css";
import DisableOrg from "../OrganizationsSetting/DisableOrg";
import { useSelector } from "react-redux";
import axios from "axios";
import CreateAlert from "../alertFactory";
import { VixoAPI } from "../../settings";
import { APIHeader, HasLoggedInUser, ExpireToken } from "../../APICallWrapper";
import FileOpen from "./Fileopen";
import { selectNotification } from "../Notification/Slices/sendNotificationSlice";
import Spinner from "../../Spinner";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { logout } from "../Notification/Slices/UserSlice";

function TopnavBar() {
  const dispatch = useDispatch();
  const notification = useSelector(selectNotification);
  const newNotification = notification;
  const navigate = useNavigate();
  const [APIHeaders, setAPIHeaders] = useState(null);

  const handleLogout = async (e) => {
    e.preventDefault();
    localStorage.clear();
    dispatch(logout());
    navigate("/");
  };

  useEffect(() => {
    LoadData();
  }, []);

  const [orgId, setOrgId] = useState(null);

  const [loading, setLoading] = useState(false);
  const [organizationCount, setOrganizationCount] = useState(0);
  const [orgnizationNotification, setorgnizationNotification] = useState([]);
  const [showViewPopup, setShowViewPopup] = useState(false);
  const [fileView, setFileView] = useState(null);
  const [showButton, setShowButton] = useState(false);
  const LoadData = () => {
    const loggedInUser = localStorage.getItem("User");
    var userObject = JSON.parse(loggedInUser);

    if (userObject && userObject != null) {
      var hasUserLoggedIn = HasLoggedInUser();
      if (hasUserLoggedIn) {
        setAPIHeaders(APIHeader());
        setOrgId(userObject.organizationId);
        LoadOrganizationNotifications(userObject.organizationId);
      }
    } else {
      navigate("/");
    }
  };
  useEffect(() => {
    if (newNotification != null) {
      GotNewAlert(newNotification);
    }
  }, [newNotification]);
  function GotNewAlert(NotificationData) {
    if (
      NotificationData != null &&
      NotificationData.organiationID == orgId &&
      NotificationData.notificationType < 8
    ) {
      var OldAlertCountsForOrganization = organizationCount;
      OldAlertCountsForOrganization = OldAlertCountsForOrganization + 1;
      setOrganizationCount(OldAlertCountsForOrganization);
      const newAlert = CreateAlert(NotificationData);
      const updatedListOfAlets = [...orgnizationNotification, newAlert];
      setorgnizationNotification(updatedListOfAlets);
      LoadOrganizationNotifications(orgId);
    } else if (
      (NotificationData.organiationID == orgId &&
        NotificationData.notificationType) === 31
    ) {
      LoadOrganizationNotifications(orgId);
    }
  }
  const LoadOrganizationNotifications = (OrganizationId) => {
    if (OrganizationId === null) {
      return;
    }
    if (HasLoggedInUser()) {
      const config=APIHeader();
    const GetAlertAPIPath =
      VixoAPI.AlertsAPI + "getOrganizationAlertsUsingSP?Id=" + OrganizationId;

      axios
        .get(GetAlertAPIPath, { headers: config.headers })
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            setData(response.data);
          }
        })
        .catch((error) => {
          if(error.response && error.response.status && error.response.status==401)
            {
              ExpireToken(navigate);
            }
          });
        } else {
          ExpireToken(navigate);
    }
  };

  function setData(data) {
    try {
      setLoading(true);
      var organizations = JSON.parse(JSON.stringify(data));
      setOrganizationCount(organizations.alertCount);
      setorgnizationNotification(organizations.cusAlerts);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }

  const handleAlertClick = (id) => {
    if (HasLoggedInUser()) {
    const config= APIHeader();
      // var id = customerAlert.id;
      const GetAlertAPIPath = VixoAPI.AlertsAPI + "viewAlerts?AlertID=" + id;
      setLoading(true);
      axios
        .get(GetAlertAPIPath, {
          headers: config.headers,
        })
        .then((response) => {
          var response = response.data;
          setLoading(false);
          if (response.statusCode === 200) {
            if (response.data.eventTypeId === 3) {
              getAlertDocData(response.data.referenceId);
            } else if (response.data.eventTypeId === 2) {
              getAlertStatementData(response.data.referenceId);
            }
            LoadOrganizationNotifications(orgId);
          }
        })
        .catch((error) => {
          setLoading(false);
          if(error.response && error.response.status && error.response.status==401)
            {
              ExpireToken(navigate);
            }
        })
        .finally(() => {
          if (fileView !== null) {
            setLoading(false);
          }
        });
    } else {
        ExpireToken(navigate);
    }
  };
  function getAlertDocData(Id) {
    if (HasLoggedInUser()) {
      const config= APIHeader();
      const GetAlertAPIPath =
        VixoAPI.DocumentAPI + "getAnyDocumentById?DocumentId=" + Id;

      axios
        .get(GetAlertAPIPath, {
          headers: config.headers,
        })
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            setFileView(response.data.fileSha1Hash);
            setShowViewPopup(true);
          }
        })
        .catch((error) => {
          if(error.response && error.response.status && error.response.status==401)
            {
               ExpireToken(navigate);
            }
        })
        .finally(() => {
          setLoading(false); // Set loading to false when document retrieval is complete
        });
    } else {
      ExpireToken(navigate);
    }
  }
  function getAlertStatementData(Id) {
    if (HasLoggedInUser()) {
      const config= APIHeader();
      const GetAlertAPIPath =
        VixoAPI.StatmentAPI + "getAnyStatementById?StatementId=" + Id;

      axios
        .get(GetAlertAPIPath, {
          headers: config.headers,
        })
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            setFileView(response.data.fileSha1Hash);
            setShowViewPopup(true);
          }
        })
        .catch((error) => {
          if(error.response && error.response.status && error.response.status==401)
            {
               ExpireToken(navigate);
            }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      ExpireToken(navigate)
    }
  }

  function formatTimeFromDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  }
  // ClearAllNotificationAll
  function ClearAllNotificationAll(orgId) {
    if (HasLoggedInUser()) {
    const config=APIHeader();
      if (orgId !== null) {
        const clearAllAlerts =
          VixoAPI.AlertsAPI +
          "ClearAllOrganizationsAlerts?organizationId=" +
          orgId;
        axios
          .get(clearAllAlerts, {
            headers: config.headers,
          })
          .then((response) => {
            var responseData = response.data; // Renaming to avoid conflict with response variable
            if (responseData.status === 200) {
              setorgnizationNotification([]);
              setOrganizationCount(0);
              toast.success(responseData.message);
            } else {
              toast.error(responseData.message);
            }
          })
          .catch((error) => {
            toast.error(error.message);
          })
          .finally(() => {
            setLoading(false); // Set loading to false regardless of success or failure
          });
      }
    } else {
      ExpireToken(navigate);
    }
  }
  //

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <>
      <Spinner show={loading} />

      <FileOpen
        show={showViewPopup}
        onHide={() => setShowViewPopup(false)}
        pdfSrc={fileView}
      />
      <DisableOrg />
      <header className="top-header" style={{ backgroundColor: "#004265" }}>
        <nav
          className="navbar navbar-expand"
          style={{ backgroundColor: "white" }}
        >
          {/* <span>
            <Link
              to="/Home"
              className="flex-start  ImageTopNavBar topnavbarlogo"
            >
              <img
                src="assets/images/LogoVixo.png"
                className="ImageTopNavBar logo-icon"
                style={{ width: "150px" }}
              />
            </Link>
          </span> */}
          <div className="top-navbar-right ms-3 ms-auto">
            <div
              className="navbar-nav align-items-center"
              style={{ backgroundColor: "white" }}
            >
              <Nav.Item>
                <div style={{ position: "relative" }}>
                  <Dropdown
                    show={isOpen}
                    onToggle={handleToggle}
                    ref={dropdownRef}
                  >
                    <Dropdown.Toggle
                      as={Nav.Link}
                      id="notifications-dropdown"
                      style={{
                        background: "none",
                        border: "none",
                        boxShadow: "none",
                      }}
                    >
                      <div className="notifications">
                        <span
                          className="notify-badge"
                          style={{ fontSize: "9px" }}
                        >
                          {organizationCount}
                        </span>
                        <FontAwesomeIcon icon={faBell} />
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      id="myalert"
                      style={{ marginRight: "-4pc", marginTop: "2pc" }}
                      className="topnav-alert"
                    >
                      <div
                        className="alertmob"
                        style={{
                          maxWidth: "270px",
                          position: "absolute !important",
                          paddingLeft: "9px",
                          maxHeight: "465px",
                          overflow: "hidden",
                          width: "18pc",
                          scrollbarWidth: "thin",

                          msOverflowStyle: "thin",
                        }}
                      >
                        <div className="p-2 border-bottom m-2 d-flex align-items-center justify-content-between">
                          <h5 className="h5 mb-0">Notifications</h5>
                          <button
                            onClick={() => ClearAllNotificationAll(orgId)}
                            style={{
                              backgroundColor: "white",
                              border: "none",
                              fontSize: "14px",
                              color: "green",
                            }}
                          >
                            Clear All
                          </button>
                        </div>
                        <div className="overflow-auto header-notifications-list p-2">
                          {orgnizationNotification &&
                            orgnizationNotification
                              .sort(
                                (a, b) =>
                                  new Date(b.createdDate) -
                                  new Date(a.createdDate)
                              )
                              .map((alert, index) => (
                                <div
                                  className="notification-scrol d-flex align-items-center mb-2 "
                                  key={index}
                                >
                                  <div
                                    className="ms-3 flex-grow-1"
                                    style={{ cursor: "Pointer" }}
                                  >
                                    <h6 className="mb-0 dropdown-msg-user">
                                      {alert.description}
                                    </h6>
                                    <div
                                      className={`d-flex align-items-center ${
                                        alert.eventTypeId === 3 ||
                                        alert.eventTypeId === 2
                                          ? "justify-content-between"
                                          : "justify-content-end"
                                      }`}
                                    >
                                      {alert.eventTypeId !== null &&
                                        (alert.eventTypeId == 3 ||
                                          (alert.eventTypeId == 2 && (
                                            <small
                                              className="mb-0 dropdown-msg-text text-secondary d-flex align-items-center cursor-pointer"
                                              onClick={() =>
                                                handleAlertClick(alert.id)
                                              }
                                            >
                                              View Details
                                            </small>
                                          )))}

                                      <p className="msg-time  text-secondary mb-0">
                                        {formatTimeFromDate(alert.createdDate)}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                  {isOpen && (
                    <div
                      style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: 999,
                      }}
                      onClick={handleClose}
                    />
                  )}
                </div>
              </Nav.Item>

              <Nav.Item>
                <Link
                  className="projects m-2 nav-item"
                  to={`${VixoAPI.DashBoard}General`}
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faGear} />
                </Link>
              </Nav.Item>

              <Nav.Item>
                <Link
                  className="m-2 nav-item projects"
                  onClick={handleLogout}
                  title="Logout"
                >
                  <FontAwesomeIcon icon={faRightFromBracket} />
                </Link>
              </Nav.Item>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}

export default TopnavBar;
