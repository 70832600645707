import { VixoAPI } from "./settings";
import { APIHeader, HasLoggedInUser } from "./APICallWrapper";
import axios from "axios";

export function UserRoleTypes() {
  const config = APIHeader();
  var LookupJson;
  try {
    const APIPath = VixoAPI.LookupAPI + "getUserRoles";
    axios
      .get(APIPath, {
        headers: config.headers,
      })
      .then((response) => {
        var response = response.data;
        if (response.statusCode === 200) {
          LookupJson = JSON.parse(JSON.stringify(response.data));
        } else {
        }
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        return LookupJson;
      });
  } catch (error) {
    throw error;
  }
}
export async function AdminUserRoleTypes() {
  const config = APIHeader();
  try {
    const lookups = localStorage.getItem("AdminUserRoleTypes");
    if (lookups == null) {
      const APIPath = VixoAPI.LookupAPI + "getAdminRoles";
      const response = await axios.get(APIPath, {
        headers: config.headers,
      });
      var apiResponse = response.data;
      if (apiResponse.statusCode == 200) {
        localStorage.setItem(
          "AdminUserRoleTypes",
          JSON.stringify(apiResponse.data)
        );
      }
    }
    const lookupsResponse = localStorage.getItem("AdminUserRoleTypes");
    return JSON.parse(lookupsResponse);
  } catch (error) {
    throw error;
  }
}

export async function InstituteTypes() {
  const config = APIHeader();
  try {
    const lookups = localStorage.getItem("InstituteTypes");
    if (lookups == null) {
      const APIPath = VixoAPI.LookupAPI + "getInstituteTypes";
      const response = await axios.get(APIPath, {
        headers: config.headers,
      });
      var apiResponse = response.data;
      if (apiResponse.statusCode == 200) {
        localStorage.setItem(
          "InstituteTypes",
          JSON.stringify(apiResponse.data)
        );
      }
    }
    const lookupsResponse = localStorage.getItem("InstituteTypes");
    return JSON.parse(lookupsResponse);
  } catch (error) {
    throw error;
  }
}

export async function ThresholdTypes() {
  const config = APIHeader();
  try {
    const lookups = localStorage.getItem("ThresholdTypes");
    if (lookups == null) {
      const APIPath = VixoAPI.LookupAPI + "getThresholdType";
      const response = await axios.get(APIPath, {
        headers: config.headers,
      });
      var apiResponse = response.data;
      if (apiResponse.statusCode == 200) {
        localStorage.setItem(
          "ThresholdTypes",
          JSON.stringify(apiResponse.data)
        );
      }
    }
    const lookupsResponse = localStorage.getItem("ThresholdTypes");
    return JSON.parse(lookupsResponse);
  } catch (error) {
    throw error;
  }
}
export async function OpenBankingType() {
  const config = APIHeader();
  try {
    const lookups = localStorage.getItem("OpenBankingType");
    if (lookups == null) {
      const APIPath = VixoAPI.LookupAPI + "getOpenBankingType";
      const response = await axios.get(APIPath, {
        headers: config.headers,
      });
      var apiResponse = response.data;
      if (apiResponse.statusCode == 200) {
        localStorage.setItem(
          "OpenBankingType",
          JSON.stringify(apiResponse.data)
        );
      }
    }
    const lookupsResponse = localStorage.getItem("OpenBankingType");
    return JSON.parse(lookupsResponse);
  } catch (error) {
    throw error;
  }
}

export async function PaymentStatusType() {
  const config = APIHeader();
  try {
    const lookups = localStorage.getItem("PaymentStatusType");
    if (lookups == null) {
      const APIPath = VixoAPI.LookupAPI + "getVixoPaymentStatusTypes";
      const response = await axios.get(APIPath, {
        headers: config.headers,
      });
      var apiResponse = response.data;
      if (apiResponse.statusCode == 200) {
        localStorage.setItem(
          "PaymentStatusType",
          JSON.stringify(apiResponse.data)
        );
      }
    }
    const lookupsResponse = localStorage.getItem("PaymentStatusType");
    return JSON.parse(lookupsResponse);
  } catch (error) {
    throw error;
  }
}

export async function LoadLookUpDatePeriodTypes() {
  if(HasLoggedInUser())
    {
      const config=APIHeader();
  try {
    const lookups = localStorage.getItem("DatePeriodTypes");
    if (lookups == null) {
      const APIPath = VixoAPI.LookupAPI + "getDatePeriodTypes";
      const response = await axios.get(APIPath, {
        headers: config.headers,
      });
      var apiResponse = response.data;
      if (apiResponse.statusCode == 200) {
        localStorage.setItem(
          "DatePeriodTypes",
          JSON.stringify(apiResponse.data)
        );
        // return JSON.parse(JSON.stringify(apiResponse.data));
      }
    }
    const lookupsResponse = localStorage.getItem("DatePeriodTypes");
    return JSON.parse(lookupsResponse);
  } catch (error) {
    throw error;
  }
}
}
