// UserSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  user: null,
};

const storedUser = JSON.parse(localStorage.getItem("User"));
if (storedUser) {
  initialState.isAuthenticated = true;
  initialState.user = storedUser;
}

const userSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload;
      localStorage.setItem("User", JSON.stringify(state.user));
    },
    logout(state) {
      state.isAuthenticated = false;
      state.user = null;
      localStorage.removeItem("User");

      // Broadcast logout event to other tabs
      localStorage.setItem("logout", Date.now().toString());
    },
  },
});

export const { login, logout } = userSlice.actions;
export const selectIsAuthenticated = (state) => state.auth.isAuthenticated;
export const selectUser = (state) => state.auth.user;

export default userSlice.reducer;
