import React, { useState, useEffect } from "react";
import TopnavBar from "../Header/TopnavBar";
import OrganizationSettingBar from "../Header/OrganizationSettingBar";
import { faGears } from "@fortawesome/free-solid-svg-icons";
import {
  ValidatePhoneGlobal,
  ValidateEmailGlobal,
} from "../Architecture/Validation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Breadcrumbs from "../HomeVixo/Breadcrumbs";
import { VixoAPI } from "../../settings";
import { APIHeader, ExpireToken, HasLoggedInUser } from "../../APICallWrapper";
import axios from "axios";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Modal, Button } from "react-bootstrap";
import "./UploadFiles.css";
import ErrorComponent from "../../ErrorComponent";
import Spinner from "../../Spinner";
import { useNavigate, useLocation } from "react-router-dom";

// import "../../Sidebar.css";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import {
  faTrash,
  faEye,
  faFilePen,
  faEnvelope,
  faPaperPlane,
  faArrowRotateRight,
} from "@fortawesome/free-solid-svg-icons";
import Customdate from "../CustomDate/Customdate";
import { CheckAllowComponentAndRedirect, IsRoleAllowed } from "../../Authentication";
const DocuCustomerDocuments = ({ customerkey }) => {
  const [isAllDocuSignFilesSelected, setIsAllDocuSignFilesSelected] =
    useState(false);
  const [Doucmentid, setDoucmentid] = useState({});
  const [docuSignAuth, setdocuSignAuth] = useState("");
  const [CustomerKey, setCustomerKey] = useState(null);
  const [customStartDate, setCustomStartDate] = useState(null);
  const [customEndDate, setCustomEndDate] = useState(null);
  const [customDateInputVisible, setCustomDateInputVisible] = useState(false);
  const [UserKey, setUserKey] = useState(null);
  const [selectedValue, setSelectedValue] = useState(9);
  const [docuSignFiles, setDocuSignFiles] = useState([]);
  const [selectedDocumentUrl, setSelectedDocumentUrl] = useState({});
  const [selectedDocumentTitle, setSelectedDocumentTitle] = useState({});
  const [selectedItems, setSelectedItems] = useState({});
  const [isMasterModal, setisMasterModal] = useState(false);
  const [isCustomDateSelected, setIsCustomDateSelected] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const [User, setUser] = useState({});
  const [userInfo, setUserInfo] = useState({
    id: "",
    organizationId: "",

    organizationName: "",
    integrationKey: "",
    userId: "",
    apiAccountId: "",
  });

  const [loading, setLoading] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const { start, end } = calculateDateRange(selectedValue);
    setCustomStartDate(start);
    setCustomEndDate(end);
    handleDropdownChange(selectedValue);
  }, []);

  function calculateDateRange(selectedValue) {
    const today = new Date();

    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    startOfMonth.setHours(0, 0, 0);

    switch (selectedValue) {
      case 1: // This Month
        const endOfMonth = new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          1
        );
        endOfMonth.setMonth(endOfMonth.getMonth(), 0);
        endOfMonth.setDate(endOfMonth.getDate() - 1);
        endOfMonth.setHours(23, 59, 59);
        return { start: startOfMonth, end: today };

      case 2: // Last Month
        const lastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
        const lastMonthStart = new Date(
          lastMonth.getFullYear(),
          lastMonth.getMonth(),
          1
        );
        const lastMonthEnd = new Date(
          lastMonth.getFullYear(),
          lastMonth.getMonth() + 1,
          0
        );
        return { start: lastMonthStart, end: lastMonthEnd };

      case 3: // Last Three Months
        const lastThreeMonthsEnd = new Date(
          today.getFullYear(),
          today.getMonth(),
          0,
          0
        );
        const lastThreeMonthsStart = new Date(lastThreeMonthsEnd);
        lastThreeMonthsStart.setMonth(lastThreeMonthsStart.getMonth() - 2);
        lastThreeMonthsStart.setDate(lastThreeMonthsStart.getDate() + 1); // Add one day to the start date

        const nextMonthForThree = new Date(lastThreeMonthsEnd);
        nextMonthForThree.setMonth(nextMonthForThree.getMonth() + 1);
        const lastDayOfNextMonthForThree = new Date(
          nextMonthForThree.getFullYear(),
          nextMonthForThree.getMonth() + 1,
          0
        );

        lastThreeMonthsEnd.setMonth(lastThreeMonthsEnd.getMonth() + 1);
        lastThreeMonthsEnd.setDate(lastDayOfNextMonthForThree.getDate());
        return { start: lastThreeMonthsStart, end: today };

      case 4: // Last Six Months
        const lastSixMonthsEnd = new Date(
          today.getFullYear(),
          today.getMonth(),
          0,
          0
        );
        const lastSixMonthsStart = new Date(lastSixMonthsEnd);
        lastSixMonthsStart.setMonth(lastSixMonthsStart.getMonth() - 5);
        lastSixMonthsStart.setDate(lastSixMonthsStart.getDate() + 1); // Add one day to the start date

        const nextMonthForSix = new Date(lastSixMonthsEnd);
        nextMonthForSix.setMonth(nextMonthForSix.getMonth() + 1);
        const lastDayOfNextMonthForSix = new Date(
          nextMonthForSix.getFullYear(),
          nextMonthForSix.getMonth() + 1,
          0
        );

        lastSixMonthsEnd.setMonth(lastSixMonthsEnd.getMonth() + 1);
        lastSixMonthsEnd.setDate(lastDayOfNextMonthForSix.getDate());
        return { start: lastSixMonthsStart, end: today };

      case 5: // Last Nine Months
        const lastNineMonthsEnd = new Date(
          today.getFullYear(),
          today.getMonth(),
          0,
          0
        );
        const lastNineMonthsStart = new Date(lastNineMonthsEnd);
        lastNineMonthsStart.setMonth(lastNineMonthsStart.getMonth() - 8);
        lastNineMonthsStart.setDate(lastNineMonthsStart.getDate()); // Add one day to the start date

        const nextMonthForNine = new Date(lastNineMonthsEnd);
        nextMonthForNine.setMonth(nextMonthForNine.getMonth() + 1);
        const lastDayOfNextMonthForNine = new Date(
          nextMonthForNine.getFullYear(),
          nextMonthForNine.getMonth() + 1,
          0
        );

        lastNineMonthsEnd.setMonth(lastNineMonthsEnd.getMonth() + 1);
        lastNineMonthsEnd.setDate(lastDayOfNextMonthForNine.getDate());
        return { start: lastNineMonthsStart, end: today };
      case 6: // This Year
        const thisYearStart = new Date(today.getFullYear(), 0, 1);
        const thisYearEnd = new Date(today.getFullYear(), 11, 31);
        return { start: thisYearStart, end: today };

      case 7: // Last Year
        const lastYearStart = new Date(today.getFullYear() - 1, 0, 1);
        const lastYearEnd = new Date(today.getFullYear() - 1, 11, 31);
        return { start: lastYearStart, end: lastYearEnd };

      case 8: // Custom Date
        // Implement your logic for custom date handling here
        return { start: null, end: null };

      case 9: // All Time
        // Consider setting a very early date as the start for 'All Time'
        const allTimeStart = new Date(2010, 0, 1);
        const allTimeEnd = new Date(); // This is today
        return { start: allTimeStart, end: today };

      default:
        return { start: null, end: null }; // Default to no dates
    }
  }

  useEffect(() => {
    LoadUser();
  }, []);

  const LoadUser = () => {
    

    const loggedInUser = localStorage.getItem("User");
    var userObject = JSON.parse(loggedInUser);

    const ComponentName="User Administration";
     CheckAllowComponentAndRedirect(ComponentName, navigate)
    if (userObject && userObject !== null) {
      setUser(userObject);

      setUserInfo(userObject);

      LoadDocusignDocuments(userObject.organizationId);
    } else {
      navigate("/");
    }
  };

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };
  const sidebarStyle = {
    marginLeft: collapsed ? "" : "250px",
  };

  const handleModalToggle = (url, title, id) => {
    setSelectedDocumentUrl(url);
    setSelectedDocumentTitle(title);
    setDoucmentid(id);
    setShowModal(!showModal);
  };

  const handleCheckboxChange = (docusignFile) => {
    docusignFile.IsSelected = !docusignFile.IsSelected;
    const objectIndex = docuSignFiles.findIndex(
      (obj) => obj.Id && obj.Id === docusignFile.Id
    );
    let newData = [...docuSignFiles];

    if (objectIndex !== -1) {
      const newData = [...docuSignFiles];
      newData[objectIndex] = docusignFile;
      setDocuSignFiles(newData);
    }
    const anyCheckboxSelected = newData.some((item) => item.IsSelected);
    setIsAllDocuSignFilesSelected(anyCheckboxSelected);

    const newSelectedItems = {
      ...selectedItems,
      [docusignFile.Id]: docusignFile.IsSelected,
    };
    setSelectedItems(newSelectedItems);
  };

  const handleRowClick = (docusignFile) => {
    handleCheckboxChange(docusignFile);
  };

  function LoadDocusignDocuments(OrganizationID) {
    if(HasLoggedInUser()){
      const config=APIHeader();
    
    try {
      const contractAPIPath =
        VixoAPI.FinicityAPI +
        "getOrganizationDocuSignDocuments?OrganizationID=" +
        OrganizationID;
      setLoading(true);
      axios
        .get(contractAPIPath,{
         headers:config.headers
        })
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            setLoading(true);
            AssignDocuSignFiles(response.data);
          } else {
          }
        })
        .catch((error) => {
          setLoading(false);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }else {
    ExpireToken(navigate, customerkey)
  }
  }

  function AssignDocuSignFiles(docusignAPIData) {
    if (docusignAPIData) {
      const DocusignFilesSource = [];
      var docusignFiles = JSON.parse(JSON.stringify(docusignAPIData));
      docusignFiles.forEach((docusignFile) => {
        var docusignFileRequest = {
          Id: docusignFile.id,
          IsActive: docusignFile.isActive,
          Title: docusignFile.documentTitle,
          DocumentURL: docusignFile.documentUrl,
          Status: docusignFile.status,
          IsSelected: false,
          CustomerName: docusignFile.customerName,
        };
        DocusignFilesSource.push(docusignFileRequest);
      });
      setDocuSignFiles(DocusignFilesSource);
    }
  }

  const [searchQuery, setSearchQuery] = useState("");
  const filteredDocuSignFiles = docuSignFiles.filter(
    (item) =>
      (item.Title &&
        item.Title.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (item.CustomerName &&
        item.CustomerName.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  // const handleDropdownChange = (value) => {
  //   setIsCustomDateSelected(value === "8");
  // };

  ///////////////////
  function UpdatePeriodOption() {
    if ((selectedValue >= 1 && selectedValue <= 7) || selectedValue <= 9) {
      // If "Last Three Months," "Last Six Months," or "Last Nine Months" is selected, calculate the date ranges
      let { start, end } = calculateDateRange();
      if (start && end) {
        setCustomStartDate(start.toISOString().substr(0, 10));
        setCustomEndDate(end.toISOString().substr(0, 10));
        setCustomDateInputVisible(false);
      }
    }
  }
  const handleDropdownChange = (value) => {
    const val = parseInt(value, 10);
    setSelectedValue(val);
    setIsCustomDateSelected(val === 8); // Set isCustomDateSelected based on whether "Custom Date" is selected

    if (val !== 8) {
      // If "Custom Date" is not selected, calculate the date range based on the selected option
      const { start, end } = calculateDateRange(val);
      if (start && end) {
        setCustomStartDate(start);
        setCustomEndDate(end);
      }
    }
  };

  const handleCustomStartDateChange = (date) => {
    if (date) {
      setCustomStartDate(date);
    }
  };

  const handleCustomEndDateChange = (date) => {
    if (date) {
      setCustomEndDate(date);
    }
  };

  return (
    <>
      <Spinner show={loading} />
      <TopnavBar />
      <OrganizationSettingBar
        collapsed={collapsed}
        toggleSidebar={toggleSidebar}
      />

      <main className="Gen" style={sidebarStyle}>
        <div className="container-fluid bg-white col-lg-12 col-sm-12">
          <div className="row">
            <div className="col-lg-12 mx-auto">
              <div className="card">
                <div className="card-header py-3 bg-transparent">
                  <h5 className="mb-0 d-inline">Setting</h5>
                  <FontAwesomeIcon
                    icon={faGears}
                    className="fa-2x "
                    style={{ width: "25px" }}
                  />
                  <Breadcrumbs />

                  <div className="container-fluid">
                    <div className="">
                      <div className="">
                        <h4 className="mt-3">Sent Documents</h4>
                      </div>
                      <div className="row">
                        <div className="col- col-lg-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                          />
                          <div className="row mt-2 mb-2">
                            <div className="col-md-12"></div>
                          </div>
                        </div>
                        <div className="col-6 text-center">
                          <div className="row">
                            {selectedValue === 8 && (
                              // Uncomment this section to display custom date input fields
                              <Customdate
                                customStartDate={customStartDate}
                                customEndDate={customEndDate}
                                setCustomStartDate={setCustomStartDate}
                                setCustomEndDate={setCustomEndDate}
                                handleCustomStartDateChange={
                                  handleCustomStartDateChange
                                }
                                handleCustomEndDateChange={
                                  handleCustomEndDateChange
                                }
                              />
                              // <h3>Custom Dates</h3>
                            )}
                          </div>
                        </div>

                        <div className="col- col-lg-3 text-end ">
                          <select
                            style={{ alignContent: "end", border: "20%" }}
                            className="dropdowntabmob h-80 rounded form-control"
                            onChange={(e) =>
                              handleDropdownChange(e.target.value)
                            }
                          >
                            <option value="9">All Time</option>
                            <option value="1">This Month</option>
                            <option value="2">Last Month</option>
                            <option value="3">Last Three Month</option>
                            <option value="4">Last Six Month</option>
                            <option value="5">Last Nine Month</option>
                            <option value="6">This Year</option>
                            <option value="7">Last Year</option>
                            <option value={8}>Custom Date</option>
                          </select>
                        </div>
                      </div>
                      {/* )} */}
                      <div
                        className="mt-3"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h6 className="datemobile">
                          Start Date{" "}
                          <span className="datemobile">
                            {customStartDate
                              ? customStartDate.toLocaleDateString()
                              : ""}
                          </span>
                        </h6>
                        <h6
                          className="datemobile"
                          style={{ marginLeft: "11px" }}
                        >
                          End Date{" "}
                          <span className="datemobile">
                            {customEndDate
                              ? customEndDate.toLocaleDateString()
                              : ""}
                          </span>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid customer-document" style={{ padding: "1pc" }}>
                    <div className="row">
                      <div className="col-md-12 mx-auto">
                        <div className="">
                          <div
                            class="card-header py-3 bg-transparent"
                            style={{ padding: "1pc" }}
                          >
                            <h5 class="mb-0" style={{ marginLeft: "1pc" }}>
                              Sent Documents
                            </h5>
                          </div>
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th> Customer Name</th>
                                <th> Document Title</th>
                                <th> Orgainzation Documents</th>

                                <th> Document Status</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {filteredDocuSignFiles.map((item) => (
                                <tr>
                                  <td>{item.CustomerName}</td>

                                  <td>{item.Title}</td>
                                  <td></td>
                                  <td>{item.Status}</td>

                                  <td>
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip id="view-tooltip">
                                          View
                                        </Tooltip>
                                      }
                                    >
                                      <FontAwesomeIcon
                                        style={{
                                          color: "#36B37E",
                                          cursor: "pointer",
                                        }}
                                        icon={faEye}
                                        onClick={() =>
                                          handleModalToggle(
                                            item.DocumentURL,
                                            item.Title,
                                            item.Id
                                          )
                                        }
                                      />
                                    </OverlayTrigger>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Modal
                    className="modal-lg"
                    show={showModal}
                    onHide={() => handleModalToggle("")}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>{selectedDocumentTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <iframe
                        title="Document Viewer"
                        src={selectedDocumentUrl}
                        width="100%"
                        height="240px"
                      />
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={() => handleModalToggle("")}
                      >
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default DocuCustomerDocuments;
