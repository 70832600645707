import React, { useState, useEffect } from "react";
import OrganizationSettingBar from "../Header/OrganizationSettingBar";
import "./Billingmodule.css";
import "./FundingRequest.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGears, faUser } from "@fortawesome/free-solid-svg-icons";
import Breadcrumbs from "../HomeVixo/Breadcrumbs";
import axios from "axios";
import { VixoAPI } from "../../settings";
import { APIHeader, ExpireToken, HasLoggedInUser } from "../../APICallWrapper";
import "react-toastify/dist/ReactToastify.css";
import { PaymentStatusType } from "../../lookup";
import { Modal, Button, Form } from "react-bootstrap";
import moment from "moment";
import Spinner from "../../Spinner";
import { saveAs } from "file-saver";
import { CSVLink } from "react-csv";
import * as exceljs from "exceljs";
import Customdate from "../CustomDates/Customdate";
import TopnavBar from "../Header/TopnavBar";
import DateComponent from "../../VixoFormats/Date";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { CheckAllowComponentAndRedirect, IsRoleAllowed } from "../../Authentication";

const FundingRequest = ({}) => {
  const [collapsed, setCollapsed] = useState(false);
  const [User, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [statusinfo, setStatusInfo] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [organizationData, setOrganizationData] = useState([]);
  const [selectedValue, setSelectedValue] = useState(9);
  const [customStartDate, setCustomStartDate] = useState(null);
  const [customEndDate, setCustomEndDate] = useState(null);
  const [customDateInputVisible, setCustomDateInputVisible] = useState(false);
  const [confirmationSuccess, setConfirmationSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [customerKey, setcustomerKey] = useState("");
  const [selectedOption, setSelectedOption] = useState("1");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [successMessageStyle, setSuccessMessageStyle] = useState({
    color: "green",
  });

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };
  const sidebarStyle = {
    marginLeft: collapsed ? "" : "250px",
  };
  const navigate = useNavigate();
  useEffect(() => {
    LoadData();
    LoadLookups();
  }, [searchQuery]);

  const [PaymentStatus, setPaymentStatus] = useState([]);
  const [errorType, setErrorType] = useState("");

  const isDataValid = () => {
    return true;
  };
  // Getting A Customer Start
  const [loadorganizationid, setLoadOrganizationId] = useState(null);
  const LoadData = () => {
   
    const loggedInUser = localStorage.getItem("User");
    var userObject = JSON.parse(loggedInUser);
    
    const ComponentName="User Administration";
    CheckAllowComponentAndRedirect(ComponentName, navigate)

    if (userObject) {
      setUser(userObject);
      setLoading(true);
      setUserInfo(userObject);

      setLoadOrganizationId(userObject.organizationId);

      setLoading(false);
    } else {
    }
  };

  // Getting A Lookups

  async function LoadLookups() {
    try {
      PaymentStatusType()
        .then((result) => {
          setPaymentStatus(result);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (ex) {
      console.error(ex + " User Role Lookup Call is not successfull");
    }
  }

  /////////////////////////////

  // Start API CALL FOR CustomerFundingRequest
  const LoadOrganization = () => {
    if (userInfo) {
      var organizationid = userInfo && userInfo.organizationId;
    }
  
    let data = {
      OrganizationID: organizationid,
      FundingRequestStatus: statusinfo,
      StartDate: selectedOption === "9" ? null : moment(startDate, "YY MMM DO").format("YYYY-MM-DD"),
      EndDate: selectedOption === "9" ? null : moment(endDate, "YY MMM DO").format("YYYY-MM-DD"),
    };
    
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        setLoading(false);
        throw error;
      }
    );
  
    setLoading(true);
    if(HasLoggedInUser())
      {
        const config=APIHeader();
    try {
      const SetDataById =
        VixoAPI.FundingAPI + "GetOraganizationCustomersFundingRequests";
      setLoading(true);
  
      axios
        .post(SetDataById, data,{
         headers:config.headers
        })
        .then((response) => {
          var responseData = response.data;
          if (responseData.statusCode === 200) {
            var statedata = JSON.parse(JSON.stringify(responseData?.data));
            setOrganizationData(statedata?.reverse() || []);
            setcustomerKey(statedata?.customerKey || null);
          } else {
            // setError(responseData.message);
          }
        })
        .catch((error) => {
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      // setLoading(false);
      throw error;
    }
  }
  else{
    ExpireToken(navigate)
  }
  };
  


  // Start Searching data  FOR CustomerFundingRequest for customername
  const filteredData = (organizationData || []).filter(
    (item) =>
      item.customerName &&
      item.customerName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Modal START
  const [isModalOpen, setModalOpen] = useState(false);
  const openModal = (item) => {
    // Update the Last Update Date to the current date
    const currentDate = getCurrentDate();
    const updatedUserData = {
      ...item,
      LastChangeDate: currentDate,
    };

    setSelectedUserData(updatedUserData);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const saveChanges = () => {
    closeModal();
  };

  // Modal Handles For Input Change Start
  const [selectedUserData, setSelectedUserData] = useState({
    amount: 0,
    interest: 0,
    terms: 0,
    purpose: 0,
    status: 0,
    LastChangeDate: new Date().toISOString(),
  });
  const handleAmountChange = (event) => {
    setSelectedUserData({
      ...selectedUserData,
      amount: event.target.value,
    });
  };
  const handleLastChangeDate = (event) => {
    setSelectedUserData({
      ...selectedUserData,
      LastChangeDate: event.target.value,
    });
  };

  const handleInterestChange = (event) => {
    setSelectedUserData({
      ...selectedUserData,
      interest: event.target.value,
    });
  };
  const handleTermsChange = (event) => {
    setSelectedUserData({
      ...selectedUserData,
      terms: event.target.value,
    });
  };
  const handleCustomerFundingChange = (event) => {
    setSelectedUserData({
      ...selectedUserData,
      customerFundingRequest: event.target.value,
    });
  };
  const handlePurposeChange = (event) => {
    setSelectedUserData({
      ...selectedUserData,
      purpose: event.target.value,
    });
  };

  const requestData = {
    id: selectedUserData.id,
    organizationId: selectedUserData.organizationId,
    userId: selectedUserData.userId,
    customerId: selectedUserData.customerId,
    customerKey: selectedUserData.customerKey,
    amount: selectedUserData.amount,
    interest: selectedUserData.interest,
    terms: selectedUserData.terms,
    purpose: selectedUserData.purpose,
    Status: selectedUserData.status !== null ? selectedUserData.status : "0",
    AccountNumber: selectedUserData?.accountNumber || null,
    CustomerFundingRequest: selectedUserData.customerFundingRequest || null,
    LastChangeDate: selectedUserData.LastChangeDate,
    lastUpdateDate:selectedUserData.lastUpdateDate,
    paymentRequestDate:selectedUserData.paymentRequestDate,

  };

  function UpdateFudingRecord(fundingRecord) {
    const indexToUpdate = organizationData.findIndex(
      (user) => user.id === fundingRecord.id
    );

    if (indexToUpdate !== -1) {
      const updatedData = [...organizationData];
      updatedData[indexToUpdate] = fundingRecord;
      setOrganizationData(updatedData);
    }
  }
  function getCurrentDate() {
    const currentDate = new Date();
    return currentDate.toLocaleDateString();
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isDataValid()) {
      return;
    }
    try {
      const UpdateCustomerFundingAPIPath =
        VixoAPI.FundingAPI + "updateCustomerFundingFromOrganization";
      setLoading(true);
      const response = await axios.put(
        UpdateCustomerFundingAPIPath,
        requestData,{
         headers:APIHeader().headers
        }
      );
      const responseData = response.data;
      if (responseData.statusCode === 200) {
        setConfirmationSuccess(true);
        setSuccessMessage(responseData.message);
        setSuccessMessageStyle({ color: "green" });

        // Update the funding record in the organizationData state
        const updatedRecord = responseData.data;
        const updatedData = organizationData.map((record) =>
          record.id === updatedRecord.id ? updatedRecord : record
        );
        setOrganizationData(updatedData);
        setSelectedUserData({
          ...selectedUserData,
          lastUpdateDate: getCurrentDate(),
        });

        // Clear the selectedUserData state and close the modal
        setSelectedUserData({});
        closeModal();
      } else {
        setErrorType(responseData.message);
      }
    } catch (error) {
      setLoading(false);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  // CSV  function
  const csvData = filteredData.map((item) => ({
    Name: item.customerName,
    "Customer Funding Request": item.customerFundingRequest,
    Amount: item.amount,
    Interest: item.interest,
    Terms: item.terms,
    Purpose: item.purpose,
    "Account Number": item.accountNumber,
    Status: item.cusStatus,
  }));

  // Excel  function
  const handleExcelExport = async () => {
    const excelData = filteredData.map((item) => ({
      Name: item.customerName,
      "Customer Funding Request": item.customerFundingRequest,
      Amount: item.amount,
      Interest: item.interest,
      Terms: item.terms,
      Purpose: item.purpose,
      "Account Number": item.accountNumber,
      Status: item.cusStatus,
    }));

    const workbook = new exceljs.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    const columns = [
      { header: "Name", key: "Name", width: 20 },
      {
        header: "Customer Funding Request",
        key: "Customer Funding Request",
        width: 30,
      },
      { header: "Amount", key: "Amount", width: 15 },
      { header: "Interest", key: "Interest", width: 15 },
      { header: "Terms", key: "Terms", width: 15 },
      { header: "Purpose", key: "Purpose", width: 30 },
      { header: "Account Number", key: "Account Number", width: 20 },
      {
        header: "Payment Request Date",
        key: "Payment Request Date",
        width: 20,
      },
      { header: "Last Update Date", key: "Last Update Date", width: 20 },
      { header: "Status", key: "Status", width: 15 },
    ];

    worksheet.columns = columns;
    worksheet.addRows(excelData);

    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), "export.xlsx");
  };

  const handleStatuChange = (statusinfo) => {
    const val = parseInt(statusinfo, 5);
    setStatusInfo(val);
    // console.log("set Status Info", statusinfo);
  };

  const handleDropdownChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
  };

  const handleDateRange = () => {
    const today = new Date();
    switch (selectedOption) {
      case "1":
        // This Month
        setStartDate(new Date(today.getFullYear(), today.getMonth(), 1));
        setEndDate(new Date(today.getFullYear(), today.getMonth() + 1, 0));
        break;
      case "2":
        // Last Month
        setStartDate(new Date(today.getFullYear(), today.getMonth() - 1, 1));
        setEndDate(new Date(today.getFullYear(), today.getMonth(), 0));
        break;
      case "3":
        // Last Three Months
        setStartDate(new Date(today.getFullYear(), today.getMonth() - 3, 1));
        setEndDate(new Date(today.getFullYear(), today.getMonth(), 0));
        break;
      case "4":
        // Last Six Months
        setStartDate(new Date(today.getFullYear(), today.getMonth() - 6, 1));
        setEndDate(new Date(today.getFullYear(), today.getMonth(), 0));
        break;
      case "5":
        // Last Nine Months
        setStartDate(new Date(today.getFullYear(), today.getMonth() - 9, 1));
        setEndDate(new Date(today.getFullYear(), today.getMonth(), 0));
        break;
      case "6":
        // This Year
        setStartDate(new Date(today.getFullYear(), 0, 1));
        setEndDate(new Date(today.getFullYear(), 11, 31));
        break;
      case "8":
        // Custom Date, do nothing as the date picker will handle it
        break;
      case "9":
        // All Time, set the earliest and latest possible dates
        setStartDate(new Date(2010, 0, 1)); // January 1, 2010
        setEndDate(today);
        break;
      default:
    }
  };
  

  useEffect(() => {
    LoadOrganization();
  }, [startDate, endDate]);
  useEffect(() => {
    handleDateRange();
  }, [selectedOption]);

  return (
    <>
      <TopnavBar />
      <OrganizationSettingBar
        collapsed={collapsed}
        toggleSidebar={toggleSidebar}
      />

      <main className="page" style={sidebarStyle}>
        <div className="container-fluid  p-3 bg-white col-lg-12 col-sm-12">
          <div className="row">
            <div className="col-md-12 mx-auto">
              <div className="card">
                <div class="row">
                  <div class="col-sm-8">
                    <div
                      className=" py-3 bg-transparent"
                      style={{ padding: "1pc" }}
                    >
                      <h5 className="mb-0 d-inline">User Administration</h5>
                      <FontAwesomeIcon
                        icon={faUser}
                        className="fa-2x "
                        style={{ width: "25px", fontSize: "18px" }}
                      />
                      <Breadcrumbs />
                    </div>
                  </div>

                  <div class="col-sm-4 mt-3">
                    <CSVLink
                      data={csvData}
                      filename={"data.csv"}
                      className="btn btn-info"
                      style={{
                        borderRadius: "20px",
                        color: "White",
                        fontWeight: "500",
                      }}
                    >
                      {" "}
                      Export CSV{" "}
                    </CSVLink>

                    <button
                      className="btn btn-success m-2"
                      onClick={handleExcelExport}
                      style={{
                        borderRadius: "20px",
                        color: "White",
                        fontWeight: "500",
                      }}
                    >
                      {" "}
                      Export to Excel{" "}
                    </button>
                  </div>
                </div>
                <hr />
                <div className="card-header py-3 bg-transparent">
                  <div className="row m-2">
                    <h4>Funding Request</h4>
                  </div>
                  <div class="row text-center">
                    <div className="col-sm-3 mb-3">
                      <select
                        className="form-select"
                        name="PaymentStatus"
                        onChange={(e) => handleStatuChange(e.target.value)}
                      >
                        <option value="0">All</option>
                        <option value="1">Pending</option>
                        <option value="2">In Progress</option>
                        <option value="3">Rejected</option>
                        <option value="4">Approved</option>
                      </select>
                  </div>
                    
                    <div className="col-sm-2"></div>
                    <div class="col-sm-4">
                      <form class="form-inline my-2 my-lg-0">
                        <input
                          class="form-control mr-sm-2"
                          type="search"
                          placeholder="Search"
                          aria-label="Search"
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                      </form>
                    </div>
                  </div>
                  <div className="col-md-3 text-end ">
                      {/* months with date dropdown */}
                      <div className=" align-items-center gap-1 my-3">
                      
                    
                          <div className="text-end">
                            <select
                              className="dropdowntabmob h-80 rounded form-control"
                              value={selectedOption}
                              onChange={handleDropdownChange}
                            >
                              <option value="1">This Month</option>
                              <option value="2">Last Month</option>
                              <option value="3">Last Three Month</option>
                              <option value="4">Last Six Month</option>
                              <option value="5">Last Nine Month</option>
                              <option value="6">This Year</option>
                              <option value="8">Custom Date</option>
                              <option value="9">All Time</option>
                            </select>
                     
                        </div>
                      </div>
                    </div>
                   
                    <div className="row">
                <div className="col-md-12" style={{display:"flex"}}>
                    <div>
                        <div className="start-date-picker d-flex align-items-center gap-1">
                       
                          {selectedOption === "8" && (
                            <>
                               <span>Start Date</span>
                              
                              <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                dateFormat="MM-dd-yy"
                              />
                            </>
                          )}
                        </div>
                        </div>
                       <div className="col-md-6"  style={{marginLeft:"flex"}}>

                        <div className="end-date-picker d-flex align-items-center gap-1">

                          {selectedOption === "8" && (
                            <>
                        <span>End Date</span>
                            
                              <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                // disabled={selectedOption !== "8"}
                                dateFormat="MM-dd-yy"
                              />
                            </>
                          )}
                        </div>
                        </div>
                    </div>
                </div>
                </div>
               

                <div className="">
                  <div className="table-responsive">
                    <table
                      id="example"
                      className="table table-striped"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <td
                            style={{ textAlign: "center", fontWeight: "600" }}
                          >
                            Name
                          </td>
                          <td style={{ textAlign: "center" }}>
                            Customer Request
                          </td>
                          <td style={{ textAlign: "center" }}>Amount</td>
                          <td style={{ textAlign: "center" }}>Interest</td>
                          <td style={{ textAlign: "center" }}>Terms</td>
                          <td style={{ textAlign: "center" }}>Purpose</td>
                          <td style={{ textAlign: "center" }}>
                            Account Number
                          </td>
                          <td style={{ textAlign: "center" }}>
                            Payment Request Date
                          </td>
                          <td style={{ textAlign: "center" }}>
                            Last Update Date
                          </td>
                          <td style={{ textAlign: "center" }}>Status</td>
                          <td style={{ textAlign: "center" }}>Action</td>
                        </tr>
                      </thead>
                      {filteredData.length > 0 ? (
                        <tbody>
                          {filteredData != null &&
                            filteredData.map((item, index) => (
                              <tr key={index}>
                                <td style={{ textAlign: "center" }} hidden>
                                  {item.customerKey}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {item.customerName}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {item.customerFundingRequest}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {item.amount}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {item.interest}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {item.terms}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {item.purpose}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {item.accountNumber}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                {item.paymentRequestDate && statusinfo === 0 && (
                                  <DateComponent value={item.paymentRequestDate} />
                                )}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {item.lastUpdateDate && statusinfo === 0 && (
                                  <DateComponent value={item.lastUpdateDate} />
                                )}
                              </td>

                                <td style={{ textAlign: "center" }}>
                                  {" "}
                                  {item.cusStatus ? item.cusStatus :"Pending"}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => openModal(item)}
                                  >
                                    Edit
                                  </button>
                                </td>
                                <td></td>
                              </tr>
                            ))}
                        </tbody>
                      ) : (
                        <h3 className="text-center">No Data</h3>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Spinner show={loading} />
      <Modal show={isModalOpen} onHide={closeModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Customer Funding Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formInput">
            {selectedUserData && (
              <div>
                <Form.Label hidden>Customer Key</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your value"
                  value={selectedUserData.customerKey}
                  hidden
                />
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your Name"
                  value={selectedUserData.customerName}
                  disabled
                />
                <Form.Label>Customer Funding Request</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Customer Funding Amount"
                  value={selectedUserData.customerFundingRequest}
                  onChange={(e) =>
                    handleCustomerFundingChange(e, "customerFunding")
                  }
                />
                <Form.Label>Amount</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter your Amount"
                  value={selectedUserData.amount}
                  onChange={(e) => handleAmountChange(e, "amount")}
                />
                <Form.Label>Interest</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter your Interest"
                  value={selectedUserData.interest}
                  onChange={(e) => handleInterestChange(e, "interest")}
                />
                <Form.Label>Terms</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter your Terms"
                  value={selectedUserData.terms}
                  onChange={(e) => handleTermsChange(e, "terms")}
                />
                <Form.Label>Purpose</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your loan Purpose"
                  value={selectedUserData.purpose}
                  onChange={(e) => handlePurposeChange(e, "Purpose")}
                />
                <Form.Label>Account Number</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your value"
                  value={selectedUserData.accountNumber}
                  disabled
                />

                {/* <Form.Label>Last Update</Form.Label>
                <Form.Control
                  type="date"

                  value={selectedUserData.lastUpdateDate}
                  onChange={(e) => handleLastChangeDate(e, "LastChangeDate")}


                /> */}

                <Form.Label>Status</Form.Label>
                <select
                  className="form-select"
                  name="PaymentStatus"
                  value={selectedUserData.status}
                  onChange={(e) =>
                    setSelectedUserData({
                      ...selectedUserData,
                      status: e.target.value,
                    })
                  }
                >
                  {PaymentStatus != null &&
                    PaymentStatus.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.displayValue}
                      </option>
                    ))}
                </select>
              </div>
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
          <Button variant="" onClick={closeModal}>
            <form onSubmit={handleSubmit}>
              <button className="btn btn-primary" id="paymentFundModalId">
                FUND
              </button>
            </form>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FundingRequest;
