import React, { useEffect, useState } from "react";
import { VixoAPI } from "../../settings";
const DownloadHome = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [customerHome, setCustomerHome] = useState(null);
  const [User, setUser] = useState(null);
  const [CurrentCustomer, setCurrentCustomer] = useState(null);
  useEffect(() => {
    CustomerSelected();
  }, []);

  function CustomerSelected() {
    const loggedInUser = localStorage.getItem("User");
    const currentCustomerLocal = localStorage.getItem("SelectedCustomer");
    var currentCustomerObject = JSON.parse(currentCustomerLocal);
    var userObject = JSON.parse(loggedInUser);
    if (loggedInUser != null && currentCustomerLocal != null) {
      setUser(userObject);
      setCurrentCustomer(currentCustomerLocal);

      try {
        if (
          loggedInUser &&
          currentCustomerObject &&
          currentCustomerObject.key &&
          userObject.key
        ) {
          var customerHomeLink =
          VixoAPI.CustomeDashboard +
            "downloadindex?Id=" +
            encodeURIComponent(currentCustomerObject.key) +
            "&U=" +
            encodeURIComponent(userObject.key);
          setCustomerHome(customerHomeLink);
        }
      } catch (Exception) {}
    }
  }
  const sidebarStyle = {
    width: collapsed ? "100%" : "100%",

    marginLeft: collapsed ? "" : "39px", 
  };

  return (
    <>
      <div>
        <iframe
          title="DownloadNow"
          src={customerHome}
          height="800px" 
          width="100%"
          style={sidebarStyle}
        />
      </div>
    </>
  );
};

export default DownloadHome;