import React from "react";

const Customdate = ({
  customStartDate,
  customEndDate,
  setCustomStartDate,
  setCustomEndDate,
  handleCustomStartDateChange,
  handleCustomEndDateChange,
}) => {
  // Get today's date in the format YYYY-MM-DD
  const today = new Date().toISOString().split("T")[0];

  return (
    <>
      <div className="container" style={{ marginTop: "-24px" }}>
        <div className="row ">
          <div className="col-6">
            <label className="text-start">Start Date</label>
            <input
              className="form-control"
              type="date"
              height={20}
              value={customStartDate}
              onChange={(e) => {
                const selectedStartDate = e.target.value;
                if (selectedStartDate <= customEndDate || !customEndDate) {
                  handleCustomStartDateChange(selectedStartDate);
                } else {
                  handleCustomStartDateChange(customEndDate);
                }
              }}
              max={customEndDate || today}
            />
          </div>
          <div className="col-6">
            <label className="text-start">End Date</label>
            <input
              className="form-control"
              type="date"
              value={customEndDate || ""}
              onChange={(e) => {
                const selectedEndDate = e.target.value;
                if (selectedEndDate >= customStartDate) {
                  handleCustomEndDateChange(selectedEndDate);
                } else {
                  handleCustomEndDateChange(customStartDate);
                }
              }}
              max={today}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Customdate;
