import React, { useState, useEffect, useCallback, useRef } from "react";
import TopnavBar from "../Header/TopnavBar";
import OrganizationSettingBar from "../Header/OrganizationSettingBar";
import "./Billingmodule.css";
import Breadcrumbs from "../HomeVixo/Breadcrumbs";
import { faGears } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "../../Sidebar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import ErrorComponent from "../../ErrorComponent";
import Spinner from "../../Spinner";
import { VixoAPI, VixoConstants } from "../../settings";
import { APIHeader, HasLoggedInUser } from "../../APICallWrapper";
import Cropper from "react-easy-crop";
import { Modal } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { CheckAllowComponentAndRedirect} from "../../Authentication";
const Companyinfo = () => {
  const navigate = useNavigate();
  const [errorType, setErrorType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [User, setUser] = useState({});
  const [Organization, setOrganization] = useState({});
  const [croppedImage, setCroppedImage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const documentInputRef = useRef(null);
  const [defaultLogoUrl, setDefaultLogoUrl] = useState(
    VixoConstants.Base64Logo
  );
  const [showReloginModal, setShowReloginModal] = useState(false);
  const LoadData = () => {
    const loggedInUser = localStorage.getItem("User");
    var userObject = JSON.parse(loggedInUser);

    const ComponentName="User Administration";
     CheckAllowComponentAndRedirect(ComponentName, navigate)
    if (userObject) {
      setUser(userObject);
      setLoading(true);

      LoadOrganization(userObject.organizationId);
    } else {
      navigate("/");
    }
  };
  useEffect(() => {
    LoadData();
  }, []);
  function setError(message) {
    setErrorType(message);
  }
  function LoadOrganization(OrganizationID) {
    if(HasLoggedInUser()){

    
    try {
      setLoading(true);
      const OrgnizationUpdateAPIPath =
        VixoAPI.OrganizationAPI + "getAny?Id=" + OrganizationID;
      axios
        .get(OrgnizationUpdateAPIPath,{
         headers:APIHeader().headers
        })
        .then((response) => {
          var response = response.data;
          setLoading(false);
          if (response.statusCode === 200) {
            var Organization = JSON.parse(JSON.stringify(response.data));
            if (Organization != null) {
              setOrganization(Organization);
            }
          } else {
            setError(response.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }else {
    navigate("/");
  }
  }
  const handleCompanyInfoChange = (e) => {
    setOrganization({
      ...Organization,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("id", Organization.id);
    formData.append("name", Organization.name);
    formData.append("addressLine1", Organization.addressLine1);
    formData.append("website", Organization.website);

    formData.append(
      "bankLinkInvitationSms",
      Organization.bankLinkInvitationSms
    );
    formData.append(
      "bankLinkInvitationText",
      Organization.bankLinkInvitationText
    );
    if (combinedImage) {
      const combinedImageBlob = await fetch(combinedImage).then((res) =>
        res.blob()
      );
      formData.append("File", combinedImageBlob);
    }
    try {
      const OrgnizationUpdateAPIPath =
        VixoAPI.OrganizationAPI + "update?OrganizationId=" + Organization.id;
      setLoading(true);

      axios
        .put(OrgnizationUpdateAPIPath, formData, {
          headers: {
            ...APIHeader().headers, 
            "Content-Type": "multipart/form-data" 
          },
        })
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            var OrganizationUpdate = JSON.parse(JSON.stringify(response.data));
            if (OrganizationUpdate != null) {
              setOrganization(OrganizationUpdate);
              LoadOrganization(OrganizationUpdate.id);

              const combinedImageUrl = combinedImage;
              setOrganization({
                ...OrganizationUpdate,
                logoUrl: combinedImageUrl,
              });
              console.log("object combinedImageUrl", combinedImageUrl);
              toast.success(response.message);
              documentInputRef.current.value = "";
              setShowReloginModal(true);
            }
          } else {
            setError(response.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      throw error;
    }
  };
  function setError(message) {
    setErrorType(message);
    setSuccessMessage(null);
  }
  const [collapsed, setCollapsed] = useState(false);
  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };
  const sidebarStyle = {
    marginLeft: collapsed ? "" : "250px",
  };
  const [showModal, setShowModal] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file.size > 10485760) {
      toast.error("Your image size is above 10MB");
      return;
    }

    const reader = new FileReader();
    reader.onload = function (e) {
      const image = new Image();
      image.src = e.target.result;

      image.onload = function () {
        if (image.width < 400 || image.height < 160) {
          toast.error("Image resolution is less than required (400x160)");
        } else {
          setCroppedImage(image.src);
          setImageFile(file);
          setShowModal(true);
        }
      };
    };

    reader.readAsDataURL(file);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [showScreenshotModal, setShowScreenshotModal] = useState(false);

  const handleOnConfirm = async () => {
    if (imageFile && croppedAreaPixels) {
      const croppedFile = await getCroppedImg(
        imageFile,
        croppedAreaPixels,
        "cropped_image.jpg"
      );
      setCroppedImage(croppedFile);
      setShowModal(false);
      toast.success("Image cropped and saved successfully");
      setCroppedImageUrl(URL.createObjectURL(croppedFile));
      setShowScreenshotModal(true);
    }
  };

  const getCroppedImg = async (
    image,
    croppedAreaPixels,
    fileName = "cropped.jpg"
  ) => {
    const imageBitmap = await createImageBitmap(image);
    const canvas = document.createElement("canvas");
    canvas.width = croppedAreaPixels.width;
    canvas.height = croppedAreaPixels.height;

    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      imageBitmap,
      croppedAreaPixels.x,
      croppedAreaPixels.y,
      croppedAreaPixels.width,
      croppedAreaPixels.height,
      0,
      0,
      croppedAreaPixels.width,
      croppedAreaPixels.height
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        const croppedFile = new File([blob], fileName, { type: "image/jpeg" });
        resolve(croppedFile);
      }, "image/jpeg");
    });
  };

  const modalBodyStyle = {
    minHeight: "400px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const handleRelogin = async (e) => {
    e.preventDefault();
    localStorage.clear();
    navigate("/");
  };

  const image1Ref = useRef(null);
  const image2Ref = useRef(null);
  const [combinedImage, setCombinedImage] = useState(null);
  const takeScreenshot = () => {
    const image1Element = image1Ref.current;
    const image2Element = image2Ref.current;

    if (image1Element && image2Element) {
      const img1 = new Image();
      img1.src = croppedImageUrl || "";

      img1.onload = () => {
        const pixelRatio = window.devicePixelRatio || 1;
        const combinedCanvas = document.createElement("canvas");
        const combinedCtx = combinedCanvas.getContext("2d");
        combinedCanvas.width = image1Element.width * pixelRatio;
        combinedCanvas.height = image1Element.height * pixelRatio;
        combinedCtx.scale(pixelRatio, pixelRatio);
        combinedCtx.drawImage(
          img1,
          0,
          0,
          image1Element.width,
          image1Element.height
        );

        const opacity = 1;
        const marginX = 225;
        const marginY = 117;

        combinedCtx.globalAlpha = opacity;
        combinedCtx.drawImage(
          image2Element,
          marginX * pixelRatio,
          marginY * pixelRatio,
          image2Element.width * pixelRatio,
          image2Element.height * pixelRatio
        );

        const combinedImageDataURL = combinedCanvas.toDataURL("");
        setCombinedImage(combinedImageDataURL);
        setShowModal(false);
        setShowScreenshotModal(false);
      };
    }
  };

  return (
    <>
      <TopnavBar />
      {errorType && <ErrorComponent errorType={errorType} />}
      <Spinner show={loading} />
      <OrganizationSettingBar
        collapsed={collapsed}
        toggleSidebar={toggleSidebar}
      />

      <main className="page-" style={sidebarStyle}>
        {successMessage && (
          <div className="alert alert-success" role="alert">
            {successMessage}
          </div>
        )}

        <div>
          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Crop Image</Modal.Title>
            </Modal.Header>
            <Modal.Body style={modalBodyStyle}>
              {imageFile && (
                <Cropper
                  image={URL.createObjectURL(imageFile)}
                  crop={crop}
                  aspect={400 / 160}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                />
              )}
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-secondary"
                onClick={() => setShowModal(false)}
                style={{
                  borderRadius: "20px",
                  color: "White",
                  fontWeight: "500",
                }}
              >
                Close
              </button>
              <button
                className="btn btn-info"
                onClick={handleOnConfirm}
                style={{
                  borderRadius: "20px",
                  color: "White",
                  fontWeight: "500",
                }}
              >
                Save
              </button>
            </Modal.Footer>
          </Modal>
        </div>
        <div>
          <Modal
            show={showScreenshotModal}
            onHide={() => setShowScreenshotModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Take Screenshot of Cropped Image</Modal.Title>
            </Modal.Header>
            <Modal.Body id="modalContent">
              <div id="imageContainer">
                <img
                  id="defaultLogo"
                  ref={image2Ref}
                  src={defaultLogoUrl}
                  alt="Logo"
                  style={{
                    maxWidth: "120px",
                    maxHeight: "120px",
                    marginTop: "-4pc",
                    marginLeft: "17pc",
                  }}
                />
                {croppedImageUrl && (
                  <img
                    id="croppedImage"
                    ref={image1Ref}
                    src={croppedImageUrl}
                    alt="Cropped"
                    style={{ width: "450px" }}
                    onLoad={() => {
                      takeScreenshot();
                    }}
                  />
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-secondary"
                onClick={() => setCroppedImageUrl(null)}
                style={{
                  borderRadius: "20px",
                  color: "White",
                  fontWeight: "500",
                }}
              >
                Close
              </button>
            </Modal.Footer>
          </Modal>
        </div>
        <Modal
          show={showReloginModal}
          onHide={() => setShowReloginModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Relogin Required</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Your changes have been saved successfully. Please relogin</p>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary"
              onClick={() => setShowReloginModal(false)}
              style={{
                borderRadius: "20px",
                color: "White",
                fontWeight: "500",
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-info"
              onClick={handleRelogin}
              style={{
                borderRadius: "20px",
                color: "White",
                fontWeight: "500",
              }}
            >
              Relogin
            </button>
          </Modal.Footer>
        </Modal>

        <div className="container-fluid  p-3 bg-white col-lg-12 col-sm-12">
          <div className="row">
            <div className="col-md-12  mx-auto">
              <div className="card">
                <div className="card-header py-3 bg-transparent">
                  <h5 className="mb-0 d-inline">Setting</h5>
                  <FontAwesomeIcon
                    icon={faGears}
                    className="fa-2x "
                    style={{ width: "25px" }}
                  />

                  <Breadcrumbs />
                </div>
                <div className="card-header bg-transparent border">
                  {" "}
                  <div className="row border">
                    <div className="row">
                      <div className="col-md-6">
                        <img
                          src={Organization.logoUrl}
                          className="uploadedimage"
                          alt="Uploaded Preview"
                          style={{ maxWidth: "400px", maxHeight: "160px" }}
                        />
                        {combinedImage && (
                          <div>
                            <h3>Preview Image</h3>
                            <img
                              src={combinedImage}
                              className="uploadedimage"
                              alt="Cropped image"
                              style={{
                                maxWidth: "400px",
                                maxHeight: "160px",
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <div className="col-md-6 mt-5 company-info">
                        <h6>Company Logo</h6>

                        <p>Must be 400x160 png file and under 10mb</p>
                      </div>
                    </div>

                    <div className="col-md-2 mt-4 text-end"></div>
                  </div>
                </div>
                <div className=" py-3 px-3 bg-transparent"></div>
                <div className="card-header py-3 bg-transparent">
                  <div className="row col-md-12">
                    <div className="col-md-3">
                      <div className="form-group ">
                        <input
                          type="file"
                          ref={documentInputRef}
                          onChange={handleFileChange}
                          style={{ width: "13pc" }}
                        />
                        {/* <span>No file chosen</span> */}
                      </div>
                    </div>

                    <div className="col-md-12 mt-4">
                      <label style={{ fontWeight: "600", marginLeft: "5px" }}>
                        Vixo Link Company Name
                      </label>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control plc mt-1"
                          id="name"
                          placeholder="Vixo Link Company Name"
                          name="name"
                          value={Organization.name}
                          onChange={handleCompanyInfoChange}
                        />
                      </div>
                      <br />
                      <div className="form-group">
                        <label style={{ fontWeight: "600", marginLeft: "5px" }}>
                          Vixo Link Company URL
                        </label>
                        <input
                          type="text"
                          className="form-control plc mt-1"
                          id="website"
                          placeholder="Vixo Link Company URL"
                          name="website"
                          value={Organization.website}
                          onChange={handleCompanyInfoChange}
                        />
                      </div>
                      <br />
                      <div className="form-group">
                        <label style={{ fontWeight: "600", marginLeft: "5px" }}>
                          Vixo Link Company Address
                        </label>
                        <input
                          type="text"
                          className="form-control plc mt-1"
                          id="addressLine1"
                          placeholder="Vixo Link Company Address"
                          name="addressLine1"
                          value={Organization.addressLine1}
                          onChange={handleCompanyInfoChange}
                        />
                      </div>

                      <div className="form-group">
                        <label
                          className="mt-3"
                          style={{ fontWeight: "600", marginLeft: "5px" }}
                        >
                          {" "}
                          Vixo Link Invitation Whitelabing SMS/Email
                        </label>

                        <input
                          type="text"
                          className="form-control plc mt-1"
                          id="bankLinkInvitationSms"
                          placeholder="Vixo Link Invitation Whitelabing SMS/Email"
                          name="bankLinkInvitationSms"
                          value={Organization.bankLinkInvitationSms}
                          onChange={handleCompanyInfoChange}
                        />
                      </div>
                      <br />
                      <div className="form-group">
                        <label style={{ fontWeight: "600", marginLeft: "5px" }}>
                          Vixo Link Whitelabing For Customer Portal{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control plc input-lg mt-1"
                          id="bankLinkInvitationText"
                          placeholder="Vixo Link Whitelabing For Customer Portal "
                          name="bankLinkInvitationText"
                          value={Organization.bankLinkInvitationText}
                          onChange={handleCompanyInfoChange}
                        />
                      </div>
                      <div className="text-end mt-2">
                        <button
                          className="btn btn-info"
                          onClick={handleSubmit}
                          style={{
                            borderRadius: "20px",
                            color: "White",
                            fontWeight: "500",
                          }}
                        >
                          &nbsp;&nbsp;&nbsp;Save&nbsp;&nbsp;&nbsp;
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
export default Companyinfo;
