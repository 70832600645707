import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import {
  ValidateNameGlobal,
  ValidatePhoneGlobal,
  ValidateEmailGlobal,
} from "../Architecture/Validation";
import ErrorComponent from "../../ErrorComponent";
import { APIHeader, ExpireToken, HasLoggedInUser } from "../../APICallWrapper";
import { OpenBankingType } from "../../lookup";
import Spinner from "../../Spinner";
import { VixoAPI } from "../../settings";
import "react-toastify/dist/ReactToastify.css";
import InputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";

const EditCustomer = ({
  showModalEdit,
  handleModalEditClose,
  editCustomer,
}) => {
  const navigate = useNavigate();
  const ComponentName = "Resend Invite";
  const [hasChanges, setHasChanges] = useState(false);
  const clearData = () => {
    setEmailIsValid(true);
    setPhoneIsValid(true);
    setErrorType(null);
  };
  const APIHeaders = APIHeader();
  const [editedPhone, setEditedPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorType, setErrorType] = useState(null);
  const [email, setEmail] = useState("");
  const [initialCustomerData, setInitialCustomerData] = useState(null);
  const [phoneIsValid, setPhoneIsValid] = useState(true);
  const [firstnameIsValid, setFirstNameIsValid] = useState(true);
  const [lastnameIsValid, setLastNameIsValid] = useState(true);
  const [emailIsValid, setEmailIsValid] = useState(true);
  const [phoneerror, setPhoneError] = useState("");
  const [fnameerror, setFNameError] = useState("");
  const [lnameerror, setLNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [selectedRadio, setSelectedRadio] = useState("invitePhone");

  const validateEmail = (email) => {
    const isValid = ValidateEmailGlobal(email);

    if (!isValid) {
      setEmailError("Enter a valid email address");
    } else {
      setEmailError("");
    }

    return isValid;
  };

  const validatePhone = (phone) => {
    const isValid = ValidatePhoneGlobal(phone);
    if (!isValid) {
      setPhoneError("Enter a valid phone number exactly 10 digits.");
    } else {
      setPhoneError(null); // Clear error message if validation passes
    }
    return isValid;
  };
  
  const validateFirstName = (name) => {
    const isValid = ValidateNameGlobal(name);
    if (!isValid) {
      setFNameError("Enter a valid First Name.");
    } else {
      setFNameError(null); // Clear error message if validation passes
    }
    return isValid;
  };

  const validateLastName = (name) => {
    const isValid = ValidateNameGlobal(name);
    if (!isValid) {
      setLNameError("Enter a valid Last Name.");
    } else {
      setLNameError(null); // Clear error message if validation passes
    }
    return isValid;
  };

  const toCamelCase = (str) => {
    return str
      .toLowerCase()
      .replace(/(^|\s)(\w)/g, (match, p1, p2) => p1 + p2.toUpperCase());
  };
  
  const handleNameChange = (e) => {
    const { name, value } = e.target;
    const camelCaseValue = toCamelCase(value);
    if (name === "firstName") {
      const isValid = validateFirstName(camelCaseValue);

      setFirstNameIsValid(isValid);
    } else if (name === "lastName") {
      const isValid = validateLastName(camelCaseValue);
      setLastNameIsValid(isValid);
    }
    setEditCustomer({ target: { name, value: camelCaseValue } });
  };
  

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    setEditedPhone(value);
    setPhoneIsValid(validatePhone(value));
    setCurrentCustomer({
      ...currentCustomer,
      phone: value,
    });
    setHasChanges(true);
  };

  useEffect(() => {
    LoadLookups();
  }, []);

  const [OpenBankingRoles, setOpenBankingRoles] = useState([]);

  async function LoadLookups() {
    try {
      const result = await OpenBankingType();
      setOpenBankingRoles(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setEmailIsValid(validateEmail(value));
  };

  const [currentCustomer, setCurrentCustomer] = useState({
    id: 0,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    identity: "",
    IsEmail: false,
    IsPhone: false,
    OpenBankingType: null,
    customerInvitation: null,
  });

  const setEditCustomer = (e) => {
    const { name, value } = e.target;

    if (name === "firstName" || name === "lastName") {
      setCurrentCustomer({
        ...currentCustomer,
        [name]: value,
        identity: `${
          name === "firstName" ? value : currentCustomer.firstName
        } ${name === "lastName" ? value : currentCustomer.lastName}`,
      });
    } else {
      setCurrentCustomer({
        ...currentCustomer,
        [name]: value,
      });
    }
    setHasChanges(true);
  };

  useEffect(() => {
    if (showModalEdit && editCustomer) {
      setCurrentCustomer({
        id: editCustomer.id,
        firstName: editCustomer.firstName,
        lastName: editCustomer.lastName,
        email: editCustomer.email,
        phone: editCustomer.phone,
        IsEmail: editCustomer.inviteEmail,
        IsPhone: editCustomer.invitePhone,
        identity: editCustomer.identity,
        OpenBankingType: editCustomer.openBankingType,
        customerInvitation: editCustomer.customerInvitation,
      });
      // Set the edited phone number initially
      setEditedPhone(editCustomer.phone);
      if (editCustomer.invitePhone) {
        setSelectedRadio("invitePhone");
      } else {
        setSelectedRadio("inviteEmail");
      }
    } else {
      setCurrentCustomer({
        id: 0,
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        IsEmail: false,
        IsPhone: false,
        identity: "",
        OpenBankingType: null,
        customerInvitation: null,
      });
      // Reset the edited phone number when the modal is closed
      setEditedPhone("");
    }
    LoadLookups();
  }, [showModalEdit, editCustomer]);

  const handleUpdateCustomer = async () => {
    if (
      !phoneIsValid ||
      !emailIsValid ||
      currentCustomer.OpenBankingType === null
    ) {
      return;
    }
    setErrorType("");

    // Determine the value of customerInvitation based on the selectedRadio
    const customerInvitation = selectedRadio === "invitePhone" ? 0 : 1;
    const data = {
      id: editCustomer.id,
      organizationId: editCustomer.organizationId,
      firstName: currentCustomer.firstName,
      lastName: currentCustomer.lastName,
      email: currentCustomer.email,
      phone: currentCustomer.phone,
      identity: currentCustomer.identity,
      InvitePhone: selectedRadio === "invitePhone" ? true : false,
      InviteEmail: selectedRadio === "inviteEmail" ? true : false,
      key: "",
      OpenBankingType: currentCustomer.OpenBankingType,
      customerInvitation: customerInvitation,
    };
    if (HasLoggedInUser()) {
      const config=APIHeader();
      try {
        const CustomerUpdate = VixoAPI.CustomerAPI + "updateMinimalCustomer";
        setLoading(true);

        const response = await axios.put(CustomerUpdate, data, {
          headers: config.headers,
        });

        const responseData = response.data;
        if (responseData.statusCode === 200) {
          clearData();
          setData(responseData.data);
        } else {
          setErrorType(responseData.message);
        }
      } catch (error) {
        console.error("Error updating customer:", error);
      } finally {
        setLoading(false);
      }
    } else {
      ExpireToken(navigate);
    }
  };

  function setData(data) {
    handleModalEditClose(data);
  }

  const handleModalClose = () => {
    setErrorType("");
    setPhoneError("");
    setFNameError("");
    setLNameError("");
    setEmailError("");
    handleModalEditClose();
  };

  const handleCheckboxChange = (checkboxName) => {
    setHasChanges(true);
    setSelectedRadio(checkboxName);
  };

  const isButtonDisabled =
    !phoneIsValid ||
    !emailIsValid ||
    !hasChanges ||
    !currentCustomer.firstName ||
    !currentCustomer.lastName ||
    !currentCustomer.identity ||
    !firstnameIsValid ||
    !lastnameIsValid ||
    currentCustomer.OpenBankingType === null ||
    (currentCustomer.IsEmail === false && currentCustomer.IsPhone === false);

  return (
    <>
      {errorType && <ErrorComponent errorType={errorType} />}
      <Spinner show={loading} />
      <Modal show={showModalEdit} onHide={() => handleModalClose()}>
        <Modal.Header closeButton>
          <Modal.Title className="mx-auto">
            <div className="container">
              <div className="row">
                <div className="col-12 text-left">
                  <span className="m-2">Edit Customer</span>
                </div>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorType && <ErrorComponent errorType={errorType} />}
          <Spinner show={loading} />
          <p>
            <div className="modal-body">
              <div className="mb-3">
                <input
                  type="hidden"
                  name="identity"
                  value={`${currentCustomer.firstName} ${currentCustomer.lastName}`}
                />
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="FirstName"
                  name="firstName"
                  placeholder="First Name"
                  value={currentCustomer.firstName}
                  onChange={(e) => {
                    setEditCustomer(e);
                    handleNameChange(e);
                  }}
                />
                {!firstnameIsValid && (
                  <span className="error">{fnameerror}</span>
                )}
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  name="lastName"
                  placeholder="Last Name"
                  value={currentCustomer.lastName}
                  onChange={(e) => {
                    setEditCustomer(e);
                    handleNameChange(e);
                  }}
                />
                {!lastnameIsValid && (
                  <span className="error">{lnameerror}</span>
                )}
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  name="email"
                  placeholder="example@vixolink.com"
                  value={currentCustomer.email}
                  onChange={(e) => {
                    setEditCustomer(e);
                    handleEmailChange(e);
                  }}
                  // style={{ borderColor: emailIsValid ? "" : "red" }}
                />
                {!emailIsValid && <span className="error">{emailError}</span>}
              </div>
              <div className="mb-3">
                <InputMask
                  mask="(999) 999-9999"
                  type="tel"
                  name="phone"
                  className="form-control"
                  placeholder="1234567890"
                  value={editedPhone}
                  onChange={handlePhoneChange}
                />
                {!phoneIsValid && <span className="error">{phoneerror}</span>}
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="number"
                  name="id"
                  placeholder="Number"
                  value={currentCustomer.phone}
                  onChange={setEditCustomer}
                  hidden
                />
              </div>

              <select
                className="form-select"
                name="OpenBankingType"
                value={currentCustomer.OpenBankingType}
                onChange={setEditCustomer}
              >
                {OpenBankingRoles != null &&
                  OpenBankingRoles.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.displayValue}
                    </option>
                  ))}
              </select>

              <div className="col-md-12">
                <div className="form-check">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="inviteByEmail"
                    name="inviteMethod"
                    value={currentCustomer.IsEmail}
                    checked={selectedRadio === "inviteEmail"}
                    onChange={() => handleCheckboxChange("inviteEmail")}
                  />
                  <label className="form-check-label" htmlFor="inviteByEmail">
                    Invite by Email
                  </label>
                </div>
                <div className="form-check">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="inviteByPhone"
                    name="inviteMethod"
                    value={currentCustomer.IsPhone}
                    checked={selectedRadio === "invitePhone"}
                    onChange={() => handleCheckboxChange("invitePhone")}
                  />
                  <label className="form-check-label" htmlFor="inviteByPhone">
                    Invite by Phone Number
                  </label>
                </div>
                <div
                  className="card-footer py-3 bg-transparent error-container"
                  style={{ color: "red" }}
                >
                  {errorType && (
                    <div className="" role="alert">
                      {errorType}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex-row d-flex justify-content-between align-items-center">
                <button
                  type="button"
                  className="btn btn-secondary mx-2"
                  onClick={() => handleModalClose()}
                  style={{
                    borderRadius: "20px",
                    color: "white",
                    fontWeight: "500",
                    width: "7pc",
                  }}
                >
                  CANCEL
                </button>
                <button
                  type="button"
                  className="btn btn-info mx-2"
                  onClick={() => handleUpdateCustomer()}
                  disabled={isButtonDisabled}
                  style={{
                    borderRadius: "20px",
                    color: "white",
                    fontWeight: "500",
                  }}
                >
                  Update Customer
                </button>
              </div>
            </div>
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditCustomer;
