import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TopnavBar from "../Header/TopnavBar";
import OrganizationSettingBar from "../Header/OrganizationSettingBar";
import ClearentComponent from "./ClearentComponent";
import AddCredit from "./AddCredit";
import "./Billingmodule.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGears } from "@fortawesome/free-solid-svg-icons";
import Breadcrumbs from "../HomeVixo/Breadcrumbs";
import ReactApexChart from "react-apexcharts";
import Button from "react-bootstrap/Button";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Spinner from "../../Spinner";
import { VixoAPI } from "../../settings";
import { APIHeader, ExpireToken, HasLoggedInUser } from "../../APICallWrapper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Form from "react-bootstrap/Form";
import { ThresholdTypes } from "../../lookup";
import { VixoConstants } from "../../settings";
import ErrorComponent from "../../ErrorComponent";
import DateComponent from "../../VixoFormats/Date";
import Money from "../../VixoFormats/Money";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { CheckAllowComponentAndRedirect } from "../../Authentication";
import PullPaymentACH from "./PullPaymentACH";
const Billing = () => {
  const navigate = useNavigate();

  const [ThresholdLookup, setThresholdLookup] = useState([]);

  useEffect(() => {
    LoadLookups();
  }, []);

  async function LoadLookups() {
    try {
      ThresholdTypes()
        .then((result) => {
          setThresholdLookup(result);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (ex) {
      console.error(ex + " setThreshold Role Lookup Call is not successfull");
    }
  }

  const [existingOrganization, setExistingOrganization] = useState({
    autoReloadAmount: "",
    autoReloadThresold: "",
  });
  const [availableBalance, setAvailableBalance] = useState(0);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [errorType, setErrorType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [orgdata, setOrgData] = useState(null);
  const [orgid, setOrgId] = useState(null);
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [organization, setOrganization] = useState({});
  const [User, setUser] = useState({});
  useEffect(() => {
    LoadData();
    loadUserforOrg();
  }, []);

  const [collapsed, setCollapsed] = useState(false);
  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };
  const sidebarStyle = {
    marginLeft: collapsed ? "" : "250px",
  };

  const [series, setSeries] = useState([
    {
      name: "Payments",
      data: [],
    },
  ]);
  const LoadData = () => {
    const loggedInUser = localStorage.getItem("User");
    var userObject = JSON.parse(loggedInUser);

    const ComponentName = "User Administration";
    CheckAllowComponentAndRedirect(ComponentName, navigate);
    if (userObject) {
      setUser(userObject);
      setLoading(true);

      LoadOrganization(userObject.organizationId);
    } else {
      navigate("/");
    }
  };

  function LoadOrganization(OrganizationID) {
    if(HasLoggedInUser()){
    try {
      if (!OrganizationID) {
        return;
      }
      setLoading(true);
      const OrgnizationUpdateAPIPath =
        VixoAPI.OrganizationAPI + "getAny?Id=" + OrganizationID;
      axios
        .get(OrgnizationUpdateAPIPath, {headers:APIHeader().headers})
        .then((response) => {
          var response = response.data;
          setLoading(false);
          if (response.statusCode === 200) {
            var Organization = JSON.parse(JSON.stringify(response.data));
            if (Organization != null) {
              setOrganization(Organization);
            }
          } else {
            setErrorType(response.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }
  else {navigate("/")}
  }

  const creditAdded = (OrganizationID) => {
    if (!OrganizationID) {
      return;
    }
    LoadExistingPaymentHistory(OrganizationID);
    LoadPaymentMehtod(OrganizationID);
   
    window.dispatchEvent(new Event("Billing"));
  };

  const addcredit = (OrganizationID) => {
    LoadExistingPaymentHistory(OrganizationID);
    LoadPaymentMehtod(OrganizationID);
    window.dispatchEvent(new Event("Billing"));
  };

  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    title: {
      text: "Payment History by Month",
      align: "left",
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Dec",
      ],
    },
  });

  const loadUserforOrg = () => {
    const loggedInUser = localStorage.getItem("User");
    var userObject = JSON.parse(loggedInUser);
    if (userObject) {
      setLoading(true); // Set loading to true before making requests
      try {
        LoadExistingPaymentHistory(userObject.organizationId);
        LoadPaymentMehtod(userObject.organizationId);
        const OrgAPIPath = `${VixoAPI.OrganizationAPI}getAny?ID=${userObject.organizationId}`;
        axios
          .get(OrgAPIPath, {
            headers: APIHeader().headers,
          })
          .then((response) => {
            var responseData = response.data;
            if (responseData.statusCode === 200) {
              setExistingOrganization(responseData.data); // Assuming the organization data is in responseData.data
            }
          })
          .catch((error) => {
            console.error("Error fetching organization data:", error);
          })
          .finally(() => {
            setLoading(false); // Set loading to false after requests are complete
          });
      } catch (error) {
        console.error("Error loading user data:", error);
        setLoading(false); // Set loading to false in case of an error
      }
    } else {
      navigate("/");
    }
  };

  function LoadExistingPaymentHistory(OrganizationID) {
    if(HasLoggedInUser()){

   
    setLoading(false);
    setLoading(true);
    const data = {
      organizationID: OrganizationID,
    };
    if(HasLoggedInUser())
      {
        const config=APIHeader();
    try {
      const VixoOrganzationPayments =
        VixoAPI.BillingAPI + "getPaymentDetailByOrganization";

      axios
        .post(VixoOrganzationPayments, data, {
          headers: config.headers,
        })
        .then((response) => {
          var responseData = response.data;
          if (responseData.statusCode === 200) {
            setAvailableBalance(responseData.data.availableBalance);
            setPaymentHistory(responseData.data.orgPaymentCredits);
            const graphData = responseData.data.orgPaymentCredits;

            const creditData = Array(12).fill(0);

            graphData.forEach((item) => {
              const month = new Date(item.date).getMonth();
              creditData[month] += item.credit;
            });

            setSeries([
              {
                name: "Credit",
                data: creditData,
              },
            ]);

            setOptions({
              ...options,
              xaxis: {
                categories: [
                  "Jan",
                  "Feb",
                  "Mar",
                  "Apr",
                  "May",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dec",
                ],
              },
            });
          } else {
            setErrorType(responseData.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setErrorType(error.message);
      setLoading(false);
    }
  }
  }
  else{
    ExpireToken(navigate);
  }
}

  const [paymentMethod, setPaymentMethod] = useState([]);

  const PaymentMethodChange = (id, orgId) => {
    setSelectedMethod(id);
    if(HasLoggedInUser())
      {
        const config=APIHeader();
    try {
      const ChangePaymentMethod = `${VixoAPI.BillingAPI}activatePaymentMethod?PaymentMethodID=${id}&OrganizationID=${orgId}`;
      setLoading(false);
      setLoading(true);
      axios
        .get(ChangePaymentMethod, {
          headers: config.headers,
        })
        .then((response) => {
          var responseMethod = response.data;
          if (responseMethod.statusCode === 200) {
            var methodData = JSON.parse(JSON.stringify(responseMethod.data));
            setPaymentMethod(methodData);

            // Check if we have more than 0 Payment Method and any of these is is Default.
            // Then Show AddCredit Component.
          } else {
            setErrorType(responseMethod.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setErrorType(error.message);
      setLoading(false);
    }
  }
  else{
    ExpireToken(navigate);
  }
  };

  const LoadPaymentMehtod = (OrganizationID, navigate) => {
    if (HasLoggedInUser()) {
      const config = APIHeader();
      try {
        const VixoPaymentMethod = `${VixoAPI.BillingAPI}getPaymentMethod?OrganizationID=${OrganizationID}`;
        setLoading(true);
        axios
          .post(VixoPaymentMethod, {}, { headers: config.headers }) // Correct way to pass headers
          .then((response) => {
            var responseData = response.data;
            if (responseData.statusCode === 200) {
              var methodData = JSON.parse(JSON.stringify(responseData.data));
              setPaymentMethod(methodData);
            } else {
              setErrorType(responseData.message);
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              ExpireToken(navigate);
            } else {
              setErrorType(error.message);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        setErrorType(error.message);
        setLoading(false);
      }
    } else {
      ExpireToken(navigate);
    }
  };
  
   const filteredPaymentMethods = paymentMethod.filter(
     (method) => method.isActive===true && method.paymentMethodType === 3
   );
   

  const handleUpdateThreshold = () => {
    try {
      const updateRequest = {
        organizationID: existingOrganization.id,
        ThresoldTypeId: existingOrganization.autoReloadThresold,
        ReloadAmount: existingOrganization.autoReloadAmount,
      };

      const updateThreshold =
        VixoAPI.OrganizationAPI + "updateThresholdAmounts";
      setLoading(false);
      setLoading(true);
      axios
        .post(updateThreshold, updateRequest, {
          headers: APIHeader().headers,
        })
        .then((response) => {
          const responseData = response.data;
          if (responseData.statusCode === 200) {
            localStorage.setItem("Org", JSON.stringify(responseData.data));
            toast.success(responseData.message);
          }
        })
        .catch((error) => {})
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setErrorType(error.message);
      setLoading(false);
    }
  };

  const handleAutoReloadAmountChange = (e) => {
    setExistingOrganization({
      ...existingOrganization,
      [e.target.name]: e.target.value,
    });
  };
  const handleAutoReloadAmountBlur = (e) => {
    const newValue = e.target.value;

    if (newValue < VixoConstants.MinimumReloadAmount) {
      setErrorType(
        "Auto Reload Amount must be at least " +
          VixoConstants.MinimumReloadAmount +
          ".00 $"
      );
      return;
    } else {
      setExistingOrganization({
        ...existingOrganization,
        [e.target.name]: newValue,
      });
      setErrorType(null);
    }
  };

  const maskCardNumber = (cardNumber) => {
    const maskedNumber = "**** ***" + cardNumber.slice(-4);
    return maskedNumber;
  };

  const maskCardNumberTable = (cardReference) => {
    const maskCardNumberTable = "**** ***" + cardReference.slice(-4);
    return maskCardNumberTable;
  };

  return (
    <>
      {errorType && <ErrorComponent errorType={errorType} />}
      <Spinner show={loading} />
      <TopnavBar />
      <OrganizationSettingBar
        collapsed={collapsed}
        toggleSidebar={toggleSidebar}
      />

      <main className="page-" style={sidebarStyle}>
        <div className="container-fluid  p-3 bg-white col-lg-12 col-sm-12">
          <div className="row">
            <div className="col-lg-12 mx-auto">
              <div className="card ">
                <div className="card-header py-3 bg-transparent">
                  <h5 className="mb-0 d-inline">Setting</h5>
                  <FontAwesomeIcon
                    icon={faGears}
                    className="fa-2x "
                    style={{ width: "25px" }}
                  />

                  <Breadcrumbs />
                </div>
                <h4 className="mt-4 mx-3">Billing</h4>
                <div>
                  <h4 className="ps-3 mb-3"> Available Credit</h4>
                  <h3 htmlofor="" className="mb-4 ps-3">
                    <Money value={availableBalance} />
                  </h3>
                </div>

                <div className="row">
                  <div
                    className="col-md-4 credit"
                    style={{ padding: "0px 0px 0px 22px" }}
                  >
                    {filteredPaymentMethods &&
                    filteredPaymentMethods.length > 0 ? (
                      <AddCredit creditAdded={creditAdded} />
                    ) : (
                      ""
                    )}
                    <ClearentComponent creditAdded={creditAdded} />
                    <PullPaymentACH
                      creditAdded={creditAdded}
                      OrganizationID={organization.id}
                    />
                  </div>
                  <div className="col-md-4 " style={{ padding: "30px" }}>
                    <div>
                      <h4 className="mt-4 mb-3"> Auto Reload Amount</h4>
                      <input
                        type="text"
                        className="form-control"
                        id="AutoReload"
                        placeholder="Amount Reload"
                        name="autoReloadAmount"
                        value={existingOrganization.autoReloadAmount}
                        onChange={handleAutoReloadAmountChange}
                        onBlur={handleAutoReloadAmountBlur}
                      />
                      {errorType && <p className="text-danger">{errorType}</p>}
                    </div>
                    <div className="col-12">
                      <div className="d-flex align-items-center justify-content-between">
                        <h4 className="my-3"> Threshold Limit</h4>
                        <div className="text-secondary">
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="refresh-tooltip">
                                Limit is set once % of amount is used up in your
                                available credit to reload set amount you've set
                                above.
                              </Tooltip>
                            }
                          >
                            <FontAwesomeIcon icon={faCircleInfo} />
                          </OverlayTrigger>
                        </div>
                      </div>

                      <select
                        className="form-select"
                        name="autoReloadThresold"
                        onChange={handleAutoReloadAmountChange}
                        value={existingOrganization.autoReloadThresold}
                      >
                        {ThresholdLookup != null &&
                          ThresholdLookup.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.displayValue}
                            </option>
                          ))}
                      </select>
                    </div>
                    <br />
                    <Button
                      onClick={handleUpdateThreshold}
                      disabled={errorType !== null}
                    >
                      Update Threshold
                    </Button>
                  </div>

                  <div className="col-md-4">
                    <div>
                      <h5 className="fw-bold px-2">Payment Method</h5>
                    </div>
                    <div className="mt-4 mb-4">
                      <label htmlFor="" style={{ width: "97%" }}>
                        {paymentMethod != null &&
                          paymentMethod.map((method, index) => (
                            <p
                              key={index}
                              style={{
                                border: "1px solid",
                                padding: "6px",
                                background: "#41bdef94",
                              }}
                            >
                              <td
                                className="fw-bold"
                                style={{ fontFamily: "cursive" }}
                              >
                                {" "}
                                <FontAwesomeIcon icon={faCreditCard} />{" "}
                                {method.cardType}
                              </td>
                              <td>
                                <span
                                  className="fw-bold"
                                  style={{ fontFamily: "cursive" }}
                                >
                                  :
                                </span>{" "}
                                {maskCardNumber(method.cardNumber)}{" "}
                              </td>
                              <td style={{ position: "relative" }}>
                                <span
                                  className="fw-bold"
                                  style={{ fontFamily: "cursive" }}
                                >
                                  Exp:
                                </span>{" "}
                                {method.expiry}
                              </td>
                              <td
                                className="payment-method"
                                style={{ float: "right", marginTop: "-24px" }}
                              >
                                <Form>
                                  <Form.Check
                                    type="switch"
                                    label=""
                                    checked={
                                      method.isActive ||
                                      method.id === selectedMethod
                                    }
                                    onChange={() =>
                                      PaymentMethodChange(
                                        method.id,
                                        method.organizationId
                                      )
                                    }
                                  />
                                </Form>
                              </td>
                            </p>
                          ))}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12  sm-4">
                    <h5 className="p-1 m-3">Historical Spend Balance</h5>
                    <div className="chart-container">
                      <ReactApexChart
                        options={options}
                        series={series}
                        type="line"
                        height={350}
                      />
                    </div>
                  </div>
                </div>

                <div className="">
                  <div className="table-responsive">
                    <h5 className="p1 m-3">Payment History</h5>
                    <table
                      id="example"
                      className="table table-striped"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Payment Method</th>
                          {/* <th>Method</th> */}
                          <th>Amounts</th>
                        </tr>
                      </thead>
                      <tbody>
                        {paymentHistory != null &&
                          paymentHistory.map((item, index) => (
                            <tr key={index}>
                              <td>
                                <DateComponent value={item.date} />
                              </td>

                              <td>{maskCardNumberTable(item.cardReference)}</td>
                              <td>
                                <Money value={item.credit} />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Billing;
