import React, { useState, useEffect } from "react";
import axios from "axios";
import ErrorComponent from "../../ErrorComponent";
import Spinner from "../../Spinner";
import { useNavigate, Link } from "react-router-dom";
import { VixoAPI } from "../../settings";
import { ValidatePhoneGlobal } from "../Architecture/Validation";
import "react-toastify/dist/ReactToastify.css";
import InputMask from "react-input-mask";
const Signup = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorType, setErrorType] = useState(null);
  const [step, setStep] = useState(1);
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const [companyInfo, setCompanyInfo] = useState({
    companyName: "",
    url: "",
    email: "",
    phone: "",
    password: "",
  });
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    password: "",
  });

  const [passwordErrors, setPasswordErrors] = useState({
    length: "",
    digit: "",
    uppercase: "",
    lowercase: "",
    special: "",
  });

  const [companyInfoErrors, setCompanyInfoErrors] = useState({});
  const [userInfoErrors, setUserInfoErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showconfirmpassword, setShowConfirmPassword] = useState(false);
  const [successMessage, setSuccessMessage] = useState("null");
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false); // New state for success modal

  const handleEmailChange = (e) => {
    const { value } = e.target;
    setCompanyInfo({
      ...companyInfo,
      email: value,
    });
  };

  const handleCompanyInfoChange = (e) => {
    const { name, value } = e.target;

    if (name === "url") {
      const updatedText = value
        .replace("https://", "")
        .replace("http://", "")
        .replace("www.", "");

      const updatedCompanyInfo = {
        ...companyInfo,
        [name]: value,
      };
      if (updatedText) {
        updatedCompanyInfo.url = `${updatedText}`;
      }
      setCompanyInfo(updatedCompanyInfo);
    } else {
      setCompanyInfo((prevCompanyInfo) => ({
        ...prevCompanyInfo,
        [name]: value,
      }));
    }
  };

  const handleUserInfoChange = (e) => {
    const { name, value } = e.target;

    setUserInfo({
      ...userInfo,
      [name]: value,
    });

    if (name === "password") {
      validatePassword(value);
    } else {
      setPasswordErrors({
        length: "",
        digit: "",
        uppercase: "",
        lowercase: "",
        special: "",
      });
    }
  };
  //////////////////////////confirm password///////////

  const [confirmPassword, setConfirmPassword] = useState(""); // New state for confirm password

  // Existing state variables and functions...

  const handleConfirmPasswordChange = (e) => {
    const { value } = e.target;
    setConfirmPassword(value);
  };

 
  const validateCompanyName = (name) => {
    return name.trim() !== "";
  };

  const validateWebsiteURL = (url) => {
    if (url === "") {
      return true;
    }

    try {
      return true;
    } catch (error) {}
  };

  const validatephone = (phone) => {
    var IsValid = ValidatePhoneGlobal(phone);

    if (!IsValid) {
    }

    return IsValid;
  };

  const validatePassword = (password) => {
    const errors = {
      length: "",
      digit: "",
      uppercase: "",
      lowercase: "",
      special: "",
    };

    if (password.length < 8) {
      errors.length = "Password must be at least 8 characters long.";
    }

    if (!/[0-9]/.test(password)) {
      errors.digit = "Password must contain at least one digit.";
    }

    if (!/[A-Z]/.test(password)) {
      errors.uppercase = "Password must contain at least one uppercase letter.";
    }

    if (!/[a-z]/.test(password)) {
      errors.lowercase = "Password must contain at least one lowercase letter.";
    }

    if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(password)) {
      errors.special = "Password must contain at least one special character.";
    }

    setPasswordErrors(errors);

    const isValidPassword = Object.values(errors).every(
      (error) => error === ""
    );
    setUserInfoErrors((prevErrors) => ({
      ...prevErrors,
      password: isValidPassword ? "" : prevErrors.password,
    }));

    return isValidPassword;
  };

  const areStep1FieldsFilled = () => {
    return (
      companyInfo.companyName.trim() !== "" &&
      companyInfo.url.trim() !== "" &&
      validateWebsiteURL(companyInfo.url) &&
      validatephone(companyInfo.phone)
    );
  };
  const areStep2FieldsFilled = () => {
    return (
      userInfo.firstName.trim() !== "" &&
      userInfo.lastName.trim() !== "" &&
      userInfo.password !== ""
    );
  };

  const handleNext = (e) => {
    e.preventDefault();

    if (step === 1 && isStep1Valid() && emailError === "") {
      setUserInfo({
        ...userInfo,
        phone: companyInfo.phone,
      });

      setStep(step + 1);
    }
  };

  const handlePrevious = (e) => {
    e.preventDefault();
    setStep(step - 1);
    setErrorType(null);
  };

  const isStep1Valid = () => {
    const errors = {};

    if (!validateCompanyName(companyInfo.companyName)) {
      errors.companyName = "Company Name is required";
    }

    if (!validateWebsiteURL(companyInfo.url)) {
      errors.url = "Enter a valid URL or leave it empty";
    }

    if (!validatephone(companyInfo.phone)) {
      errors.phone = "Enter a valid phone number exactly 10 digits";
    }

    setCompanyInfoErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const isStep2Valid = () => {
    const errors = {};
    if (!validatePassword(userInfo.password)) {
      errors.password = (
        <React.Fragment>
          Invalid password format. At least 8 characters, one digit,
          <br />
          one uppercase letter, and one special character.
          <br />
        </React.Fragment>
      );
    }
    setUserInfoErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const [isSignupSuccessful, setIsSignupSuccessful] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isStep2Valid()) {
      return;
    }
    if (userInfo.password !== confirmPassword) {
      setErrorType("Passwords do not match. Please make sure your passwords match.");
      return;
    }

    const data = {
      Name: companyInfo.companyName,
      Email: companyInfo.email,
      PhonePrimary: companyInfo.phone,
      Website: companyInfo.url,
      FirstName: userInfo.firstName,
      LastName: userInfo.lastName,
      UserEmail: companyInfo.email,
      Phone: companyInfo.phone,
      Password: userInfo.password,
    };

    try {
      const SignupAPIPath = VixoAPI.OrganizationAPI + "create";
      setLoading(true);

      axios
        .post(SignupAPIPath, data)
        .then((response) => {
          var response = response.data;
          if (response.statusCode === 200) {
            setSuccessMessage(response.message);
            setIsSignupSuccessful(true);
            setIsSuccessModalOpen(true);
            setErrorType("")
          } else {
            setErrorType(response.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          throw error;
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      throw error;
    }

    if (window.newrelic) {
      console.log("Adding page action with custom attributes...");
      window.newrelic.addPageAction("SignupEvent", {
        // Name: companyInfo.companyName,
        Email: companyInfo.email,
        // Add more attributes as needed
      });
    }
  };

  // function setError(message) {
  //   setErrorType(message);
  // }

  const handleEnterKeyPress = (e) => {
    if (e.keyCode === 13) {
      document.getElementById("finish-button").click();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleEnterKeyPress);
    return () => {
      document.removeEventListener("keydown", handleEnterKeyPress);
    };
  }, []);

  const handleCancelRedirect = () => {
    navigate("/Signin");
  };

  return (
    <>
      {errorType && <ErrorComponent errorType={errorType} />}
      <Spinner show={loading} />
      <main className="authentication-content">
        <div
          className="container-fluid d-flex align-items-center justify-content-center vh-100"
          style={{ overflow: "hidden" }}
        >
          <div className="authentication-card">
            <div
              className="card shadow  overflow-hidden"
              style={{ borderRadius: "7%" }}
            >
              <div className="row g-0 ">
                {step === 1 && (
                  <div className="col-lg-12">
                    <div className="card-body p-3 ">
                      <form className="form-body ">
                        <span className="m-5">
                          <img
                            src="assets/images/LogoVixo.png"
                            className="logo-icon"
                            alt="logo icon"
                            style={{ width: "180px", height: "101px" }}
                          />
                        </span>
                        <h2 className="fw-bold">Step 1: Company Details</h2>
                        <br />
                        <label className="fw-bold">BASIC COMPANY INFO:</label>
                        <br></br>
                        <input
                          type="text"
                          name="companyName"
                          className="form-control "
                          placeholder="Organization Name"
                          value={companyInfo.companyName}
                          onChange={handleCompanyInfoChange}
                          autoComplete="off"
                          style={{
                            width: "100%",
                            borderRadius: "9px",
                            padding: "0.375rem 0.75rem ",
                          }}
                        />
                        {companyInfoErrors.companyName && (
                          <div className="invalid-feedback">
                            {companyInfoErrors.companyName}
                          </div>
                        )}

                        <input
                          type="text"
                          name="url"
                          className="form-control "
                          value={companyInfo.url}
                          placeholder="Website Url"
                          onChange={handleCompanyInfoChange}
                          autoComplete="off"
                        />
                        {companyInfoErrors.email && (
                          <div className="text-danger">{companyInfo.email}</div>
                        )}
                        <br />
                        <label className="fw-bold">
                          PRIMARY COMPANY CONTACT:
                        </label>
                        <br />
                        <input
                          type="text"
                          name="email"
                          value={companyInfo.email}
                          className="form-control mt-1 mb-1"
                          placeholder="example@vixolink.com"
                          onChange={handleEmailChange}
                          autoComplete="off"
                        />
                        {emailError && (
                          <div className="text-danger">{emailError}</div>
                        )}

                        <small className="text-muted">
                          {/* Suggested email: {companyInfo.email} */}
                        </small>
                        {companyInfoErrors.email && (
                          <div className="text-danger ">
                            {companyInfoErrors.email}
                          </div>
                        )}
                        {emailError && (
                          <div className="text-danger">{emailError}</div>
                        )}
                        {companyInfoErrors.email && (
                          <div className="text-danger">
                            {companyInfoErrors.email}
                          </div>
                        )}
                        {emailError && (
                          <div className="text-danger">{emailError}</div>
                        )}
                        <InputMask
                          mask="(999) 999-9999"
                          type="tel"
                          name="phone"
                          className={`form-control mb-1 mt-2 ${
                            companyInfoErrors.phone || phoneError
                              ? "is-invalid"
                              : ""
                          }`}
                          placeholder="(123)456-7890"
                          value={companyInfo.phone}
                          onChange={handleCompanyInfoChange}
                          autoComplete="off"
                        />
                        {companyInfoErrors.phone && (
                          <div className="text-danger">
                            {companyInfoErrors.phone}
                          </div>
                        )}
                        {phoneError && (
                          <div className="text-danger">{phoneError}</div>
                        )}
                        <button
                          id="finish-button"
                          className="btn btn-primary m-3"
                          onClick={handleNext}
                          disabled={
                            !areStep1FieldsFilled() ||
                            companyInfo.email.indexOf("@") === -1 ||
                            companyInfo.email.split("@")[0] === "" ||
                            !!emailError
                          }
                        >
                          Next
                        </button>

                        <div
                          class="col-12 text-end"
                          style={{
                            display: "flex",
                            alignContent: "center",
                            justifyContent: "center",
                          }}
                        >
                          <p className="mb-2 mt-2">
                            Already have an account?&nbsp;&nbsp;
                            <Link
                              to="/signin"
                              style={{ textDecoration: "none" }}
                            >
                              Sign In here
                            </Link>
                          </p>
                        </div>
                      </form>
                    </div>
                  </div>
                )}
                {step === 2 && (
                  <div className="col-lg-12">
                    <div className="card-body p-3 ">
                      <form className="form-body">
                        <span className="m-5">
                          <img
                            src="assets/images/LogoVixo.png"
                            className="logo-icon"
                            alt="logo icon"
                            style={{ width: "180px", height: "101px" }}
                          />
                        </span>
                        <h2 className="fw-bold">Step 2: Tell us about you</h2>

                        <br />
                        <label className="fw-bold">THE BASICS:</label>
                        <br />

                        <input
                          type="text"
                          name="firstName"
                          value={userInfo.firstName}
                          className={`form-control ${
                            userInfoErrors.firstName ? "is-invalid" : ""
                          }`}
                          placeholder="First Name"
                          onChange={handleUserInfoChange}
                          autocomplete="off"
                        />

                        <input
                          type="text"
                          name="lastName"
                          className={`form-control mt-1 ${
                            userInfoErrors.lastName ? "is-invalid" : ""
                          }`}
                          placeholder="Last Name"
                          value={userInfo.lastName}
                          onChange={handleUserInfoChange}
                          autocomplete="off"
                        />
                        <br />
                        <label className="fw-bold mb-2">Password</label>
                        <div className="input-group">
                          <input
                            type={showPassword ? "text" : "password"}
                            name="password"
                            value={userInfo.password}
                            className={`form-control ${
                              userInfoErrors.password ? "is-invalid" : ""
                            }`}
                            autoComplete="off"
                            placeholder="Enter Password"
                            onChange={handleUserInfoChange}
                          />
                          <span
                            className="input-group-text"
                            style={{ cursor: "pointer" }}
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? (
                              <span role="img" aria-label="Visible">
                                👁️
                              </span>
                            ) : (
                              <span role="img" aria-label="Hidden">
                                🙈
                              </span>
                            )}
                          </span>
                        </div>

                        {userInfoErrors.password && (
                          <div className="text-danger">
                            {userInfoErrors.password}
                          </div>
                        )}

                        <label className="fw-bold mb-2">
                          Confirm Password
                        </label>
                        <div className="input-group mb-3">
                          <input
                            type={showconfirmpassword ? "text" : "password"}
                            name="confirmPassword"
                            className={`form-control ${
                              userInfoErrors.confirmPassword ? "is-invalid" : ""
                            }`}
                            autoComplete="off"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                          />
                          <span
                            className="input-group-text"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              setShowConfirmPassword(!showconfirmpassword)
                            }
                          >
                            {showconfirmpassword ? (
                              <span role="img" aria-label="Visible">
                                👁️
                              </span>
                            ) : (
                              <span role="img" aria-label="Hidden">
                                🙈
                              </span>
                            )}
                          </span>
                        </div>
                        {userInfoErrors.confirmPassword && (
                          <div className="text-danger">
                            {userInfoErrors.confirmPassword}
                          </div>
                        )}

                        <div>
                          By signing up, I agree to Vixolinks's
                          <Link
                            to="/termscondition"
                            target="_blank"
                            style={{ textDecoration: "none" }}
                          >
                            {" "}
                            Terms and Condition{" "}
                          </Link>
                        </div>

                        <button
                          className="btn btn-primary m-4"
                          onClick={handlePrevious}
                          disabled={step === 1 || isSignupSuccessful}
                        >
                          Previous
                        </button>
                        <button
                          id="finish-button"
                          className="btn btn-primary"
                          onClick={handleSubmit}
                          disabled={
                            !areStep2FieldsFilled() || isSignupSuccessful
                          }
                        >
                          Finish
                        </button>
                        <div className="" style={{ color: "red" }}>
                          {errorType && (
                            <div className="" role="alert">
                              {errorType.split(",").map((part, index) => (
                                <div key={index}>{part.trim()}</div>
                              ))}
                            </div>
                          )}
                        </div>

                        <div
                          className="container"
                          style={{
                            maxWidth: "500px",
                            height: "50px",
                            overflow: "hidden",
                          }}
                        >
                          <div className="row justify-content-center">
                            <div className="col-md-12">
                              {isSuccessModalOpen && (
                                <div
                                  className="modal"
                                  tabIndex="-1"
                                  role="dialog"
                                  style={{ display: "block" }}
                                >
                                  <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                      <div className="modal-header">
                                        <h5 className="modal-title">
                                          Organization Created
                                        </h5>
                                        <button
                                          type="button"
                                          className="btn-close"
                                          data-dismiss="modal"
                                          aria-label="Close"
                                          onClick={handleCancelRedirect}
                                        ></button>
                                      </div>
                                      <div className="modal-body">
                                        <p style={{ color: "green" }}>
                                          {successMessage}
                                        </p>
                                      </div>
                                      <div className="modal-footer">
                                        <button
                                          type="button"
                                          className="btn btn-secondary"
                                          style={{
                                            background: "rgb(11, 94, 215)",
                                          }}
                                          onClick={handleCancelRedirect}
                                        >
                                          OK
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div
                          class="col-12 text-end"
                          style={{
                            display: "flex",
                            alignContent: "center",
                            justifyContent: "center",
                          }}
                        >
                          Already have an account?&nbsp;&nbsp;
                          <Link to="/" className="text-decoration-none">
                            Sign In
                          </Link>
                        </div>
                      </form>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Signup;
