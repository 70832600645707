import React,{useState} from 'react';
import './FloatingBanner.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircleXmark,
  } from "@fortawesome/free-solid-svg-icons";
const FloatingBanner = ({ environment }) => {
    const [isBannerVisible, setIsBannerVisible] = useState(true);

    const handleBannerClose = () => {
      setIsBannerVisible(false);
    };
  
  return (
    <>
        {isBannerVisible && (
            <div className={`floating-banner ${environment.trim().toUpperCase() === 'DEV' ? 'development-banner' : (environment.trim().toUpperCase() === 'LOCAL' ? 'local-banner' : (environment.trim().toUpperCase() === 'PRODUCTIONLOCAL' ? 'local-banner': 'hidden-banner'))}`}>
                {environment.trim().toUpperCase() !== 'PRODUCTION' && (environment.trim().toUpperCase() === 'DEV' ? 'Development Environment' : (environment.trim().toUpperCase() === 'PRODUCTIONLOCAL' ? 'Production-Local  Environment' : 'Local Environment')) }
                <button onClick={handleBannerClose} className="close-button">
                    <FontAwesomeIcon icon={faCircleXmark} />
                </button>
            </div>

        )}
    </>
  );    
};

export default FloatingBanner;
