import React, { useState } from 'react';
import SideBar from '../Header/SideBar';
import Breadcrumbs from './Breadcrumbs';

function Invitation() {
  const [collapsed, setCollapsed] = useState(false);

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  const sidebarStyle = {
    width: collapsed ? "100%" : "84%",
    marginLeft: collapsed ? "" : "250px", 
  };

  return (
    <>
    
   
      {/* <SideBar collapsed={collapsed} toggleSidebar={toggleSidebar} /> */}

      <main className="Invitee" style={sidebarStyle}>
        <div className="container-fluid  p-3 bg-white col-lg-12 col-sm-12 ml-2" style={{marginLeft:'-1rem', marginTop: '-1rem'}}>
          <div className="row upper-border">
            
          </div>
          <div className="row border ml-3">
          
               <div class="card-header py-3 bg-transparent" style={{marginLeft:'2rem'}}>
                  <h5 class="">Invitation</h5>
                  <Breadcrumbs />
                </div>
            <div className="col-md-12 border" style={{marginLeft:'1rem'}}>
              <textarea
                class="form-control w-100 border"
                rows="5"
                style={{
                  height: '100px',
                  justifyContent: 'center',
                  resize: 'both',
                  marginTop: '1rem',
                }}
              >
                Your bank information will be used to help verify the information provided on your loan application.
              </textarea>
              <br />

              <div className="col-md-12">
                <input
                  type="radio"
                  className="form-check-input"
                  name="inviteMethod"
                  id="invitePhone"
                  value="phone"
                  label="Invite by Phone Number"
                />
                <label className="ml-1">&nbsp;&nbsp;Send Bank Link Invite to Cell Phone </label> <br />
                <input
                  type="radio"
                  className="form-check-input"
                  name="inviteMethod"
                  id="inviteEmail"
                  value="email"
                  label="Invite by Email"
                />
                <label>&nbsp;&nbsp;Send Bank Link Invite to Email</label>
                <br />
              </div>
              <br />
              <div class="col-6 text-start pb-1" style={{marginLeft:'1rem'}}>
                <button type="button" class="btn btn-primary px-5 " >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      
      </main>
      
    </>
  );
}

export default Invitation;
