import { Modal, Toast } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import React, { useState, useEffect } from "react";
import { APIHeader, ExpireToken } from "../../APICallWrapper";

// import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import {
  ValidatePhoneGlobal,
  ValidateEmailGlobal,
  ValidateNameGlobal,
} from "../Architecture/Validation";
import ErrorComponent from "../../ErrorComponent";
import Spinner from "../../Spinner";
import { VixoAPI } from "../../settings";
import { OpenBankingType } from "../../lookup";
import { useSelector } from "react-redux";

import { selectNotification } from "../Notification/Slices/sendNotificationSlice";
import APIErrorComponent from "../../APIErrorComponent";
import { useNavigate } from "react-router-dom";
import { HasLoggedInUser } from "../../APICallWrapper";
const AddCustomerModal = ({
  showModal,
  handleModalClose,
  onAddCustomerSuccess,
}) => {
  const navigate = useNavigate();
  const ComponentName = "Resend Invite";
  const [User, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);

  const notification = useSelector(selectNotification);

  const [selectedRadio, setSelectedRadio] = useState("invitePhone");
  const [CreateSms, setCreateSms] = useState();
  const [orgnizationid, setorgnizationid] = useState(null);
  const [organizationMessage, setorganizationMessage] = useState(null);
  const [customerInfo, setCustomerInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    OpenBankingType: "0",
    Identity: "",
    Massage: CreateSms && CreateSms,
  });

  const [errorType, setErrorType] = useState({
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
  });

  const [orgID, setorgID] = useState();
  const LoadData = () => {
    const loggedInUser = localStorage.getItem("User");
    var UserObject = JSON.parse(loggedInUser);
    if (UserObject !== null) {
      setorgnizationid(UserObject.organizationId);
      setCreateSms(UserObject.bankLinkInvitationSms);
      setorgID(UserObject.id);
    } else {
    }
  };

  // loadorganization for text msg

  useEffect(() => {
    if (orgnizationid !== null) {
      loadorganization();
    }
  }, [orgnizationid]);

  function loadorganization() {
    if (HasLoggedInUser()) {
      const config=APIHeader();
      try {
        setLoading(true);
        const OrgnizationUpdateAPIPath =
          VixoAPI.OrganizationAPI + "get?ID=" + orgnizationid;
        setLoading(true);
        axios
          .get(OrgnizationUpdateAPIPath, { headers: config.headers })
          .then((response) => {
            var responseData = response.data;
            if (responseData.statusCode === 200) {
              setorganizationMessage(responseData.data.bankLinkInvitationSms);
              // Use responseData directly here
            } else {
              //  Toast.error(responseData.message);
            }
          })
          .catch((error) => {
            setLoading(false);
            throw error;
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
        throw error;
      }
    } else {
      ExpireToken(navigate);
    }
  }

  const [APIResponse, setAPIResponse] = useState(null);
  useEffect(() => {
    const loggedInUser = localStorage.getItem("User");

    if (loggedInUser) {
      try {
        setUser(JSON.parse(loggedInUser));
      } catch (exception) {}
    }
  }, []);

  useEffect(() => {
    LoadLookups();
  }, []);

  useEffect(() => {
    if (User != null) {
      LoadData();
    }
  }, [User]);

  const [OpenBankingRoles, setOpenBankingRoles] = useState([]);

  async function LoadLookups() {
    try {
      OpenBankingType()
        .then((result) => {
          setOpenBankingRoles(result);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (ex) {
      console.error(
        ex + " setOpenBankingRoles Role Lookup Call is not successfull"
      );
    }
  }

  useEffect(() => {
    const isFilled =
      customerInfo.firstName &&
      customerInfo.lastName &&
      customerInfo.email &&
      customerInfo.phone;
    const nameIsValid = ValidateNameGlobal(customerInfo.firstName);
    const lastNameIsValid = ValidateNameGlobal(customerInfo.lastName);
    const emailIsValid = ValidateEmailGlobal(customerInfo.email);
    const phoneIsValid = ValidatePhoneGlobal(customerInfo.phone);

    setIsFormFilled(
      isFilled && emailIsValid && phoneIsValid && nameIsValid && lastNameIsValid
    );
  }, [customerInfo]);

  const resetForm = () => {
    setCustomerInfo({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      OpenBankingType: "0",
    });
    setErrorType(null);
    setAPIResponse(" ");
  };

  const handleRadioChange = (value) => {
    setSelectedRadio(value);
  };

  const handleCustomerSubmit = async (e) => {
    e.preventDefault();
    const nameIsValid = ValidateNameGlobal(customerInfo.firstName);
    const lastNameIsValid = ValidateNameGlobal(customerInfo.lastName);
    const emailIsValid = ValidateEmailGlobal(customerInfo.email);
    const phoneIsValid = ValidatePhoneGlobal(customerInfo.phone);

    if (!nameIsValid) {
      setErrorType((prevError) => ({
        ...prevError,
        firstName: "Enter a valid first name.",
      }));
      return;
    }
    if (!lastNameIsValid) {
      setErrorType((prevError) => ({
        ...prevError,
        lastName: "Enter a valid last name.",
      }));
      return;
    }

    if (!emailIsValid) {
      setErrorType((prevError) => ({
        ...prevError,
        email: "Enter a valid email address.",
      }));
      return;
    }

    if (!phoneIsValid) {
      setErrorType((prevError) => ({
        ...prevError,
        phone: "Enter a valid phone number exactly 10 digits.",
      }));
      return;
    }

    const identity = `${customerInfo.firstName} ${customerInfo.lastName}`;
    const data = {
      Id: 0,
      organizationId: User.organizationId,
      firstName: customerInfo.firstName,
      lastName: customerInfo.lastName,
      email: customerInfo.email,
      Identity: identity,
      phone: customerInfo.phone,
      InvitePhone: selectedRadio === "invitePhone",
      InviteEmail: selectedRadio === "inviteEmail",
      contactMethod: selectedRadio,
      UserId: User.id,
      OpenBankingType: customerInfo.OpenBankingType,
      Massage: organizationMessage,
    };
    if(HasLoggedInUser())
      {
        const config=APIHeader();
    try {
      const CustomerPIPath = VixoAPI.CustomerAPI + "create";
      setLoading(true);

      const response = await axios.post(CustomerPIPath, data, {
        headers: config.headers,
      });

      if (response.data.statusCode === 200) {
        setData(response.data.data);
        clearData();
        // toast.success("Customer added successfully!");
        onAddCustomerSuccess();

        if (selectedRadio === "invitePhone") {
        }
      } else {
        //setErrorType(response.data.message);
        setAPIResponse(response);
      }
    } catch (error) {
      setLoading(false);
      throw error;
    } finally {
      setLoading(false);
    }
  }
  else{
    ExpireToken(navigate);
  }

    if (window.newrelic) {
      console.log(
        "Adding page action with custom attributes... on add customer"
      );
      window.newrelic.addPageAction("AddCustomer", {
        Email: customerInfo.email,
      });
    }
  };

  const clearData = () => {
    resetForm();
    handleModalClose();
    setAPIResponse();
  };

  const setData = (data) => {
    handleModalClose(data);
  };

  const [isFormFilled, setIsFormFilled] = useState(false);
  const formatPhoneNumber = (phoneNumber) => {
    return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1)$2-$3");
  };

  const unformatPhoneNumber = (formattedPhoneNumber) => {
    return formattedPhoneNumber.replace(/\D/g, "");
  };

  function GotNewAlert(notification) {
    if (
      notification != null &&
      parseInt(notification.organiationID) == orgID &&
      notification.notificationType == 22
    ) {
    }
  }

  useEffect(() => {
    setAPIResponse(null);
  }, [customerInfo.email]);

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setCustomerInfo({ ...customerInfo, email: value });
    setErrorType((prevError) => ({
      ...prevError,
      email: ValidateEmailGlobal(value) ? null : "Enter a valid email address.",
    }));
  };
  const handlePhoneChange = (e) => {
    const value = unformatPhoneNumber(e.target.value);
    setCustomerInfo({ ...customerInfo, phone: value });
    setErrorType((prevError) => ({
      ...prevError,
      phone: ValidatePhoneGlobal(value)
        ? null
        : "Enter a valid phone number exactly 10 digits.",
    }));
  };

    const toCamelCase = (str) => {
    return str
      .toLowerCase()
      .replace(/(^|\s)(\w)/g, (match, p1, p2) => p1 + p2.toUpperCase());
  };

  const handleFirstName = (e) => {
    // const value = e.target.value;
    const value = toCamelCase(e.target.value);
    setCustomerInfo({ ...customerInfo, firstName: value });
    setErrorType((prevError) => ({
      ...prevError,
      firstName: ValidateNameGlobal(value) ? null : "Enter a valid first name.",
    }));
  };

  const handlelastName = (e) => {
    // const value = e.target.value;
    const value = toCamelCase(e.target.value);
    setCustomerInfo({ ...customerInfo, lastName: value });
    setErrorType((prevError) => ({
      ...prevError,
      lastName: ValidateNameGlobal(value) ? null : "Enter a valid last name.",
    }));
  };
  return (
    <>
      {/* <ToastContainer/> */}
      <Spinner show={loading} />
      <Modal show={showModal} onHide={clearData}>
        <Modal.Header closeButton>
          <Modal.Title className="mx-auto">
            <div className="container">
              <div className="row">
                <div className="col-12 text-left">
                  <span className="m-2">Create Customer</span>
                </div>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {APIResponse && APIResponse.data && APIResponse.data.message && (
            <div className="alert alert-danger" role="alert">
              {APIResponse.data.message}
            </div>
          )}
          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              id="FirstName"
              name="firstName"
              placeholder="First Name"
              value={customerInfo.firstName}
              onChange={handleFirstName}
              style={{
                borderColor:
                  errorType && errorType.firstName ? "red" : "initial",
              }}
            />
            {errorType && errorType.firstName && (
              <span className="error">{errorType.firstName}</span>
            )}
          </div>
          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              id="lastName"
              name="lastName"
              placeholder="Last Name"
              value={customerInfo.lastName}
              onChange={handlelastName}
              style={{
                borderColor:
                  errorType && errorType.lastName ? "red" : "initial",
              }}
            />
            {errorType && errorType.lastName && (
              <span className="error">{errorType.lastName}</span>
            )}
          </div>
          <div className="mb-3">
            <input
              type="text"
              className="form-control"
              id="email"
              name="email"
              placeholder="example@vixolink.com"
              value={customerInfo.email}
              onChange={handleEmailChange}
              style={{
                borderColor: errorType && errorType.email ? "red" : "initial",
              }}
            />
            {errorType && errorType.email && (
              <span className="error">{errorType.email}</span>
            )}
          </div>
          <div>
            <input
              type="text"
              name="phone"
              className="form-control"
              placeholder="(123) 456-7890"
              maxLength="13"
              value={formatPhoneNumber(customerInfo.phone)}
              onChange={handlePhoneChange}
              style={{
                borderColor: errorType && errorType.phone ? "red" : "initial",
              }}
            />
            {errorType && errorType.phone && (
              <span className="error">{errorType.phone}</span>
            )}
          </div>

          <div className="col-md-12 mb-3 mt-3">
            <select
              className="form-select"
              name="OpenBankingType"
              value={customerInfo.OpenBankingType}
              onChange={(e) =>
                setCustomerInfo({
                  ...customerInfo,
                  OpenBankingType: e.target.value,
                })
              }
            >
              {OpenBankingRoles != null &&
                OpenBankingRoles.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.displayValue}
                  </option>
                ))}
            </select>
          </div>
          <div className="col-md-12">
            <div className="form-check">
              <input
                type="radio"
                className="form-check-input"
                id="inviteByEmail"
                name="inviteMethod"
                value="inviteEmail"
                checked={selectedRadio === "inviteEmail"}
                onChange={() => handleRadioChange("inviteEmail")}
              />
              <label className="form-check-label" htmlFor="inviteByEmail">
                Invite by Email
              </label>
            </div>
            <div className="form-check">
              <input
                type="radio"
                className="form-check-input"
                id="inviteByPhone"
                name="inviteMethod"
                value="invitePhone"
                checked={selectedRadio === "invitePhone"}
                onChange={() => handleRadioChange("invitePhone")}
              />
              <label className="form-check-label" htmlFor="inviteByPhone">
                Invite by Phone Number
              </label>
            </div>
          </div>

          <div
            className="flex-row justify-content-between"
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "12px",
            }}
          >
            <button
              type="button"
              className="btn btn-secondary mx-2"
              onClick={clearData}
              style={{
                borderRadius: "20px",
                width: "7pc",
                fontWeight: "500",
              }}
            >
              CANCEL
            </button>
            <button
              type="button"
              className="btn btn-info mx-2 custom-button"
              onClick={handleCustomerSubmit}
              // disabled={!isFormFilled}
              style={{
                borderRadius: "20px",
                fontWeight: "500",
                color: "white",
              }}
            >
              Add Customer
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddCustomerModal;
