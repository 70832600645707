import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { VixoAPI } from "../../settings";
import axios from "axios";
import Spinner from "../../Spinner";
import { APIHeader, ExpireToken, HasLoggedInUser } from "../../APICallWrapper";
const Customer = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState(null);
  const [error, setError] = useState(null);
  const [ShowLoginScreen, setShowLoginScreen] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [CustomerRedirectHyperlink, setCustomerRedirectHyperlink] = useState(
    ""
  );
  const [CustomerPublicToken, setCustomerPublicToken] = useState("");
  const navigate = useNavigate();
  const [authToken, setAuthToken] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const getAuthTokenFromCookie = () => {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith("VixoCredentials=")) {
        const authToken = cookie.substring("VixoCredentials=".length);
        return authToken;
      }
    }
    return null;
  };
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const userid = params.get("u_id");
    const cusFirstName = params.get("c_fname");
    const cusLastName = params.get("c_lname");
    const cusEmail = params.get("c_email");
    const cusPhone = params.get("c_phone");
    const useremail = params.get("u_email");

    setEmail(params.get("u_email"));
    const authToken = getAuthTokenFromCookie();
    if (!authToken || authToken == null) {
      setShowLoginScreen(true);
    } else {
      setUserId(userid);
      if (useremail != null) {
        loadUser(
          useremail,
          cusFirstName,
          cusLastName,
          cusEmail,
          cusPhone,
          authToken
        );
      }
    }
  }, [location]);
  const csutomerData = (
    organizationId,
    userKey,
    cusFirstName,
    cusLastName,
    cusEmail,
    cusPhone,
    authToken
  ) => {
    const data = {
      Id: 0,
      organizationId: organizationId,
      firstName: cusFirstName,
      lastName: cusLastName,
      email: cusEmail,
      phone: cusPhone,
      UserKey: userKey,
      AuthToken: authToken,
    };

    if (authToken != null && authToken.length > 0) {
     
        try {
          setLoading(true);
          const CustomerPIPath = VixoAPI.CustomerAPI + "getPublicCustomer";
          setLoading(true);
          const config = APIHeader();
          axios
            .post(CustomerPIPath, data, {
              headers: APIHeader().headers,
            })
            .then((response) => {
              var response = response.data;
              if (response.statusCode === 200) {
                setLoading(true);
                var CustomerData = JSON.parse(JSON.stringify(response.data));
                setCustomerPublicToken(CustomerData);
                setCustomerRedirectHyperlink(CustomerData.homeLink);
                window.location.replace(CustomerData.homeLink);
              } else {
                setError(response.Message);
              }
            })
            .catch((error) => {
              setLoading(false);
              throw error;
            })
            .finally(() => {
              setLoading(false);
            });
        } catch (error) {
          setLoading(false);
          throw error;
        }
      }
  };
  const loadUser = (
    userEmail,
    cusFirstName,
    cusLastName,
    cusEmail,
    cusPhone,
    authToken
  ) => {
    if(HasLoggedInUser())
      {
        const config=APIHeader();
      try {
        var encodedUserEmail = encodeURIComponent(userEmail);
        setLoading(true);
        const UserPath = `${VixoAPI.UserAPI}getUserByEmail?Email=${encodedUserEmail}`;
        setLoading(true);
        axios
          .get(UserPath, {
            headers: config.headers,
          })
          .then((response) => {
            var responseData = response.data;
            if (responseData.statusCode === 200) {
              var UserData = JSON.parse(JSON.stringify(responseData.data));
              if (UserData != null) {
                csutomerData(
                  UserData.organizationId,
                  UserData.key,
                  cusFirstName,
                  cusLastName,
                  cusEmail,
                  cusPhone,
                  authToken
                );
              }
            } else {
              setError(responseData.message);
            }
          })
          .catch((error) => {
            setLoading(false);
            if(error.response && error.response.status && error.response.status==401)
              {
                 ExpireToken(navigate);
              }
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
        throw error;
      }
    }
    else{
      ExpireToken(navigate)
    }
  };

  function ReloadAfterLogin(AuthToken) {
    const params = new URLSearchParams(location.search);
    const userid = params.get("u_id");
    const cusFirstName = params.get("c_fname");
    const cusLastName = params.get("c_lname");
    const cusEmail = params.get("c_email");
    const cusPhone = params.get("c_phone");
    const useremail = params.get("u_email");

    setEmail(params.get("u_email"));

    setUserId(userid);
    if (useremail != null) {
      loadUser(
        useremail,
        cusFirstName,
        cusLastName,
        cusEmail,
        cusPhone,
        AuthToken
      );
    }

    const data = {
      Id: 0,
      firstName: cusFirstName,
      lastName: cusLastName,
      email: cusEmail,
      phone: cusPhone,
      AuthToken: AuthToken,
    };

    if (AuthToken != null && AuthToken.length > 0) {
     
        try {
          setLoading(true);
          const CustomerPIPath = VixoAPI.CustomerAPI + "getPublicCustomer";
          setLoading(true);
          axios
            .post(CustomerPIPath, data, {
              headers: APIHeader().headers,
            })
            .then((response) => {
              var response = response.data;
              if (response.statusCode === 200) {
                setLoading(true);
                var CustomerData = JSON.parse(JSON.stringify(response.data));
                //setCustomerPublicToken(CustomerData);
                //setCustomerRedirectHyperlink(CustomerData.homeLink);
                window.location.replace(CustomerData.homeLink);
              } else {
                setError(response.Message);
              }
            })
            .catch((error) => {
              setLoading(false);
              throw error;
            })
            .finally(() => {
              setLoading(false);
            });
        } catch (error) {
          setLoading(false);
          throw error;
        }
      }
  }
  const createAuthToken = async () => {
    const data = {
      UserName: email,
      Password: password,
      AppType: 2,
    };
    
      try {
        setLoading(true);
        const createAuthTokenPath = `${VixoAPI.AuthenticationAPI}createAuthToken`;

        const response = await axios.post(createAuthTokenPath, data);
        var APIResponse = response.data;
        if (APIResponse.statusCode === 200) {
          const vixolinkToken = APIResponse.data;

          try {
            const expirationDate = new Date();
            expirationDate.setDate(expirationDate.getDate() + 30);

            // Save vixolinkToken in VixoCredentials cookie
            document.cookie = `VixoCredentials=${vixolinkToken}; expires=${expirationDate.toUTCString()}; path=/`;
          } catch (error) {
            console.log(error);
          }
          setAuthToken(vixolinkToken);
          ReloadAfterLogin(vixolinkToken);
        } else {
          setError(APIResponse.message);
        }
      } catch (error) {
        setLoading(false);
        throw error;
      } finally {
        setLoading(false);
      }
  };

  return (
    <>
      <Spinner show={loading} />

      <main className="authentication-content">
        <div
          className="container-fluid d-flex align-items-center justify-content-center vh-100"
          style={{ overflow: "hidden" }}
        >
          <div className="authentication-card">
            <div className="card shadow rounded-0 overflow-hidden rounded-1">
              <div className="row g-0">
                <div className="col-lg-12">
                  <div className="card-body  px-4 py-4 ">
                    <form className="form-body">
                      <span className="m-5">
                        {" "}
                        <img
                          src="assets/images/LogoVixo.png"
                          className="logo-icon"
                          alt="logo icon"
                          style={{ width: "180px", height: "101px" }}
                        />
                      </span>
                      <label className="fw-bold mb-2 mt-2">
                        {CustomerPublicToken.address}
                      </label>
                      <label className="fw-bold mb-2 mt-2">
                        {CustomerPublicToken.email}
                      </label>

                      <br />
                      {ShowLoginScreen ? (
                        <>
                          <h3 className="fw-bold">Login for User</h3>
                          <br />
                          <label className="fw-bold mb-2 mt-2">{email}</label>
                          <br />

                          <label className="fw-bold mb-2 mt-2">Password</label>
                          <div className="d-flex align-items-center border border-black rounded">
                            <input
                              style={{
                                width: "85%",
                                padding: "4px 3px",
                                outline: "none",
                                border: "none",
                                backgroundColor: "white",
                              }}
                              type={showPassword ? "text" : "password"}
                              name="password"
                              placeholder="Enter Password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            <span
                              className="input-group-text text-end"
                              style={{ cursor: "pointer" }}
                              onClick={() => toggleShowPassword()}
                            >
                              {showPassword ? (
                                <span role="img" aria-label="Visible">
                                  👁️
                                </span>
                              ) : (
                                <span role="img" aria-label="Hidden">
                                  🙈
                                </span>
                              )}
                            </span>
                          </div>
                          <p className="fw-bold mb-3 text-danger text-center">
                            {error ? error : ""}
                          </p>
                          <button
                            className="btn btn-primary form-control  mt-5"
                            onClick={(e) => {
                              e.preventDefault();
                              createAuthToken();
                            }}
                          >
                            Submit
                          </button>
                        </>
                      ) : (
                        ""
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Customer;
