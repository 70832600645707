import React, { useState, useEffect } from "react";
import TopnavBar from "../Header/TopnavBar";
import { Link, useNavigate } from "react-router-dom";
import OrganizationSettingBar from "../Header/OrganizationSettingBar";
import { format } from "date-fns";
import Breadcrumbs from "../HomeVixo/Breadcrumbs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGears } from "@fortawesome/free-solid-svg-icons";
import DateComponent from "../../VixoFormats/Date";
import Money from "../../VixoFormats/Money";
import axios from "axios";
import Spinner from "../../Spinner";
import { VixoAPI } from "../../settings";
import { APIHeader, ExpireToken, HasLoggedInUser } from "../../APICallWrapper";
import { CheckAllowComponentAndRedirect, IsRoleAllowed } from "../../Authentication";

const ActivityLog = () => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [billingHistory, setBillingHistory] = useState([]);
  const [filteredBillingHistory, setFilteredBillingHistory] = useState([]);
  const [errorType, setErrorType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    LoadData();
  }, []);

  useEffect(() => {
    if (searchQuery.trim() === "") {
      setFilteredBillingHistory(billingHistory);
    } else {
      const filteredData = billingHistory.filter((item) =>
        Object.values(item).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
      setFilteredBillingHistory(filteredData);
    }
  }, [searchQuery, billingHistory]);

  const LoadData = () => {


    const loggedInUser = localStorage.getItem("User");
    var userObject = JSON.parse(loggedInUser);

    const ComponentName="User Administration";
     CheckAllowComponentAndRedirect(ComponentName, navigate)
    if (userObject && userObject !== null) {

      LoadExistingBillingHistory(userObject.organizationId);
    } else {
      navigate("/");
    }
  };


  const LoadExistingBillingHistory = async (OrganizationID) => {
    setLoading(true);
    
    const data = { organizationID: OrganizationID };
    if(HasLoggedInUser()){
    const config= APIHeader();
    try {
      const ActivityLogAPI =
        VixoAPI.BillingAPI + "GetOrganizationBillingDetail";
      const response = await axios.post(ActivityLogAPI, data,{
       headers:config.headers
      });
      const responseData = response.data;

      if (responseData.statusCode === 200) {
        setBillingHistory(responseData.data);
        setLoading(false);
      } else {
        setErrorType(responseData.message);
      }
    } catch (error) {
      setErrorType(error.message);
      setLoading(false);
    }
  }
  else{

    ExpireToken(navigate)
  }
  };

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };
  const sidebarStyle = {
    marginLeft: collapsed ? "" : "250px",
  };

  return (
    <>
      <Spinner show={loading} />
      <TopnavBar />
      <OrganizationSettingBar
        collapsed={collapsed}
        toggleSidebar={toggleSidebar}
      />

      <main className="page" style={sidebarStyle}>
        <div className="container-fluid  p-3 bg-white col-lg-12 col-sm-12">
          <div className="row">
            <div className="col-md-12 mx-auto">
              <div className="card">
                <div className="card-header py-3 bg-transparent">
                  <h5 className="mb-0 d-inline">Setting</h5>
                  <FontAwesomeIcon
                    icon={faGears}
                    className="fa-2x "
                    style={{ width: "25px" }}
                  />

                  <Breadcrumbs />
                </div>

                <div className="card-header py-3 bg-transparent">
                  <div class="row">
                    <div class="col-sm-8">
                      <h4>ActivityLog</h4>
                    </div>
                    <div class="col-sm-4">
                      <form class="form-inline my-2 my-lg-0">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search"
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                      </form>
                    </div>
                  </div>
                </div>

                <div className="">
                  <div className="table-responsive">
                    <table
                      id="example"
                      className="table table-striped"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>CustomerId</th>
                          <th>Customer Name</th>
                          <th>Vixo User Id</th>
                          <th>User Name</th>
                          <th>Description</th>
                           <th>Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredBillingHistory.map((item, index) => (
                          <tr key={index}>
                            <td>
                              {/* <DateComponent value={item.date} /> */}
                              {format(new Date(item.date), "yyyy-MM-dd")}
                            </td>
                            <td>
                              <Link
                                target="_blank"
                                to={`${VixoAPI.CustomeDashboard}home?id=${item.customerKey}`}
                                style={{
                                  cursor: "pointer",
                                  textDecoration: "none",
                                  color: "black",
                                }}
                              >
                                {item.customerId}
                              </Link>
                            </td>
                            <td style={{ whiteSpace: "pre-wrap" }}>
                              {" "}
                              <Link
                                target="_blank"
                                to={`${VixoAPI.CustomeDashboard}home?id=${item.customerKey}`}
                                style={{
                                  cursor: "pointer",
                                  textDecoration: "none",
                                  color: "black",
                                }}
                              >
                                {item.customerIdentity === null
                                  ? "N/A"
                                  : item.customerIdentity}{" "}
                              </Link>
                            </td>
                            <td>
                              {item.userId === item.customerId ? (
                                <>N/A</>
                              ) : (
                                <>{item.userId}</>
                              )}
                            </td>
                            <td>
                              {item.userName === null ? "N/A" : item.userName}{" "}
                            </td>
                            <td style={{ whiteSpace: "pre-wrap" }}>
                              {item.eventName}
                            </td>

                            <td>
                              <Money value={item.price} />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ActivityLog;
