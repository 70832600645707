
import { configureStore } from '@reduxjs/toolkit';
import sendNotificationSlice from '../Slices/sendNotificationSlice';
 import UserSlice from '../Slices/UserSlice';
const store = configureStore({
  reducer: {
    notification: sendNotificationSlice,
    auth: UserSlice,
  },
});

window.addEventListener("storage", (event) => {
  if (event.key === "logout") {
    // Broadcast a custom event to trigger reload in all tabs
    window.dispatchEvent(new Event("reloadAllTabs"));
  }
});

window.addEventListener("storage", (event) => {
  if (event.key === "User") {
    // Broadcast a custom event to trigger reload in all tabs
    window.dispatchEvent(new Event("reloadAllTabsLogin"));
  }
});



export default store;