import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export function APIHeaderForLookup()
{
  
    const config = {
      headers: {
      Authorization: `Bearer ${""}`
      }
    }
      return config;

}

export function APIHeader()
{
  
  const loggedInUser = localStorage.getItem("User");
  var userObject = JSON.parse(loggedInUser);
  if(userObject && userObject!=null && userObject.authToken!=null)
  {
    var accesstoken=  userObject.authToken;
    const config = {
        headers: {
        Authorization: `Bearer ${accesstoken}`
        }
    };
    return config;
}

}
export function HasLoggedInUser(UserID)
{
  var loggedInUser = localStorage.getItem("User");
  var userObject = JSON.parse(loggedInUser);
  if(userObject && userObject!=null && userObject.authToken!=null)
  {
    return true;
  }
  return false;
}
export function  ExpireToken(navigate)
{
  
  localStorage.removeItem("User");
  navigate("/");
    
 
}