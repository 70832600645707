import React from "react";
import axios from "axios";
import Sidebar from "../Header/SideBar";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InstituteTypes } from "../../lookup";
import ErrorComponent from "../../ErrorComponent";
import Spinner from "../../Spinner";
import { VixoAPI } from "../../settings";
import { APIHeader, ExpireToken, HasLoggedInUser } from "../../APICallWrapper";
import Breadcrumbs from "./Breadcrumbs";
function AddBankAccountFile(props) {
  const btn = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "15px",
  };

  const [collapsed, setCollapsed] = useState(false);
  const [processingFile, setProcessingFile] = useState(false);

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };
  const sidebarStyle = {
    width: collapsed ? "100%" : "82%",
    marginLeft: collapsed ? "" : "250px",
  };
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  var CusKey = params.get("key");
  const [addAccount, setBankAccount] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [errorType, setErrorType] = useState(null);

  const [formData, setFormData] = useState({
    instituteName: 0,
    accountTitle: "",
    accountType: "",
    currency: "",
    ownerName: "",
    ownerAddress: "",
    routingNumber: "",
    accountNumber: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const parsedValue = name === "instituteName" ? parseInt(value, 10) : value;

    setFormData({
      ...formData,
      [name]: parsedValue,
    });
  };
  const [InstituteRoles, setInstituteRoles] = useState([]);

  useEffect(() => {
    LoadLookups();
  }, []);

  async function LoadLookups() {
    try {
      InstituteTypes()
        .then((result) => {
          setInstituteRoles(result);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (ex) {
      console.error(ex + " InstituteType Role Lookup Call is not successfull");
    }
  }

  const handleAddAccount = async (e) => {
    e.preventDefault();
    const data = {
      InstitutionId: parseInt(formData.instituteName, 10),
      accountTitle: formData.accountTitle,
      accountType: formData.accountType,
      currency: formData.currency,
      ownerName: formData.ownerName,
      ownerAddress: formData.ownerAddress,
      routingNumber: formData.routingNumber,
      accountNumber: formData.accountNumber,
      OcrtypeId: 4,
      ConnectionId: 4,
    };
    if (HasLoggedInUser()) {
      try {
        const CreateAccountAPIPath =
          VixoAPI.AccountAPI + "create?Key=" + CusKey; // Replace with your actual API URL
        setLoading(true);
        axios
          .post(CreateAccountAPIPath, data, {
            headers: APIHeader().headers,
          })
          .then((response) => {
            var response = response.data;
            if (response.statusCode === 200) {
              toast.success(response.message);
              clearData();
              setBankAccount(false);
              setBankOcrAccount(false);
            } else {
              toast(response.message);
            }
          })
          .catch((error) => {
            setLoading(false);
            throw error;
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (error) {
        throw error;
      }
    } else {
      navigate("/");
    }
  };

  function clearData() {
    setFormData({
      instituteName: 0,
      accountTitle: "",
      accountType: "",
      currency: "",
      ownerName: "",
      ownerAddress: "",
      routingNumber: "",
      accountNumber: "",
    });
  }
  const [addOcrAccount, setBankOcrAccount] = React.useState(false);
  const handleDocument = async (e) => {
    e.preventDefault();
    const selectedFiles = e.target.files;
    if (!selectedFiles || selectedFiles.length === 0) {
      alert("Please select one or more files.");
      return;
    }
    const data = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      data.append("Files", selectedFiles[i]);
    }
    if (HasLoggedInUser()) {
      const config= APIHeader();
      try {
        setProcessingFile(true);
        const OcrBankAPI = VixoAPI.OCRAPI + "getocraccount";
        axios
          .post(OcrBankAPI, data, {
            headers:config.headers,
          })
          .then((response) => {
            var response = response.data;
            if (response.statusCode === 200) {
              setOcrBank(response.data);
            } else {
              toast(response.message);
            }
          })
          .catch((error) => {
            setLoading(false);
            if(error.response && error.response.status && error.response.status==401)
              {
                ExpireToken(navigate);
              }
          })
          .finally(() => {
            setProcessingFile(false);
          });
      } catch (error) {
        throw error;
      }
    } else {
      ExpireToken(navigate)
    }
  };
  function setOcrBank(Ocrbankdata) {
    try {
      setLoading(true);
      var OcrBankdata = JSON.parse(JSON.stringify(Ocrbankdata));
      setFormData(OcrBankdata);
      setLoading(false);
      setBankOcrAccount(true);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  }
  return (
    <>
      {errorType && <ErrorComponent errorType={errorType} />}
      <Spinner show={loading} />
      <ToastContainer />

      {/* <Sidebar collapsed={collapsed} toggleSidebar={toggleSidebar} /> */}

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={addAccount}
        onHide={setBankAccount}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add An Account
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorType && <ErrorComponent errorType={errorType} />}
          <Spinner show={loading} />
          <p>
            <div className="modal-body">
              <form className="row g-3" onSubmit={handleAddAccount}>
                <div className="col-12">
                  <label className="form-label">Institute Name</label>
                  <select
                    className="form-select"
                    name="instituteName"
                    value={formData.instituteName}
                    onChange={handleChange}
                  >
                    <option value={0}>Select Institutes</option>
                    {InstituteRoles != null &&
                      InstituteRoles.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.displayValue}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-12">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Account title"
                    name="accountTitle"
                    value={formData.accountTitle}
                    onChange={handleChange}
                  />
                </div>
                <div class="col-12">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Account type"
                    name="accountType"
                    value={formData.accountType}
                    onChange={handleChange}
                  />
                </div>
                <div class="col-12">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Currency"
                    name="currency"
                    value={formData.currency}
                    onChange={handleChange}
                  />
                </div>
                <div class="col-12">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Owner Name"
                    name="ownerName"
                    value={formData.ownerName}
                    onChange={handleChange}
                  />
                </div>
                <div class="col-12">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Owner Address"
                    name="ownerAddress"
                    value={formData.ownerAddress}
                    onChange={handleChange}
                  />
                </div>
                <div class="col-12">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Routing Number"
                    name="routingNumber"
                    value={formData.routingNumber}
                    onChange={handleChange}
                  />
                </div>
                <div class="col-12">
                  <div class="row g-3">
                    <div class="col-lg-12">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Account Number"
                        name="accountNumber"
                        value={formData.accountNumber}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div class="col-12  ">
                  <button
                    className="btn btn-info float-end"
                    style={{
                      marginRight: "10px",
                      borderRadius: "20px",
                      color: "white",
                      fontWeight: "500",
                    }}
                    type="submit"
                  >
                    Add Account
                  </button>
                </div>
              </form>
            </div>
          </p>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={addOcrAccount}
        onHide={setBankOcrAccount}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add An Account
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorType && <ErrorComponent errorType={errorType} />}
          <Spinner show={loading} />
          <p>
            <div className="modal-body">
              <form className="row g-3" onSubmit={handleAddAccount}>
                <div className="col-12">
                  <label className="form-label">Institute Name</label>
                  <select
                    className="form-select"
                    name="instituteName"
                    value={formData.instituteName}
                    onChange={handleChange}
                  >
                    <option value={0}>Select Institutes</option>
                    {InstituteRoles != null &&
                      InstituteRoles.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.displayValue}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-12">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Account title"
                    name="accountTitle"
                    value={formData.accountTitle}
                    onChange={handleChange}
                  />
                </div>
                <div class="col-12">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Account type"
                    name="accountType"
                    value={formData.accountType}
                    onChange={handleChange}
                  />
                </div>
                <div class="col-12">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Currency"
                    name="currency"
                    value={formData.currency}
                    onChange={handleChange}
                  />
                </div>
                <div class="col-12">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Owner Name"
                    name="ownerName"
                    value={formData.ownerName}
                    onChange={handleChange}
                  />
                </div>
                <div class="col-12">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Owner Address"
                    name="ownerAddress"
                    value={formData.ownerAddress}
                    onChange={handleChange}
                  />
                </div>
                <div class="col-12">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Routing Number"
                    name="routingNumber"
                    value={formData.routingNumber}
                    onChange={handleChange}
                  />
                </div>
                <div class="col-12">
                  <div class="row g-3">
                    <div class="col-lg-12">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Account Number"
                        name="accountNumber"
                        value={formData.accountNumber}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div class="col-12  ">
                  <button
                    className="btn btn-primary float-end"
                    style={{ padding: "10px 20px", marginRight: "10px" }}
                    type="submit"
                  >
                    Add Account
                  </button>
                </div>
              </form>
            </div>
          </p>
        </Modal.Body>
      </Modal>

      <main className="Invitee" style={sidebarStyle}>
        <div className="container-fluid shadow p-3 bg-white col-lg-12 col-sm-12">
          <div className="row">
            <div className="col-md-12 mx-auto">
              <div className="card">
                <p className="m-2">
                  {" "}
                  <Breadcrumbs />
                </p>
                <div className="card-header py-3 bg-transparent ">
                  <h3 style={btn}>
                    {" "}
                    <br />
                  </h3>
                  <form>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <div className=" " style={btn}>
                            <Button
                              variant="info"
                              onClick={() => setBankAccount(true)}
                              style={{
                                borderRadius: "20px",
                                color: "white",
                                fontWeight: "500",
                              }}
                            >
                              Add Bank Account
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="row text-center mb-5 mt-5">
                    <p className="mb-3">OR</p>
                    <h4> Create Bank Account Using Bank Statement</h4>
                    <div className="documentinput mb-5 mt-5">
                      <input
                        className="form-control form-control-lg"
                        id="formFileLg"
                        type="file"
                        onChange={handleDocument}
                        multiple
                      />
                    </div>
                    {processingFile && <Spinner show={true} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default AddBankAccountFile;
